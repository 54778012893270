import { CloseButton, FormControl, FormLabel, Grid, GridItem, Heading, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid } from "@chakra-ui/react";
import { yupResolver } from '@hookform/resolvers/yup';
import { Input } from 'components/form/input';
import { Loading } from 'components/Loading';
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Button } from "reactstrap";
import { api } from "services/api";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as Yup from 'yup';
import { apiFetch } from '../../../../components/util/entity-utils';

type CriarClienteProps = {
  isNew: boolean;
  isOpen: boolean;
  onClose: () => void;
  dataToEdit?: any;
};

type CreateClienteFormData = {
  cliente: string
  sgCliente: string
  nomeContato: string
  telefoneContato: string
  emailContato: string
  cep: string
  rua: string
  numero: string
  complemento: string
  municipio: string
  bairro: string
}

const requiredFieldText = 'Campo obrigatório'

const schema = Yup.object().shape({
  cliente: Yup.string().required(requiredFieldText),
  sgCliente: Yup.string().required(requiredFieldText),
  nomeContato: Yup.string().required(requiredFieldText),
  telefoneContato: Yup.string().required(requiredFieldText),
  emailContato: Yup.string()
    .email('Insira um e-mail válido')
    .required(requiredFieldText),
  cep: Yup.string().required(requiredFieldText),
  rua: Yup.string().required(requiredFieldText),
  numero: Yup.string().required(requiredFieldText),
  municipio: Yup.string().required(requiredFieldText),
  bairro: Yup.string().required(requiredFieldText),
})

export const ModalCriarCliente = ({
  isNew,
  isOpen,
  onClose,
  dataToEdit,
}: CriarClienteProps) => {
  const [loading, setLoading] = useState(false);
  const [sgIndisponivel, setSgIndisponivel] = useState('');
  const [emailIndisponivel, setEmailIndisponivel] = useState('');
  const MySwal = withReactContent(Swal);

  const formMethods = useForm<CreateClienteFormData>({
    resolver: yupResolver(schema),
    defaultValues: dataToEdit,
  });

  const handleRegisterCliente: SubmitHandler<CreateClienteFormData> = async (formData) => {

    try {
      const { data } = await api.post('aswcliente', {
        method: isNew ? '_insert' : '_update',
        ...formData,
        endereco: formData.rua,
      })

      setLoading(false)
      if (data.message == "Nada encontrado") {
        MySwal.fire({
          title: 'Email já cadastrado',
          icon: "warning",
        })
        setEmailIndisponivel('Email indisponível')
      } else {
        onCloseEditModal();
        MySwal.fire({
          title:
            data?.message ||
            (!!data?.success
              ? 'Cadastro efetuado com sucesso'
              : 'Não foi possível cadastar'),
          icon: !!data.success ? 'success' : 'warning',
        })
        setTimeout(() => {
          location.reload()
        }, 2000);

      }
    } catch (err) {
      onCloseEditModal();
      MySwal.fire({
        title: isNew
          ? 'Houve um erro ao tentar cadastar'
          : 'Não foi possível editar',
        icon: 'error',
      })
      setTimeout(() => {
        location.reload()
      }, 2000);
    }
  }

  const onCloseEditModal = () => {
    formMethods.reset();
    setEmailIndisponivel(' ');
    setSgIndisponivel('');
    onClose()
  }

  const verificarSg = async (value) => {
    const res: {
      data: any;
      success?: boolean;
      message?: string;
    } = await apiFetch('aswcliente', {
      method: '_find',
      body: {
        sgCliente: value,
      },

    });
    if (res.success == true) {
      setSgIndisponivel('Sigla indisponível')
    } else {
      setSgIndisponivel(' ')
    }

  }
  useEffect(() => {
    const loadDataToEdit = () => {
      formMethods.setValue('cliente', dataToEdit.cliente);
      formMethods.setValue('sgCliente', dataToEdit.sgCliente);
      formMethods.setValue('nomeContato', dataToEdit.nomeContato);
      formMethods.setValue('telefoneContato', dataToEdit.telefoneContato);
      formMethods.setValue('emailContato', dataToEdit.emailContato);
      formMethods.setValue('cep', dataToEdit.cep);
      formMethods.setValue('rua', dataToEdit.rua);
      formMethods.setValue('numero', dataToEdit.numero);
      formMethods.setValue('complemento', dataToEdit.complemento);
      formMethods.setValue('municipio', dataToEdit.municipio);
      formMethods.setValue('bairro', dataToEdit.bairro);
    }
    if (!isNew) {
      loadDataToEdit()
    }
  }, [dataToEdit, isNew])
  return (
    <div>
      <Modal
        isOpen={isOpen}
        onClose={onCloseEditModal}
        motionPreset="slideInBottom"
        size={'4xl'}
      >
        <ModalOverlay />
        <ModalContent tabIndex={150}>
          <ModalHeader>
            <Grid templateColumns='repeat(5, 1fr)' gap={1}>
              <GridItem colStart={6} colEnd={6} h='10'>
                <CloseButton size='sm' onClick={() => onClose()} />
              </GridItem>
            </Grid>
            <Heading fontSize={'md'} fontWeight={400}>
              {isNew ? 'Cadastrar novo cliente' : 'Editar cliente'}
            </Heading>
          </ModalHeader>
          <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(handleRegisterCliente)}>
              <ModalBody>

                <SimpleGrid columns={2} spacingX="15px" spacingY="15px">
                  <FormControl>
                    <FormLabel>Cliente*</FormLabel>
                    <Input
                      id="cadastro-cliente-nome"
                      type="text"
                      name='cliente'
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Sigla*</FormLabel>
                    <Input
                      id="cadastro-cliente-nome"
                      type="text"
                      name="sgCliente"
                      onChange={(e) => verificarSg(e.target.value)}
                    />
                    <p className="text-warning"><small>{sgIndisponivel}</small></p>

                  </FormControl>
                  <FormControl>
                    <FormLabel>Nome do contato*</FormLabel>
                    <Input
                      id="cadastro-cliente-nome"
                      type="text"
                      name='nomeContato'
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Telefone do contato*</FormLabel>
                    <Input
                      id="cadastro-cliente-nome"
                      name="telefoneContato"
                      mask={"phone"}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>E-mail do contato*</FormLabel>
                    <Input
                      id="cadastro-cliente-nome"
                      type="text"
                      name="emailContato"
                      onChange={(event) => {
                        const { value } = event.target;
                        event.persist();
                        formMethods.setValue('emailContato', value);
                      }}
                    />
                    <p className="text-warning"><small>{emailIndisponivel}</small></p>
                  </FormControl>
                  <FormControl>
                    <FormLabel>CEP*</FormLabel>
                    <Input
                      id="cadastro-cliente-nome"
                      type="text"
                      name="cep"
                      mask={"cep"}
                      onChange={(event) => {
                        const { value } = event.target;
                        event.persist();
                        formMethods.setValue('cep', value);
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Rua*</FormLabel>
                    <Input
                      id="cadastro-cliente-nome"
                      type="text"
                      name="rua"
                      onChange={(event) => {
                        const { value } = event.target;
                        event.persist();
                        formMethods.setValue('rua', value);
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Número*</FormLabel>
                    <Input
                      id="cadastro-cliente-nome"
                      type="text"
                      name="numero"
                      onChange={(event) => {
                        const { value } = event.target;
                        event.persist();
                        formMethods.setValue('numero', value);
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Complemento</FormLabel>
                    <Input
                      id="cadastro-cliente-nome"
                      type="text"
                      name="complemento"
                    />
                    <span className="text-warning">
                    </span>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Município*</FormLabel>
                    <Input
                      id="cadastro-cliente-nome"
                      type="text"
                      name='municipio'
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Bairro*</FormLabel>
                    <Input
                      id="cadastro-cliente-nome"
                      type="text"
                      name="bairro"
                    />
                  </FormControl>
                </SimpleGrid>

              </ModalBody>
              <ModalFooter>
                <Button color="primary" type="submit" className="float-right" disabled={sgIndisponivel == 'Sigla indisponível' || emailIndisponivel == 'Email indisponível' ? true : false}>
                  {isNew ? 'Cadastrar Cliente' : 'Salvar alterações'}
                </Button>
              </ModalFooter>
            </form>
          </FormProvider>
          {loading && <Loading />}
        </ModalContent>

      </Modal>

    </div>
  )
};

export default ModalCriarCliente;
