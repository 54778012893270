import {
  Box,
  Flex,
  HStack,
  IconButton,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Button, Input } from 'reactstrap';
import { dateTimeToScreen, dateToScreen } from 'shared/util';
import cpfMask from 'shared/util/cpfMask';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { ConsultaItemType } from 'types/consulta';

const MySwal = withReactContent(Swal);

type Props = {
  consulta: ConsultaItemType;
  aprovar: () => void;
  cancelar: () => void;
};

export const ModalDetalhe = ({ consulta, aprovar, cancelar }: Props) => {
  const colunas = [
    {
      key: 'Agendamento',
      value: dateTimeToScreen(consulta.dataHora),
    },
    {
      key: 'Paciente',
      value: consulta.pacienteNome,
    },
    {
      key: 'CPF',
      value: cpfMask(consulta.pacienteCPF),
    },
    {
      key: 'N° Carteirinha / Validade',
      value: `${consulta.numeroCartao} - ${dateTimeToScreen(
        consulta.validadeCartao
      )}`,
    },
    {
      key: 'Nome Profissional de Saúde',
      value: consulta.psNome,
    },
    {
      key: 'Especialidade',
      value: consulta.descricaoPS,
    },
    {
      key: 'Convênio / Plano',
      value: consulta.planoConvenio,
    },
  ];
  return (
    <Stack minW="40">
      <Flex w="full" justify="space-between" align="flex-start" mb="10">
        <Text fontSize="x-large">{dateToScreen(consulta.dataHora)}</Text>
        <IconButton
          aria-label="close"
          onClick={() => {
            MySwal.close();
          }}
        >
          <i className="fa fa-times"></i>
        </IconButton>
      </Flex>
      <Text fontWeight="bold" textAlign="left" pb="8">
        Paciente
      </Text>
      <HStack spacing={12}>
        <ImageAndLabel
          src={
            consulta?.pacienteFoto
              ? consulta?.pacienteFoto
              : '/images/imageNotFound.svg'
          }
          label="Rosto do paciente"
        />
        <ImageAndLabel
          src={
            consulta?.pacienteDocFront
              ? consulta?.pacienteDocFront
              : '/images/imageNotFound.svg'
          }
          label="Frente do documento"
        />
        <ImageAndLabel
          src={
            consulta?.pacienteDocBack
              ? consulta?.pacienteDocBack
              : '/images/imageNotFound.svg'
          }
          label="Verso do documento"
        />
      </HStack>
      <SimpleGrid columns={2} spacing={10} pb="16">
        <Box>
          <Text align="initial" fontSize="13px">
            Nome do paciente
          </Text>
          <Input disabled value={consulta?.pacienteNome} />
        </Box>
        <Box>
          <Text align="initial" fontSize="13px">
            CPF
          </Text>
          <Input disabled value={consulta?.pacienteCPF} />
        </Box>
      </SimpleGrid>
      <SimpleGrid columns={2} spacing={10} pb="16">
        <Box>
          <Text align="initial" fontSize="13px">
            nº da Carteirinha
          </Text>
          <Input disabled value={consulta?.numeroCartao} />
        </Box>
        <Box>
          <Text align="initial" fontSize="13px">
            Validade da Carteirinha
          </Text>
          <Input
            disabled
            value={
              consulta?.validadeCartao
                ? dateToScreen(consulta?.validadeCartao)
                : ''
            }
          />
        </Box>
      </SimpleGrid>
      <SimpleGrid columns={2} spacing={10} pb="16">
        <Box>
          <Text align="initial" fontSize="13px">
            Convênio
          </Text>
          <Input disabled value={consulta?.convenio} />
        </Box>
        <Box>
          <Text align="initial" fontSize="13px">
            Plano
          </Text>
          <Input disabled value={consulta?.planoConvenio} />
        </Box>
      </SimpleGrid>
      <Text fontWeight="700" textAlign="left" pb="4">
        Profissional de Saúde
      </Text>
      <SimpleGrid columns={2} spacing={10}>
        <Box>
          <Text align="initial" fontSize="13px">
            Nome Profissional
          </Text>
          <Input disabled value={consulta?.psNome} />
        </Box>
        <Box mb="15">
          <Text align="initial" fontSize="13px">
            Especialidade
          </Text>
          <Input disabled value={consulta.descricaoPS} />
        </Box>
      </SimpleGrid>
      <Flex width="full" alignItems="center" justify="flex-end">
        <HStack mt="4">
          <Button
            color="primary"
            style={{ borderRadius: '10px', padding: '5px 30px' }}
            onClick={aprovar}
          >
            Aprovar
          </Button>
          <Button
            outline
            color="secondary"
            style={{ borderRadius: '10px', padding: '5px 30px' }}
            onClick={cancelar}
          >
            Negar
          </Button>
        </HStack>
      </Flex>
    </Stack>
  );
};

const ImageAndLabel = ({ src, label }: { src: string; label: string }) => {
  return (
    <Flex direction="column" align="center" justify="center" maxW="90px">
      <Image alt="image" src={src} w="86px" h="84px" />
      <Text py="4" fontSize="12px">
        {label}
      </Text>
    </Flex>
  );
};
