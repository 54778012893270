import { Input, } from "components/form/input";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Modal, ModalFooter, ModalHeader, Row } from "reactstrap";
import { SintomasType } from "./types";

type Props = {
    isEditable: boolean;
    dados: any;
    close: (value: boolean) => void;
    formSintomasGripais: (value: SintomasType) => void;
};

export const RenderModalSintomasGripais = (props: Props) => {

    const [habilitarSintomas, setHabilitarSintomas] = useState<boolean>(true);

    const formMethods = useForm<SintomasType>({
        defaultValues: {},
    })

    const handleFormSubmit = async (data: SintomasType) => {
        props.formSintomasGripais(data);
        props.close(false);
    }

    return (
        <Modal
            isOpen={true}
            className="sindrome-gripal-modal"
            toggle={() => props.close(false)}
        >
            <ModalHeader toggle={() => props.close(false)}><p className="text-secondary">Sintomas Gripais</p></ModalHeader>
            <FormProvider {...formMethods}>
                <form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
                    <Row className='align-items-center row mx-1'>
                        <div className="d-flex ">
                            <Input name="tosse" checked={props.dados?.huTriagSGTosse} type='checkbox' className='form-check-input mr-5' disabled={!props.isEditable} />
                            <p className='basic-text mx-2'>Tosse</p>
                        </div>

                        <div className="d-flex ">
                            <Input name="coriza" checked={props.dados?.huTriagSGCoriza} type='checkbox' className='form-check-input mr-5' disabled={!props.isEditable} />
                            <p className='basic-text mx-2'>Coriza</p>
                        </div>

                        <div className="d-flex ">
                            <Input name="dorGarganta" checked={props.dados?.huTriagSGDorGarganta} type='checkbox' className='form-check-input mr-5' disabled={!props.isEditable} />
                            <p className='basic-text mx-2'>Dor de Garganta</p>
                        </div>

                        <div className="d-flex ">
                            <Input name="dorCabeca" checked={props.dados?.huTriagSGDorCabeca} type='checkbox' className='form-check-input mr-5' disabled={!props.isEditable} />
                            <p className='basic-text mx-2'>Dor de Cabeça</p>
                        </div>

                        <div className="d-flex ">
                            <Input name="espirros" checked={props.dados?.huTriagSGEspirros} type='checkbox' className='form-check-input mr-5' disabled={!props.isEditable} />
                            <p className='basic-text mx-2'>Espirros</p>
                        </div>

                        <div className="d-flex ">
                            <Input name="dorCostas" checked={props.dados?.huTriagSGDorCostas} type='checkbox' className='form-check-input mr-5' disabled={!props.isEditable} />
                            <p className='basic-text mx-2'>Dor nas Costas</p>
                        </div>

                        <div className="d-flex ">
                            <Input name="diarreia" checked={props.dados?.huTriagSGDiarreia} type='checkbox' className='form-check-input mr-5' disabled={!props.isEditable} />
                            <p className='basic-text mx-2'>Diarreia</p>
                        </div>

                        <div className="d-flex ">
                            <Input name="febre" checked={props.dados?.huTriagSGFebre} type='checkbox' className='form-check-input mr-5' disabled={!props.isEditable} />
                            <p className='basic-text mx-2'>Febre</p>
                        </div>
                        <div className="d-flex ">
                            <Input name="outrosSintomas" type='checkbox' className='form-check-input mr-5' onChange={() => setHabilitarSintomas(!habilitarSintomas)} disabled={!props.isEditable} />
                            <p className='basic-text mx-2'>Outros sintomas</p>
                        </div>
                        <label>
                            <small>Quais?</small>
                            <Input name="sintomas" value={props.dados?.huTriagSGOutros} disabled={props.isEditable ? habilitarSintomas : !props.isEditable} className='fit-space p-4 form-control' />
                        </label>
                    </Row>
                    <ModalFooter className='border-top'>
                        {props.isEditable &&
                            <div className='fit-space'>
                                <Button
                                    type="submit"
                                    color="primary"
                                    className='btn-outline-primary fit-space'
                                    disabled={!props.isEditable}
                                >
                                    <strong>
                                        Confirmar
                                    </strong>
                                </Button>
                            </div>
                        }
                    </ModalFooter>
                </form>
            </FormProvider>
        </Modal>
    )
}
