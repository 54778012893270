export const colors = {
  // blue: '#56a1e1',
  blue: '#56a1e1', //hospital do amor
  yellow: '#ffc107',
  orange: '#FFB200',
  textBlue: '#2767a0',
  danger: '#e70015',
  green: '#008000',
  green_2: '#3D8361',
  gray_blue: '#edf2f7',
  gray: '#808080',
  gray_2: '#595959',
  textGray: '#808080', //hospital do amor
  darkBlue: '#2E2E6F', //hospital do amor
};
