import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';
import { BsPlusCircleFill } from 'react-icons/bs';
import { Spinner } from 'reactstrap';

import { CirurgiasType, ResultExamesType } from 'contexts/Consulta/types';
import { useConsultaEmergencialContext } from 'contexts/ConsultaEmergencial';
import { useAlert, useApiRequest } from 'hooks';

import { DateInputForm, DateInputFormRefProps } from '../date-input-form';

type ButtonTextProps = {
  type: 'confirm' | 'cancel'
}

const ButtonText = ({ type }: ButtonTextProps) => (
  <>
    {' '}
    <i className={type === 'cancel' ? 'fa fa-ban' : 'fa fa-trash'} />{' '}
    {type === 'cancel' ? 'Cancelar' : 'Apagar'}{' '}
  </>
)

export const CirurgiasExames = () => {
  const alert = useAlert()
  const { consulta: dadosConsulta } = useConsultaEmergencialContext()

  const cirurgiasApi = useApiRequest<CirurgiasType[]>(
    'aswusuprontcirurgias',
    []
  )
  const examesApi = useApiRequest<ResultExamesType[]>(
    'aswusuprontresultexames',
    []
  )

  const loadCirurgiasExames = () => {
    if (dadosConsulta != undefined) {
      cirurgiasApi.request({
        method: '_list',
        usuarioCPF: dadosConsulta.dadosConsulta.usuarioCPF,
      });
      examesApi.request({
        method: '_list',
        usuarioCPF: dadosConsulta.dadosConsulta.usuarioCPF,
      });
    }
  }

  const cirurgiasRef = useRef<DateInputFormRefProps>(null)
  const examesRef = useRef<DateInputFormRefProps>(null)

  const actionModal = (
    title: string,
    text: string,
    onConfirm: () => Promise<void>
  ) => {
    alert
      .warning({
        title: title,
        html: text,
        showCancelButton: true,
        cancelButtonText: <ButtonText type="cancel" />,
        confirmButtonText: <ButtonText type="confirm" />,
      })
      .then(async (response) => {
        if (response.isConfirmed) {
          await onConfirm()
          alert.success({
            title: 'Apagado com successo',
          })
        }
      })
  }


  useEffect(() => {
    loadCirurgiasExames();
  }, [dadosConsulta]);

  return (
    <Box>
      <Box marginBottom={'16px'}>
        <Box>
          <Flex width={'100%'} alignItems={'center'}>
            <Text flex={'1'} fontSize={'md'} fontWeight={'normal'} color={'gray.600'}>
              Cirurgias Prévias{' '}
              {cirurgiasApi.isLoading && <Spinner size="sm" />}
            </Text>
            <Button
              variant={'outline'}
              leftIcon={<BsPlusCircleFill />}
              border={'none'}
              color={'blue.400'}
              _hover={{
                bg: 'transparent'
              }}
              _focus={{
                outline: 'none'
              }}
              onClick={() => {
                cirurgiasRef?.current?.open()
              }}
            >
              Adicionar
            </Button>
          </Flex>
          <Box>
            <div className="form-control">
              {cirurgiasApi.state.map((item, index) => {
                return (
                  <DateInputForm
                    originalData={item}
                    key={item.pcGUID}
                    data={item.pcData}
                    descricao={item.pcDescricao}
                    onDelete={({ originalData }) => {
                      actionModal(
                        'Cirurgia prévia',
                        `Excluir ${originalData.pcDescricao}?`,
                        async () => {
                          await cirurgiasApi.request({
                            method: '_delete',
                            usuarioCPF: dadosConsulta.dadosConsulta.usuarioCPF,
                            id: originalData.pcGUID,
                          })
                        }
                      )
                    }}
                    onUpdate={async ({ data, descricao, originalData }) => {
                      await cirurgiasApi.request({
                        method: '_update',
                        usuarioCPF: dadosConsulta?.dadosConsulta?.usuarioCPF,
                        data,
                        descricao,
                        id: originalData.pcGUID,
                      })
                    }}
                  />
                )
              })}

              <DateInputForm
                ref={cirurgiasRef}
                onSave={async ({ data, descricao }) => {
                  await cirurgiasApi.request({
                    method: '_insert',
                    usuarioCPF: dadosConsulta?.dadosConsulta?.usuarioCPF,
                    data,
                    descricao,
                  })
                }}
              />
            </div>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box marginBottom={'16px'}>
          <Box>
            <Flex width={'100%'} alignItems={'center'}>
              <Text flex={'1'} fontSize={'md'} fontWeight={'normal'} color={'gray.600'}>
                Resultados de Exames
                {examesApi.isLoading && <Spinner size="sm" />}
              </Text>
              <Button
                variant={'outline'}
                leftIcon={<BsPlusCircleFill />}
                border={'none'}
                color={'blue.400'}
                _hover={{
                  bg: 'transparent'
                }}
                _focus={{
                  outline: 'none'
                }}
                onClick={() => {
                  examesRef.current.open()
                }}
              >
                Adicionar
              </Button>
            </Flex>
            <Box>
              <div className="form-control">
                {examesApi.state.map((medicamento, i) => {
                  return (
                    <DateInputForm
                      originalData={medicamento}
                      key={medicamento.reGUID}
                      data={medicamento.reData}
                      descricao={medicamento.reDescricao}
                      onDelete={({ originalData }) => {
                        actionModal(
                          'Resultado do exame',
                          `Excluir ${originalData.reDescricao}?`,
                          async () => {
                            await examesApi.request({
                              method: '_delete',
                              usuarioCPF:
                                dadosConsulta?.dadosConsulta?.usuarioCPF,
                              id: originalData.reGUID,
                            })
                          }
                        )
                      }}
                      onUpdate={async ({ data, descricao, originalData }) => {
                        examesApi.request({
                          method: '_update',
                          usuarioCPF: dadosConsulta?.dadosConsulta?.usuarioCPF,
                          data,
                          descricao,
                          id: originalData.reGUID,
                        })
                      }}
                    />
                  )
                })}
                <DateInputForm
                  ref={examesRef}
                  onSave={async ({ data, descricao }) => {
                    await examesApi.request({
                      method: '_insert',
                      usuarioCPF: dadosConsulta?.dadosConsulta?.usuarioCPF,
                      data,
                      descricao,
                    })
                  }}
                />
              </div>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
