import { useApiOnMount } from 'hooks/useApi';
import {
  createContext, useContext
} from 'react';
import { useParams } from 'react-router-dom';
import { AtendimentoEmergenciatypel } from './types';

type ContextType = {
  consulta: AtendimentoEmergenciatypel;
  isFetchingConsulta: boolean;
  idConsulta: string;
};

const ConsultaEmergencialContext = createContext<ContextType>({} as ContextType);

export const useConsultaEmergencialContext = () => useContext(ConsultaEmergencialContext);

type ConsultaEmergencialProviderProps = {
  children: JSX.Element;
  idConsulta?: string;
};

export const ConsultaEmergencialProvider = ({
  children,
  idConsulta,
}: ConsultaEmergencialProviderProps) => {
  const { idConsulta: idConsultaRouter } = useParams<{ idConsulta: string }>();
  const { psCpf } = useParams<{ psCpf: string }>();

  const ID_CONSULTA = idConsultaRouter || idConsulta;

  const { data: consulta, isFetching: isFetchingConsulta } =
    useApiOnMount<AtendimentoEmergenciatypel>('aswagendausuprofsaude', {
      method: "_buscarAtendimentoEmergencial",
      idConsulta: ID_CONSULTA,
      psCPF: psCpf,
      staleTimeOnMinutes: 0,
    });

  return (
    <ConsultaEmergencialContext.Provider
      value={{
        consulta,
        isFetchingConsulta,
        idConsulta: ID_CONSULTA,
      }}
    >
      {children}
    </ConsultaEmergencialContext.Provider>
  );
};
