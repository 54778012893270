type OptionProps = {
  key: string;
  value: string;
};

type FormOptionsProps = React.SelectHTMLAttributes<HTMLSelectElement> & {
  options: OptionProps[];
};

export function FormOptions({ options, ...rest }: FormOptionsProps) {
  return (
    <>
      <select className="form-select" placeholder="Selecione..." {...rest}>
        <option value="">Selecione...</option>
        {options &&
          Array.isArray(options) &&
          options.map((option: OptionProps) => {
            return (
              <option key={`${option.key}`} value={option.key}>
                {option.value}
              </option>
            );
          })}
      </select>
    </>
  );
}
