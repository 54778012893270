import { useRef } from 'react';
import QRCode from 'react-qr-code';
import { useReactToPrint } from 'react-to-print';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import { Logo } from 'components/icons';
import { LocalAtendimentoType } from 'pages/local-atendimento/components/pages';

import { ContainerA4, ContainerA5, ContainerFooterA4, ContainerFooterA5, ContainerListInstrucoesA4, ContainerListInstrucoesA5, ContainerMasterA5, ContainerQrCodeA4, ContainerQrCodeA5, HeaderA4, HeaderA5, HeaderSubTitleA4, HeaderSubTitleA5, HeaderTitleLogoA4, HeaderTitleLogoA5, ListIconA4, ListIconA5, ListInstrucoesA4, ListInstrucoesA5, ListItemA4, ListItemA5, TitleRegistroPresencaA4, TitleRegistroPresencaA5 } from './styles';

interface IPropsModalPrintPreview {
  showModal: boolean;
  toggle: () => void;
  localAtendimentoSelected: LocalAtendimentoType | null;
  formato: string;
}

function ModalPrintPreview({
  showModal,
  toggle,
  localAtendimentoSelected,
  formato,
}: IPropsModalPrintPreview) {
  const refImpressao = useRef();
  const handlePrint = useReactToPrint({
    content: () => refImpressao.current,
  });
  return (
    <Modal size={'lg'} isOpen={showModal} toggle={toggle}>
      <ModalHeader toggle={toggle}>Preview</ModalHeader>
      <ModalBody>
        <Row className="d-flex justify-content-center align-items-center">
          {formato === 'A4' ? (
            <ContainerA4 ref={refImpressao}>
              <HeaderA4>
                <div>
                  <HeaderTitleLogoA4>
                    <Logo />
                  </HeaderTitleLogoA4>
                  <HeaderSubTitleA4>
                    <h2>{localAtendimentoSelected.laNome}</h2>
                  </HeaderSubTitleA4>
                </div>
              </HeaderA4>
              <TitleRegistroPresencaA4>
                Registro de presença
              </TitleRegistroPresencaA4>
              <ContainerQrCodeA4>
                <QRCode
                  value={localAtendimentoSelected.laDescricao}
                  size={400}
                  title="Registro de Presença"
                />
              </ContainerQrCodeA4>
              <ContainerListInstrucoesA4>
                <ListInstrucoesA4>
                  <ListItemA4>
                    <ListIconA4>1</ListIconA4> Entre no Aplicativo Tília Saúde
                    Digital
                  </ListItemA4>
                  <ListItemA4>
                    <ListIconA4>2</ListIconA4> Clique no botão "Consultas
                    Agendadas"
                  </ListItemA4>
                  <ListItemA4>
                    <ListIconA4>3</ListIconA4> No seu agendamento, aperte o
                    botão "Registrar Presença"
                  </ListItemA4>
                  <ListItemA4>
                    <ListIconA4>4</ListIconA4> Aponte a câmera do celular para o
                    QRCode acima
                  </ListItemA4>
                </ListInstrucoesA4>
              </ContainerListInstrucoesA4>
              <ContainerFooterA4>
                <p>
                  <i>Pronto! Sua presença será registrada automaticamente.</i>
                </p>
                <img src="/images/rodape.svg" alt="Rodape" />
              </ContainerFooterA4>
            </ContainerA4>
          ) : (
            <ContainerMasterA5 ref={refImpressao}>
              <ContainerA5>
                <HeaderA5>
                  <div>
                    <HeaderTitleLogoA5>
                      <Logo />
                    </HeaderTitleLogoA5>
                    <HeaderSubTitleA5>
                      <h2>{localAtendimentoSelected.laNome}</h2>
                    </HeaderSubTitleA5>
                  </div>
                </HeaderA5>
                <TitleRegistroPresencaA5>
                  Registro de presença
                </TitleRegistroPresencaA5>
                <ContainerQrCodeA5>
                  <QRCode
                    value={localAtendimentoSelected.laDescricao}
                    size={200}
                    title="Registro de Presença"
                  />
                </ContainerQrCodeA5>
                <ContainerListInstrucoesA5>
                  <ListInstrucoesA5>
                    <ListItemA5>
                      <ListIconA5>1</ListIconA5> Entre no Aplicativo Tília Saúde
                      Digital
                    </ListItemA5>
                    <ListItemA5>
                      <ListIconA5>2</ListIconA5> Clique no botão "Consultas
                      Agendadas"
                    </ListItemA5>
                    <ListItemA5>
                      <ListIconA5>3</ListIconA5> No seu agendamento, aperte o
                      botão "Registrar Presença"
                    </ListItemA5>
                    <ListItemA5>
                      <ListIconA5>4</ListIconA5> Aponte a câmera do celular para
                      o QRCode acima
                    </ListItemA5>
                  </ListInstrucoesA5>
                </ContainerListInstrucoesA5>
                <ContainerFooterA5>
                  <p>
                    <i>Pronto! Sua presença será registrada automaticamente.</i>
                  </p>
                  <img src="/images/rodape.svg" alt="Rodape" />
                </ContainerFooterA5>
              </ContainerA5>
              <ContainerA5>
                <HeaderA5>
                  <div>
                    <HeaderTitleLogoA5>
                      <Logo />
                    </HeaderTitleLogoA5>
                    <HeaderSubTitleA5>
                      <h2>{localAtendimentoSelected.laNome}</h2>
                    </HeaderSubTitleA5>
                  </div>
                </HeaderA5>
                <TitleRegistroPresencaA5>
                  Registro de presença
                </TitleRegistroPresencaA5>
                <ContainerQrCodeA5>
                  <QRCode
                    value={localAtendimentoSelected.laDescricao}
                    size={200}
                    title="Registro de Presença"
                  />
                </ContainerQrCodeA5>
                <ContainerListInstrucoesA5>
                  <ListInstrucoesA5>
                    <ListItemA5>
                      <ListIconA5>1</ListIconA5> Entre no Aplicativo Tília Saúde
                      Digital
                    </ListItemA5>
                    <ListItemA5>
                      <ListIconA5>2</ListIconA5> Clique no botão "Consultas
                      Agendadas"
                    </ListItemA5>
                    <ListItemA5>
                      <ListIconA5>3</ListIconA5> No seu agendamento, aperte o
                      botão "Registrar Presença"
                    </ListItemA5>
                    <ListItemA5>
                      <ListIconA5>4</ListIconA5> Aponte a câmera do celular para
                      o QRCode acima
                    </ListItemA5>
                  </ListInstrucoesA5>
                </ContainerListInstrucoesA5>
                <ContainerFooterA5>
                  <p>
                    <i>Pronto! Sua presença será registrada automaticamente.</i>
                  </p>
                  <img src="/images/rodape.svg" alt="Rodape" />
                </ContainerFooterA5>
              </ContainerA5>
            </ContainerMasterA5>
          )}
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={handlePrint}
          className="btn btn-primary text-center justify-content-center"
          type="button"
        >
          Imprimir
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalPrintPreview;
