// @ts-nocheck
/* eslint no-console: off */

import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import ReactPaginate from 'react-paginate';
import 'react-quill/dist/quill.snow.css';
import SelectAsync from 'react-select/async';
import { Loading } from 'components/Loading';
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from 'reactstrap';
// import { pt } from 'date-fns/esm/locale';
// registerLocale('pt', pt);
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { AUTH_TOKEN_KEY, BASE_API_VERSION_PATH } from 'shared/util';
import {
  AppProps,
  getFormFromSelect,
  getListAxios,
  GetServerSideProps,
  jsonParse,
  nextCookie,
  showFieldsSelectAsync,
  useRouter,
} from '../../../components/util/entity-utils';
import {
  Link,
  Translate,
  translate,
  useTranslation,
} from '../../../components/util/translate-component';
import { api } from '../../../services/api';

const MySwal = withReactContent(Swal);

export interface IPerfilMenuRotasProps extends AppProps {
  nome?: any;
  extraFilters?: any;
  activePage?: any;
  sort?: any;
  _router: any;
  props: any;
  order?: any;
  itemsPerPage?: any;
  totalItems?: any;
  state?: any;
  getEntityFiltersURL?: Function;
  setState?: Function;
  t?: Function;
  perfilMenuRotasList?: any;
  setPerfilMenuRotasList?: Function;
  perfilMenuRotasEntity?: any;
  setPerfilMenuRotasEntity?: Function;

  getAllEntities?: Function;
  sortEntities?: Function;
  sortFunction?: Function;
  handlePagination?: Function;
  deleteEntityModal?: Function;
  saveEntity?: Function;
  renderHeaderUpdate?: Function;
  renderBodyUpdate?: Function;
  renderNewModal?: Function;
  renderUpdateModal?: Function;
  fieldsBase?: any;
  getFullEntityFormValue?: Function;
  openUpdateModal?: Function;
  cancelFilters?: Function;
  filterEntity?: Function;
  renderFilter?: Function;
  renderTable?: Function;
  handleSyncList?: Function;
  getEntitiesFetch?: Function;
  deleteEntityFetch?: Function;
  getEntityFetch?: Function;
  createEntityFetch?: Function;
  updateEntityFetch?: Function;
  getPerfilMenuRotasState?: Function;
  returnFunction?: Function;
  perfilMenus?: any;
  menuRotas?: any;
  grupoMenus?: any;
  userAccount?: any;
  loading?: any;
}

const apiUrl = BASE_API_VERSION_PATH + 'aswperfilmenurotas';

const getEntityFetch: any = (
  context: IPerfilMenuRotasProps,
  entity: any,
  selectFields = ''
) => {
  context.setPerfilMenuRotasEntity(entity);
  return entity;
};

const createEntityFetch: any = async (
  context: IPerfilMenuRotasProps,
  entity: any,
  listFiltersPage: any
) => {
  console.info(entity);
  const res = await api.post(apiUrl, {
    method: '_insert',
    perfil: entity.perfilMenu?.nome,
    grupo: entity.grupoMenus?.nome,
    rotas: entity.menuRotas.map((v: any) => v.nome),
  });
  const json = await res.data;
  context.getAllEntities();
  return json;
};

const updateEntityFetch: any = async (
  context: IPerfilMenuRotasProps,
  entity: any,
  listFiltersPage: any
) => {
  console.info(entity);
  const res = await api.post(apiUrl, {
    method: '_update',
    perfil: entity.perfilMenu?.nome,
    grupo: entity.grupoMenus?.nome,
    rotas: entity.menuRotas.map((v: any) => v.nome),
  });
  const json = await res.data;
  context.getAllEntities();
  return json;
};

const deleteEntityFetch: any = async (
  context: IPerfilMenuRotasProps,
  _perfilMenuRotasEntity: any,
  listFiltersPage: any
) => {
  console.info(_perfilMenuRotasEntity);
  const res = await api.post(apiUrl, {
    method: '_delete',
    perfil: _perfilMenuRotasEntity.perfilMenu.nome,
    grupo: _perfilMenuRotasEntity.grupo,
  });
  const json = await res.data;
  context.getAllEntities();
  return true;
};

const getEntityFiltersURL = (context: IPerfilMenuRotasProps, offset = null) => {
  return (
    '' +
    (context.state.nome ? 'nome=' + context.state.nome + '&' : '') +
    (context.state.baseFilters
      ? 'baseFilters=' + context.state.baseFilters + '&'
      : '') +
    (context.state.extraFilters
      ? 'extraFilters=' +
      encodeURI(JSON.stringify(context.state.extraFilters)) +
      '&'
      : '') +
    'page=' +
    context.state.activePage +
    '&' +
    'size=' +
    context.state.itemsPerPage +
    '&' +
    (offset !== null ? 'offset=' + offset + '&' : '') +
    'sort=' +
    context.state.sort +
    ',' +
    context.state.order +
    '&' +
    ''
  );
};

const getAllEntities = async (
  context: IPerfilMenuRotasProps,
  _fieldsBase: any
) => {
  _fieldsBase = _fieldsBase ? _fieldsBase : {};
  const { nome, extraFilters, activePage, itemsPerPage, sort, order } =
    context.state;

  const res = await context.getEntitiesFetch(
    nome,
    typeof _fieldsBase.extraFilters !== undefined
      ? _fieldsBase.extraFilters
      : extraFilters,
    typeof _fieldsBase.activePage !== undefined
      ? _fieldsBase.activePage
      : context.state.activePage,
    typeof _fieldsBase.itemsPerPage !== undefined
      ? _fieldsBase.itemsPerPage
      : context.state.itemsPerPage,
    `${_fieldsBase.sort ? _fieldsBase.sort : context.state.sort},${_fieldsBase.order ? _fieldsBase.order : context.state.order
    }`
  );

  context.setState({
    ...context.state,
    totalItems: 0,
  });

  const json = await res.json();
  context.setPerfilMenuRotasList(json);
};

const sortEntities = (
  context: IPerfilMenuRotasProps,
  _newState = undefined
) => {
  context.getAllEntities();
  const endURL = `?page=${context.state.activePage}&sort=${context.state.sort},${context.state.order}`;
  context._router.push(`?${context.getEntityFiltersURL()}`);
};

const sortFunction = (context: IPerfilMenuRotasProps, p: any) => () => {
  context.setState({
    ...context.state,
    order:
      p === context.state.sort && context.state.order === 'asc'
        ? 'desc'
        : 'asc',
    sort: p,
  });
  context.getAllEntities({
    order:
      p === context.state.sort && context.state.order === 'asc'
        ? 'desc'
        : 'asc',
    sort: p,
  });
};

const handlePagination = (context: IPerfilMenuRotasProps, activePage: any) => {
  context.setState({
    ...context.state,
    activePage,
  });
  context.getAllEntities({ activePage });
};

const deleteEntityModal = (
  context: IPerfilMenuRotasProps,
  _perfilMenuRotasEntity: any
) => {
  MySwal.fire({
    title: (
      <Translate t={context.t} contentKey="entity.delete.title">
        Confirm delete operation
      </Translate>
    ),
    html: (
      <Translate
        t={context.t}
        contentKey="perfilMenuRotas.delete.question"
        interpolate={{ id: _perfilMenuRotasEntity.id }}
      >
        Are you sure you want to delete this PerfilMenuRotas?
      </Translate>
    ),
    icon: 'warning',
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonColor: '#ffc107',
    cancelButtonText: (
      <>
        {' '}
        <i className="fa fa-ban" />
        &nbsp;{' '}
        <Translate t={context.t} contentKey="perfilMenuRotas.btnCancel">
          Cancel
        </Translate>
      </>
    ),
    confirmButtonText: (
      <>
        {' '}
        <i className="fa fa-trash" />
        &nbsp;
        <Translate t={context.t} contentKey="perfilMenuRotas.btnDelete">
          Delete
        </Translate>
      </>
    ),
  }).then(async (response) => {
    if (response.isConfirmed) {
      await context.deleteEntityFetch(_perfilMenuRotasEntity, {
        reloadList: false,
      });
      context.getAllEntities();
      MySwal.fire({
        title: 'Confirmado!',
        icon: 'success',
      });
    }
  });
};

const saveEntity = async (context: IPerfilMenuRotasProps, isNew: any) => {
  const errors = [];

  const selectFieldsList = [
    'perfilMenu.nome',
    'menuRotas.nome',
    'grupoMenus.nome',
  ];

  if (errors.length === 0) {
    const _entity = {
      ...context.perfilMenuRotasEntity,
      // ...values,

      perfilMenu: getFormFromSelect(
        context.state.perfilMenuFormValue,
        'many-to-one'
      ), // many-to-one - ownerSide

      menuRotas: getFormFromSelect(
        context.state.menuRotasFormValue,
        'many-to-many'
      ), // many-to-many - otherSide

      grupoMenus: getFormFromSelect(
        context.state.grupoMenusFormValue,
        'many-to-many'
      ), // many-to-many - otherSide

      id: context.state.idFormValue,
      nome: context.state.nomeFormValue,
    };

    const entity = _entity;

    const { nome, extraFilters, activePage, itemsPerPage, sort, order } =
      context.state;

    if (isNew) {
      context['state'] = {
        ...context.state,
        showModalForm: false,
        showModalEdit: null,
      };
      await context.createEntityFetch(entity);
      context.getAllEntities();
    } else {
      context['state'] = {
        ...context.state,
        showModalForm: false,
        showModalEdit: null,
      };
      await context.updateEntityFetch(entity);
      context.getAllEntities();
    }
  }
};

const renderHeaderUpdate = (context: IPerfilMenuRotasProps, isNew: any) => {
  const baseFilters =
    context.state && context.state['baseFilters']
      ? context.state['baseFilters']
      : null;

  return (
    <>
      <div id="page-heading">
        <span className="page-header ml-3">
          {isNew ? (
            <Translate
              t={context.t}
              contentKey="perfilMenuRotas.home.createLabel"
            >
              Create a PerfilMenuRotas
            </Translate>
          ) : (
            <Translate
              t={context.t}
              contentKey="perfilMenuRotas.home.editLabel"
            >
              Edit a PerfilMenuRotas
            </Translate>
          )}
        </span>
      </div>
    </>
  );
};

const renderBodyUpdate = (
  context: IPerfilMenuRotasProps,
  _perfilMenuRotasEntity: any,
  isNew: any
) => {
  const baseFilters =
    context.state && context.state['baseFilters']
      ? context.state['baseFilters']
      : null;

  return (
    <>
      <Row className="justify-content-center">
        <Col md="11">
          {context.loading ? (
            <p>Loading...</p>
          ) : (
            <div>
              {!isNew ? (
                <div>
                  <Row>
                    <Col md="12">
                      <input
                        id="perfil-menu-rotas-id"
                        type="hidden"
                        className="form-control"
                        name="id"
                        required
                        readOnly
                      />
                    </Col>
                  </Row>
                </div>
              ) : null}
              <Row className="row-perfil-menu-rotas-first-column">
                <Col md="12">
                  <div>
                    <Row>
                      <Col md="12">
                        <Label
                          className="mt-4 label-single-line"
                          for="perfil-menu-rotas-perfilMenu"
                        >
                          <Translate
                            t={context.t}
                            contentKey="perfilMenuRotas.perfilMenu"
                          >
                            Perfil Menu
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="12">
                        <SelectAsync
                          isMulti={false}
                          isDisabled={!isNew}
                          id="perfil-menu-rotas-perfilMenu-form"
                          name={'perfilMenu'}
                          instanceId="perfil-menu-rotas-perfilMenu-form"
                          placeholder={translate(
                            context.t,
                            'perfilMenuRotas.perfilMenu-input-placeholder'
                          )}
                          className={'css-select-control'}
                          data-type-rel="many-to-one-owner-side"
                          value={
                            context.state.perfilMenuFormValue
                              ? context.state.perfilMenuFormValue
                              : ''
                          }
                          onChange={(options) =>
                            context.setState({
                              ...context.state,
                              grupoMenuStartSelectOptions: undefined,
                              perfilMenuFormValue: options,
                            })
                          }
                          defaultOptions={
                            context.state.perfilMenuStartSelectOptions
                              ? context.state.perfilMenuStartSelectOptions
                              : []
                          }
                          loadingMessage={(input) =>
                            translate(context.t, 'selectAsync.loadingMessage')
                          }
                          noOptionsMessage={(input) =>
                            context.state.perfilMenuStartSelectOptions ===
                              undefined
                              ? translate(
                                context.t,
                                'selectAsync.loadingMessage'
                              )
                              : translate(
                                context.t,
                                'selectAsync.noOptionsMessage'
                              )
                          }
                          onMenuOpen={async () => {
                            if (
                              context.state.perfilMenuStartSelectOptions ===
                              undefined
                            ) {
                              const result = await getListAxios(
                                'aswperfilmenu',
                                {},
                                0,
                                100,
                                'nomeTela,asc',
                                'nomeTela'
                              );
                              context.setState({
                                ...context.state,
                                perfilMenuStartSelectOptions: result
                                  ? result.map((p: any) => ({
                                    ...p,
                                    value: p.id,
                                    label:
                                      showFieldsSelectAsync(p, 'nomeTela') ||
                                      '',
                                  }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={(inputValue, callback) => {
                            (async () => {
                              const result = await getListAxios(
                                'aswPerfilMenu',
                                { nomeTela: inputValue },
                                0,
                                100,
                                'nomeTela,asc',
                                'nomeTela'
                              );
                              callback(
                                result
                                  ? result.map((p: any) => ({
                                    ...p,
                                    value: p.id,
                                    label:
                                      showFieldsSelectAsync(p, 'nomeTela') ||
                                      '',
                                  }))
                                  : []
                              );
                            })();
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="12">
                  <div>
                    <Row>
                      <Col md="12">
                        <Label
                          className="mt-4 label-single-line"
                          for="grupo-menu-perfil-menu-grupoMenu"
                        >
                          <Translate
                            t={context.t}
                            contentKey="grupoMenuPerfilMenu.grupoMenu"
                          >
                            Grupo Menu
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="12">
                        <SelectAsync
                          isDisabled={!isNew}
                          isMulti={false}
                          id="grupo-menu-perfil-menu-grupoMenu-form"
                          name={'grupoMenu'}
                          instanceId="grupo-menu-perfil-menu-grupoMenu-form"
                          placeholder={translate(
                            context.t,
                            'grupoMenuPerfilMenu.grupoMenu-input-placeholder'
                          )}
                          className={'css-select-control'}
                          data-type-rel="many-to-one-owner-side"
                          value={
                            context.state.grupoMenusFormValue
                              ? context.state.grupoMenusFormValue
                              : ''
                          }
                          onChange={(options) =>
                            context.setState({
                              ...context.state,
                              grupoMenusFormValue: options,
                            })
                          }
                          defaultOptions={
                            context.state.grupoMenuStartSelectOptions
                              ? context.state.grupoMenuStartSelectOptions
                              : []
                          }
                          loadingMessage={(input) =>
                            translate(context.t, 'selectAsync.loadingMessage')
                          }
                          noOptionsMessage={(input) =>
                            context.state.grupoMenuStartSelectOptions ===
                              undefined
                              ? translate(
                                context.t,
                                'selectAsync.loadingMessage'
                              )
                              : translate(
                                context.t,
                                'selectAsync.noOptionsMessage'
                              )
                          }
                          onMenuOpen={async () => {
                            if (
                              context.state.grupoMenuStartSelectOptions ===
                              undefined
                            ) {
                              const result = await getListAxios(
                                'aswgrupomenu',
                                {
                                  perfilMenu:
                                    context?.state?.perfilMenuFormValue?.nome,
                                },
                                0,
                                100,
                                'descricao,asc',
                                'descricao'
                              );
                              context.setState({
                                ...context.state,
                                grupoMenuStartSelectOptions: result
                                  ? result.map((p: any) => ({
                                    ...p,
                                    value: p.id,
                                    label:
                                      showFieldsSelectAsync(p, 'descricao') ||
                                      '',
                                  }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={(inputValue, callback) => {
                            (async () => {
                              const result = await getListAxios(
                                'aswgrupomenu',
                                {
                                  descricao: inputValue,
                                  perfilMenu:
                                    context?.state?.perfilMenuFormValue?.nome,
                                },
                                0,
                                100,
                                'descricao,asc',
                                'descricao'
                              );
                              callback(
                                result
                                  ? result.map((p: any) => ({
                                    ...p,
                                    value: p.id,
                                    label:
                                      showFieldsSelectAsync(p, 'descricao') ||
                                      '',
                                  }))
                                  : []
                              );
                            })();
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="12">
                  <div>
                    <Row>
                      <Col md="12">
                        <Label
                          className="mt-4 label-single-line"
                          for="perfil-menu-rotas-menuRotas"
                        >
                          <Translate
                            t={context.t}
                            contentKey="perfilMenuRotas.menuRotas"
                          >
                            Menu Rotas
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="12">
                        <SelectAsync
                          isMulti={true}
                          id="perfil-menu-rotas-menuRotas-form"
                          name={'menuRotas'}
                          instanceId="perfil-menu-rotas-menuRotas-form"
                          placeholder={translate(
                            context.t,
                            'perfilMenuRotas.menuRotas-input-placeholder'
                          )}
                          className={'css-select-control'}
                          data-type-rel="many-to-many-other-side"
                          value={
                            context.state.menuRotasFormValue
                              ? context.state.menuRotasFormValue
                              : ''
                          }
                          onChange={(options) =>
                            context.setState({
                              ...context.state,
                              menuRotasFormValue: options,
                            })
                          }
                          defaultOptions={
                            context.state.menuRotasStartSelectOptions
                              ? context.state.menuRotasStartSelectOptions
                              : []
                          }
                          loadingMessage={(input) =>
                            translate(context.t, 'selectAsync.loadingMessage')
                          }
                          noOptionsMessage={(input) =>
                            context.state.menuRotasStartSelectOptions ===
                              undefined
                              ? translate(
                                context.t,
                                'selectAsync.loadingMessage'
                              )
                              : translate(
                                context.t,
                                'selectAsync.noOptionsMessage'
                              )
                          }
                          onMenuOpen={async () => {
                            if (
                              context.state.menuRotasStartSelectOptions ===
                              undefined
                            ) {
                              const result = await getListAxios(
                                'aswmenurotas',
                                {},
                                0,
                                100,
                                'rota,asc',
                                'rota'
                              );
                              context.setState({
                                ...context.state,
                                menuRotasStartSelectOptions: result
                                  ? result.map((p: any) => ({
                                    ...p,
                                    value: p.id,
                                    label:
                                      showFieldsSelectAsync(p, 'rota') || '',
                                  }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={(inputValue, callback) => {
                            (async () => {
                              const result = await getListAxios(
                                'aswmenurotas',
                                { nome: inputValue },
                                0,
                                100,
                                'rota,asc',
                                'rota'
                              );
                              callback(
                                result
                                  ? result.map((p: any) => ({
                                    ...p,
                                    value: p.id,
                                    label:
                                      showFieldsSelectAsync(p, 'rota') || '',
                                  }))
                                  : []
                              );
                            })();
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

const renderNewModal = (context: IPerfilMenuRotasProps) => {
  return (
    <Modal
      className="perfil-menu-rotas-new-modal"
      size={'xl'}
      isOpen={context.state.showModalForm}
      toggle={() =>
        context.setState({ ...context.state, showModalForm: false })
      }
    >
      <ModalHeader
        toggle={() =>
          context.setState({ ...context.state, showModalForm: false })
        }
      >
        {context.renderHeaderUpdate(true)}
      </ModalHeader>
      <div>
        <ModalBody>{context.renderBodyUpdate({}, true)}</ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            id="save-entity"
            onClick={async (evt) => {
              const target = evt.currentTarget;
              target.disabled = true;
              await context.saveEntity(true);
              target.disabled = false;
            }}
            className="float-right jh-create-entity"
          >
            <Translate t={context.t} contentKey="perfilMenuRotas.btnSave">
              Save
            </Translate>
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

const renderUpdateModal = (context: IPerfilMenuRotasProps) => {
  return context.perfilMenuRotasEntity &&
    (context.perfilMenuRotasEntity.id || context.perfilMenuRotasEntity._id) ? (
    <Modal
      className="perfil-menu-rotas-update-modal"
      size={'lg'}
      isOpen={
        context.state.showModalEdit === context.perfilMenuRotasEntity.id ||
        context.state.showModalEdit === context.perfilMenuRotasEntity._id
      }
      toggle={() => context.setState({ ...context.state, showModalEdit: null })}
    >
      <ModalHeader
        toggle={() =>
          context.setState({ ...context.state, showModalEdit: null })
        }
      >
        {context.renderHeaderUpdate(false)}
      </ModalHeader>
      <div>
        <ModalBody>
          {context.renderBodyUpdate(context.perfilMenuRotasEntity, false)}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            id="save-entity"
            onClick={(evt) => {
              const target = evt.currentTarget;
              target.disabled = true;
              context.saveEntity(false);
              target.disabled = false;
            }}
            className="float-right jh-create-entity"
          >
            <Translate t={context.t} contentKey="perfilMenuRotas.btnSave">
              Save
            </Translate>
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  ) : (
    <> </>
  );
};

const getFullEntityFormValue = (context: IPerfilMenuRotasProps, v: any) => {
  let p: any = null;

  p = v.perfilMenu;
  const perfilMenuEntity = p
    ? { ...p, value: p.id, label: p['nome'] ? p.nome : '' }
    : p;

  const menuRotasEntity = v.menuRotas
    ? v.menuRotas
      .filter((p1: any) => p1)
      .map((p1: any) => ({
        ...p1,
        value: p1.id,
        label: p1['rota'] ? p1.rota : '',
      }))
    : v.menuRotas;

  p = v.grupoMenus;
  const grupoMenusEntity = p
    ? { ...p, value: p.id, label: p['nome'] ? p.nome : '' }
    : p;

  return {
    idFormValue: v.id, // id,
    nomeFormValue: v.nome, // nome,
    perfilMenuFormValue: perfilMenuEntity,
    menuRotasFormValue: menuRotasEntity,
    grupoMenusFormValue: grupoMenusEntity,
  };
};

const openUpdateModal = (
  context: IPerfilMenuRotasProps,
  v: any,
  edit = true
) => {
  if (v.id) {
    context.getEntityFetch(v);
  } else {
    context.setExampleEmbebedWithEnumEntity(v);
  }
  context.setState({
    ...context.state,
    showModalEdit: v._id || v.id,
    showModalEditView: edit,
    ...getFullEntityFormValue(context, v),
  });
};

const cancelFilters = (context: IPerfilMenuRotasProps) => {
  const newState = {
    ...context.state,
    nome: '',
  };
  context.setState(newState);
  context.sortEntities(newState);
};

const filterEntity = (context: IPerfilMenuRotasProps) => {
  const newState = {
    ...context.state,
    activePage: 0,
  };
  context.setState(newState);
  context.sortEntities(newState);
};

const renderFilter = (context: IPerfilMenuRotasProps) => {
  return (
    <>
      <div id="form-filter">
        <div className="row mt-1 ml-3 mr-3">
          {context.state.baseFilters !== 'nome' ? (
            <Col md="12" className="col-filter-perfil-menu-rotas-nome">
              <Row className="mr-1 mt-1">
                <Label id="nomeLabel" for="perfil-menu-rotas-nome">
                  <Translate t={context.t} contentKey="perfilMenuRotas.nome">
                    Nome Grupo
                  </Translate>
                </Label>

                <input
                  className="form-control"
                  type="text"
                  name="nome"
                  id="perfil-menu-rotas-nome"
                  value={context.state.nome}
                  onChange={(evt: any) =>
                    context.setState({
                      ...context.state,
                      nome: evt.target.value,
                    })
                  }
                  placeholder={'Buscar'}
                />
              </Row>
            </Col>
          ) : null}
        </div>

        <div className="row mb-2 mr-4 justify-content-end pull-right">
          <Button
            id="search-submit"
            color="primary"
            onClick={() => context.filterEntity()}
          >
            <i className="fa fa-search" />
            &nbsp;
          </Button>
        </div>
      </div>
    </>
  );
};

export const renderTable = (context: IPerfilMenuRotasProps) => {
  return (
    <>
      {context.load && <Loading />}
      {context.perfilMenuRotasList &&
        context.perfilMenuRotasList.filter &&
        context.perfilMenuRotasList.filter(
          (v: any) => typeof v.deletedAt === 'undefined' || v.deletedAt === null
        ).length > 0 ? (
        <div id="perfil-menu-rotas-table-list" className="table-list">
          <Table
            id="perfil-menu-rotas-table-list"
            responsive
            aria-describedby="perfil-menu-rotas-heading"
            className={'table-hover table-striped table-responsive-css'}
          >
            <thead className={'thead-light'}>
              <tr>
                <th id="perfilMenu-cell-header">
                  <Translate
                    t={context.t}
                    contentKey="perfilMenuRotas.perfilMenu"
                  />
                </th>
                <th id="grupoMenus-cell-header">
                  <Translate
                    t={context.t}
                    contentKey="grupoMenuPerfilMenu.grupoMenu"
                  />
                </th>
                <th id="menuRotas-cell-header">
                  <Translate
                    t={context.t}
                    contentKey="perfilMenuRotas.menuRotas"
                  />
                </th>

                <th />
              </tr>
            </thead>
            <tbody>
              <tr></tr>
              {context.perfilMenuRotasList
                .filter(
                  (v: any) =>
                    typeof v.deletedAt === 'undefined' || v.deletedAt === null
                )
                .map((perfilMenuRotas: any, i: number) => (
                  <tr key={`entity-${i}`}>
                    <td id="perfilMenu-cell" role="many-to-one">
                      <b className="visible-xs">
                        {' '}
                        <Translate
                          t={context.t}
                          contentKey="perfilMenuRotas.perfilMenu"
                        />{' '}
                      </b>
                      <span> {perfilMenuRotas.perfil} </span>
                    </td>

                    <td id="grupoMenus-cell" role="many-to-many">
                      <b className="visible-xs">
                        {' '}
                        <Translate
                          t={context.t}
                          contentKey="perfilMenuRotas.grupoMenus"
                        />{' '}
                      </b>

                      {showFieldsSelectAsync(
                        perfilMenuRotas,
                        'grupoMenus.descricao',
                        null
                      ).map((v: any, ikey: any) => (
                        <span key={ikey}> {v} </span>
                      ))}
                    </td>

                    <td id="menuRotas-cell" role="many-to-many">
                      <b className="visible-xs">
                        {' '}
                        <Translate
                          t={context.t}
                          contentKey="perfilMenuRotas.menuRotas"
                        />{' '}
                      </b>

                      {showFieldsSelectAsync(
                        perfilMenuRotas,
                        'menuRotas.rota',
                        null
                      ).map((v: any, ikey: any) => (
                        <span key={ikey}> {v} </span>
                      ))}
                    </td>

                    <td className="text-right">
                      <div className="btn-group flex-btn-group-container">
                        <Link href={'#'}>
                          <a
                            className={'btn btn-primary btn-sm'}
                            onClick={() =>
                              context.openUpdateModal(perfilMenuRotas)
                            }
                          >
                            <i className="fa fa-pencil-alt" />
                          </a>
                        </Link>
                        <Link href={'#'}>
                          <a
                            className={'btn btn-primary btn-sm'}
                            onClick={() =>
                              context.deleteEntityModal(perfilMenuRotas)
                            }
                          >
                            <i size="2x" className="fa fa-trash" />
                          </a>
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          {context.state.totalItems > context.state.itemsPerPage ? (
            <div
              className={
                context.perfilMenuRotasList &&
                  context.perfilMenuRotasList.length > 0
                  ? ''
                  : 'd-none'
              }
            >
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                previousClassName={'page-item'}
                nextClassName={'page-item'}
                breakClassName={'page-item'}
                pageClassName={'page-item'}
                nextLinkClassName={'btn btn-primary'}
                previousLinkClassName={'btn btn-primary'}
                breakLinkClassName={'btn btn-primary'}
                pageLinkClassName={'btn btn-default'}
                activeLinkClassName={'btn btn-primary'}
                pageCount={
                  context.state.totalItems / context.state.itemsPerPage
                }
                breakLabel={'...'}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={(v: any) => {
                  if (v.selected >= 0) context.handlePagination(v.selected);
                }}
                containerClassName={'pagination'}
                activeClassName={'active'}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      ) : !context.loading ? (
        <div className="alert alert-warning">
          <Translate t={context.t} contentKey="perfilMenuRotas.home.notFound">
            No Perfil + Grupo + Rotas found
          </Translate>
        </div>
      ) : (
        <div />
      )}
    </>
  );
};

const handleSyncList = (context: IPerfilMenuRotasProps) => {
  context.sortEntities();
};

export const returnFunction = (context: IPerfilMenuRotasProps) => {
  return (
    <div>
      <h4 id="perfil-menu-rotas-heading" data-cy="PerfilMenuRotasHeading">
        <Translate t={context.t} contentKey="perfilMenuRotas.home.title">
          Perfil + Grupo + Rotas
        </Translate>
        <div className="d-flex justify-content-end">
          <Button
            onClick={() =>
              context.setState({
                ...context.state,
                showModalForm: true,
                idFormValue: null,
                nomeFormValue: null,

                perfilMenuStartSelectOptions: undefined,
                perfilMenuFormValue: null,

                menuRotasStartSelectOptions: undefined,
                menuRotasFormValue: null,

                grupoMenusStartSelectOptions: undefined,
                grupoMenusFormValue: null,
                isNew: true,
              })
            }
            color="primary"
            className="btn btn-primary float-right jh-create-entity"
          >
            <Translate t={context.t} contentKey="perfilMenuRotas.btnNewItem">
              Cadastro de Perfil + Grupo + Rotas
            </Translate>
          </Button>{' '}
          &nbsp;
        </div>
      </h4>
      <div className="table-responsive">
        {context.renderFilter ? context.renderFilter() : () => { }}
        {context.renderTable ? context.renderTable() : () => { }}
        {context.renderNewModal ? context.renderNewModal() : () => { }}
        {context.renderUpdateModal ? context.renderUpdateModal() : () => { }}
      </div>
    </div>
  );
};

export const getPerfilMenuRotasState = (
  context: IPerfilMenuRotasProps,
  location: any
) => {
  const _urlBase = new URL(`http://localhost${location}`); // using a dummy url for parsing
  const extraFilters = jsonParse(
    _urlBase.searchParams.get('extraFilters') || '{}'
  );
  const baseFilters = _urlBase.searchParams.get('baseFilters') || '';
  const offset: any = _urlBase.searchParams.get('offset') || 0;
  const activePage: any = _urlBase.searchParams.get('page') || 0;
  const itemsPerPage: any = _urlBase.searchParams.get('size') || 20;
  const sortOrder: any = (_urlBase.searchParams.get('sort') || 'id,asc').split(
    ','
  );

  const nome = _urlBase.searchParams.get('nome') || '';
  return {
    activePage,
    itemsPerPage,
    sort: sortOrder[0] ? sortOrder[0] : 'id',
    order: sortOrder[1] ? sortOrder[1] : 'asc',
    baseFilters,
    extraFilters,
    offset,
    nome,
  };
};

export const getEntitiesFetch = async (
  context: IPerfilMenuRotasProps,
  nome: any,
  extraFilters: any,
  page: any,
  size: any,
  sort: any,
  selectFields = '',
  authorizationBearer = '',
  getAccount = false
) => {
  const res = await api.post(apiUrl, {
    method: '_list',
    nome,
  });
  const _json = await res.data;

  res.json = () =>
    new Promise((resolve) =>
      resolve(
        _json['data']
          ? [
            ..._json['data'].map((v: any) => ({
              id: Math.floor((1 + Math.random()) * 0x10000),
              perfilMenu: {
                id: v.perfil,
                nome: v.perfil,
                descricao: v.perfilTela,
              },
              grupoMenus: {
                id: v.grupo,
                nome: v.grupo,
                descricao: v.grupoDescricao,
              },
              menuRotas: [
                ...v.rotas.map((x) => ({
                  id: x.nome,
                  nome: x.nome,
                  rota: x.rota,
                })),
              ],
              ...v,
            })),
          ]
          : []
      )
    );
  context.setLoad(false);
  return res;
};

export const PerfilMenuRotas = (props: IPerfilMenuRotasProps) => {
  const { t } = useTranslation();
  const _router = useRouter();
  const [perfilMenuRotasList, setPerfilMenuRotasList] = useState(
    props.perfilMenuRotasList ? props.perfilMenuRotasList : ([] as any)
  );
  const [perfilMenuRotasEntity, setPerfilMenuRotasEntity] = useState(
    props.perfilMenuRotasEntity ? props.perfilMenuRotasEntity : ({} as any)
  );
  const [load, setLoad] = useState(true);
  const { loading, totalItems } = props;

  const [state, setState] = useState({
    nome: props.nome ? props.nome : '',
    extraFilters: props.extraFilters ? props.extraFilters : '',
    activePage: props.activePage ? props.activePage : '0',
    itemsPerPage: props.itemsPerPage,
    totalItems: props.totalItems ? props.totalItems : 0,
    sort: props.sort ? props.sort : 'id',
    order: props.order ? props.order : 'asc',
  } as any);

  useEffect(() => {
    context.getAllEntities();
  }, [_router.asPath]);

  const context = {
    t,
    props,
    _router,
    state,
    setState,
    perfilMenuRotasList,
    setPerfilMenuRotasList,
    perfilMenuRotasEntity,
    setPerfilMenuRotasEntity,
    load,
    setLoad,

    getAllEntities: props['getAllEntities']
      ? (_fieldsBase) => props['getAllEntities'](context, _fieldsBase)
      : (_fieldsBase) => getAllEntities(context, _fieldsBase),
    sortEntities: props['sortEntities']
      ? () => props['sortEntities'](context)
      : () => sortEntities(context),
    sortFunction: props['sortFunction']
      ? (p) => props['sortFunction'](context, p)
      : (p) => sortFunction(context, p),
    handlePagination: props['handlePagination']
      ? (activePage) => props['handlePagination'](context, activePage)
      : (activePage) => handlePagination(context, activePage),
    deleteEntityModal: props['deleteEntityModal']
      ? (_cadastroTelemedicinaEntity) =>
        props['deleteEntityModal'](context, _cadastroTelemedicinaEntity)
      : (_cadastroTelemedicinaEntity) =>
        deleteEntityModal(context, _cadastroTelemedicinaEntity),
    saveEntity: props['saveEntity']
      ? (isNew) => props['saveEntity'](context, isNew)
      : (isNew) => saveEntity(context, isNew),
    getFullEntityFormValue: props['getFullEntityFormValue']
      ? (v: any) => props['getFullEntityFormValue'](context, v)
      : (v: any) => getFullEntityFormValue(context, v),
    openUpdateModal: props['openUpdateModal']
      ? (v: any, edit = true) => props['openUpdateModal'](context, v, edit)
      : (v: any, edit = true) => openUpdateModal(context, v, edit),
    renderBodyUpdate: props['renderBodyUpdate']
      ? (_perfilMenuRotasEntity: any, isNew: any) =>
        props['renderBodyUpdate'](context, _perfilMenuRotasEntity, isNew)
      : (_perfilMenuRotasEntity: any, isNew: any) =>
        renderBodyUpdate(context, _perfilMenuRotasEntity, isNew),
    renderUpdateModal: props['renderUpdateModal']
      ? () => props['renderUpdateModal'](context)
      : () => renderUpdateModal(context),
    renderNewModal: props['renderNewModal']
      ? () => props['renderNewModal'](context)
      : () => renderNewModal(context),

    renderFilter: props['renderFilter']
      ? () => props['renderFilter'](context)
      : () => renderFilter(context),
    cancelFilters: props['cancelFilters']
      ? () => props['cancelFilters'](context)
      : () => cancelFilters(context),
    filterEntity: props['filterEntity']
      ? () => props['filterEntity'](context)
      : () => filterEntity(context),
    renderHeaderUpdate: props['renderHeaderUpdate']
      ? (isNew) => props['renderHeaderUpdate'](context, isNew)
      : (isNew) => renderHeaderUpdate(context, isNew),
    renderTable: props['renderTable']
      ? () => props['renderTable'](context)
      : () => renderTable(context),
    handleSyncList: props['handleSyncList']
      ? () => props['handleSyncList'](context)
      : () => handleSyncList(context),
    getEntitiesFetch: (
      nome: any,
      extraFilters: any,
      page: any,
      size: any,
      sort: any,
      selectFields = '',
      authorizationBearer = ''
    ) =>
      getEntitiesFetch(
        context,
        nome,
        extraFilters,
        page,
        size,
        sort,
        selectFields,
        authorizationBearer
      ),

    createEntityFetch: (entity: any, listFiltersPage: any) =>
      createEntityFetch(context, entity, listFiltersPage),
    updateEntityFetch: (entity: any, listFiltersPage: any) =>
      updateEntityFetch(context, entity, listFiltersPage),
    deleteEntityFetch: (id: any, listFiltersPage: any) =>
      deleteEntityFetch(context, id, listFiltersPage),
    getEntityFetch: (id) => getEntityFetch(context, id),

    getPerfilMenuRotasState: props['getPerfilMenuRotasState']
      ? (location) => props['getPerfilMenuRotasState'](context, location)
      : (location) => getPerfilMenuRotasState(context, location),
    getEntityFiltersURL: props['getEntityFiltersURL']
      ? () => props['getEntityFiltersURL'](context)
      : () => getEntityFiltersURL(context),
    return: props['returnFunction']
      ? () => props['returnFunction'](context)
      : () => returnFunction(context),
  };

  return context;
};

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const q = nextCookie(ctx);
  const _fieldsBase = getPerfilMenuRotasState(null, ctx.resolvedUrl);

  const res = await getEntitiesFetch(
    null,
    _fieldsBase.nome,
    _fieldsBase.extraFilters,
    _fieldsBase.activePage,
    _fieldsBase.itemsPerPage,
    `${_fieldsBase.sort},${_fieldsBase.order}`,
    '',
    q[AUTH_TOKEN_KEY],
    true
  );

  if (!q[AUTH_TOKEN_KEY] || q[AUTH_TOKEN_KEY] === '' || res.status === 401) {
    ctx.res.setHeader('Location', `/login`);
    ctx.res.statusCode = 302;
    ctx.res.end();
    return { props: {} };
  }

  const userAccount = JSON.parse(res.headers.get('User-Account-Data'));

  return {
    props: {
      ..._fieldsBase,
      userAccount,
      totalItems: res.headers.get('X-Total-Count'),
      perfilMenuRotasList: await res.json(),
    },
  };
};

export default PerfilMenuRotas;
