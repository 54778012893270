import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Divider,
  Flex,
  Heading,
  useDisclosure
} from '@chakra-ui/react';
import { ConfirmarExclusaoModal } from 'components/confirmar-exclusao';
import { useEffect, useState } from 'react';
import { BsSearch, BsTrash } from 'react-icons/bs';
import { FiEdit } from 'react-icons/fi';
import { IoIosArrowForward } from 'react-icons/io';
import { MdOutlineQuestionAnswer } from 'react-icons/md';
import {
  Link as ReactLink,
  useHistory,
  useLocation,
  useParams
} from 'react-router-dom';
import { api } from 'services/api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { colors } from 'theme/colors';
import { Input } from '../../../components/crud/Input';
import {
  ActionComponents,
  HandleActionProps, ItemOrdem,
  ordenarProps, TableParamsProps
} from '../../../components/crud/Table/types';

import {
  Table
} from '../../../components/crud/Table';

import { ModalCrud } from './components/modal-crud';
import { IPerguntaDTO } from './dtos/IGestaoPergunta.dto';

const GestaoPerguntas = () => {
  const MySwal = withReactContent(Swal);
  let { qsId } = useParams<any>();
  const search = useLocation().search;
  const slugQuestao = new URLSearchParams(search).get('slugQuestao');
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [originParams, setOriginParams] = useState(null);
  const [ordemList, setOrdemList] = useState<ItemOrdem[]>([]);
  const [inputFilter, setInputFilter] = useState('');
  const [ultima, setUltima] = useState<number>();
  const [perguntaList, setPerguntaList] = useState<IPerguntaDTO[]>();
  const [showDeletarModal, setShowDeletarModal] = useState<boolean>(false);
  const [qpIDDelete, setQpIDDelete] = useState<string>('');
  const tableParams: TableParamsProps[] = [
    {
      header: 'Ordem',
      key: 'qpOrdem',
    },
    {
      header: 'Pergunta',
      key: 'qpTexto',
    },
    {
      header: 'Obrigatoriedade',
      key: 'obrigatorio',
    },
    {
      header: 'Tipo',
      key: 'tipo',
    },
  ];

  const actionComponents: ActionComponents[] = [
    {
      component: MdOutlineQuestionAnswer,
      type: 'icon',
      action: 'icon-alternativas',
      header: 'Alternativas',
      props: {
        size: 25,
        color: colors.blue,
      },
    },
    {
      component: FiEdit,
      type: 'icon',
      action: 'icon-edit',
      header: 'Editar',
      props: {
        size: 25,
        color: colors.blue,
      },
    },
    {
      component: BsTrash,
      type: 'icon',
      action: 'icon-remover-pergunta',
      header: 'Remover',
      props: {
        size: 25,
        color: colors.gray_2,
      },
    },
  ];

  const alterarOrdem = async (props: ordenarProps) => {
    let questions = [];
    filterPerguntas.map((e) => {
      if (e.qpID == props.id) {
        questions.push({
          qpID: e.qpID,
          qpTexto: e.qpTexto,
          qpOrdem: props.novaOrdem,
          qpSucessora: props.novaOrdem + 1,
          qpAntecessora: props.novaOrdem - 1,
          qpTipo: e.qpTipo,
          qpObrigatorio: e.qpObrigatorio,
          qpRangeRespInicio: e.qpRangeRespInicio ? e.qpRangeRespInicio : '0',
          qpRangeRespFim: e.qpRangeRespFim ? e.qpRangeRespFim : '0',
        });
      } else {
        questions.push({
          qpID: e.qpID,
          qpTexto: e.qpTexto,
          qpOrdem: props.novaOrdem == e.qpOrdem ? props.ordemSubstituida : e.qpOrdem,
          qpSucessora: props.novaOrdem == e.qpOrdem ? props.ordemSubstituida + 1 : e.qpSucessora,
          qpAntecessora: props.novaOrdem == e.qpOrdem ? props.ordemSubstituida - 1 : e.qpAntecessora,
          qpTipo: e.qpTipo,
          qpObrigatorio: e.qpObrigatorio,
          qpRangeRespInicio: e.qpRangeRespInicio ? e.qpRangeRespInicio : '0',
          qpRangeRespFim: e.qpRangeRespFim ? e.qpRangeRespFim : '0',
        });
      }
    });
    const { data } = await api.post('aswquestionario', {
      method: "updateQuestion",
      questions
    });

    if (data.success == true) {
      handleGetPergunta();
      MySwal.fire({
        title: data?.message || 'Editado com sucesso!',
        icon: 'success',
      });
    } else {
      MySwal.fire({
        title: data?.message || 'Houve um erro ao tentar editar!',
        icon: 'error',
      });
    }
  }
  const handlerAction = async (props: HandleActionProps<IPerguntaDTO>) => {
    if (props.action === 'icon-edit') {
      setOriginParams(props.dataLn);
      onOpen();
    }

    if (props.action === 'icon-alternativas') {
      history.push(
        `/gestao-alternativas/${qsId}/${props.dataLn.qpID}?slugQuestao=${slugQuestao}&slugPergunta=${props.dataLn.qpTexto}&tipo=${props.dataLn.qpTipo}`
      );
    }
    if (props.action === 'icon-remover-pergunta') {
      setQpIDDelete(props.dataLn.qpID);
      setShowDeletarModal(true);
    }
  };

  const deletarPergunta = async () => {
    const { data } = await api.post('aswquestionario', {
      method: 'deleteQuestion',
      qpID: qpIDDelete,
    });
    if (data.success == true) {
      MySwal.fire({
        title: data?.message || 'Apagado com sucesso!',
        icon: 'success',
      });
      handleGetPergunta();
    } else {
      MySwal.fire({
        title: data?.message || 'Houve um erro ao tentar apagar!',
        icon: 'error',
      });
    }
  };

  const getTipoTraducao = (sigla: string) => {
    switch (sigla) {
      case 'A':
        return 'Alternativa';
      case 'B':
        return 'Booleana';
      case 'D':
        return 'Dissertativa';
      case 'M':
        return 'Multipla';
      case 'R':
        return 'Range';
      default:
        return '-';
    }
  };

  const handleGetPergunta = async () => {
    setIsLoading(true);
    api
      .post('aswquestionario', {
        method: 'listQuestions',
        qsID: qsId,
      })
      .then(({ data }) => {
        let dataDados = [];
        data.data.map((e) => {
          dataDados.push({
            obrigatorio: e.qpObrigatorio == 0 ? 'Não' : 'Sim',
            tipo: getTipoTraducao(e.qpTipo),
            ...e,
          });
        });

        dataDados.sort(function (a, b) {
          if (a.qpOrdem > b.qpOrdem) {
            return 1;
          }
          if (a.qpOrdem < b.qpOrdem) {
            return -1;
          }
          return 0;
        });

        setPerguntaList(dataDados);
        let maior = 0;
        dataDados.map((e) => {
          if (e.qpOrdem > maior) {
            maior = e.qpOrdem;
          }
        })
        let ordemData = [];
        for (let index = 1; index < maior + 1; index++) {
          ordemData.push({
            value: index,
            key: index
          });
        }
        setOrdemList(ordemData)
        setUltima(maior);
        setIsLoading(false);
      });
  };

  const filterPerguntas =
    inputFilter.length > 0
      ? perguntaList?.filter((pergunta) =>
        pergunta.qpTexto?.includes(inputFilter)
      )
      : perguntaList;

  useEffect(() => {
    handleGetPergunta();
  }, []);

  return (
    <Flex gap="2rem" justifyContent="" flexDirection="column">
      <ModalCrud
        dados={filterPerguntas}
        isOpen={isOpen}
        onClose={onClose}
        configParams={{ qsId, ultima }}
        originParams={originParams}
        handlerChange={async () => {
          handleGetPergunta();
        }}
      />
      <Breadcrumb spacing="8px" separator={<IoIosArrowForward />}>
        <BreadcrumbItem>
          <BreadcrumbLink as={ReactLink} to="/gestao-questionarios">
            Questionários
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink>{slugQuestao}</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink>Perguntas</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Divider color={colors.gray_blue} />
      <Flex gap="2rem" justifyContent="space-between" flexDirection="row">
        <Heading as="h3" size="md">
          Pergunta:
        </Heading>
        <Button
          variant="outline"
          border="1px"
          color={colors.blue}
          borderColor={colors.blue}
          _hover={{ bg: colors.blue, color: '#fff' }}
          _active={{ bg: colors.textBlue }}
          type="submit"
          onClick={() => {
            setOriginParams(null);
            onOpen();
          }}
        >
          Cadastrar pergunta
        </Button>
      </Flex>
      <Flex gap="1rem" justifyContent="space-between" flexDirection="column">
        <Input
          icon={{ icon: BsSearch, size: 20, color: colors.blue }}
          queryDeelay={1000}
          placeholder="Digite sua pesquisa aqui"
          handlerChange={(e) => setInputFilter(e)}
        />
      </Flex>
      <Table
        ordem={(props: ordenarProps) => alterarOrdem(props)}
        ordemData={ordemList}
        isLoading={isLoading}
        data={filterPerguntas}
        tableParams={tableParams}
        actionComponents={actionComponents}
        handlerAction={handlerAction}
      />

      {showDeletarModal && (
        <ConfirmarExclusaoModal
          close={(value: boolean) => {
            setShowDeletarModal(value);
          }}
          confirmar={() => {
            deletarPergunta();
          }}
        />
      )}
    </Flex>
  );
};

export { GestaoPerguntas };

