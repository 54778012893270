import { Box, Flex, Text } from '@chakra-ui/react'
import { useEffect, useRef } from 'react'
import { Spinner } from 'reactstrap'

import { useConsultaContext } from 'contexts/Consulta'
import { CirurgiasType, ResultExamesType } from 'contexts/Consulta/types'
import { useAlert, useApiRequest } from 'hooks'

import { DateInputForm, DateInputFormRefProps } from '../../components/date-input-form'

type ButtonTextProps = {
  type: 'confirm' | 'cancel'
}

const ButtonText = ({ type }: ButtonTextProps) => (
  <>
    {' '}
    <i className={type === 'cancel' ? 'fa fa-ban' : 'fa fa-trash'} />{' '}
    {type === 'cancel' ? 'Cancelar' : 'Apagar'}{' '}
  </>
)

export const CirurgiasExames = () => {
  const alert = useAlert()
  const { consulta: dadosConsulta } = useConsultaContext()

  const cirurgiasApi = useApiRequest<CirurgiasType[]>(
    'aswusuprontcirurgias',
    []
  )
  const examesApi = useApiRequest<ResultExamesType[]>(
    'aswusuprontresultexames',
    []
  )

  const loadCirurgiasExames = () => {
    if (dadosConsulta != undefined) {
      cirurgiasApi.request({
        method: '_list',
        usuarioCPF: dadosConsulta.dadosConsulta.usuarioCPF,
      });
      examesApi.request({
        method: '_list',
        usuarioCPF: dadosConsulta.dadosConsulta.usuarioCPF,
      });
    }
  }

  useEffect(() => {
    loadCirurgiasExames();
  }, [dadosConsulta]);

  const actionModal = (
    title: string,
    text: string,
    onConfirm: () => Promise<void>
  ) => {
    alert
      .warning({
        title: title,
        html: text,
        showCancelButton: true,
        cancelButtonText: <ButtonText type="cancel" />,
        confirmButtonText: <ButtonText type="confirm" />,
      })
      .then(async (response) => {
        if (response.isConfirmed) {
          await onConfirm()
          alert.success({
            title: 'Apagado com successo',
          })
        }
      })
  }

  return (
    <Box>
      <Box marginBottom={'16px'}>
        <Box>
          <Flex width={'100%'} alignItems={'center'}>
            <Text flex={'1'} fontSize={'md'} fontWeight={'normal'} color={'gray.600'}>
              Cirurgias Prévias{' '}
              {cirurgiasApi.isLoading && <Spinner size="sm" />}
            </Text>

          </Flex>
          <Box>
            <div className="form-control">
              {cirurgiasApi.state.map((item, index) => {
                return (
                  <DateInputForm
                    originalData={item}
                    key={item.pcGUID}
                    data={item.pcData}
                    descricao={item.pcDescricao}
                  />
                )
              })}
            </div>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box marginBottom={'16px'}>
          <Box>
            <Flex width={'100%'} alignItems={'center'}>
              <Text flex={'1'} fontSize={'md'} fontWeight={'normal'} color={'gray.600'}>
                Resultados de Exames
                {examesApi.isLoading && <Spinner size="sm" />}
              </Text>

            </Flex>
            <Box>
              <div className="form-control">
                {examesApi.state.map((medicamento, i) => {
                  return (
                    <DateInputForm
                      originalData={medicamento}
                      key={medicamento.reGUID}
                      data={medicamento.reData}
                      descricao={medicamento.reDescricao}
                    />
                  )
                })}
              </div>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
