import { FormEvent, useEffect, useState } from 'react';
import { RightIcon } from 'components/icons';
import { Button, Col, Label, Row } from 'reactstrap';
import ReactInputMask from 'react-input-mask';
import { useAlert, useApiRequest, useUser } from 'hooks';
import { LocalType } from './types';
import { Select } from '@chakra-ui/react';
import { IEpisodioConsulta, IEpisodioEncaminhamento } from 'types/episodio';
import { WrapperLoading } from 'components/wraper-loading';
import { Loading } from 'components/Loading';
import * as S from './styles';
import { api } from 'services/api';

type Props = {
  episodio: IEpisodioEncaminhamento;
  onClose: (reload?: boolean) => void;
};

type PsType = {
  psCPF: string;
  psNome: string;
  peDescricao: string;
};

type HorarioType = `${number}:${number}`;
type AgendaType = {
  manha: HorarioType[][];
  tarde: HorarioType[][];
  noite: HorarioType[][];
  madrugada: HorarioType[][];
};

const listaNomeTurno: Array<keyof AgendaType> = [
  'madrugada',
  'manha',
  'tarde',
  'noite',
];

const getNameTurno = (t: keyof AgendaType) => {
  return {
    madrugada: 'Madrugada',
    manha: 'Manhã',
    tarde: 'Tarde',
    noite: 'Noite',
  }[t];
};

const agendaApiInitialState = {
  madrugada: [],
  manha: [],
  tarde: [],
  noite: [],
};

export function PreServEncaminhamentoUsuarioAgenda(props: Props) {
  const user = useUser();
  const { episodio } = props;
  const cpf = episodio.usuarioCPF;

  if (!cpf) {
    return <>Usuário não encontrado!</>;
  }

  const alert = useAlert();

  const [isLoading, setIsLoading] = useState(false);
  const [tipoAgenda, setTipoAgenda] = useState<'F' | 'T' | ''>('');
  const [laDescricao, setLaDescricao] = useState('fake');
  const [psCPF, setPsCPF] = useState('');
  const [turno, setTurno] = useState<keyof AgendaType>();
  const [horario, setHorario] = useState<HorarioType>();
  const [dataHoraAgenda, setDataHoraAgenda] = useState('');

  const apiAgendar = useApiRequest('aswagendarusuario');
  const apiConvPlanoSUS = useApiRequest<{
    nmPlanoConvenio: string;
    sgConvenio: string;
  }>('aswepisodiosms');

  const localApi = useApiRequest<LocalType[]>('aswagendalocaisatendimento', []);
  const psApi = useApiRequest<PsType[]>('aswepisodiosms', []);
  const agendapi = useApiRequest<AgendaType>(
    'aswagendarusuario',
    agendaApiInitialState
  );

  const verificaTurno = (key: keyof AgendaType) =>
    agendapi.state[key]?.length > 0;

  const clearTurnoHorario = () => {
    setTurno(null);
    setHorario(null);
    agendapi.setState(agendaApiInitialState);
  };

  const disabledSubmitForm = () =>
    (tipoAgenda === 'F' && !laDescricao) || !psCPF || !turno || !horario;

  useEffect(() => {
    if (!!episodio?.sgInstSaude) {
      clearTurnoHorario();
      psApi.request({
        method: 'listarProfissionais',
        especialidade: episodio.epEspecialidade,
        sgInstSaude: episodio.sgInstSaude,
      });
    }
  }, [episodio]);

  useEffect(() => {
    if (!!psCPF && tipoAgenda === 'F') {
      clearTurnoHorario();
      localApi.request({
        method: 'listarLocalAtendimentoPs',
        sgCliente: user.sgCliente,
        sgInstSaude: episodio.sgInstSaude,
        psCPF,
      });
    }
  }, [psCPF, tipoAgenda]);

  useEffect(() => {
    if (!!dataHoraAgenda && !!tipoAgenda && !!psCPF) {
      clearTurnoHorario();
      agendapi.request({
        method: '_loadHorariosDisponiveis',
        caDtCarga: dataHoraAgenda,
        sgInstSaude: episodio?.sgInstSaude,
        agTeleFisico: tipoAgenda,
        sgCliente: user?.sgCliente,
        psCPF,
        laDescricao,
      });
    }
  }, [dataHoraAgenda, tipoAgenda, laDescricao]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const result = await apiAgendar.submit({
      cpf,
      instSelected: { key: episodio.sgInstSaude, value: episodio.sgInstSaude },
      sgConvenio: {
        key: apiConvPlanoSUS.state.sgConvenio,
        value: apiConvPlanoSUS.state.nmPlanoConvenio,
      },
      planoSelected: {
        key: apiConvPlanoSUS.state.sgConvenio,
        value: apiConvPlanoSUS.state.nmPlanoConvenio,
      },
      especialidadeSelected: episodio.epEspecialidade,
      profissionalSaudeSelected: psCPF,
      periodoSelecionado: turno,
      horarioSelecionado: horario,
      dataHoraAgenda,
      method: '_agendar',
      agTeleFisico: tipoAgenda,
      laDescricao,
      sgCliente: user?.sgCliente,
    });

    if (result.success) {
      alert.success({
        title: result.message || 'Agendamento efetuado',
      });

      setIsLoading(true);

      await api.post('aswepisodiosms', {
        method: 'vinculaConsultaEncaminhamento',
        sgCliente: user.sgCliente,
        usuarioCPF: cpf,
        sgInstSaude: episodio.sgInstSaude,
        epIdentificador: episodio.epIdentificador,
        eeTipo: episodio.eeTipo,
        dtInclusao: episodio.eeData,
        idConsulta: result?.data?.idConsulta,
      });

      props.onClose(true);
      return;
    }

    alert.warning({
      title: result.message || 'Não foi possível efetuar o agendamento',
    });
  };

  useEffect(() => {
    if (user?.sgCliente) {
      apiConvPlanoSUS.request({
        method: 'buscaConvPlanoSUS',
        usuarioCPF: cpf,
        sgCliente: user.sgCliente,
        sgInstSaude: episodio.sgInstSaude,
      });
    }
  }, [user]);

  return (
    <S.Container>
      <S.Row>
        <div>
          <Button color="primary" onClick={() => props.onClose()}>
            <i className="fa fa-arrow-left" />
          </Button>
          <b className="ms-2 fw-bold">
            <span style={{ textTransform: 'uppercase' }}>
              ENCAMINHAMENTO DE USUÁRIOS <RightIcon width={20} />
              {episodio.nmUsuario}
            </span>
          </b>
        </div>
        <div></div>
      </S.Row>
      <S.ScheduleForm onSubmit={handleSubmit}>
        <Row>
          <Col md="12" className="mt-4">
            <Row>
              <Col
                md="2"
                onClick={() => {
                  setTipoAgenda('T');
                }}
              >
                <input
                  id="T"
                  type="radio"
                  name="tipoAgenda"
                  value="T"
                  className="pointer"
                />
                <Label for="T" className="pointer">
                  Telessaúde
                </Label>
              </Col>
              <Col
                md="2"
                onClick={() => {
                  setTipoAgenda('F');
                }}
              >
                <input
                  id="F"
                  type="radio"
                  name="tipoAgenda"
                  value="F"
                  className="pointer"
                />
                <Label for="F" className="pointer">
                  Presencial
                </Label>
              </Col>
            </Row>
          </Col>
        </Row>

        {tipoAgenda && (
          <>
            <Row>
              <Col md="6">
                <Label className="mt-4 label-single-line" for="cpf">
                  CPF
                </Label>
                <ReactInputMask
                  type="text"
                  name="cpf"
                  className={'form-control'}
                  mask="999.999.999-99"
                  alwaysShowMask={true}
                  disabled
                  value={cpf}
                />
              </Col>
              <Col md="6">
                <Label className="mt-4 label-single-line" for="nomeSobrenome">
                  Nome do usuário
                </Label>
                <input
                  type="text"
                  className="form-control w-100"
                  name="nomeSobrenome"
                  required
                  value={episodio.nmUsuario}
                  disabled
                  placeholder="..."
                />
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Label className="mt-4 label-single-line" for="cpf">
                  Especialidade
                </Label>
                <input
                  type="text"
                  className="form-control w-100"
                  name="nomeSobrenome"
                  required
                  value={episodio.epEspecialidade}
                  disabled
                  placeholder="..."
                />
              </Col>
              <Col md="6">
                <>
                  <Label className="mt-4 label-single-line">
                    Selecione Profissional de saúde
                  </Label>
                  <WrapperLoading isLoading={psApi.isLoading}>
                    <Select
                      maxH={'36px'}
                      borderRadius={'10px'}
                      value={psCPF}
                      onChange={(event) => {
                        const { value } = event.target;
                        setPsCPF(value);
                      }}
                      placeholder={'Selecione...'}
                    >
                      {psApi.state.map((item, index) => {
                        return (
                          <option key={index} value={item.psCPF}>
                            {item.psNome}
                          </option>
                        );
                      })}
                    </Select>
                  </WrapperLoading>
                </>
              </Col>
              {tipoAgenda === 'F' && (
                <Col md="6">
                  <Label className="mt-4 label-single-line">
                    Selecione o local de atendimento
                  </Label>
                  <WrapperLoading isLoading={localApi.isLoading}>
                    <Select
                      maxH={'36px'}
                      borderRadius={'10px'}
                      value={laDescricao}
                      onChange={(event) => {
                        const { value } = event.target;
                        setLaDescricao(value);
                      }}
                      placeholder={'Selecione...'}
                    >
                      {localApi.state.map((item, index) => {
                        return (
                          <option key={index} value={item.laDescricao}>
                            {item.laNome}
                          </option>
                        );
                      })}
                    </Select>
                  </WrapperLoading>
                </Col>
              )}
              <Col md="6">
                <Label
                  className="mt-4 label-single-line"
                  htmlFor="convenioValidade"
                >
                  Data do Agendamento
                </Label>
                <WrapperLoading isLoading={agendapi.isLoading}>
                  <input
                    type="date"
                    onChange={(event) => {
                      setDataHoraAgenda(event.target.value);
                    }}
                    className="form-control"
                    min={new Date().toISOString().split('T')[0]}
                  />
                </WrapperLoading>
              </Col>
              <Col md="6">
                <Label className="mt-4 label-single-line">Serviço</Label>
                <input
                  type="text"
                  className="form-control w-100"
                  name="servico"
                  required
                  value={episodio.servico}
                  disabled
                  placeholder="..."
                />
              </Col>
            </Row>

            {listaNomeTurno.some(verificaTurno) && (
              <S.HoursContainer>
                {listaNomeTurno
                  .filter(verificaTurno)
                  .map((t: keyof AgendaType) => (
                    <Button
                      color="primary"
                      className="mx-1 btn-title"
                      onClick={() => {
                        setTurno(t);
                        setHorario(null);
                      }}
                      active={turno === t}
                    >
                      {getNameTurno(t)}
                    </Button>
                  ))}
              </S.HoursContainer>
            )}

            {turno && (
              <>
                <S.HoursContainer>
                  {agendapi.state[turno].map((items) => (
                    <S.HoursColumn key={items.join('')}>
                      {items.map((h) => (
                        <Button
                          color="primary"
                          key={h}
                          active={h === horario}
                          onClick={() => setHorario(h)}
                        >
                          {h}
                        </Button>
                      ))}
                    </S.HoursColumn>
                  ))}
                </S.HoursContainer>
              </>
            )}

            <S.ConfirmScheduleContainer>
              <Button
                type="submit"
                color="primary"
                disabled={disabledSubmitForm()}
              >
                Confirmar agendamento
              </Button>
            </S.ConfirmScheduleContainer>
          </>
        )}
      </S.ScheduleForm>

      {(apiAgendar.isLoading || isLoading) && <Loading />}
    </S.Container>
  );
}
