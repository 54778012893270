import { Props } from './types';
import * as S from './styles';
import { Button } from 'reactstrap';
import { useUser } from 'hooks';

export function Home({}: Props) {
  const user = useUser();
  return (
    <S.Container>
      <S.Col>
        <S.Img />
      </S.Col>
      <S.Col>
        <p>Olá, {user?.nome}</p>
        <Button color="primary" onClick={user?.handleLogout}>
          SAIR
        </Button>
      </S.Col>
    </S.Container>
  );
}
