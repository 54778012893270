import { useEffect, useRef, useState } from 'react';
import { LOG } from 'shared/util';
import {
  MemedPacientInterface,
  MemedPrescricaoResultType,
} from '../shared/model/memed-pacient';
import { useApiRequest } from './useApi';

export const getMemedURL = () => {
  const base = `memed.com.br`;
  const NODE_ENV = process.env.NODE_ENV;

  if (NODE_ENV === 'development') return `beta.${base}`;
  return base;
};

const src = `https://${getMemedURL()}/modulos/plataforma.sinapse-prescricao/build/sinapse-prescricao.min.js`;

type Status = 'idle' | 'loading' | 'ready' | 'error';
type ScriptElt = HTMLScriptElement | null;

type Props = {
  onGeneratePrescription: (prescription: MemedPrescricaoResultType) => void;
  sgCliente: string;
  sgInstSaude: string;
  paciente: MemedPacientInterface;
  token: string;
  psCPF: string;
};

export const useMemed = ({
  onGeneratePrescription,
  sgCliente,
  sgInstSaude,
  paciente,
  token,
  psCPF,
}: Props) => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState<Status>(src ? 'loading' : 'idle');
  const api = useApiRequest('aswprofissionalsaude', null);

  const script = useRef<ScriptElt>();

  const setStateFromEvent = (event: Event) => {
    setStatus(event.type === 'load' ? 'ready' : 'error');
  };

  const setAttributeFromEvent = (event: Event) => {
    script.current?.setAttribute(
      'data-status',
      event.type === 'load' ? 'ready' : 'error'
    );
  };

  const logOut = () => {
    try {
      if (script.current) {
        script.current.removeEventListener('load', setStateFromEvent);
        script.current.removeEventListener('error', setStateFromEvent);

        script.current.removeEventListener('load', setAttributeFromEvent);
        script.current.removeEventListener('error', setAttributeFromEvent);
      }

      if (typeof MdHub !== 'undefined') {
        MdHub.server.unbindEvents();
        delete window.MdHub;
      }

      if (typeof MdSinapsePrescricao !== 'undefined') {
        delete window.MdSinapsePrescricao;
      }

      const scripts = Array.from(document.getElementsByTagName('script'));
      if (scripts && scripts.length > 0) {
        scripts.forEach((script) => {
          if (script.src.includes('memed.com.br'))
            script?.parentNode?.removeChild(script);
        });
      }
    } catch (err: any) {
      console.log('useMemed() -> Erro no logout()', err);
    }
  };

  const definePacient = (pacient: MemedPacientInterface): Promise<any> => {
    return new Promise(function (resolve, reject) {
      try {
        MdHub.command
          .send('plataforma.prescricao', 'setPaciente', {
            idExterno: pacient?.idExterno,
            nome: pacient?.nome,
            nome_social: pacient?.nome_social,
            endereco: pacient?.endereco,
            cidade: pacient?.cidade,
            telefone: pacient?.telefone,
            peso: pacient?.peso,
            altura: pacient?.altura,
            nome_mae: pacient?.nome_mae,
            dificuldade_locomocao: pacient?.dificuldade_locomocao,
            cpf: paciente.cpf,
            data_nascimento: paciente.data_nascimento,
          })
          .then(
            (response: any) => {
              LOG('definePacient -> ', { response });
              resolve('');
            },
            (error) => {
              LOG('definePacient error-> ', error);
              reject(error);
            }
          );
      } catch (err: any) {
        LOG('definePacient catch-> ', err);
      }
    });
  };

  const initEventsMemed = (onGenerate: Props['onGeneratePrescription']) => {
    try {
      MdSinapsePrescricao.event.add(
        'core:moduleHide',
        (module: { moduleName: string }) => {
          if (module.moduleName === 'plataforma.prescricao') {
            console.info('====== Módulos da Memed encerrados ======');
          }
        }
      );

      MdSinapsePrescricao.event.add(
        'core:moduleInit',
        (module: { name: string }) => {
          if (module.name === 'plataforma.prescricao') {
            MdHub.command.send('plataforma.prescricao', 'setFeatureToggle', {
              // Desativa a opção de excluir um paciente
              deletePatient: false,
              // Desabilita a opção de remover/trocar o paciente
              removePatient: false,
              // Esconde o formulário de confirmação dos dados do paciente para receituário de controle especial
              // caso a cidade e o endereço tenham sido definidos com o comando `setPaciente`
              editPatient: false,
            });

            MdHub.event.add('prescricaoImpressa', onGenerate);
          }
        }
      );
    } catch (err: any) {
      console.error(err);
    }
  };

  const addScript = () => {
    script.current = document.querySelector(`script[src="${src}"]`);

    if (!script.current) {
      script.current = document.createElement('script');
      script.current.setAttribute('type', 'text/javascript');
      script.current.src = src;
      script.current.async = true;
      script.current.setAttribute('data-status', 'loading');
      script.current.setAttribute(`data-color`, '#0b64c2');
      script.current.setAttribute(`data-token`, token);

      document.body.appendChild(script.current);

      script.current.addEventListener('load', setAttributeFromEvent);
      script.current.addEventListener('error', setAttributeFromEvent);
    } else {
      setStatus(script.current.getAttribute('data-status') as Status);
    }

    script.current.addEventListener('load', setStateFromEvent);
    script.current.addEventListener('error', setStateFromEvent);
    setTimeout(() => {
      setLoading(false);
    }, 3500);
  };

  const open = async () => {
    LOG('memed -> open', { paciente });
    setLoading(true);
    await definePacient(paciente);
    setLoading(false);
    MdHub.module.show('plataforma.prescricao');
  };

  const bootstrap = () => {
    if (!!token) {
      api
        .submit({
          method: '_updateMemedLogo',
          sgCliente,
          sgInstSaude,
          medicotoken: token,
          psCPF,
        })
        .then(addScript)
        .catch((error) => {
          LOG('Cath no bootstrap->_updateMemedLog', error);
        });
    } else {
      setError('Não existe token memed do médico');
    }
  };

  useEffect(() => {
    if (status === 'ready') {
      initEventsMemed(onGeneratePrescription);
    }
  }, [status]);

  useEffect(() => {
    if (token) {
      bootstrap();
    }

    return () => {
      logOut();
    };
  }, []);

  return { open, loading, logOut, error };
};
