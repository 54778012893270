import { Flex, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react';
import { colors } from 'theme/colors';

/**
 * @docs
 * - https://chakra-ui.com/docs/components/radio/usage
 *
 * @returns FormOneOption
 */
type TCheck = {
  options: {
    title: string;
    value: string;
  }[];
  title: string;
  selected: (param: string) => void;
};

const FormOneOption = ({ options, title, selected }: TCheck) => {
  return (
    <Flex gap="2rem" justifyContent="" flexDirection="column">
      <Text fontSize="2xl">{title}</Text>
      <Stack>
        <RadioGroup colorScheme="blue" defaultValue="1">
          <Stack spacing={4} direction="column">
            {options.map((param, key) => (
              <Radio
                key={key}
                value={param.value.toLowerCase().replace(/\s/g, '')}
                onChange={(e) => selected(String(e.target.value))}
              >
                {param.title}
              </Radio>
            ))}
          </Stack>
        </RadioGroup>
      </Stack>
    </Flex>
  );
};

export { FormOneOption };
