import { ConsultaEmergencialProvider } from 'contexts/ConsultaEmergencial';
import { useApiOnMount } from 'hooks';
import { useParams } from 'react-router-dom';
import { Row } from 'reactstrap';
import { AppointmentTabsEmergencial } from '../consulta/AppointmentTabsEmergencial';
import { HeaderEmergencial } from './header';

export const consultaEmergencial = (props) => {

    const { idConsulta } = useParams<{ idConsulta: string }>();
    const { psCpf } = useParams<{ psCpf: string }>();
    const { data: consulta, } =
        useApiOnMount('aswagendausuprofsaude', {
            method: '_buscarAtendimentoEmergencial',
            idConsulta: idConsulta,
            psCPF: psCpf
        });
    return (
        <>
            <ConsultaEmergencialProvider {...props}>
                {consulta && <HeaderEmergencial consulta={consulta} />}
                <Row style={{ marginTop: `900px` }}>
                    <div className="table-responsive">
                        <div id="atendimento-form-view" className="form-view">
                            <div className="body-update">
                                <AppointmentTabsEmergencial />
                            </div>
                        </div>
                    </div>
                </Row>
            </ConsultaEmergencialProvider>
        </>
    )
}