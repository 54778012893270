import axios from 'axios';
import { useEffect, useState } from 'react';
import { api } from 'services/api';
import { LOG } from 'shared/util';
import { useOnlyMounted } from './useOnlyMounted';

const BASE_URL = 'https://servicodados.ibge.gov.br/api/v1/';

export type ValueLabelType = {
  value: string;
  label: string;
};

export const useFetchEstadosCidades = () => {
  const { onlyMounted } = useOnlyMounted();
  const [isFetchingEstadosCidades, setIsFetchingEstadosCidades] =
    useState(false);
  const [estados, setEstados] = useState<ValueLabelType[]>([]);
  const [cidades, setCidades] = useState<ValueLabelType[]>([]);
  useEffect(() => {
    axios.get(`${BASE_URL}/localidades/estados`).then(({ data }) => {
      onlyMounted(() => {
        setEstados(
          data.map((item) => ({
            value: item.sigla,
            label: item.nome,
          }))
        );
        setIsFetchingEstadosCidades(false);
      });
    });
  }, []);

  return {
    isFetchingEstadosCidades,
    estados,
    cidades,
    getCidades: (estado: string) => {
      setCidades([]);
      setIsFetchingEstadosCidades(true);
      axios
        .get(`${BASE_URL}localidades/estados/${estado}/municipios`)
        .then(({ data }) => {
          onlyMounted(() => {
            setCidades(
              data.map((item) => ({
                value: item.nome,
                label: item.nome,
              }))
            );
            setIsFetchingEstadosCidades(false);
          });
        });
    },
  };
};

export type GrupoMenuType = ValueLabelType & { tipo?: string };

export const useFetchOptionsFormPS = () => {
  const { onlyMounted } = useOnlyMounted();
  const [isFetchingOptionsFormPS, setIsFetchingOptionsFormPS] = useState(false);

  const [orgaoEmissorList, setOrgaoEmissorList] = useState<ValueLabelType[]>(
    []
  );
  const [especialidadeList, setEspecialidadeList] = useState<ValueLabelType[]>(
    []
  );
  const [grupoMenuList, setGrupoMenuList] = useState<GrupoMenuType[]>([]);
  const [instList, setInstList] = useState<ValueLabelType[]>([]);
  const [locaisAtendimento, setLocaisAtendimento] = useState<ValueLabelType[]>(
    []
  );

  useEffect(() => {
    setIsFetchingOptionsFormPS(true);
    api
      .post('aswformularioopcoes', {
        method: '_listarOpcoesFormPS',
      })
      .then(({ data }) => {
        onlyMounted(() => {
          if (Array.isArray(data.data.orgaoEmissor)) {
            setOrgaoEmissorList(data.data.orgaoEmissor);
          }
          if (Array.isArray(data.data.especialidade)) {
            setEspecialidadeList(data.data.especialidade);
          }
          if (Array.isArray(data.data.grupoMenu)) {
            setGrupoMenuList(data.data.grupoMenu);
          }
          if (Array.isArray(data.data.instList)) {
            setInstList(data.data.instList);
          }
          if (Array.isArray(data.data.locaisAtendimento)) {
            setLocaisAtendimento(data.data.locaisAtendimento);
          }
        });
      })
      .catch((err) => {
        LOG('Catch na função useFetchOptionsFormPS()', err);
      })
      .finally(() => {
        setIsFetchingOptionsFormPS(false);
      });
  }, []);

  return {
    isFetchingOptionsFormPS,
    orgaoEmissorList,
    especialidadeList,
    grupoMenuList,
    instList,
    locaisAtendimento,
  };
};
