import { useState } from 'react';
import { Table } from 'reactstrap';
import cpfMask from 'shared/util/cpfMask';
import { InstType } from '../../types';

import { ModalCriarAdministrador } from '../modal-cadastrar-administradores';

type UserType = {
  cpf: string;
  email: string;
  nome: string;
  telefone: string;
  cargo?: string;
  departamento?: string;
  instituicoes: InstType[];
};

type Props = {
  users: UserType[];
  sgCliente: string;
  requestOnEditOrInsert: () => void;
};

const ListaAdmin = ({ sgCliente, users, requestOnEditOrInsert }: Props) => {
  const [dataToEdit, setDataToEdit] = useState<UserType | null>(null);

  const handleEditUser = (itemToEdit: UserType) => {
    setDataToEdit(itemToEdit);
  };

  return (
    <div className="table-responsive">
      {users.length > 0 ? (
        <div id="cadastro-administradores-table-list" className="table-list">
          <Table
            id="cadastro-administradores-table-list"
            responsive
            aria-describedby="cadastro-administradores-heading"
            className={'table-hover table-striped table-responsive-css'}
          >
            <thead className={'thead-light'}>
              <tr>
                <th>Nome do Administrador</th>
                <th>CPF</th>
                <th>Cargo</th>
                <th className="text-center">Departamento</th>
                <th className="text-center">Editar</th>
              </tr>
            </thead>
            <tbody>
              {users &&
                users.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.nome}</td>
                      <td>{cpfMask(item.cpf)}</td>
                      <td>{item.cargo}</td>
                      <td>{item.departamento}</td>
                      <td>
                        <div
                          className="btn btn-primary btn-lg"
                          onClick={() => handleEditUser(item)}
                        >
                          <i className="fa fa-pencil-alt" />
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      ) : (
        <div className="alert alert-warning">
          Nenhum Administrador encontrado
        </div>
      )}
      {!!dataToEdit && (
        <ModalCriarAdministrador
          isNew={false}
          onClose={() => {
            setDataToEdit(null);
          }}
          dataToEdit={dataToEdit}
          requestOnEditOrInsert={requestOnEditOrInsert}
          sgCliente={sgCliente}
        />
      )}
    </div>
  );
};

export default ListaAdmin;
