import { Box, Select } from '@chakra-ui/react';
import dayjs from 'dayjs';
import React, { useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useHistory } from 'react-router-dom';
import { Alert, Button, Col, Label, Row, Table } from 'reactstrap';

import { DialogConfirm, DialogConfirmRefProps } from 'components/dialog-confirm';
import { Loading } from 'components/Loading';
import { WrapperLoading } from 'components/wraper-loading';
import { AgendaPresencialType } from 'pages/atendimento-triagem';
import { LOG } from 'shared/util';

import { useAlert, useApiRequest, useFlashText, useUser } from '../../hooks/index';
import { Modaltrigem } from '../../pages/atendimento/components/modal-triagem/index';
import { api } from '../../services/api';
import cancelIcon from '../../shared/assets/cancelIcon.svg';
import description from './assets/description.svg';

import './style.css';

type MotivoCancelamentoType = {
  mcDescricao: string;
  mcOrdemExibicao: number;
  mcPSPaciente: string;
};

const ButtonHistory = (props: any) => {
  const { setShowHistory, user, item } = props;
  const [loading, setLoading] = React.useState<boolean>(false);
  const alert = useAlert();

  const fnSearchButton = async () => {
    setLoading(true);
    try {
      const { data } = await api.post('aswhistusuario', {
        method: '_list',
        sgCliente: user?.sgCliente,
        psCpf: user?.cpf,
        usuarioCPF: item.usuarioCPF,
      });
      if (!data.data.length) {
        alert.warning({
          title: 'Não existe histórico de atendimento para esse paciente',
        });
      }
      setShowHistory(data.data[0]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <button onClick={fnSearchButton}>
        {loading ? (
          <i
            style={{ color: '#56a1e1', height: '22px' }}
            className="fas fa-spinner fa-spin"
          ></i>
        ) : (
          <img style={{ height: '19px' }} src={description} alt="Description" />
        )}
      </button>
    </>
  );
};

function ConsultaAgendamentos() {
  const [startDate, setStartDate] = useState<Date>();
  const [searchInput, setSearchInput] = useState('');
  const [showHistory, setShowHistory] = useState(null);
  const [agendamentos, setAgendamentos] = useState([]);
  const [error, setError] = useState<String>();
  const user = useUser();
  const [atendimentoSelecionado, setAtendimentoSelecionado] = useState<any>();
  const [motivoCancelamento, setMotivoCancelamento] =
    useState<MotivoCancelamentoType | null>(null);
  const [loading, setLoading] = useState(false);
  const motivoFlash = useFlashText();
  const confirmCancelRef = useRef<DialogConfirmRefProps>(null);
  const motivosHook = useApiRequest<Array<MotivoCancelamentoType>>(
    'aswmotivocancelamento',
    []
  );

  const agendamentosHook = useApiRequest<AgendaPresencialType[]>(
    'aswagendausuprofsaude',
    []
  );

  const cols = {
    attendanceDay: 'Data de atendimento',
    hour: 'Horário',
    usuario: 'Paciente',
    action: '',
  };

  const consultaAgenda = useApiRequest<any[]>('aswagendausuprofsaude', []);

  async function fnsearchSchedule() {
    if (!startDate) {
      return setError('Data obrigatória');
    } else if (consultaAgenda.isLoading) {
      return;
    }
    setError('');
    setLoading(true);
    if (user.grupoEscolhido.grupo != 'medico') {
      const data = {
        method: '_listarConsultaAgendamentosRecepcao',
        sgCliente: user?.sgCliente,
        sgInstSaude: user?.grupoEscolhido?.sgInstSaude,
        ...(searchInput ? { search: searchInput } : { search: '' }),
        ...(startDate && {
          dataInicial: dayjs(startDate).format('YYYY-MM-DD'),
        }),
      };
      const res = await api.post('aswagendausuprofsaude', data);
      setAgendamentos(res.data.data);
      // await consultaAgenda.request(data);
    } else {

      const data = {
        method: '_listarConsultaAgendamentos',
        psCPF: user?.cpf,
        sgCliente: user?.sgCliente,
        sgInstSaude: user?.grupoEscolhido?.sgInstSaude,
        ...(searchInput ? { search: searchInput } : { search: '' }),
        ...(startDate && {
          dataInicial: dayjs(startDate).format('YYYY-MM-DD'),
        }),
      };
      const res = await api.post('aswagendausuprofsaude', data);
      setAgendamentos(res.data.data);
    }
    setLoading(false);
  }

  function fnSearchDate(date: Date) {
    setStartDate(date);
  }

  const formatDate = (date: string) => {
    const newDate = new Date(date);

    const day = String(newDate.getDate()).padStart(2, '0');
    const month = String(newDate.getMonth() + 1).padStart(2, '0'); // Os meses começam em 0
    const year = newDate.getFullYear();
    const hour = String(newDate.getHours()).padStart(2, '0');
    const minute = String(newDate.getMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} ${hour}:${minute}`;
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="breadcrumb">
          <h6>CONSULTA DE AGENDAMENTOS</h6>
        </div>
      </div>
      <div></div>
      <form action="">
        <Row className="__container-input">
          <Col xl="9" xs="12" md="9" className="__parcel-input">
            <label className="__label-agendamento" htmlFor="schedulingSearch">
              Buscar pelo paciente
            </label>
            <div className="input-group mb-3">
              <input
                aria-describedby="search-input"
                name="search"
                id="schedulingSearch"
                placeholder="CPF ou Usuário"
                type="text"
                className="form-control"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
              <div
                role={consultaAgenda.isLoading ? 'button' : ''}
                onClick={() => fnsearchSchedule()}
                className="input-group-append"
              >
                <span
                  className="input-group-text"
                  id="search-input"
                  style={{
                    borderRadius: '0px 10px 10px 0px',
                    cursor: 'pointer',
                  }}
                >
                  {consultaAgenda.isLoading ? (
                    <>
                      <i
                        style={{ padding: '4px', color: '#56a1e1' }}
                        className="fas fa-spinner fa-spin"
                      ></i>
                    </>
                  ) : (
                    <i
                      style={{ padding: '4px', color: '#56a1e1' }}
                      className="fa fa-search"
                      color="warning"
                    />
                  )}
                </span>
              </div>
            </div>
          </Col>
          <Col xs="12" md="3" className="__parcel-input">
            <label
              className="__label-agendamento"
              htmlFor="schedulingSearchDate"
            >
              Data de pesquisa
            </label>
            <ReactDatePicker
              name="dataInicial"
              required
              id="schedulingSearchDate"
              className="form-control"
              placeholderText="Data Inicial"
              selected={startDate}
              onChange={(date: Date) => {
                fnSearchDate(date);
              }}
              value={startDate as unknown as string}
              dateFormat="dd/MM/yyyy"
            />
            {error ? (
              <p className="__text-error text-warning">
                Data inicial obrigatória.
              </p>
            ) : null}
          </Col>
        </Row>
      </form>
      <div className="__table-agendamento">
        {agendamentos?.length === 0 ? (
          <div
            style={{ width: '100%', textAlign: 'center', marginBottom: '0' }}
            className="alert alert-warning"
          >
            Nada encontrado
          </div>
        ) : (
          <table
            style={{
              width: '100%',
              borderRadius: '10px',
              minWidth: '510px',
            }}
          >
            <thead>
              {/* {Object.values(cols).map((collumn) => {
                  return (
                    <td key={collumn}>
                      <strong>{collumn}</strong>
                    </td>
                  );
                })} */}
              <tr className="__tr-agendamento-head">
                <td>Paciente</td>
                <td>Especialidade</td>
                <td>Profissional</td>
                <td>Local</td>
                <td>Data</td>
                <td></td>
              </tr>
            </thead>
            <tbody className="__billing-management-table-body">
              {agendamentos.map((item: any, index: Number) => (
                <tr>
                  <td>{item.usuario}</td>
                  <td>{item.peDescricao}</td>
                  <td>{item.psNome}</td>
                  <td>{item.localAtendimento}</td>
                  <td>{formatDate(item.agendamento)}</td>
                  <td>
                    <ButtonHistory
                      key={index}
                      {...{ setShowHistory, user, item }}
                    />
                  </td>
                  <td>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        cursor: 'pointer',
                      }}
                      title="Cancelar Agendamento"
                      onClick={() => {
                        setAtendimentoSelecionado(item);
                        if (motivosHook.state.length < 1) {
                          motivosHook.request({
                            method: '_listSpecifics',
                          });
                        }
                        setMotivoCancelamento(null);
                        setAtendimentoSelecionado(item);
                        confirmCancelRef?.current?.open();
                      }}
                    >
                      <img src={cancelIcon} alt="cancelIcon" />
                    </div>
                  </td>
                </tr>
              ))}
              {/* {consultaAgenda?.state?.map((item: any, index: Number) => {
                const data = {
                  attendanceDay: item.agendamento
                    .substring(0, 10)
                    .split('-')
                    .reverse()
                    .join('/'),
                  hour: item.agendamento.substring(11),
                  usuario: item.usuario,
                  action: '',
                };
                return (
                  <tr className="__tr-agendamento" key={`cols-${index}`}>
                    {Object.keys(cols).map((collumn, i) => {
                      return collumn === 'action' ? (
                        <td
                          style={{ minWidth: '20px' }}
                          key={`td-${Math.random()}`}
                        >
                          <ButtonHistory
                            key={i}
                            {...{ setShowHistory, user, item }}
                          />
                        </td>
                      ) : data[collumn] === 'Realizado' ? (
                        <td key={`td-${Math.random()}`}>
                          {data[collumn] === 'Realizado'}
                          <p className="__td-status">{data[collumn]}</p>
                        </td>
                      ) : (
                        <td key={`td-${Math.random()}`}>{data[collumn]}</td>
                      );
                    })}
                  </tr>
                );
              })} */}
            </tbody>
          </table>
        )}

        <DialogConfirm
          ref={confirmCancelRef}
          titulo="Cancelar o atendimento"
          cancelButton={{ text: 'Cancelar' }}
          buttons={[
            {
              text: 'Confirma',
              color: 'red',
              // closeOnClick: true,
              onClick: () => {
                LOG(motivoCancelamento);
                if (!motivoCancelamento) {
                  motivoFlash.setText('Selecione o motivo!');
                  return;
                }
                agendamentosHook
                  .submit({
                    method: '_cancel',
                    agDataHoraAgenda: atendimentoSelecionado?.agendamento,
                    agIdConsulta: atendimentoSelecionado?.agIdConsulta,
                    psCPF: atendimentoSelecionado?.psCPF,
                    usuarioCPF: atendimentoSelecionado?.usuarioCPF,
                    mcDescricao: motivoCancelamento?.mcDescricao,
                    mcPSPaciente: motivoCancelamento?.mcPSPaciente,
                    sgCliente: 'asg',
                    sgInstSaude: atendimentoSelecionado.sgInstSaude,
                  })
                  .then((response) => {
                    if (response.success) {
                      agendamentosHook.setState(
                        agendamentosHook.state.filter(
                          (a) =>
                            a.idConsulta !== atendimentoSelecionado?.idConsulta
                        )
                      );
                    }
                    fnsearchSchedule();
                    confirmCancelRef?.current?.close();
                  });
              },
            },
          ]}
        >
          <Box flex="1">
            <WrapperLoading isLoading={motivosHook.isLoading}>
              <Select
                size={'md'}
                value={motivoCancelamento?.mcDescricao || ''}
                onChange={(event) => {
                  const { value } = event.target;
                  if (value) {
                    event.persist();
                    const motivo = motivosHook.state.find(
                      (m) => m.mcDescricao === value
                    );
                    if (motivo) {
                      setMotivoCancelamento(motivo);
                    }
                  }
                }}
              >
                <option value="">Selecione o motivo</option>
                {motivosHook.state.map((item, index) => {
                  return (
                    <option key={index} value={item.mcDescricao}>
                      {item.mcDescricao}
                    </option>
                  );
                })}
              </Select>
            </WrapperLoading>
            <small>&nbsp;{motivoFlash.text}</small>
          </Box>
        </DialogConfirm>
        {loading && <Loading />}
      </div>
      {showHistory != null && (
        <Modaltrigem
          eHistorico
          idConsulta={showHistory.agIdConsulta}
          nomeUsuario={showHistory?.pacienteNome}
          usuarioNomeSocial={showHistory?.usuarioNomeSocial}
          close={() => {
            setShowHistory(null);
          }}
        />
      )}
    </>
  );
}

export default ConsultaAgendamentos;
