import { InputHTMLAttributes, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Col, Label, Row } from 'reactstrap';
import { cepMask } from 'shared/util/cepMask';
import cpfMask from 'shared/util/cpfMask';
import phoneMask from 'shared/util/phoneMask';

const masks = {
  cpf: (value: string) => cpfMask(String(value)),
  phone: (value: string) => phoneMask(String(value)),
  cep: (value: string) => cepMask(String(value)),
};

type Props = InputHTMLAttributes<HTMLInputElement> & {
  mask?: keyof typeof masks;
  label?: string;
};

export const Input = ({ name, label, className, mask, ...rest }: Props) => {
  const {
    register,
    getValues,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
  } = useFormContext();

  const errorMessage = errors[name]?.message;

  const [restProps, setRestProps] = useState<Partial<Props>>({});

  useEffect(() => {
    if (mask && masks[mask]) {
      const values = getValues();
      if (mask === 'phone') {
        setRestProps({
          minLength: 14,
          maxLength: 15,
        });
      }
      if (
        values[name] &&
        (typeof values[name] === 'string' || typeof values[name] === 'number')
      ) {
        setValue(name, masks[mask](String(values[name])));
      }
    }
  }, []);

  const element = watch(name);

  useEffect(() => {
    if (mask) {
      if (masks[mask]) {
        setValue(name, masks[mask](element));
      }
    }

    if (errorMessage && element) {
      clearErrors(name);
    }
  }, [element]);

  return (
    <>
      <Row>
        {!!label && (
          <Col md="12">
            <Label className="mt-4 label-single-line" for={name}>
              {label}
            </Label>
          </Col>
        )}
        <Col md="12">
          <input
            {...register(name)}
            id={name}
            className={className || 'form-control'}
            {...rest}
            {...restProps}
          />
          {!!errorMessage && (
            <span className="text-warning">
              <small>{errorMessage}</small>
            </span>
          )}
        </Col>
      </Row>
    </>
  );
};
