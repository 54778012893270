import { Box, Flex, Heading, Modal, ModalBody, ModalContent, ModalOverlay, Stack, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

type ModalPrescricoesHistoricoProps = {
  isOpen: boolean;
  onClose: () => void;
  content?: any;
};

type Prescription = {
  dtInclusao: string;
  hpAltoCusto: string;
  hpCidId: string;
  hpCodeReceita: string;
  hpComposicao: string;
  hpControleEspecial: string;
  hpDataSolicitacao: string;
  hpFabricante: string;
  hpMedicamentoDescricao: string;
  hpMedicamentoId: string;
  hpMedicamentoNome: string;
  hpMemedDigitsPresc: string;
  hpMemedIdPresc: string;
  hpMemedLinkPresc: string;
  hpPosologia: string;
  hpPreco: string;
  hpQuantidade: string;
  hpReceituario: string;
  hpTarja: string;
  hpTipo: string;
  hpTitularidade: string;
  hpUID: string;
};

interface buildGroupProps {
  groupItem: string;
  groups: any;
}

const BuildGroup = ({ groupItem, groups }: buildGroupProps) => {
  const [build, setBuild] = useState(false);
  useEffect(() => {
    Promise.all(
      groups?.map((group) => {
        if (groupItem === group) {
          const newGroups = groups.filter((grupo) => grupo !== group);
          groups = newGroups;
          setBuild(true);
        } else {
          setBuild(false);
        }
      })
    );
  }, []);

  return (
    <>
      {build && (
        <Flex bgColor="#cecece">
          <Text p={1}>Prescrição {groupItem}</Text>
        </Flex>
      )}
    </>
  );
};

function ModalPrescricoesHistorico({
  isOpen,
  onClose,
  content,
}: ModalPrescricoesHistoricoProps) {
  const [prescricoes, setPrescricoes] = useState([]);

  useEffect(() => {
    if (isOpen) {
      teste();
    }
  }, [isOpen]);

  const teste = () => {
    const grupos = Object.values(
      content.listagemPrescricao.reduce((accumulator, objeto) => {
        const hpMemedIdPresc = objeto.hpMemedIdPresc;
        const hpMemedLinkPresc = objeto.hpMemedLinkPresc;

        // Inicializa um array vazio se ainda não houver um grupo para hpMemedIdPresc
        accumulator[hpMemedIdPresc] = accumulator[hpMemedIdPresc] || {
          hpMemedIdPresc,
          hpMemedLinkPresc,
          objetos: [],
        };

        // Adiciona o objeto ao grupo correspondente
        accumulator[hpMemedIdPresc].objetos.push(objeto);

        return accumulator;
      }, {})
    );
    setPrescricoes(grupos);
    console.log(grupos);
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'5xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Stack gap={4} my={'4'}>
            '
            <Heading fontSize={'lg'} fontWeight={'normal'}>
              Histórico de Prescrições
            </Heading>
            {content.listagemPrescricao?.length === 0 ? (
              <Heading fontSize={'sm'} color={'gray.400'} fontWeight={'normal'}>
                Não há prescrições disponíveis.
              </Heading>
            ) : (
              prescricoes?.map((item: any, index: number) => {
                return (
                  <>
                    <Flex bgColor="#b6dcf1" color="#000000">
                      <Text
                        p={1}
                        ml={3}
                        cursor="pointer"
                        onClick={() =>
                          window.open(item.hpMemedLinkPresc, '_blank')
                        }
                      >
                        Prescrição {item.hpMemedIdPresc}
                      </Text>
                    </Flex>
                    {item.objetos.map((prescricao, index) => (
                      <Flex
                        alignItems={'flex-start'}
                        width={'100%'}
                        key={index}
                      >
                        <Text mr={'4'}>{index + 1}.</Text>
                        <Box flex={1}>
                          <Text size={'lg'} fontWeight={'semibold'}>
                            {prescricao.hpMedicamentoNome}
                          </Text>
                          <Text size={'xs'}>{prescricao.hpPosologia}</Text>
                        </Box>
                        <Text textAlign={'right'}>
                          {prescricao.hpQuantidade} embalagem(ns)
                        </Text>
                      </Flex>
                    ))}
                  </>
                );
              })
            )}
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export { ModalPrescricoesHistorico };

// content.listagemPrescricao?.map(
//   (item: Prescription, index: number) => {
//     return (
//       <>
//         <Flex
//           alignItems={'flex-start'}
//           width={'100%'}
//           key={index}
//         >
//           <Text mr={'4'}>{index + 1}.</Text>
//           <Box flex={1}>
//             <Text size={'lg'} fontWeight={'semibold'}>
//               {item.hpMedicamentoNome}
//             </Text>
//             <Text size={'xs'}>{item.hpPosologia}</Text>
//           </Box>
//           <Text textAlign={'right'}>
//             {item.hpQuantidade} embalagem(ns)
//           </Text>
//         </Flex>
//       </>
//     );
