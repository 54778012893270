import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactPaginate from 'react-paginate';
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from 'reactstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// @ts-nocheck
import * as Yup from 'yup';

import { api } from 'services/api';

import { BASE_API_VERSION_PATH } from 'shared/util';
import {
  AppProps,
  jsonParse,
  useRouter,
} from '../../../components/util/entity-utils';
import {
  Link,
  Translate,
  useTranslation,
} from '../../../components/util/translate-component';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-quill/dist/quill.snow.css';

const ReactQuill =
  typeof window === 'object' ? require('react-quill') : () => false;

const MySwal = withReactContent(Swal);

export interface ICadastroTelemedicinaProps extends AppProps {
  telemedicina?: any;
  extraFilters?: any;
  activePage?: any;
  sort?: any;
  _router: any;
  props: any;
  order?: any;
  itemsPerPage?: any;
  totalItems?: any;
  state?: any;
  getEntityFiltersURL?: Function;
  setState?: Function;
  t?: Function;
  cadastroTelemedicinaList?: any;
  setCadastroTelemedicinaList?: Function;
  cadastroTelemedicinaEntity?: any;
  setCadastroTelemedicinaEntity?: Function;

  getAllEntities?: Function;
  sortEntities?: Function;
  sortFunction?: Function;
  handlePagination?: Function;
  deleteEntityModal?: Function;
  saveEntity?: Function;
  renderHeaderUpdate?: Function;
  renderBodyUpdate?: Function;
  renderNewModal?: Function;
  renderUpdateModal?: Function;
  fieldsBase?: any;
  openUpdateModal?: Function;
  cancelFilters?: Function;
  filterEntity?: Function;
  renderFilter?: Function;
  renderTable?: Function;
  handleSyncList?: Function;
  getEntitiesFetch?: Function;
  deleteEntityFetch?: Function;
  getEntityFetch?: Function;
  createEntityFetch?: Function;
  updateEntityFetch?: Function;
  getCadastroTelemedicinaState?: Function;
  returnFunction?: Function;
  cadastroInstituicaos?: any;
  userAccount?: any;
  loading?: any;
}

const apiUrl = BASE_API_VERSION_PATH + 'aswtelemedicina';

export const getEntitiesFetch = async (
  context: ICadastroTelemedicinaProps,
  telemedicina: any,
  extraFilters: any,
  page: any,
  size: any,
  sort: any,
  selectFields = '',
  authorizationBearer = '',
  getAccount = false
) => {
  const res = await api.post(apiUrl, {
    method: '_list',
    telemedicina,
  });
  const _json = await res.data;

  return res;
};

const getEntityFetch: any = async (
  context: ICadastroTelemedicinaProps,
  entity: any,
  selectFields = ''
) => {
  const res = await api.post(apiUrl, {
    method: '_find',
    telemedicina: entity.telemedicina,
  });

  const _json = await res.data;

  const json = { ..._json['data'], id: entity.id };

  context.setCadastroTelemedicinaEntity(json);

  return json;
};

const createEntityFetch: any = async (
  context: ICadastroTelemedicinaProps,
  entity: any,
  listFiltersPage: any
) => {
  //TODO
  const res = await api.post(apiUrl, {
    method: '_insert',
    telemedicina: entity.telemedicina,
    tokenTelemedicina: entity.tokenTelemedicina,
    emailTokenTelemedicina: entity.emailTokenTelemedicina,
    host: entity.host,
    path: entity.path,
    port: entity.port,
    methodTelemedicina: entity.method,
    queryGroup: entity.queryGroup,
  });
  const json = await res.data;
  context.getAllEntities();
  return json;
};

const updateEntityFetch: any = async (
  context: ICadastroTelemedicinaProps,
  entity: any,
  listFiltersPage: any
) => {
  const res = await api.post(apiUrl, {
    method: '_update',
    // TODO: direto do context p/ evitar modificacoes externas
    telemedicina: context?.cadastroTelemedicinaEntity?.telemedicina,
    //tokenTelemedicina: entity.tokenTelemedicina,
    emailTokenTelemedicina: entity.emailTokenTelemedicina,
    //host: entity.host,
    //path: entity.path,
    //port: entity.port,
    //methodTelemedicina: entity.method,
    //queryGroup: entity.queryGroup,
  });
  const json = await res.data;
  context.getAllEntities();
  return json;
};

const deleteEntityFetch: any = async (
  context: ICadastroTelemedicinaProps,
  entity: any,
  listFiltersPage: any
) => {
  const res = await api.post(apiUrl, {
    method: '_delete',
    nome: entity.nome,
  });
  const json = await res.data;
  context.getAllEntities();
  return true;
};

const getEntityFiltersURL = (
  context: ICadastroTelemedicinaProps,
  offset = null
) => {
  return (
    '' +
    (context.state.telemedicina
      ? 'telemedicina=' + context.state.telemedicina + '&'
      : '') +
    (context.state.baseFilters
      ? 'baseFilters=' + context.state.baseFilters + '&'
      : '') +
    (context.state.extraFilters
      ? 'extraFilters=' +
        encodeURI(JSON.stringify(context.state.extraFilters)) +
        '&'
      : '') +
    'page=' +
    context.state.activePage +
    '&' +
    'size=' +
    context.state.itemsPerPage +
    '&' +
    (offset !== null ? 'offset=' + offset + '&' : '') +
    'sort=' +
    context.state.sort +
    ',' +
    context.state.order +
    '&' +
    ''
  );
};

const getAllEntities = async (
  context: ICadastroTelemedicinaProps,
  _fieldsBase: any
) => {
  // TODO
  _fieldsBase = _fieldsBase ? _fieldsBase : {};
  const { telemedicina, extraFilters, activePage, itemsPerPage, sort, order } =
    context.state;

  const res = await context.getEntitiesFetch(
    telemedicina,
    typeof _fieldsBase.extraFilters !== undefined
      ? _fieldsBase.extraFilters
      : extraFilters,
    typeof _fieldsBase.activePage !== undefined
      ? _fieldsBase.activePage
      : context.state.activePage,
    typeof _fieldsBase.itemsPerPage !== undefined
      ? _fieldsBase.itemsPerPage
      : context.state.itemsPerPage,
    `${_fieldsBase.sort ? _fieldsBase.sort : context.state.sort},${
      _fieldsBase.order ? _fieldsBase.order : context.state.order
    }`
  );

  context.setState({
    ...context.state,
    totalItems: 0, // era paara pagionação
  });

  const json = await res.data.data;
  context.setCadastroTelemedicinaList(json);
};

const sortEntities = (
  context: ICadastroTelemedicinaProps,
  _newState = undefined
) => {
  context.getAllEntities();
  const endURL = `?page=${context.state.activePage}&sort=${context.state.sort},${context.state.order}`;
  context._router.push(`?${context.getEntityFiltersURL()}`);
};

const sortFunction = (context: ICadastroTelemedicinaProps, p: any) => () => {
  context.setState({
    ...context.state,
    order:
      p === context.state.sort && context.state.order === 'asc'
        ? 'desc'
        : 'asc',
    sort: p,
  });
  context.getAllEntities({
    order:
      p === context.state.sort && context.state.order === 'asc'
        ? 'desc'
        : 'asc',
    sort: p,
  });
};

const handlePagination = (
  context: ICadastroTelemedicinaProps,
  activePage: any
) => {
  context.setState({
    ...context.state,
    activePage,
  });
  context.getAllEntities({ activePage });
};

const deleteEntityModal = (
  context: ICadastroTelemedicinaProps,
  _cadastroTelemedicinaEntity: any
) => {
  MySwal.fire({
    title: (
      <Translate t={context.t} contentKey="entity.delete.title">
        Confirm delete operation
      </Translate>
    ),
    html: (
      <Translate
        t={context.t}
        contentKey="cadastroTelemedicina.delete.question"
        interpolate={{ id: _cadastroTelemedicinaEntity.id }}
      >
        Are you sure you want to delete this CadastroTelemedicina?
      </Translate>
    ),
    icon: 'warning',
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonColor: '#e70015',
    cancelButtonText: (
      <>
        {' '}
        <i className="fa fa-ban" />
        &nbsp;{' '}
        <Translate t={context.t} contentKey="cadastroTelemedicina.btnCancel">
          Cancel
        </Translate>
      </>
    ),
    confirmButtonText: (
      <>
        {' '}
        <i className="fa fa-trash" />
        &nbsp;
        <Translate t={context.t} contentKey="cadastroTelemedicina.btnDelete">
          Delete
        </Translate>
      </>
    ),
  }).then(async (response) => {
    if (response.isConfirmed) {
      await context.deleteEntityFetch(_cadastroTelemedicinaEntity, {
        reloadList: false,
      });
      context.getAllEntities();
      MySwal.fire({
        title: (
          <Translate t={context.t} contentKey="entity.delete.title.confirmed">
            Confirmed delete operation
          </Translate>
        ),
        icon: 'success',
      });
    }
  });
};

const saveEntity = async (
  context: ICadastroTelemedicinaProps,
  data: any,
  isNew: any
) => {
  //TODO
  if (isNew) {
    context['state'] = {
      ...context.state,
      showModalForm: false,
      showModalEdit: null,
    };
    await context.createEntityFetch(data, {});
    await context.getAllEntities();
  } else {
    context['state'] = {
      ...context.state,
      showModalForm: false,
      showModalEdit: null,
    };
    await context.updateEntityFetch(data);
    await context.getAllEntities();
  }
};

const renderHeaderUpdate = (
  context: ICadastroTelemedicinaProps,
  isNew: any
) => {
  const baseFilters =
    context.state && context.state['baseFilters']
      ? context.state['baseFilters']
      : null;

  return (
    <>
      <div id="page-heading">
        <span className="page-header ml-3">
          {isNew ? (
            <Translate
              t={context.t}
              contentKey="cadastroTelemedicina.home.createLabel"
            >
              Create a CadastroTelemedicina
            </Translate>
          ) : (
            <Translate
              t={context.t}
              contentKey="cadastroTelemedicina.home.editLabel"
            >
              Edit a CadastroTelemedicina
            </Translate>
          )}
        </span>
      </div>
    </>
  );
};

type formDataType = {
  id?: number;
  telemedicina: string;
  tokenTelemedicina: string;
  emailTokenTelemedicina: string;
  methodTelemedicina: string;
  host: string;
  path: string;
  port: string;
  queryGroup: string;
};

const renderBodyUpdate = (context, isNew: boolean = true) => {
  const requiredFieldsMessage = 'Campo obrigatório';
  const schema = Yup.object().shape<
    Partial<Record<keyof formDataType, Yup.AnySchema>>
  >({
    telemedicina: Yup.string().required(requiredFieldsMessage),
    emailTokenTelemedicina: Yup.string()
      .email('Insira um e-mail válido')
      .required(requiredFieldsMessage),
    methodTelemedicina: Yup.string(),
    host: Yup.string(),
    path: Yup.string(),
    port: Yup.string(),
    queryGroup: Yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const telemedicinaData = context?.cadastroTelemedicinaEntity;
  /**
   * Evita sobrecarga de renderização devido as N mudanças do "context"
   * Obs: Recomendado usar o useMemo para as mudanças de estado
   */
  useMemo(() => {
    reset({
      telemedicina: telemedicinaData?.telemedicina || '',
      tokenTelemedicina: telemedicinaData?.tokenTelemedicina || '',
      emailTokenTelemedicina: telemedicinaData?.emailTokenTelemedicina || '',
      methodTelemedicina: telemedicinaData?.methodTelemedicina || '',
      host: telemedicinaData?.host || '',
      path: telemedicinaData?.path || '',
      port: telemedicinaData?.port || '',
      queryGroup: telemedicinaData?.queryGroup || '',
    });
  }, [telemedicinaData]);

  const handleFormSubmit = async (data: any) => {
    try {
      await saveEntity(context, data, isNew);
      reset();
    } catch (err) {
      console.warn(err);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Row className="justify-content-center">
        <Col md="11">
          {context.loading ? (
            <p>Loading...</p>
          ) : (
            <Row className="row-cadastro-telemedicina-first-column">
              <Col md="4">
                <div>
                  <Row>
                    <Col md="12">
                      <Label
                        className="mt-4 label-single-line"
                        id="telemedicinaLabel"
                        for="cadastro-telemedicina-telemedicina"
                      >
                        <Translate
                          t={context.t}
                          contentKey="cadastroTelemedicina.telemedicina"
                        >
                          Nome da Telemedicina
                        </Translate>
                      </Label>
                    </Col>
                    <Col md="12">
                      <input
                        readOnly={!isNew}
                        {...register('telemedicina')}
                        id="cadastro-telemedicina-telemedicina"
                        type="text"
                        name="telemedicina"
                        className={'form-control'}
                      />
                      {errors?.telemedicina?.message && (
                        <div className="text-danger pt-2">
                          {errors?.telemedicina?.message}
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col md="4">
                <div>
                  <Row>
                    <Col md="12">
                      <Label
                        className="mt-4 label-single-line"
                        id="tokenTelemedicinaLabel"
                        for="cadastro-telemedicina-tokenTelemedicina"
                      >
                        <Translate
                          t={context.t}
                          contentKey="cadastroTelemedicina.tokenTelemedicina"
                        >
                          Token Telemedicina
                        </Translate>
                      </Label>
                    </Col>
                    <Col md="12">
                      <input
                        {...register('tokenTelemedicina')}
                        readOnly={!isNew}
                        id="cadastro-telemedicina-tokenTelemedicina"
                        type="text"
                        name="tokenTelemedicina"
                        className={'form-control'}
                      />
                      {errors?.tokenTelemedicina?.message && (
                        <div className="text-danger pt-2">
                          {errors?.tokenTelemedicina?.message}
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col md="4">
                <div>
                  <Row>
                    <Col md="12">
                      <Label
                        className="mt-4 label-single-line"
                        id="emailTokenTelemedicinaLabel"
                        for="cadastro-telemedicina-emailTokenTelemedicina"
                      >
                        <Translate
                          t={context.t}
                          contentKey="cadastroTelemedicina.emailTokenTelemedicina"
                        >
                          E-mail Token Telemedicina*
                        </Translate>
                      </Label>
                    </Col>
                    <Col md="12">
                      <input
                        readOnly={false}
                        {...register('emailTokenTelemedicina')}
                        id="cadastro-telemedicina-emailTokenTelemedicina"
                        type="text"
                        name="emailTokenTelemedicina"
                        className={'form-control'}
                      />
                      {errors?.emailTokenTelemedicina?.message && (
                        <div className="text-danger pt-2">
                          {errors?.emailTokenTelemedicina?.message}
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col md="4">
                <div>
                  <Row>
                    <Col md="12">
                      <Label
                        className="mt-4 label-single-line"
                        id="hostLabel"
                        for="cadastro-telemedicina-host"
                      >
                        <Translate
                          t={context.t}
                          contentKey="cadastroTelemedicina.host"
                        >
                          Host
                        </Translate>
                      </Label>
                    </Col>
                    <Col md="12">
                      <input
                        readOnly={!isNew}
                        {...register('host')}
                        id="cadastro-telemedicina-host"
                        type="text"
                        name="host"
                        className={'form-control'}
                      />
                      {errors?.host?.message && (
                        <div className="text-danger pt-2">
                          {errors?.host?.message}
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col md="4">
                <div>
                  <Row>
                    <Col md="12">
                      <Label
                        className="mt-4 label-single-line"
                        id="pathLabel"
                        for="cadastro-telemedicina-path"
                      >
                        <Translate
                          t={context.t}
                          contentKey="cadastroTelemedicina.path"
                        >
                          Path
                        </Translate>
                      </Label>
                    </Col>
                    <Col md="12">
                      <input
                        readOnly={!isNew}
                        {...register('path')}
                        id="cadastro-telemedicina-path"
                        type="text"
                        name="path"
                        className={'form-control'}
                      />
                      {errors?.path?.message && (
                        <div className="text-danger pt-2">
                          {errors?.path?.message}
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col md="2">
                <div>
                  <Row>
                    <Col md="12">
                      <Label
                        className="mt-4 label-single-line"
                        id="portLabel"
                        for="cadastro-telemedicina-port"
                      >
                        <Translate
                          t={context.t}
                          contentKey="cadastroTelemedicina.port"
                        >
                          Port
                        </Translate>
                      </Label>
                    </Col>
                    <Col md="12">
                      <input
                        readOnly={!isNew}
                        {...register('port')}
                        id="cadastro-telemedicina-port"
                        type="text"
                        name="port"
                        className={'form-control'}
                      />
                      {errors?.port?.message && (
                        <div className="text-danger pt-2">
                          {errors?.port?.message}
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col md="2">
                <div>
                  <Row>
                    <Col md="12">
                      <Label
                        className="mt-4 label-single-line"
                        id="methodLabel"
                        for="cadastro-telemedicina-method"
                      >
                        <Translate
                          t={context.t}
                          contentKey="cadastroTelemedicina.method"
                        >
                          Method
                        </Translate>
                      </Label>
                    </Col>
                    <Col md="12">
                      <input
                        readOnly={!isNew}
                        {...register('methodTelemedicina')}
                        id="cadastro-telemedicina-method"
                        type="text"
                        name="methodTelemedicina"
                        className={'form-control'}
                      />
                      {errors?.methodTelemedicina?.message && (
                        <div className="text-danger pt-2">
                          {errors?.methodTelemedicina?.message}
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col md="4">
                <div>
                  <Row>
                    <Col md="12">
                      <Label
                        className="mt-4 label-single-line"
                        id="queryGroupLabel"
                        for="cadastro-telemedicina-queryGroup"
                      >
                        <Translate
                          t={context.t}
                          contentKey="cadastroTelemedicina.queryGroup"
                        >
                          QueryGroup
                        </Translate>
                      </Label>
                    </Col>
                    <Col md="12">
                      <input
                        readOnly={!isNew}
                        {...register('queryGroup')}
                        id="cadastro-telemedicina-queryGroup"
                        type="text"
                        name="queryGroup"
                        className={'form-control'}
                      />
                      {errors?.queryGroup?.message && (
                        <div className="text-danger pt-2">
                          {errors?.queryGroup?.message}
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <ModalFooter>
        <Button
          color="primary"
          id="save-entity"
          className="float-right jh-create-entity"
        >
          <Translate t={context.t} contentKey="cadastroTelemedicina.btnSave">
            Save
          </Translate>
        </Button>
      </ModalFooter>
    </form>
  );
};

const renderNewModal = (
  context: ICadastroTelemedicinaProps,
  isNew: boolean = true
) => {
  return (
    <Modal
      className="cadastro-telemedicina-new-modal"
      size={'xl'}
      isOpen={context.state.showModalForm}
      toggle={() =>
        context.setState({ ...context.state, showModalForm: false })
      }
    >
      <ModalHeader
        toggle={() =>
          context.setState({ ...context.state, showModalForm: false })
        }
      >
        {context.renderHeaderUpdate(true)}
      </ModalHeader>
      <ModalBody>{context.renderBodyUpdate({}, true)}</ModalBody>
    </Modal>
  );
};

const renderUpdateModal = (context: ICadastroTelemedicinaProps) => {
  // TODO
  // console.log(context.state.showModalEdit, context.cadastroTelemedicinaEntity);
  return (
    <Modal
      className="cadastro-telemedicina-new-modal"
      size={'xl'}
      isOpen={!!context.state.showModalEdit}
      toggle={() =>
        context.setState({ ...context.state, showModalEdit: false })
      }
    >
      <ModalHeader
        toggle={() =>
          context.setState({ ...context.state, showModalEdit: false })
        }
      >
        {context.renderHeaderUpdate(false)}
      </ModalHeader>
      <ModalBody>{context.renderBodyUpdate(context, false)}</ModalBody>
    </Modal>
  );
};

const openUpdateModal = (
  context: ICadastroTelemedicinaProps,
  v: any,
  edit = true
) => {
  if (v.id) {
    context.getEntityFetch(v);
  } else {
    // context.setExampleEmbebedWithEnumEntity(v);
  }

  let p: any = null;
  p = v.instituicao;
  const instituicaoEntity = p
    ? { ...p, value: p.id, label: p['instSaude'] ? p.instSaude : '' }
    : p;

  context.setState({
    ...context.state,
    showModalEdit: v.id,
    idFormValue: v.id, // id,
    telemedicinaFormValue: v.telemedicina, // telemedicina,
    tokenTelemedicinaFormValue: v.tokenTelemedicina, // tokenTelemedicina,
    emailTokenTelemedicinaFormValue: v.emailTokenTelemedicina, // emailTokenTelemedicina,
    hostFormValue: v.host, // host,
    pathFormValue: v.path, // path,
    portFormValue: v.port, // port,
    methodFormValue: v.method, // method,
    queryGroupFormValue: v.queryGroup, // queryGroup,
    instituicaoFormValue: instituicaoEntity,
  });
};

const cancelFilters = (context: ICadastroTelemedicinaProps) => {
  const newState = {
    ...context.state,
    telemedicina: '',
  };
  context.setState(newState);
  context.sortEntities(newState);
};

const filterEntity = (context: ICadastroTelemedicinaProps) => {
  const newState = {
    ...context.state,
    activePage: 0,
  };
  context.setState(newState);
  context.sortEntities(newState);
};

const renderFilter = (context: ICadastroTelemedicinaProps) => {
  return (
    <>
      <div id="form-filter">
        <div className="row mt-1 ml-3 mr-3">
          {context.state.baseFilters !== 'telemedicina' ? (
            <Col
              md="12"
              className="col-filter-cadastro-telemedicina-telemedicina"
            >
              <Row className="mr-1 mt-1">
                <Label
                  id="telemedicinaLabel"
                  for="cadastro-telemedicina-telemedicina"
                >
                  <Translate
                    t={context.t}
                    contentKey="cadastroTelemedicina.telemedicina"
                  >
                    Nome da Telemedicina
                  </Translate>
                </Label>

                <input
                  className="form-control"
                  type="text"
                  name="telemedicina"
                  id="cadastro-telemedicina-telemedicina"
                  value={context.state.telemedicina}
                  onChange={(evt: any) =>
                    context.setState({
                      ...context.state,
                      telemedicina: evt.target.value,
                    })
                  }
                  placeholder={'Buscar'}
                />
              </Row>
            </Col>
          ) : null}
        </div>

        <div className="row mb-2 mr-4 justify-content-end pull-right">
          <Button
            id="search-submit"
            color="primary"
            onClick={() => context.filterEntity()}
          >
            <i className="fa fa-search" />
            &nbsp;
          </Button>
        </div>
      </div>
    </>
  );
};

export const renderTable = (context: ICadastroTelemedicinaProps) => {
  return (
    <>
      {context.cadastroTelemedicinaList &&
      context.cadastroTelemedicinaList.filter &&
      context.cadastroTelemedicinaList.filter(
        (v: any) => typeof v.deletedAt === 'undefined' || v.deletedAt === null
      ).length > 0 ? (
        <div id="cadastro-telemedicina-table-list" className="table-list">
          <Table
            id="cadastro-telemedicina-table-list"
            responsive
            aria-describedby="cadastro-telemedicina-heading"
            className={'table-hover table-striped table-responsive-css'}
          >
            <thead className={'thead-light'}>
              <tr>
                <th
                  className="hand"
                  onClick={context.sortFunction('telemedicina')}
                >
                  <Translate
                    t={context.t}
                    contentKey="cadastroTelemedicina.telemedicina"
                  />
                  <i
                    className={
                      context.state.sort === 'telemedicina'
                        ? context.state.order === 'asc'
                          ? 'fa fa-sort-up'
                          : 'fa fa-sort-down'
                        : 'fa fa-sort'
                    }
                  />
                </th>
                <th
                  className="hand"
                  onClick={context.sortFunction('emailTokenTelemedicina')}
                >
                  <Translate
                    t={context.t}
                    contentKey="cadastroTelemedicina.emailTokenTelemedicina"
                  />
                  <i
                    className={
                      context.state.sort === 'emailTokenTelemedicina'
                        ? context.state.order === 'asc'
                          ? 'fa fa-sort-up'
                          : 'fa fa-sort-down'
                        : 'fa fa-sort'
                    }
                  />
                </th>
                <th className="hand" onClick={context.sortFunction('host')}>
                  <Translate
                    t={context.t}
                    contentKey="cadastroTelemedicina.host"
                  />
                  <i
                    className={
                      context.state.sort === 'host'
                        ? context.state.order === 'asc'
                          ? 'fa fa-sort-up'
                          : 'fa fa-sort-down'
                        : 'fa fa-sort'
                    }
                  />
                </th>

                <th />
              </tr>
            </thead>
            <tbody>
              <tr></tr>
              {context.cadastroTelemedicinaList
                .filter(
                  (v: any) =>
                    typeof v.deletedAt === 'undefined' || v.deletedAt === null
                )
                .map((cadastroTelemedicina: any, i: number) => (
                  <tr key={`entity-${i}`}>
                    <td id="telemedicina-cell">
                      <b className="visible-xs">Nome da Telemedicina</b>
                      <div className="px-1">
                        {cadastroTelemedicina.telemedicina}
                      </div>
                    </td>
                    <td id="emailTokenTelemedicina-cell">
                      <b className="visible-xs">E-mail Token Telemedicina</b>
                      <div className="px-1">
                        {cadastroTelemedicina.emailTokenTelemedicina}{' '}
                      </div>
                    </td>
                    <td id="host-cell">
                      <b className="visible-xs">Host</b>
                      <div className="px-1">{cadastroTelemedicina.host}</div>
                    </td>
                    <td className="text-right">
                      <div className="btn-group flex-btn-group-container">
                        <Link href={'#'}>
                          <a
                            className={'btn btn-primary btn-sm'}
                            onClick={() => {
                              context.openUpdateModal(cadastroTelemedicina);
                            }}
                          >
                            <i className="fa fa-pencil-alt" />
                          </a>
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          {context.state.totalItems > context.state.itemsPerPage ? (
            <div
              className={
                context.cadastroTelemedicinaList &&
                context.cadastroTelemedicinaList.length > 0
                  ? ''
                  : 'd-none'
              }
            >
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                previousClassName={'page-item'}
                nextClassName={'page-item'}
                breakClassName={'page-item'}
                pageClassName={'page-item'}
                nextLinkClassName={'btn btn-primary'}
                previousLinkClassName={'btn btn-primary'}
                breakLinkClassName={'btn btn-primary'}
                pageLinkClassName={'btn btn-default'}
                activeLinkClassName={'btn btn-primary'}
                pageCount={
                  context.state.totalItems / context.state.itemsPerPage
                }
                breakLabel={'...'}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={(v: any) => {
                  if (v.selected >= 0) context.handlePagination(v.selected);
                }}
                containerClassName={'pagination'}
                activeClassName={'active'}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      ) : !context.loading ? (
        <div className="alert alert-warning">
          <Translate
            t={context.t}
            contentKey="cadastroTelemedicina.home.notFound"
          >
            No Telemedicinas found
          </Translate>
        </div>
      ) : (
        <div />
      )}
    </>
  );
};

const handleSyncList = (context: ICadastroTelemedicinaProps) => {
  context.sortEntities();
};

export const returnFunction = (context: ICadastroTelemedicinaProps) => {
  return (
    <div>
      <h4
        id="cadastro-telemedicina-heading"
        data-cy="CadastroTelemedicinaHeading"
      >
        <Translate t={context.t} contentKey="cadastroTelemedicina.home.title">
          Telemedicinas
        </Translate>
        <div className="d-flex justify-content-end">
          <Button
            onClick={() =>
              context.setState({
                ...context.state,
                showModalForm: true,
                idFormValue: null,
                telemedicinaFormValue: null,
                tokenTelemedicinaFormValue: null,
                emailTokenTelemedicinaFormValue: null,
                hostFormValue: null,
                pathFormValue: null,
                portFormValue: null,
                methodFormValue: null,
                queryGroupFormValue: null,

                instituicaoStartSelectOptions: undefined,
                instituicaoFormValue: null,
                isNew: true,
              })
            }
            color="primary"
            className="btn btn-primary float-right jh-create-entity"
          >
            <Translate
              t={context.t}
              contentKey="cadastroTelemedicina.btnNewItem"
            >
              Cadastrar Nova Telemedicina
            </Translate>
          </Button>{' '}
          &nbsp;
        </div>
      </h4>
      <div className="table-responsive">
        {context.renderFilter ? context.renderFilter() : () => {}}
        {context.renderTable ? context.renderTable() : () => {}}
        {context.renderNewModal ? context.renderNewModal() : () => {}}
        {context.renderUpdateModal ? context.renderUpdateModal() : () => {}}
      </div>
    </div>
  );
};

export const getCadastroTelemedicinaState = (
  context: ICadastroTelemedicinaProps,
  location: any
) => {
  const _urlBase = new URL(`http://localhost${location}`); // using a dummy url for parsing
  const extraFilters = jsonParse(
    _urlBase.searchParams.get('extraFilters') || '{}'
  );
  const baseFilters = _urlBase.searchParams.get('baseFilters') || '';
  const offset: any = _urlBase.searchParams.get('offset') || 0;
  const activePage: any = _urlBase.searchParams.get('page') || 0;
  const itemsPerPage: any = _urlBase.searchParams.get('size') || 20;
  const sortOrder: any = (_urlBase.searchParams.get('sort') || 'id,asc').split(
    ','
  );

  const telemedicina = _urlBase.searchParams.get('telemedicina') || '';
  return {
    activePage,
    itemsPerPage,
    sort: sortOrder[0] ? sortOrder[0] : 'id',
    order: sortOrder[1] ? sortOrder[1] : 'asc',
    baseFilters,
    extraFilters,
    offset,
    telemedicina,
  };
};

export const CadastroTelemedicina = (props: ICadastroTelemedicinaProps) => {
  const { t } = useTranslation();
  const _router = useRouter();
  const [cadastroTelemedicinaList, setCadastroTelemedicinaList] = useState(
    props.cadastroTelemedicinaList
      ? props.cadastroTelemedicinaList
      : ([] as any)
  );
  const [cadastroTelemedicinaEntity, setCadastroTelemedicinaEntity] = useState(
    props?.cadastroTelemedicinaEntity
      ? props?.cadastroTelemedicinaEntity
      : ({} as any)
  );

  const { loading, totalItems } = props;

  const [state, setState] = useState({
    telemedicina: props.telemedicina ? props.telemedicina : '',
    extraFilters: props.extraFilters ? props.extraFilters : '',
    activePage: props.activePage ? props.activePage : '0',
    itemsPerPage: props.itemsPerPage,
    totalItems: props.totalItems ? props.totalItems : 0,
    sort: props.sort ? props.sort : 'id',
    order: props.order ? props.order : 'asc',
  } as any);

  useEffect(() => {
    context.getAllEntities();
  }, [_router.asPath]);

  const context = {
    t,
    props,
    _router,
    state,
    setState,
    cadastroTelemedicinaList,
    setCadastroTelemedicinaList,
    cadastroTelemedicinaEntity,
    setCadastroTelemedicinaEntity,

    getAllEntities: props['getAllEntities']
      ? (_fieldsBase) => props['getAllEntities'](context, _fieldsBase)
      : (_fieldsBase) => getAllEntities(context, _fieldsBase),
    sortEntities: props['sortEntities']
      ? () => props['sortEntities'](context)
      : () => sortEntities(context),
    sortFunction: props['sortFunction']
      ? (p) => props['sortFunction'](context, p)
      : (p) => sortFunction(context, p),
    handlePagination: props['handlePagination']
      ? (activePage) => props['handlePagination'](context, activePage)
      : (activePage) => handlePagination(context, activePage),
    deleteEntityModal: props['deleteEntityModal']
      ? (_cadastroTelemedicinaEntity) =>
          props['deleteEntityModal'](context, _cadastroTelemedicinaEntity)
      : (_cadastroTelemedicinaEntity) =>
          deleteEntityModal(context, _cadastroTelemedicinaEntity),
    saveEntity: props['saveEntity']
      ? (isNew, data) => props['saveEntity'](context, data, isNew)
      : (isNew, data) => saveEntity(context, data, isNew),
    openUpdateModal: props['openUpdateModal']
      ? (v: any, edit = true) => props['openUpdateModal'](context, v, edit)
      : (v: any, edit = true) => openUpdateModal(context, v, edit),
    renderBodyUpdate: props['renderBodyUpdate']
      ? (cadastroTelemedicinaEntity: any, isNew: any) =>
          props['renderBodyUpdate'](context, isNew)
      : (cadastroTelemedicinaEntity: any, isNew: any) =>
          renderBodyUpdate(context, isNew),
    renderUpdateModal: props['renderUpdateModal']
      ? () => props['renderUpdateModal'](context)
      : () => renderUpdateModal(context),
    renderNewModal: props['renderNewModal']
      ? () => props['renderNewModal'](context)
      : () => renderNewModal(context),

    renderFilter: props['renderFilter']
      ? () => props['renderFilter'](context)
      : () => renderFilter(context),
    cancelFilters: props['cancelFilters']
      ? () => props['cancelFilters'](context)
      : () => cancelFilters(context),
    filterEntity: props['filterEntity']
      ? () => props['filterEntity'](context)
      : () => filterEntity(context),
    renderHeaderUpdate: props['renderHeaderUpdate']
      ? (isNew) => props['renderHeaderUpdate'](context, isNew)
      : (isNew) => renderHeaderUpdate(context, isNew),
    renderTable: props['renderTable']
      ? () => props['renderTable'](context)
      : () => renderTable(context),
    handleSyncList: props['handleSyncList']
      ? () => props['handleSyncList'](context)
      : () => handleSyncList(context),
    getEntitiesFetch: (
      telemedicina: any,
      extraFilters: any,
      page: any,
      size: any,
      sort: any,
      selectFields = '',
      authorizationBearer = ''
    ) =>
      getEntitiesFetch(
        context,
        telemedicina,
        extraFilters,
        page,
        size,
        sort,
        selectFields,
        authorizationBearer
      ),

    createEntityFetch: (entity: any, listFiltersPage: any) =>
      createEntityFetch(context, entity, listFiltersPage),
    updateEntityFetch: (entity: any, listFiltersPage: any) =>
      updateEntityFetch(context, entity, listFiltersPage),
    deleteEntityFetch: (id: any, listFiltersPage: any) =>
      deleteEntityFetch(context, id, listFiltersPage),
    getEntityFetch: (id) => getEntityFetch(context, id),

    getCadastroTelemedicinaState: props['getCadastroTelemedicinaState']
      ? (location) => props['getCadastroTelemedicinaState'](context, location)
      : (location) => getCadastroTelemedicinaState(context, location),
    getEntityFiltersURL: props['getEntityFiltersURL']
      ? () => props['getEntityFiltersURL'](context)
      : () => getEntityFiltersURL(context),
    return: props['returnFunction']
      ? () => props['returnFunction'](context)
      : () => returnFunction(context),
  };

  return context;
};

export default CadastroTelemedicina;
