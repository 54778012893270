import { ConsultaType } from 'contexts/Consulta/types';
import { useApiRequest, useUser } from 'hooks';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Table } from 'reactstrap';

import { RightIcon } from 'components/icons';

import * as S from './styles';
import { TypePacienteAPI } from 'components/formUsuarioSMS/types';
import { IEpisodioConsulta } from 'types/episodio';
import { dateTimeToScreen } from 'shared/util';

export function SmsConsultaPacienteDetalhes() {
  const user = useUser();
  const history = useHistory();
  const { cpf, identificadorSMS } = useParams<{
    cpf: string;
    identificadorSMS: string;
  }>();

  const userApi = useApiRequest<TypePacienteAPI>(
    'aswusuarios',
    {} as TypePacienteAPI
  );

  const episodioApi = useApiRequest<IEpisodioConsulta>(
    'aswepisodiosms',
    {} as IEpisodioConsulta
  );

  useEffect(() => {
    if (user.sgCliente && cpf) {
      userApi.request({
        cpf,
        method: '_findByCpf',
        sgCliente: user.sgCliente,
      });
      episodioApi.request({
        method: 'consultarEpisodioSMS',
        epIdentificador: identificadorSMS,
      });
    }
  }, [cpf, user]);

  if (!cpf) {
    // Tem de tratar
    return <>Nada encontrado!</>;
  }

  return (
    <S.Container>
      <S.Row>
        <div>
          <Button color="primary" onClick={history.goBack}>
            <i className="fa fa-arrow-left" />
          </Button>
          <b className="ms-2 fw-bold">
            <span style={{ textTransform: 'uppercase' }}>
              CONSULTA DE USUÁRIOS <RightIcon width={20} />
              {userApi?.state?.nmUsuario}
            </span>
          </b>
        </div>
        <div>
          <Button
            color="primary"
            onClick={() => {
              history.replace({
                pathname: 'perfil-completo',
                state: { isActive: true },
              });
            }}
          >
            Perfil Completo
          </Button>
        </div>
      </S.Row>

      <S.Title>Triagem Documentação</S.Title>
      {episodioApi.state.epData ? (
        <S.TableSMS>
          <S.THeadSMS>
            <S.TrSMS>
              <S.ThSMS>Data</S.ThSMS>
              <S.ThSMS>Status</S.ThSMS>
              <S.ThSMS>Comentário</S.ThSMS>
            </S.TrSMS>
          </S.THeadSMS>
          <tbody>
            <S.TrSMS>
              <S.TdSMS>
                {episodioApi.state.epDataTriagemMedica
                  ? dateTimeToScreen(
                      new Date(episodioApi.state.epDataTriagemMedica)
                    )
                  : ''}
              </S.TdSMS>
              <S.TdSMS>
                {episodioApi.state.invalidDocs ? (
                  <S.NotSuccessSpan>Pendente</S.NotSuccessSpan>
                ) : (
                  <S.SuccessSpan>Aprovado</S.SuccessSpan>
                )}
              </S.TdSMS>
              <S.TdSMS>{episodioApi.state.epTextoDoc}</S.TdSMS>
            </S.TrSMS>
          </tbody>
        </S.TableSMS>
      ) : (
        <S.EmptyMsg>Sem movimentações</S.EmptyMsg>
      )}

      <S.TitleTriagemMedica>Triagem Médica</S.TitleTriagemMedica>
      {episodioApi.state.epDataTriagemMedica ? (
        <S.TableSMS>
          <S.THeadSMS>
            <S.TrSMS>
              <S.ThSMS>Data</S.ThSMS>
              <S.ThSMS>Especialidade</S.ThSMS>
              <S.ThSMS>Status</S.ThSMS>
              <S.ThSMS>Comentário</S.ThSMS>
            </S.TrSMS>
          </S.THeadSMS>
          <tbody>
            <S.TrSMS>
              <S.TdSMS>
                {episodioApi.state.epDataTriagemMedica
                  ? dateTimeToScreen(
                      new Date(episodioApi.state.epDataTriagemMedica)
                    )
                  : ''}
              </S.TdSMS>
              <S.TdSMS>{episodioApi.state.epEspecialidade}</S.TdSMS>
              <S.TdSMS>
                {episodioApi.state.epStTriagemMedica === 'A' ||
                episodioApi.state.epStTriagemMedica === 'C' ? (
                  <S.SuccessSpan>
                    {episodioApi.state.epStTriagemMedicaTexto}
                  </S.SuccessSpan>
                ) : (
                  <S.NotSuccessSpan>
                    {episodioApi.state.epStTriagemMedicaTexto}
                  </S.NotSuccessSpan>
                )}
              </S.TdSMS>
              <S.TdSMS>{episodioApi.state.epTextoTriagemMedica}</S.TdSMS>
            </S.TrSMS>
          </tbody>
        </S.TableSMS>
      ) : (
        <S.EmptyMsg>Sem movimentações</S.EmptyMsg>
      )}

      <S.Title>Encaminhamento Prestador de Serviço</S.Title>
      {episodioApi.state.encaminhamentos &&
      episodioApi.state.encaminhamentos.length > 0 ? (
        <Table
          responsive
          className={'table-hover table-striped table-responsive-css mt-4'}
        >
          <thead className={'thead-light'}>
            <tr>
              <th>Tipo</th>
              <th>Texto</th>
              <th>Data</th>
            </tr>
          </thead>
          <tbody>
            {episodioApi.state.encaminhamentos.map((item) => (
              <tr>
                <td>{item.eeTipoTexto}</td>
                <td>{item.eeTexto}</td>
                <td>
                  {item.eeData ? dateTimeToScreen(new Date(item.eeData)) : ''}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <S.EmptyMsg>Sem movimentações</S.EmptyMsg>
      )}
    </S.Container>
  );
}
