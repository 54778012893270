import { useDisclosure } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Input } from 'components/form/input';
import { Select as Selecionar } from 'components/form/select';
import { Loading } from 'components/Loading';
import { useApiRequest, useUser } from 'hooks';
import { ChangeEvent, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import 'react-quill/dist/quill.snow.css';
import ReactSelect from 'react-select';
import { Button, Col, Label, Row } from 'reactstrap';
import { api } from 'services/api';
import { LOG } from 'shared/util';
import isValidCpf from 'shared/util/validacao-cpf';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as Yup from 'yup';
import { apiFind, viacepRequest } from '../../../components/util/entity-utils';
import cpfMask from '../../../shared/util/cpfMask';
import ModalConvenio from './modal-convenio';
import { DadosConvenio, DadosUsuarioType, PacienteType } from './types';

const MySwal = withReactContent(Swal);

const requiredFieldText = 'Campo obrigatório';

const getSchema = (isEdit = false) =>
  Yup.object().shape({
    email: Yup.string()
      .email('Insira um e-mail válido')
      .required(requiredFieldText),
    ...(isEdit
      ? {
          cpf: Yup.string()
            .required(requiredFieldText)
            .min(9, 'Formato inválido'),
        }
      : {}),
    nome: Yup.string().required(requiredFieldText).min(6, 'Formato inválido'),
    nmUsuario: Yup.string().min(2, 'Formato inválido'),
    usuarioCNS: Yup.string().min(2, 'Formato inválido'),
    telefone: Yup.string()
      .required(requiredFieldText)
      .min(10, 'Formato inválido'),
    nascimento: Yup.string().required(requiredFieldText),
    genero: Yup.string().required(requiredFieldText),
    cep: Yup.string().required(requiredFieldText).min(8, 'Formato inválido'),
    enderecoUsuario: Yup.string().required(requiredFieldText),
    numeroUsuario: Yup.string().required(requiredFieldText),
    bairro: Yup.string()
      .required(requiredFieldText)
      .typeError('Campo Obrigatório')
      .min(2, 'Formato inválido'),
    complementoUsuario: Yup.string().nullable(),
    cidadeUsuario: Yup.string()
      .required(requiredFieldText)
      .min(2, 'Formato inválido'),
    ufUsuario: Yup.string().required(requiredFieldText),
  });

type TypePaciente = {
  nmUsuario: string;
  emailUsuario: string;
  sgCliente: string;
  telefoneUsuario: string;
  usuarioCEP: string;
  usuarioCNS: string;
  usuarioCPF: string;
  usuarioCidade: string;
  usuarioEndereco: string;
  usuarioGenero: string;
  usuarioInstSaudePrefer: string;
  usuarioNasc: string;
  usuarioNomeSocial?: string;
  usuarioNumero: string;
  usuarioComplemento: string;
  usuarioPais: string;
  usuarioUF: string;
  dtCriacao: string;
  dtModificacao: string;
  usuarioBairro: null;
  usuarioCargo: null;
  dadosConvenio: {
    sgCliente: string;
    convenio: string;
    plano: string;
    instSaude: string;
    usuarioCPF: string;
    numeroCartao: string;
    cartaoData?: string;
  };
};

export const CadastroPaciente = () => {
  const user = useUser();

  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const formMethods = useForm<PacienteType>({
    resolver: yupResolver(getSchema(isEdit)),
    defaultValues: {},
  });

  const [state, setState] = useState({
    instituicaosId: {},
    convenioId: undefined,
    convenioStartFilter: undefined,
    planoId: {},
    planoStartFilter: undefined,
    instituicaosStartFilter: undefined,
    blockAddressFields: false,
    cartaoValidade: null,
  } as any);

  const [userForm, setUserForm] = useState<PacienteType>();
  const [optionsConvenio, setOptionsConvenio] = useState([]);
  const [optionsPlano, setOptionsoptionsPlano] = useState([]);
  const [optionsInst, setOptionsInst] = useState([]);
  const [instituicaoSelecionada, setInstituicaoSelecionada] = useState('');
  const [cpf, setCpf] = useState('');
  const [email, setEmail] = useState('');
  const [paciente, setPaciente] = useState<DadosUsuarioType | null>(null);
  const [convenioDados, setConvenioDados] = useState<DadosConvenio>({
    cartaoData: '',
    convenio: '',
    numeroCartao: '',
    plano: '',
    sgCliente: '',
  });
  const [instEdit, setInstEdit] = useState<{ value: string; label: string }>({
    value: '',
    label: '',
  });
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [disabledFilds, setDisabledFilds] = useState<boolean>(false);
  const [disableFixedFields, setDisableFixedFields] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [editable, setEditable] = useState<boolean>(true);

  const instApi = useApiRequest<Array<{ value: string; label: string }>>(
    'aswinstsaude',
    [],
    {
      method: '_listIsUsuarioLogado',
      callback: (value, setState) => {
        LOG({ value });
        setState(
          value.map((p: any) => ({
            value: p.sgInstSaude,
            label: p.instSaude,
          }))
        );
      },
    }
  );

  const userApi = useApiRequest<TypePaciente>(
    'aswusuarios',
    {} as TypePaciente
  );

  const buscarConvenios = async (instituicao: string) => {
    const result = await api.post('aswconvenioplano', {
      sgInstSaude: instituicao,
      method: '_listConvenioPlano',
    });
    let options = [];
    result.data.data.convenios.map((p: any) =>
      options.push({
        planos: p.planos,
        value: p.key,
        label: p.value,
      })
    );
    setState({
      ...state,
      convenioId: '',
      planoId: '',
      planoStartFilter: '',
    });
    setOptionsConvenio(options);
  };

  const [showModal, setShowModal] = useState(false);
  const [cpfError, setCpfError] = useState('');
  const [cpfInvalido, setCpfInvalido] = useState(false);
  const [emailError, setEmailError] = useState('');

  const cadastrarPaciente = async (data: any) => {
    const body = {
      method: cpfError != 'CPF já cadastrado' ? '_insert' : '_update',
      nmUsuario: data.nome,
      emailUsuario: data.email,
      usuarioNomeSocial: data.usuarioNomeSocial,
      usuarioCNS: data.usuarioCNS,
      telefoneUsuario: data.telefone,
      usuarioCEP: data.cep,
      usuarioCPF: cpf.replace(/[^0-9,]*/g, '').replace(',', '.'),
      usuarioCidade: data.cidadeUsuario,
      usuarioEndereco: data.enderecoUsuario,
      usuarioGenero: data.genero,
      usuarioInstSaudePrefer: instituicaoSelecionada,
      usuarioNasc: data.nascimento,
      usuarioNumero: data.numeroUsuario,
      usuarioComplemento: data.complementoUsuario,
      usuarioBairro: data.bairro,
      usuarioPais: 'Brasil',
      usuarioUF: data.ufUsuario,
      dadosConvenio: null,
      sgCliente: user?.sgCliente,
    };
    // MySwal.fire({
    //   title: 'Estamos criando o paciente',
    //   icon: 'warning',
    // })
    await api.post('aswusuarios', body).then((response) => {
      if (response.status == 200 || response.status == 201) {
        // setCpf('');
        // setEmail('');
        // formMethods.reset();
        // setShowModal(false);
        MySwal.fire({
          title:
            body.method === '_insert'
              ? 'Usuário cadastrado'
              : 'Usuário atualizado',
          icon: 'success',
        });
        return true;

      }
    });
    //setShowModal(false);
    // MySwal.fire({
    //   title: 'Usuario cadastrado no sistema corretamente!',
    //   icon: 'success',
    // });
    // }
  };

  const handleFormSubmit = async (data: PacienteType) => {
    try {
      if (!cpfInvalido && !emailError && !editing) {
        setUserForm(data);
        // cadastrarPaciente(data);
        onOpen();
      } else {
        if(cadastrarPaciente(data)){
          isEditing(false);
        };
      }
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
      }
    }
  };

  const loadUserDataByCPF = async (dadosUsuario: DadosUsuarioType) => {
    setDisableFixedFields(true);
    setDisabledFilds(true);
    setEditable(false);
    setLoading(true);
    const label = instApi.state.find(
      (p) => p.value == dadosUsuario.usuarioInstSaudePrefer
    );
    setInstEdit(label);
    setPaciente(dadosUsuario);
    formMethods.setValue('email', dadosUsuario.emailUsuario);
    setEmail(dadosUsuario.emailUsuario);
    setInstituicaoSelecionada(dadosUsuario.usuarioInstSaudePrefer);
    buscarConvenios(dadosUsuario.usuarioInstSaudePrefer);
    formMethods.setValue('nome', dadosUsuario.nmUsuario);
    formMethods.setValue('usuarioNomeSocial', dadosUsuario.usuarioNomeSocial);
    formMethods.setValue('usuarioCNS', dadosUsuario.usuarioCNS);
    setEmail(dadosUsuario.idConsulente);
    formMethods.setValue('telefone', dadosUsuario.telefoneUsuario);
    formMethods.setValue('genero', dadosUsuario.usuarioGenero);
    formMethods.setValue('cep', dadosUsuario.usuarioCEP);
    formMethods.setValue('numeroUsuario', dadosUsuario.usuarioNumero);
    formMethods.setValue('complementoUsuario', dadosUsuario.usuarioComplemento);
    formMethods.setValue('nascimento', dadosUsuario.usuarioNasc);
    formMethods.setValue('enderecoUsuario', dadosUsuario.usuarioEndereco);
    formMethods.setValue('bairro', dadosUsuario.usuarioBairro);
    formMethods.setValue('cidadeUsuario', dadosUsuario.usuarioCidade);
    formMethods.setValue('ufUsuario', dadosUsuario.usuarioUF);
    setLoading(false);
  };

  const verificaCPF = async (value: string) => {
    setCpfError('');
    userApi
      .submit({
        method: '_findByCpf',
        sgCliente: user?.sgCliente,
        cpf: value,
      })
      .then((data) => {
        if (!!data.data.usuarioCPF) {
          MySwal.fire({
            icon: 'warning',
            title: 'CPF já cadastrado no sistema!',
          });
          setCpfError('CPF já cadastrado');
          loadUserDataByCPF(data.data);
          setConvenioDados(data.data.dadosConvenio);
        }
      });

    return;
  };

  const validarCPF = async (value: string) => {
    if (isValidCpf(value.match(/\d/g).join('')) == false) {
      setCpfInvalido(true);
      return true;
    } else {
      setCpfInvalido(false);
      return false;
    }
  };

  const verificaEmail = async (value: string) => {
    const res = await apiFind('aswusuarios', {
      body: { email: value },
      showMessageOnErro: false,
    });
    const json = await res['data'];

    if (res['success'] !== false) {
      verificaEmail !== null;
      MySwal.fire({
        title: 'E-mail já cadastrado no sistema!',
        icon: 'warning',
      });
      setEmailError('Email já cadastrado');
      return false;
    } else {
      setEmailError('');
    }
  };

  const handleChangeCpf = (event: ChangeEvent<HTMLInputElement>) => {
    setCpfError('');
    const { value } = event.target;
    let formatted = cpfMask(value);
    setCpf(formatted);

    if (formatted.length >= 14) {
      if (validarCPF(formatted)) {
        verificaCPF(formatted);
      }
    }
  };

  const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    let findedLetter = value.includes('@');
    if (findedLetter) {
      verificaEmail(value);
    }
    setEmail(value);
  };

  const validarCEP = (value: string) => {
    if (!value) return false;
    MySwal.fire({
      title: 'CEP não encontrado!',
      icon: 'warning',
    });
  };

  const isEditing = (state: boolean) =>{
    if(state){
      setDisabledFilds(false);
      setEditing(true);
    } else {
      setDisabledFilds(true);
      setEditing(false);
    }

  }

  return (
    <div>
      {loading && <Loading />}
      <h4>
        <span>
          {isEdit || editing ? 'Atualização de paciente' : 'Cadastro de paciente'}
        </span>
      </h4>
      <div className="table-responsive">
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
            <Row className="justify-content-center">
              <Col md="11">
                <div>
                  <Row className="row-cadastro-paciente-first-column">
                    <Col md="4">
                      <Input
                        disabled={disableFixedFields}
                        name="cpf"
                        mask="cpf"
                        label="CPF*"
                        placeholder="CPF"
                        value={cpf}
                        onChange={handleChangeCpf}
                      />
                      <div className="text-warning">{cpfError}</div>
                      {cpfInvalido && (
                        <div className="text-warning"> CPF inválido</div>
                      )}
                    </Col>
                    <Col md="4">
                      <Input
                        disabled={disabledFilds}
                        name="nome"
                        label=" Nome e Sobrenome*"
                        placeholder="Nome e Sobrenome"
                        onChange={(event) => {
                          const { value } = event.target;
                          event.persist();
                          formMethods.setValue('nome', value);
                        }}
                      />
                    </Col>
                    <Col md="4">
                      <Input
                        disabled={disabledFilds}
                        name="usuarioNomeSocial"
                        label=" Nome social"
                        placeholder="Nome social(opcional)"
                        onChange={(event) => {
                          const { value } = event.target;
                          event.persist();
                          formMethods.setValue('usuarioNomeSocial', value);
                        }}
                      />
                    </Col>
                    <Col md="4">
                      <Input
                        disabled={disabledFilds}
                        name="usuarioCNS"
                        label="CNS*"
                        placeholder="CNS (Cartão SUS)"
                        onChange={(event) => {
                          const { value } = event.target;
                          event.persist();
                          formMethods.setValue('usuarioCNS', value);
                        }}
                      />
                    </Col>

                    <Col md="4">
                      <Input
                        disabled={disabledFilds}
                        type="email"
                        name="email"
                        value={email}
                        label="E-mail*"
                        placeholder="E-mail"
                        onChange={handleChangeEmail}
                      />
                      <span className="text-warning">{emailError}</span>
                    </Col>
                    <Col md="4">
                      <Input
                        disabled={disabledFilds}
                        name="telefone"
                        mask="phone"
                        label="Celular*"
                        placeholder="Celular"
                      />
                    </Col>
                    <Col md="4">
                      <Input
                        disabled={disabledFilds}
                        type="date"
                        name="nascimento"
                        label="Data de Nascimento*"
                        max={new Date().toISOString().split('T')[0]}
                      />
                    </Col>
                    <Col md="4">
                      <Selecionar
                        disabled={disabledFilds}
                        label="Gênero*"
                        placeholder="Selecione..."
                        name={'genero'}
                        options={[
                          { value: 'M', label: 'Masculino' },
                          { value: 'F', label: 'Feminino' },
                        ]}
                      />
                    </Col>
                    
                  </Row>
                  <Row>
                    <Col md="12">
                      <Row>
                        <Col md="12">
                          <Label className="mt-4 label-single-line">
                            Instituição de Saúde de Preferência*
                          </Label>
                        </Col>
                        <Col>
                          <ReactSelect
                            isDisabled={disabledFilds}
                            classNamePrefix="react-select"
                            className={'css-select-control'}
                            options={instApi.state}
                            isMulti={false}
                            placeholder="Selecione..."
                            value={instEdit}
                            onChange={(options, actionMeta) => {
                              const label = instApi.state.find(
                                (p) => p.value == options.value
                              );
                              setInstEdit(label);
                              setInstituicaoSelecionada(options.value);

                              buscarConvenios(options.value);
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="2">
                      <Input
                        disabled={disabledFilds}
                        name="cep"
                        mask="cep"
                        label="CEP*"
                        placeholder="CEP"
                        onChange={(event) => {
                          const { value } = event.target;
                          event.persist();
                          formMethods.setValue('cep', value);
                        }}
                        onKeyUp={(inputVal) => {
                          if (
                            inputVal.currentTarget?.value
                              ?.replace('-', '')
                              .replaceAll('_', '').length === 8
                          ) {
                            viacepRequest(
                              inputVal.currentTarget.value,
                              (cepRequest: any) => {
                                if (cepRequest !== null) {
                                  formMethods.setValue(
                                    'enderecoUsuario',
                                    cepRequest.cepRequestLogradouro
                                  );
                                  formMethods.setValue(
                                    'bairro',
                                    cepRequest.cepRequestBairro
                                  );
                                  formMethods.setValue(
                                    'cidadeUsuario',
                                    cepRequest.cepRequestLocalidade
                                  );
                                  formMethods.setValue(
                                    'ufUsuario',
                                    cepRequest.cepRequestUf
                                  );
                                } else {
                                  validarCEP(inputVal.currentTarget.value);
                                }
                              }
                            );
                          } else {
                            formMethods.setValue('enderecoUsuario', '');
                            formMethods.setValue('bairro', '');
                            formMethods.setValue('cidadeUsuario', '');
                            formMethods.setValue('ufUsuario', '');
                          }
                        }}
                      />
                    </Col>
                    <Col md="4">
                      <Input
                        disabled={true}
                        label="Endereço*"
                        type="text"
                        name="enderecoUsuario"
                        placeholder="Endereço"
                      />
                    </Col>
                    <Col md="2">
                      <Input
                        disabled={disabledFilds}
                        label="Número*"
                        type="text"
                        name="numeroUsuario"
                        placeholder="Número"
                        onChange={(event) => {
                          const { value } = event.target;
                          event.persist();
                          formMethods.setValue('numeroUsuario', value);
                        }}
                      />
                    </Col>
                    <Col md="4">
                      <Input
                        disabled={disabledFilds}
                        label="Complemento"
                        type="text"
                        name="complementoUsuario"
                        placeholder="Complemento"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <Input
                        disabled={true}
                        label="Bairro*"
                        type="text"
                        name="bairro"
                        placeholder="Bairro"
                      />
                    </Col>
                    <Col md="4">
                      <Input
                        disabled={true}
                        label="Cidade*"
                        type="text"
                        name="cidadeUsuario"
                        placeholder="Cidade"
                      />
                    </Col>
                    <Col md="4">
                      <Input
                        disabled={true}
                        label="UF*"
                        type="text"
                        name="ufUsuario"
                        placeholder="Estado"
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center mt-4">
              <Col md="11">
                <Button
                  type="submit"
                  color="primary"
                  disabled={loading}
                  style={{ float: 'right' }}
                  className="btn btn-primary float-right jh-create-entity"
                >
                  <span>{editing ? 'Confirmar alterações' : 'Próximo'}</span>
                </Button>
                {!editable && <Button
                  color="primary"
                  disabled={editable}
                  style={{ float: 'right', marginRight: '10px' }}
                  className="btn btn-primary float-right jh-create-entity"
                  onClick={() => isEditing(true)}
                >
                  <span>Editar</span>
                </Button>}
              </Col>
            </Row>
          </form>
        </FormProvider>
        {isOpen && (
          <ModalConvenio
            isOpen={isOpen}
            onClose={onClose}
            userForm={userForm}
            instituicaoSelecionada={instituicaoSelecionada as any}
            setState={setState}
            state={state}
            optionsConvenio={optionsConvenio}
            dadosConvenioEdit={convenioDados}
          />
        )}
      </div>
    </div>
  );
};
