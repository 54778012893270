import { useDisclosure } from '@chakra-ui/react';
import { Loading } from 'components/Loading';
import { useApiOnMount } from 'hooks';
import { useState } from 'react';
import { Button } from 'reactstrap';
import ListaClientes from './lista-clientes';
import ModalCriarCliente from './modal-criar-Cliente';

export const CadastroCliente = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [isNew, setIsNew] = useState(true);
  const [buscaList, setBuscaList] = useState<any[]>([]);
  const [busca, setBusca] = useState('');
  const { data, isLoading } = useApiOnMount('aswcliente', {
    method: '_list',
  });

  const filtrarDados = (buscar: string) => {
    if (buscar != '') {
      if (buscar.trim()) {
        const buscaFiltrada = data.filter(
          (cliente) =>
            cliente.sgCliente.toLowerCase().includes(buscar.toLocaleLowerCase()) |
            cliente.nomeContato.toLowerCase().includes(buscar.toLocaleLowerCase())
        );
        buscaFiltrada.sort(function (a, b) {
          if (a.nomeContato > b.nomeContato) {
            return 1;
          }
          if (a.nomeContato < b.nomeContato) {
            return -1;
          }
          return 0;
        });
        setBuscaList(buscaFiltrada);
      } else {
        data.sort(function (a, b) {
          if (a.nomeContato > b.nomeContato) {
            return 1;
          }
          if (a.nomeContato < b.nomeContato) {
            return -1;
          }
          return 0;
        });
        setBuscaList(data);
      }
    } else {
      setBuscaList([]);
    }
  };

  return (
    <>
      <div>
        <h2>clientes</h2>
        <div className="d-flex justify-content-end"> 
          <Button
            onClick={onOpen}
            color="primary"
            className="btn btn-primary float-right jh-create-entity"
          >
            Cadastrar Novo Cliente
          </Button>
        </div>
        <div id="form-filter">
          <div className="row mt-1 ml-3 mr-3">
            <input
              className="form-control"
              type="text"
              name="cliente"
              value={busca}
              onChange={(e) => {
                setBusca(e.target.value);
                filtrarDados(e.target.value);
              }}
              id="cadastro-cliente-cliente"
              placeholder={'Buscar cliente'}
            />
          </div>

          <div className="row mb-2 mr-4 justify-content-end pull-right">
            <Button
              onClick={() => filtrarDados(busca)}
              id="search-submit"
              color="primary"
            >
              <i className="fa fa-search" />
            </Button>
          </div>
        </div>
        <div>
          <ListaClientes dadosCliente={busca == '' ? data ?? [] : buscaList}></ListaClientes>
        </div>
      </div>
      <ModalCriarCliente
        isNew={isNew}
        isOpen={isOpen}
        onClose={onClose}
      />
      {isLoading && <Loading />}
    </>
  );
};
