import { Switch } from 'react-router-dom';
import ErrorBoundaryRoute from '../../shared/error/error-boundary-route';
import { AgendamentoConsultas } from './_base/agendamento-consultas';

export const nAgendamentoConsultas = () =>
  AgendamentoConsultas({} as any).return();

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute path={match.path} component={nAgendamentoConsultas} />
    </Switch>
  </>
);

export default Routes;
