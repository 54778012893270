import styled from 'styled-components';
import { colors } from 'theme/colors';

export const Container = styled.div`
  .table-list {
    max-height: 420px;
    overflow-y: scroll;
  }
`;

export const SpanTipoGestao = styled.span`
  font-size: 12px;
  color: ${colors.textGray};
`;
