import cookie from 'js-cookie';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { Loading } from 'components/Loading';
import { useOnlyMounted } from 'hooks/useOnlyMounted';
import { api } from 'services/api';
import { AUTH_TOKEN_KEY, AUTH_USER_DATA, AUTH_USER_ROUTES } from 'shared/util';

import { ModalSelecionarPerfil } from './components/modal-selecionar-perfil';
import LoginForm from './login-form';

const MySwal = withReactContent(Swal);
export interface ILoginProps extends RouteComponentProps<any> {
  param: any;
}

interface IGrupo {
  sgCliente: string;
  sgInstSaude: string;
  grupo: string;
  perfil: string;
  rotas: string[];
}

export const Login = (props: ILoginProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [perfilModal, setPerfilModal] = useState(false);
  const [perfilEcolhido, setPerfilEcolhido] = useState('');
  const [perfis, setPerfis] = useState([]);
  const [dadosUsuario, setDadosUsuario] = useState<any>([]);
  const [grupoEscolhido, setGrupoEscolhido] = useState<IGrupo>();

  const updateGrupoEscolhido = (_perfilEscolhido: string) => {
    setPerfilEcolhido(_perfilEscolhido);
    const objGrupo = perfis.find(
      (perfil) => perfil.perfil === _perfilEscolhido
    );
    setGrupoEscolhido(objGrupo);
  };

  const { onlyMounted } = useOnlyMounted();

  useEffect(() => {
    if (perfilEcolhido != '') {
      const rotas = {};
      perfis.map((p: any) => {
        if (p.perfil === perfilEcolhido) {
          p.rotas.forEach((x) => {
            if (!rotas[x]) {
              rotas[x.replace('/', '')] = {
                rota: x,
                nome: x,
                grupos: [
                  {
                    institucao: p.sgInstSaude,
                    institucaoNome: p.sgInstSaude,
                    grupoNome: p.grupo,
                    grupo: p.grupo,
                  },
                ],
              };
            } else {
              rotas[x.nome]['grupos'].push({
                institucao: p.sgInstSaude,
                institucaoNome: p.sgInstSaude,
                grupoNome: p.grupo,
                grupo: p.grupo,
              });
            }
          });
        }
      });
      if (Object.keys(rotas).length > 0) {
        cookie.set(AUTH_TOKEN_KEY, dadosUsuario?.token);
        localStorage.setItem(
          AUTH_USER_DATA,
          JSON.stringify({ ...dadosUsuario.datosUsuario, grupoEscolhido })
        );
        localStorage.setItem(AUTH_USER_ROUTES, JSON.stringify(rotas));

        handleClose();
      } else {
        MySwal.fire({
          title: 'O Usuario não tem nenhuma rota ativada',
          icon: 'error',
        });
      }
    }
  }, [perfilEcolhido]);

  const handleLogin = async (username: any, password: any) => {
    setIsSubmitting(true);
    try {
      const { data: json } = await api.post('aswlogin', {
        username,
        password,
      });

      if (json.success === true) {
        const rotas = {};

        if (json?.data?.dadosUsuario?.perfis.length > 1) {
          setPerfis(json?.data?.dadosUsuario?.perfis);
          setPerfilModal(true);
          setDadosUsuario(json);
        } else {
          json.datosUsuario.institucoes.forEach((v) =>
            v.rotas.forEach((x) => {
              if (!rotas[x.nome]) {
                rotas[x.nome] = {
                  rota: x.rota,
                  nome: x.nome,
                  grupos: [
                    {
                      institucao: v.institucao,
                      institucaoNome: v.institucaoNome,
                      grupoNome: v.grupoNome,
                      grupo: v.grupo,
                    },
                  ],
                };
              } else {
                rotas[x.nome]['grupos'].push({
                  institucao: v.institucao,
                  institucaoNome: v.institucaoNome,
                  grupoNome: v.grupoNome,
                  grupo: v.grupo,
                });
              }
            })
          );
          if (Object.keys(rotas).length > 0) {
            cookie.set(AUTH_TOKEN_KEY, json.token);
            localStorage.setItem(
              AUTH_USER_DATA,
              JSON.stringify({
                ...json.datosUsuario,
                grupoEscolhido: json?.data?.dadosUsuario?.perfis[0],
              })
            );
            localStorage.setItem(AUTH_USER_ROUTES, JSON.stringify(rotas));

            handleClose();
          } else {
            MySwal.fire({
              title: 'O Usuario não tem nenhuma rota ativada',
              icon: 'error',
            });
          }
        }
      } else {
        MySwal.fire({ title: json.message, icon: 'error' });
      }
    } catch (error) {
      MySwal.fire({ title: 'Ocorreu algum erro', icon: 'error' });
    } finally {
      onlyMounted(() => {
        setIsSubmitting(false);
      });
    }
  };

  const handleClose = () => {
    props?.history?.push('/');
  };

  return (
    <Container className="container-login">
      <Row>
        <Col md={1}> </Col>
        <Col md={6} className="image-login">
          <span className="techtools-image-login rounded" />
        </Col>
        <Col md={4}>
          <LoginForm handleLogin={handleLogin} />
        </Col>
        <Col md={1}> </Col>
      </Row>
      {isSubmitting && <Loading />}
      {perfilModal && (
        <ModalSelecionarPerfil
          perfis={perfis}
          close={(value: boolean) => {
            setPerfilModal(value);
          }}
          perfilEscolhido={updateGrupoEscolhido}
        />
      )}
    </Container>
  );
};

export default Login;
