import { Table, Button } from 'reactstrap';
import { Col, Row } from 'reactstrap';
import { Input } from 'components/form/input';
import { FormProvider } from 'react-hook-form';
import { Select as Selecionar } from 'components/form/select';
import { useController } from './useController';
import { Loading } from 'components/Loading';
import { APICEPType, FormUsuarioSMSProps } from './types';
import { api } from 'services/api';
import { DocumentViewicon } from 'components/icons';
import { dateToScreen, onlyNumber } from 'shared/util';
import {
  BotaoAdicionarEpisodio,
  TitleContainer,
  TitleEpisódio,
} from './styles';
import { useState } from 'react';
import ModalDetalheEpisodio from './components/modal-detalhe-episodio';

export const FormUsuarioSMS = (props: FormUsuarioSMSProps) => {
  const ctrl = useController(props);

  const [showModalEpisodio, setShowModalEpisodio] = useState<boolean>(false);

  const [episodioSelecionado, setEpisodioSelecionado] = useState<string>();

  const [action, setAction] = useState<string>('');

  const toggleModalEpisodio = () => {
    setShowModalEpisodio(!showModalEpisodio);
  };

  const refreshDados = () => {
    ctrl.verificaCPF(ctrl.cpfSelected);
  };

  let edit = false;

  const respCond = (e) => {
    if(e.epTercResp){
      return {
        etapa: 'Atenção Terciária',
        resp: e.epTercResp,
        status: e.epTercStatus

      }
    }
    if(e.epSecResp) {
      return {
        etapa: 'Atenção Secundária',
        resp: e.epSecResp,
        status: e.epSecStatus

      }
    }
    if(e.epPrimResp) {
      return {
        etapa: 'Atenção Primária',
        resp: e.epPrimResp,
        status: e.epPrimStatus
      }
    }
    return {
        etapa: '',
        resp: '',
        status: ''
    }

  }



  return (
    <>
      <FormProvider {...ctrl.formMethods}>
        <form onSubmit={ctrl.formMethods.handleSubmit(ctrl.handleRegisterUser)}>
          {(!props.onlyView || props.title) && (
            <div className="d-flex justify-content-between">
              <div className="breadcrumb">
                <h6>{props.title || 'CADASTRO DE USUÁRIO'}</h6>
              </div>
              {!props.onlyView && (
                <Button
                  color="primary"
                  className="btn btn-primary"
                  type="submit"
                >
                  Cadastrar
                </Button>
              )}
            </div>
          )}

          <Row className="">
            <Col md="4">
              <Input
                disabled={props.onlyView && !props.findByCpf}
                name="cpf"
                mask="cpf"
                label="CPF*"
                placeholder="CPF"
              />
              <div className="text-warning">{ctrl.cpfError}</div>
              {ctrl.cpfInvalido && (
                <div className="text-warning"> CPF inválido</div>
              )}
            </Col>
            <Col md="4">
              <Input
                disabled={props.onlyView}
                name="nome"
                label=" Nome e Sobrenome*"
                placeholder="Nome e Sobrenome"
              />
            </Col>
            <Col md="4">
              <Input
                disabled={props.onlyView}
                type="email"
                name="email"
                label="E-mail*"
                placeholder="E-mail"
              />
              <span className="text-warning">{ctrl.emailError}</span>
            </Col>
            <Col md="4">
              <Input
                disabled={props.onlyView}
                name="telefone"
                mask="phone"
                label="Celular*"
                placeholder="Celular"
              />
            </Col>
            <Col md="4">
              <Input
                disabled={props.onlyView}
                type="date"
                name="nascimento"
                label="Data de Nascimento*"
                max={new Date().toISOString().split('T')[0]}
              />
            </Col>
            <Col md="4">
              <Selecionar
                disabled={props.onlyView}
                label="Gênero*"
                placeholder="Selecione..."
                name={'genero'}
                options={[
                  { value: 'M', label: 'Masculino' },
                  { value: 'F', label: 'Feminino' },
                ]}
              />
            </Col>
            <Col md="4">
              <Input
                disabled={props.onlyView}
                name="cartaoSUS"
                label="Cartão SUS*"
                placeholder="Cartão SUS"
              />
            </Col>
            <Col md="4">
              <Input
                disabled={props.onlyView}
                name="identidade"
                label="Identidade*"
                placeholder="Identidade"
              />
            </Col>
            <Col md="4">
              <Row>
                <Col>
                  <Selecionar
                    disabled={props.onlyView}
                    label="Instituição de Saúde de Preferência*"
                    name="usuarioInstSaudePrefer"
                    options={(ctrl.user?.institucoes || []).map((inst) => ({
                      label: inst.institucaoNome,
                      value: inst.institucao,
                    }))}
                    placeholder="Selecione..."
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md="2">
              <Input
                disabled={props.onlyView}
                name="cep"
                mask="cep"
                label="CEP*"
                placeholder="CEP"
                onChange={(event) => {
                  const { value } = event.target;
                  event.persist();
                  ctrl.formMethods.setValue('cep', value);
                }}
                onKeyUp={async (inputVal) => {
                  if (
                    inputVal.currentTarget?.value
                      ?.replace('-', '')
                      .replaceAll('_', '').length === 8
                  ) {
                    const { data } = await api.post<APICEPType>(
                      'aswcheckdata',
                      {
                        method: '_buscaCEP',
                        cep: inputVal.currentTarget.value,
                      }
                    );
                    if (data) {
                      ctrl.formMethods.setValue(
                        'enderecoUsuario',
                        data.data.endereco
                      );
                      ctrl.formMethods.setValue('bairro', data.data.bairro);
                      ctrl.formMethods.setValue(
                        'cidadeUsuario',
                        data.data.cidade
                      );
                      ctrl.formMethods.setValue('ufUsuario', data.data.uf);
                    } else {
                      ctrl.formMethods.setValue('enderecoUsuario', '');
                      ctrl.formMethods.setValue('bairro', '');
                      ctrl.formMethods.setValue('cidadeUsuario', '');
                      ctrl.formMethods.setValue('ufUsuario', '');
                    }
                  }
                }}
              />
            </Col>
            <Col md="4">
              <Input
                disabled={props.onlyView}
                label="Endereço*"
                type="text"
                name="enderecoUsuario"
                placeholder="Endereço"
              />
            </Col>
            <Col md="2">
              <Input
                disabled={props.onlyView}
                label="Número*"
                type="text"
                name="numeroUsuario"
                placeholder="Número"
              />
            </Col>
            <Col md="4">
              <Input
                disabled={props.onlyView}
                label="Complemento"
                type="text"
                name="complementoUsuario"
                placeholder="Complemento"
              />
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <Input
                disabled={props.onlyView}
                label="Bairro*"
                type="text"
                name="bairro"
                placeholder="Bairro"
              />
            </Col>
            <Col md="4">
              <Input
                disabled={props.onlyView}
                label="Cidade*"
                type="text"
                name="cidadeUsuario"
                placeholder="Cidade"
              />
            </Col>
            <Col md="4">
              <Input
                disabled={props.onlyView}
                label="UF*"
                type="text"
                name="ufUsuario"
                placeholder="Estado"
              />
            </Col>
          </Row>
        </form>

        {ctrl.isLoading && <Loading />}
      </FormProvider>
      {ctrl.onlyView && (
        <>
          <TitleContainer>
            <TitleEpisódio>Episódios</TitleEpisódio>
            {ctrl.perfil === 'sms-admin' && (
              <BotaoAdicionarEpisodio
                disabled={ctrl.cpfSelected === ''}
                onClick={() => {
                  setAction('new');
                  setEpisodioSelecionado('');
                  toggleModalEpisodio();
                }}
              >
                Adicionar Episódio
              </BotaoAdicionarEpisodio>
            )}
          </TitleContainer>
          {ctrl.listaEpisodiosUsuarios.length > 0 ? (
            <div className="table-list mt-3">
              <Table
                responsive
                className={'table-hover table-striped table-responsive-css'}
              >
                <thead className={'thead-light'}>
                  <tr>
                    <th>Data</th>
                    <th>Etapa</th>
                    <th>Responsável</th>
                    <th>Status</th>
                    <th>Hipótese Diagnóstica</th>
                    <th>Especialidade</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {ctrl.listaEpisodiosUsuarios.map((a) => {
                    return (
                      <tr key={a.epIdentificador}>
                        <td>{dateToScreen(a.epData)}</td>
                        <td>{respCond(a).etapa ? respCond(a).etapa : '' }</td>
                        <td>{respCond(a).resp ? respCond(a).resp : '' }</td>
                        <td>{respCond(a).status ? respCond(a).status : '' }</td>
                        <td>{a.epHipoteseDiagnostica}</td>
                        <td>{a.epEspecialidade}</td>

                        <td>
                          <div className="btn-group flex-btn-group-container">
                            
                            <a
                              className="btn btn-primary btn-sm"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Visualizar"
                              onClick={() => {
                                setAction('view');
                                setEpisodioSelecionado(a.epIdentificador);
                                toggleModalEpisodio();
                                // ctrl.history.push(
                                //   `${
                                //     ctrl.history.location.pathname
                                //   }/${onlyNumber(ctrl.cpfValue)}/${
                                //     a.epIdentificador
                                //   }`
                                // );
                              }}
                            >
                              {/* <DocumentViewicon /> */}
                              <i className="fa fa-eye" />

                            </a>
                            <a
                              // className="btn-sm m-2"
                              className='btn btn-primary btn-sm'
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Editar"
                              onClick={() => {
                                setAction('edit');
                                setEpisodioSelecionado(a.epIdentificador);
                                toggleModalEpisodio();
                              }}
                            >
                              {/* <FiEdit /> */}
                              {/* <DocumentViewicon /> */}
                              <i className="fa fa-pencil-alt" />
                            </a>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          ) : (
            <div className="mt-4 alert alert-warning">
              Nenhum episódio encontrado
            </div>
          )}
        </>
      )}
      <ModalDetalheEpisodio
        showModalForm={showModalEpisodio}
        toggle={toggleModalEpisodio}
        identificadorEpisodio={episodioSelecionado}
        cpf={ctrl.cpfSelected}
        refreshDados={refreshDados}
        action={action}
      />
    </>
  );
};
