import styled from 'styled-components';

export const Container = styled.div`
  height: 50px;
  background-color: #e9e9e9;
  text-align: right;

  div {
    padding-top: 10px;
  }
`;
