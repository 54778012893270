//@ts-nocheck
import { Controller, useFormContext } from 'react-hook-form';
import ReactSelect, { Props as SelectProps } from 'react-select';
import { Col, Label, Row } from 'reactstrap';

type OptionType = { value: string; label: string };

type Props = SelectProps & {
  name: string;
  options: OptionType[];
  label?: string;
  disabled?: boolean;
};

export function Select({
  name,
  label,
  className,
  isMulti,
  options,
  onChange,
  defaultValue,
  disabled,
  ...rest
}: Props) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const errorMessage = errors[name]?.message;

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange: onChangeCtrl, onBlur } }) => {
          const _value =
            options?.filter((option: OptionType) =>
              Array.isArray(options)
                ? value?.includes(option.value)
                : value === option.value
            ) || [];

          return (
            <>
              <Row>
                {!!label && (
                  <Col md="12">
                    <Label className="mt-4 label-single-line" for={name}>
                      {label}
                    </Label>
                  </Col>
                )}
                <Col md="12">
                  <ReactSelect
                    isDisabled={disabled}
                    classNamePrefix="react-select"
                    className={className || 'css-select-control'}
                    options={options}
                    onBlur={onBlur}
                    isMulti={isMulti}
                    onChange={(
                      options: OptionType | OptionType[],
                      actionMeta
                    ) => {
                      const option = Array.isArray(options)
                        ? options.map((op) => op.value)
                        : options.value;

                      onChangeCtrl(option);
                      if (onChange) {
                        onChange(option, actionMeta);
                      }
                    }}
                    value={_value}
                    defaultValue={defaultValue}
                    {...rest}
                  />
                  {errorMessage && (
                    <span className="text-warning">
                      <small>{errorMessage}</small>
                    </span>
                  )}
                </Col>
              </Row>
            </>
          );
        }}
      />
    </>
  );
}
