/* eslint no-console: off */
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import React, { useEffect, useState } from 'react';
import { Switch } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { useOnlyMounted, useUser } from 'hooks';
import { api } from 'services/api';

import ErrorBoundaryRoute from '../../shared/error/error-boundary-route';

const MySwal = withReactContent(Swal);

export const DashboardTelemedicina = () => {
  const [instituicao, setinstituicao] = useState('');
  const { onlyMounted } = useOnlyMounted();
  const [dashboardObj, setDashboardObj] = useState(null);
  const dashboardRef = React.useRef(null);
  const user = useUser();

  useEffect(() => {
    if(user?.grupoEscolhido?.sgInstSaude){
      console.log(user?.grupoEscolhido?.sgInstSaude)
      setinstituicao(user.grupoEscolhido.sgInstSaude)
    }
  }, [user?.grupoEscolhido?.sgInstSaude])

  async function embed(sgInstSaude: string): Promise<void> {

    executaRequisicao(
      'aswquick',
      { dashboard: 'telemedicina' },
      ({ data, success }) => {
        if (!data.EmbedUrl) {
          MySwal.fire({
            title: 'Erro ao consultar Dashboard',
            html: '',
            icon: 'error',
            showConfirmButton: true,
            confirmButtonColor: '#e70015',
          });
          return;
        }
        console.log(sgInstSaude)
        const options = {
          url: data.EmbedUrl,
          container: dashboardRef.current,
          height: 'AutoFit',
          loadingHeight: '400px',
          scrolling: 'no',
          locale: 'pt-BR',
          footerPaddingEnabled: true,
          parameters: {
            sgInstSaude,
          },
        };
        setDashboardObj(QuickSightEmbedding.embedDashboard(options));
      }
    );
  }

  const executaRequisicao = (
    url = 'aswinstsaude',
    params: object,
    callback: Function
  ) => {
    api
      .post(url, params)
      .then(({ data }) => {
        onlyMounted(() => {
          callback(data);
        });
      })
      .finally(() => {
        onlyMounted(() => {});
      });
  };

  React.useEffect(() => {
    instituicao && embed(instituicao);
    return;
  }, [instituicao]);

  return (
    <>
      <div className="breadcrumb">
        <h6>DASHBOARD - TELEMEDICINA</h6>
      </div>
      <div ref={dashboardRef} />
    </>
  );
};

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute path={match.path} component={DashboardTelemedicina} />
    </Switch>
  </>
);

export default Routes;
