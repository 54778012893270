import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { CirurgiasExames } from './DadosHistorico/cirurgias-exames';
import { DocumentosExames } from './DadosHistorico/documentos-exames';
import { HistoricoAtendimento } from './DadosHistorico/historico-atendimento';
import { HistoricoPessoal } from './DadosHistorico/historico-pessoal';
import { HistoricoTriagemSindromeGripal } from './DadosHistorico/historico-triagem-sindrome-gripal';
import { Prontuario } from './DadosHistorico/prontuario';
export function AppointmentTabsEmergencial() {
  return (
    <Tabs>
      <TabList>
        <Tab fontSize={'14px'}>Prontuário Clínico</Tab>
        <Tab fontSize={'14px'}>Cirurgias e Exames</Tab>
        <Tab fontSize={'14px'}>Histórico Pessoal</Tab>
        <Tab fontSize={'14px'}>Histórico de atendimentos</Tab>
        {/* <Tab fontSize={'14px'}>Histórico Triagem Síndrome Gripal</Tab> */}
        <Tab fontSize={'14px'}>Documentos e Exames</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <Prontuario />
        </TabPanel>
        <TabPanel>
          <CirurgiasExames />
        </TabPanel>
        <TabPanel>
          <HistoricoPessoal />
        </TabPanel>
        <TabPanel>
          <HistoricoAtendimento />
        </TabPanel>
        {/* <TabPanel>
                    <HistoricoTriagemSindromeGripal />
                </TabPanel> */}
        <TabPanel>
          <DocumentosExames />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
