import { useDisclosure } from '@chakra-ui/react';
import { useConsultaContext } from 'contexts/Consulta';
import { ModalPrescricoesHistorico } from 'contexts/Consulta/components/modal-prescricoes-historico';
import { ModalSOAPHistorico } from 'contexts/Consulta/components/modal-soap-historico';
import { HistAtendimento } from 'contexts/Consulta/types';
import { useApiRequest } from 'hooks';
import { useEffect, useState } from 'react';
import { Button, Col, Row, Table } from 'reactstrap';
import { dateTimeToScreen } from 'shared/util';

type CID = {
  agIdConsulta: string;
  cid10: string;
  descricao: string;
  dtInclusao: string;
  huDataHoraAgenda: string;
  secundario: number;
  usuarioCPF: string;
};

type Prescription = {
  dtInclusao: string;
  hpAltoCusto: string;
  hpCidId: string;
  hpCodeReceita: string;
  hpComposicao: string;
  hpControleEspecial: string;
  hpDataSolicitacao: string;
  hpFabricante: string;
  hpMedicamentoDescricao: string;
  hpMedicamentoId: string;
  hpMedicamentoNome: string;
  hpMemedDigitsPresc: string;
  hpMemedIdPresc: string;
  hpMemedLinkPresc: string;
  hpPosologia: string;
  hpPreco: string;
  hpQuantidade: string;
  hpReceituario: string;
  hpTarja: string;
  hpTipo: string;
  hpTitularidade: string;
  hpUID: string;
};

type ApiDataItem = {
  agIdConsulta: string;
  dtInclusao: string;
  huAvaliacao: string;
  huDataHoraAgenda: string;
  huDescricao: string;
  huDtFimConsulta: string;
  huDtInicioConsulta: string;
  huObjetivo: string;
  huPlano: string;
  huRetorno: string;
  huSubjetstring;
  listagemCID10: CID[];
  listagemPrescricao: Prescription[];
  psNome: string;
};

export const HistoricoAtendimento = () => {
  const [itemSelecinoado, setItemSelecionado] =
    useState<HistAtendimento | null>(null);
  const [prescriptionData, setPrescriptionData] = useState([]);
  const {
    isOpen: isOpenSOAP,
    onClose: onCloseSOAP,
    onOpen: onOpenSOAP,
  } = useDisclosure();
  const {
    isOpen: prescriptionIsOpen,
    onClose: prescriptionOnClose,
    onOpen: prescriptionOnOpen,
  } = useDisclosure();
  const { consulta: dadosConsulta } = useConsultaContext();
  const api = useApiRequest<HistAtendimento[]>('aswhistusuario', []);

  useEffect(() => {
    if (dadosConsulta?.dadosConsulta.usuarioCPF) {
      api.request({
        method: '_list',
        usuarioCPF: dadosConsulta?.dadosConsulta?.usuarioCPF,
      });
    }
  }, [dadosConsulta?.dadosConsulta]);

  function handleModalSOAP(values: HistAtendimento) {
    setItemSelecionado(values);
    onOpenSOAP();
  }

  function handleModalPrescriptions(value) {
    setPrescriptionData(value);
    prescriptionOnOpen();
  }

  return (
    <>
      <Row>
        <Col md="12">
          <Table
            id="atendimento-table-list"
            responsive
            aria-describedby="atendimento-heading"
            className={
              'table-hover table-striped table-responsive-css td-atendimentos'
            }
          >
            <thead className={'thead-light'}>
              <tr>
                <th />
                <th> Data </th>
                <th> Tipo Evento </th>
                <th> Descrição </th>
                <th> Profissional Saúde </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {api.state.map((item) => (
                <tr key={item.agIdConsulta}>
                  <td></td>
                  <td id="horario-cell">
                    {dateTimeToScreen(new Date(item.huDataHoraAgenda))}
                  </td>
                  <td>{item.huTipo}</td>
                  <td>{item.huDescricao}</td>
                  <td>{item.psNome}</td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button
                        color="primary"
                        className={'btn-sm'}
                        onClick={() => handleModalSOAP(item)}
                      >
                        Detalhes
                      </Button>
                      {item.listagemPrescricao.length > 0 && (
                        <Button
                          color="primary"
                          className={'btn-sm'}
                          onClick={() => handleModalPrescriptions(item)}
                        >
                          Ver Prescrição
                        </Button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <ModalSOAPHistorico
        isOpen={isOpenSOAP}
        onClose={onCloseSOAP}
        content={itemSelecinoado}
      />

      <ModalPrescricoesHistorico
        isOpen={prescriptionIsOpen}
        onClose={prescriptionOnClose}
        content={prescriptionData}
      />
    </>
  );
};
