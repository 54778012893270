import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Divider,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  SimpleGrid,
  Stack, Text,
  Textarea
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { IModalCrudDTO } from 'components/crud/dtos/IModalDto';
import { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useForm } from 'react-hook-form';
import { api } from 'services/api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { colors } from 'theme/colors';
import * as Yup from 'yup';
import { IConfigParamsDTO, IPerguntaDTO } from '../../dtos/IGestaoPergunta.dto';

function ModalCrud({
  dados,
  isOpen,
  onClose,
  configParams,
  originParams,
  handlerChange,
}: IModalCrudDTO<IPerguntaDTO, IConfigParamsDTO>) {
  const MySwal = withReactContent(Swal);
  const [obrigatorio, setObrigatorio] = useState<boolean>(false);
  const [qpTipo, setQpTipo] = useState<string>('');
  const [qpRangeRespInicio, setQpRangeRespInicio] = useState<number>(0);
  const [qpRangeRespFim, setQpRangeRespFim] = useState<number>(0);
  const requiredFieldText = 'Campo obrigatório*';
  const schema = Yup.object().shape({
    qpTexto: Yup.string().required(requiredFieldText),
    qpControlRange: Yup.boolean(),
    qpObrigatorio: Yup.boolean(),
  });
  const {
    register,
    setValue,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<IPerguntaDTO>({
    resolver: yupResolver(schema),
  });

  const cleanFilds = () => {
    setQpTipo('');
    setQpRangeRespInicio(0);
    setQpRangeRespFim(0);
    setValue('qpTexto', '');
    setValue('qpObrigatorio', false);
  };
  useEffect(() => {
    if (originParams == null) {
      cleanFilds();
    } else {
      setQpTipo(originParams.qpTipo);
      setQpRangeRespInicio(originParams.qpRangeRespInicio);
      setQpRangeRespFim(originParams.qpRangeRespFim);
      setValue('qpTexto', originParams.qpTexto);
      setValue('qpObrigatorio', originParams.qpObrigatorio);
    }
  }, [originParams]);

  const handleFormSubmit = async (params: IPerguntaDTO) => {
    if (originParams) {
      const { data } = await api.post('aswquestionario', {
        method: 'updateQuestion',
        qpID: originParams.qpID,
        qpTexto: params.qpTexto,
        qpOrdem: originParams.qpOrdem,
        qpSucessora: originParams.qpSucessora,
        qpAntecessora: originParams.qpAntecessora,
        qpTipo: qpTipo,
        qpObrigatorio: params.qpObrigatorio,
        qpRangeRespInicio:qpRangeRespInicio ? qpRangeRespInicio : '0',
        qpRangeRespFim: qpRangeRespFim ? qpRangeRespFim: '0',
      });

      if (data.success == true) {
        cleanFilds();
        handlerChange();
        onClose();
        reset();
        MySwal.fire({
          title: data?.message || 'Editado com sucesso!',
          icon: 'success',
        });
      } else {
        MySwal.fire({
          title: data?.message || 'Houve um erro ao tentar editar!',
          icon: 'error',
        });
      }
    } else {
      const { data } = await api.post('aswquestionario', {
        method: 'createQuestion',
        qsID: configParams.qsId,
        qpOrdem: configParams.ultima + 1,
        qpTexto: params.qpTexto,
        qpSucessora: configParams.ultima + 2,
        qpAntecessora: configParams.ultima,
        qpTipo: qpTipo,
        qpObrigatorio: params.qpObrigatorio,
        qpRangeRespInicio: qpRangeRespInicio ? qpRangeRespInicio : '0',
        qpRangeRespFim: qpRangeRespFim ? qpRangeRespFim : '0',
      });

      if (data.success == true) {
        cleanFilds();
        handlerChange();
        onClose();
        reset();
        MySwal.fire({
          title: data?.message || 'Criado com sucesso!',
          icon: 'success',
        });
      } else {
        MySwal.fire({
          title: data?.message || 'Houve um erro ao tentar criar!',
          icon: 'error',
        });
      }
    }
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={() => onClose()}
      size={'4xl'}
    >
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <ModalContent>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <ModalHeader>
            {originParams ? 'Editar' : 'Criar'} pergunta
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid columns={1} gap={4} my={1}>
              <Box experimental_spaceY={2}>
                <Text>Enunciado da pergunta</Text>
                <Textarea
                  name="qpTexto"
                  placeholder="Enunciado da pergunta"
                  {...register('qpTexto', {
                    /**/
                  })}
                />
                {errors.qpTexto?.message && (
                  <Text color={colors.orange}>{errors.qpTexto?.message}</Text>
                )}
              </Box>
              <Box experimental_spaceY={2}>
                <Text>Tipo da pergunta</Text>
                <Stack>
                  <RadioGroup onChange={(e) => {
                    setQpTipo(e)
                  }} value={qpTipo}>
                    <Stack direction='row'>
                      <Radio value="A">Alternativa</Radio>
                      <Radio value="B">Booleana</Radio>
                      <Radio value="M">Multipla escolha</Radio>
                      <Radio value="D">Dissertativa (texto livre)</Radio>
                      <Radio value="R">Range</Radio>
                    </Stack>
                  </RadioGroup>
                  {errors.qpTipo?.message && (
                    <Text color={colors.orange}>{errors.qpTipo?.message}</Text>
                  )}
                </Stack>
              </Box>
              {qpTipo == "R" &&
                <SimpleGrid columns={2} gap={4} my={4}>
                  <Box experimental_spaceY={2}>
                    <Text>Início do range</Text>
                    <Input type="number" placeholder="Número  inicial do range" value={qpRangeRespInicio} onChange={(e) => setQpRangeRespInicio(parseInt(e.target.value))} />
                    {errors.qpRangeRespInicio?.message && (
                      <Text color={colors.orange}>{requiredFieldText}</Text>
                    )}
                  </Box>
                  <Box experimental_spaceY={2}>
                    <Text>Final do range</Text>
                    <Input type={"number"} placeholder="Número  limite do range" value={qpRangeRespFim} onChange={(e) => setQpRangeRespFim(parseInt(e.target.value))} />
                    {errors.qpRangeRespFim?.message && (
                      <Text color={colors.orange}>{requiredFieldText}</Text>
                    )}
                  </Box>
                </SimpleGrid>
              }

              <Box experimental_spaceY={4} mt={4}>
                <Stack>
                  <Controller
                    name="qpObrigatorio"
                    control={control}
                    render={({ field: { ref, value, ...rest } }) => {
                      return (
                        <CheckboxGroup
                          {...rest}
                          colorScheme="blue"
                          defaultValue={value}
                        >
                          <Stack spacing={[1, 5]} direction={['column', 'row']}>
                            <Checkbox
                              defaultChecked={obrigatorio}
                              onChange={(e) => {
                                setValue('qpObrigatorio', !obrigatorio);
                                setObrigatorio(!obrigatorio);
                              }}
                            >
                              Pergunta obrigatória
                            </Checkbox>
                          </Stack>
                        </CheckboxGroup>
                      );
                    }}
                  />
                </Stack>
                {errors.qpObrigatorio?.message && (
                  <Text color={colors.orange}>
                    {errors.qpObrigatorio?.message}
                  </Text>
                )}
              </Box>
            </SimpleGrid>
          </ModalBody>
          <Divider color={colors.gray_blue} mt={'1rem'} />
          <ModalFooter>
            <Flex
              gap="2rem"
              justifyContent="space-between"
              flexDirection="row"
              alignItems="center"
              width={'100%'}
            >
              <Button
                variant="outline"
                border="1px"
                color={colors.blue}
                disabled={qpTipo != '' ? false : true}
                borderColor={colors.blue}
                _hover={{ bg: colors.blue, color: '#fff' }}
                _active={{ bg: colors.textBlue }}
                type="submit"
              >
                {originParams ? 'Editar' : 'Criar'}
              </Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

export { ModalCrud };

