import { ChakraProvider, extendTheme, theme } from '@chakra-ui/react';
import { ptBR } from 'date-fns/locale';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import { colors as techtoolsColors } from 'theme/colors';

import AppRoutes from './routes';

import '@kenshooui/react-multi-select/dist/style.css';
import 'react-toastify/dist/ReactToastify.css';
import './app.scss';
import './config/dayjs.ts';
import { VideoScreen } from 'pages/video';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

registerLocale('pt-br', ptBR);
setDefaultLocale('pt-br');

const customTheme = extendTheme({
  colors: {
    ...techtoolsColors,
    ...theme.colors,
    blue: {
      ...theme.colors.blue,
      400: techtoolsColors.blue,
    },
  },
});

const App = () => {
  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={customTheme}>
          <ToastContainer
            position={toast.POSITION.TOP_LEFT}
            className="toastify-container"
            toastClassName="toastify-toast"
          />
          <Switch>
            <Route path="/video" component={VideoScreen} />
            <Route path="/" component={AppRoutes} />
          </Switch>
        </ChakraProvider>
      </QueryClientProvider>
    </Router>
  );
};

export default App;
