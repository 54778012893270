import { useDisclosure } from '@chakra-ui/react';
import {
  DialogConfirm,
  DialogConfirmRefProps
} from 'components/dialog-confirm';
import { HeaderContainer } from 'components/header/styles';
import { useConsultaContext } from 'contexts/Consulta';
import { useApiRequest, useMemed, useUser } from 'hooks';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import SelectAsync from 'react-select/async';
import { Button, Col, Container, Row, Spinner } from 'reactstrap';
import { api } from 'services/api';
import { MemedPrescricaoResultType } from 'shared/model/memed-pacient';
import { dateTimeToServer, debounce, LOG } from 'shared/util';
import { calcularIdade } from 'shared/util/calcularIdade';
import { CID10ItemType, ConsultaType } from '../../types';
import { ModalChamarEspecialidade } from '../modal-chamar-especialidade';
import { Modalcpf } from '../modal-CPF';

type Props = {
  consulta: ConsultaType;
};

export const HeaderEmergencial = (props: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const validValuesToSendToMemed = ['CRM', 'CRP'];
  const history = useHistory();
  const usuarioLogado = useUser();
  const { participanteCpf } = useParams<{ participanteCpf: string }>();
  const confirmRef = useRef<DialogConfirmRefProps>(null);
  const [huDtInicioConsulta, sethuDtInicioConsulta] = useState(new Date());
  const [showModalEspecialidade, setShowModalEspecialidade] =
    useState<boolean>(false);
  const [showModalCPF, setShowModalCPF] = useState<boolean>(true);
  const [huQueixa, sethuQueixa] = useState<string>('');
  const [huHistQueixa, sethuHistQueixa] = useState<string>('');
  const [cid10, setCid10] = useState<CID10ItemType[]>([]);
  const [cid10Secundario, setCid10Secundario] = useState<CID10ItemType[]>([]);
  const [cid10StartFilter, setCid10StartFilter] = useState<CID10ItemType[]>([]);
  const opcoesParaCID10 = cid10StartFilter.filter((item) =>
    cid10Secundario.every((c) => c.value !== item.value)
  );
  const opcoesParaCID10Secundario = cid10StartFilter.filter((item) =>
    cid10.every((c) => c.value !== item.value)
  );
  const [ano, mes, dia] = (
    props?.consulta?.dadosConsulta?.usuarioNasc || ''
  ).split('-');
  const { tipoConsulta } = useConsultaContext();

  const agendaApi = useApiRequest('aswagendausuprofsaude', {});

  const encerrarAtendimento = (cpf?: string) => {
    if (props.consulta?.dadosConsulta?.psCPF == participanteCpf) {
      agendaApi
        .submit({
          method: '_encerrarAtendimentoEmergencial',
          huQueixa,
          huHistQueixa,
          cid10,
          usuarioCPF: cpf ?? '',
          psCPF: props?.consulta?.dadosConsulta.psCPF,
          cid10Secundario,
          tipoConsulta,
          idConsulta: props.consulta?.dadosConsulta?.idConsulta,
          huDtInicioConsulta: dateTimeToServer(huDtInicioConsulta),
        })
        .finally(() => {
          history.push(
            `/atendimento-compartilhado`
          );
        });
    } else {
      const [, path] = history.location.pathname.split('/');
      precricaoMemed.logOut();
      history.replace(`/${path}`);
    }

  };

  const salvarPrescricao = async (prescription: MemedPrescricaoResultType) => {
    LOG({ prescription });
    await api.post('aswprescricao', {
      method: '_registraPrescricaoMemed',
      sgCliente: usuarioLogado.sgCliente,
      sgInstSaude: props?.consulta?.dadosConsulta.sgInstSaude,
      psCPF: props?.consulta?.dadosConsulta.psCPF,
      usuarioCPF: props?.consulta?.dadosConsulta.usuarioCPF,
      agIdConsulta: props?.consulta?.dadosConsulta.idConsulta,
      prescricao: prescription.prescricao,
      huDataHoraAgenda: props?.consulta?.dadosConsulta.agDataHoraAgenda,
    });
  };

  const precricaoMemed = useMemed({
    token: props?.consulta?.dadosConsulta.psCadastroMEMED,
    psCPF: props?.consulta?.dadosConsulta.psCPF,
    sgCliente: usuarioLogado?.sgCliente,
    sgInstSaude: props?.consulta?.dadosConsulta?.sgInstSaude,
    paciente: {
      idExterno: props?.consulta?.dadosConsulta.usuarioCPF,
      cpf: props?.consulta?.dadosConsulta.usuarioCPF,
      nome: props?.consulta?.dadosConsulta.nmUsuario,
      telefone: props?.consulta?.dadosConsulta.telefoneUsuario,
      endereco: props?.consulta?.dadosConsulta.usuarioEndereco,
      cidade: props?.consulta?.dadosConsulta.usuarioCidade,
      data_nascimento: `${dia}/${mes}/${ano}`,
    },
    onGeneratePrescription: salvarPrescricao,
  });

  const [, link] = String(
    props?.consulta?.dadosConsulta?.linkProfSaude
  ).includes('?')
    ? props?.consulta?.dadosConsulta?.linkProfSaude?.split('?')
    : [];
  const urlLink =
    location.origin +
    '/video/?apiKey=' +
    props?.consulta?.video?.videoApiKey +
    '&' +
    link;

  const handleLoadCID10Options = debounce(
    (inputValue: string, callback: Function) => {
      if (!inputValue) return;
      api
        .post('aswcid10', {
          method: '_list',
          cdCategoria: inputValue,
          cdDescricao: inputValue,
          page: 0,
          size: 100,
          sort: 'cdDescricao,asc',
        })
        .then(({ data }) => {
          const values = data.data
            ? data.data.map((p: any) => ({
              ...p,
              value: p.cdCategoria,
              label: `${p.cdCategoria} - ${p.cdDescricao}`,
            }))
            : [];
          callback(values);
          setCid10StartFilter(values);
        });
    },
    250
  );

  return (
    <HeaderContainer>
      <div
        id="atendimento-detail-view"
        className="tela-paciente"
        style={{ height: 920 }}
      >
        <Row className="tela-emergencial-header">
          <Col md={12}>
            <Row>
              <Col className="text-center" id="div-paciente">
                {props?.consulta?.dadosConsulta?.nmInstSaude}
              </Col>
              <Col id="div-paciente">
                Profissional: {props?.consulta?.dadosConsulta?.psNome}
              </Col>
              <Col id="div-paciente">
                Nome: {props?.consulta?.dadosConsulta?.nmUsuario ? props?.consulta?.dadosConsulta?.nmUsuario : 'Não Informado'}
              </Col>
              <Col>
                <span className="mr-10" id="status">
                  Idade:
                  {props?.consulta?.dadosConsulta?.usuarioNasc ? calcularIdade(props?.consulta?.dadosConsulta?.usuarioNasc) : ' Não Informado'}
                </span>
              </Col>
              <Col>
                <span>
                  <Button
                    className="btn btn-primary  btn-lg"
                    onClick={() => setShowModalEspecialidade(true)}
                  >
                    Chamar Especialidade
                  </Button>
                </span>
              </Col>
              <Col>
                {precricaoMemed &&
                  props?.consulta?.dadosConsulta.psCadastroMEMED && validValuesToSendToMemed.includes(usuarioLogado?.professional?.rccOrgaoEmissor) && props.consulta?.dadosConsulta?.psCPF == participanteCpf && props?.consulta?.dadosConsulta?.usuarioCPF != "" && (
                    <span>
                      <Button
                        color="primary"
                        disabled={precricaoMemed.loading}
                        onClick={() => precricaoMemed.open()}
                      >
                        Fazer Prescrição
                        {precricaoMemed.loading && (
                          <>
                            &nbsp;
                            <Spinner size="sm" />
                          </>
                        )}
                      </Button>
                    </span>
                  )}
              </Col>
              <Col>
                <span>
                  <Button
                    className="btn btn-warning"
                    onClick={() => {
                      confirmRef.current.open();
                    }}
                  >
                    Encerrar Atendimento
                    {agendaApi.isLoading && (
                      <>
                        &nbsp;
                        <Spinner size="sm" />
                      </>
                    )}
                  </Button>
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Container>
          <div className="d-flex justify-content-center mt-3">
            <iframe
              style={{
                height: `${740 / 1.3}px`,
                width: `${1650}px`,
              }}
              src={urlLink}
              allow="camera *;microphone *"
              allowFullScreen={true}
            ></iframe>
          </div>
        </Container>
        <Row className="mt-2 mx-5 justify-content-center">
          <Col md="9">
            <Row>
              <Col md="6">
                <span>
                  <textarea
                    style={{
                      marginTop: '10px',
                      height: '150px',
                    }}
                    className="form-control"
                    value={huQueixa}
                    onChange={(v) => sethuQueixa(v.target.value)}
                    placeholder="Queixas"
                  />
                </span>
                <span>
                  <SelectAsync
                    id="atendimento-cid10-filter"
                    instanceId="atendimento-cid10-filter"
                    className={'css-select-control mt-3'}
                    placeholder="CID-2019-Principal"
                    name={'cid10'}
                    value={cid10}
                    onChange={(options) => setCid10([options])}
                    defaultOptions={opcoesParaCID10}
                    options={opcoesParaCID10}
                    loadOptions={handleLoadCID10Options}
                    noOptionsMessage={() => 'Nada encontrado'}
                    loadingMessage={() => 'Buscando...'}
                  />
                </span>
              </Col>
              <Col md="6">
                <span>
                  <textarea
                    style={{
                      marginTop: '10px',
                      height: '150px',
                    }}
                    className="form-control"
                    placeholder="Notas clínicas"
                    value={huHistQueixa}
                    onChange={(v) => sethuHistQueixa(v.target.value)}
                  />
                </span>
                <span>
                  <SelectAsync
                    isMulti
                    id="atendimento-cid10-filter"
                    instanceId="atendimento-cid10-filter"
                    className={'css-select-control mt-3'}
                    placeholder="CID-2019-Secundário"
                    name={'cid10'}
                    value={cid10Secundario}
                    onChange={(options) =>
                      setCid10Secundario(options as CID10ItemType[])
                    }
                    defaultOptions={opcoesParaCID10Secundario}
                    options={opcoesParaCID10Secundario}
                    loadOptions={handleLoadCID10Options}
                    noOptionsMessage={() => 'Nada encontrado'}
                    loadingMessage={() => 'Buscando...'}
                  />
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <DialogConfirm
        ref={confirmRef}
        titulo="Encerrar atendimento"
        mensagem="Confirma o encerramento do atendimento?"
        cancelButton={{ text: 'Cancelar' }}
        buttons={props.consulta?.dadosConsulta?.psCPF == participanteCpf && props?.consulta?.dadosConsulta?.usuarioCPF == "" ? [
          {
            text: 'Confirma',
            color: 'yellow',
            closeOnClick: true,
            onClick: () => {
              encerrarAtendimento();
            },
          },
          // {
          //   text: 'Informar CPF do paciente',
          //   color: 'cyan',
          //   closeOnClick: true,
          //   onClick: () => {
          //     onOpen();
          //   },
          // },
        ] :

          [{
            text: 'Confirma',
            color: 'yellow',
            closeOnClick: true,
            onClick: () => {
              encerrarAtendimento();
            },
          }]
        }
      />
      {showModalCPF && (
        <Modalcpf
          isOpen={isOpen}
          onClose={onClose}
          encerrarAtendimento={encerrarAtendimento}
        />
      )}
      {showModalEspecialidade && (
        <ModalChamarEspecialidade
          psCpf={props.consulta?.dadosConsulta?.psCPF}
          close={(value: boolean) => {
            setShowModalEspecialidade(value);
          }}
        />
      )}
    </HeaderContainer>
  );
};
