import { useDisclosure } from '@chakra-ui/react';
import { useState } from 'react';
import { Table } from 'reactstrap';
import LinkInstitucoes from '../LinkInstitucoes';
import ModalCriarCliente from '../modal-criar-Cliente';

const ListaClientes = ({ dadosCliente }: { dadosCliente: any[] }) => {

  const { isOpen, onClose, onOpen } = useDisclosure();
  const [isNew, setIsNew] = useState(true);
  const [dataToEdit, setDataToEdit] = useState<any>(null);

  const handleEditUser = (itemToEdit: any) => {
    setIsNew(false);
    setDataToEdit(itemToEdit);
    onOpen();
  };
  return (
    <div className="table-responsive">
      {dadosCliente.length > 0 ? (
        <div id="cadastro-cliente-table-list" className="table-list">
          <Table
            id="cadastro-cliente-table-list"
            responsive
            aria-describedby="cadastro-cliente-heading"
            className={'table-hover table-striped table-responsive-css'}
          >
            <thead className={'thead-light'}>
              <tr>
                <th>Cliente</th>
                <th>Sigla</th>
                <th>Nome</th>
                <th>Telefone</th>
                <th>E-mail</th>
                <th id="LinkInstitucoes-cell-header">
                  Instituições de saúde
                </th>
                <th>Editar</th>
              </tr>
            </thead>
            <tbody>
              {dadosCliente &&
                dadosCliente.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td id="cliente-cell">
                        <b className="visible-xs"> Cliente </b>
                        {item.cliente}
                      </td>

                      <td id="sgCliente-cell">
                        <b className="visible-xs"> Sigla </b>
                        {item.sgCliente}
                      </td>

                      <td id="nomeContato-cell">
                        <b className="visible-xs"> Nome do Contato </b>
                        {item.nomeContato}
                      </td>
                      <td id="telefoneContato-cell">
                        <b className="visible-xs"> Telefone de Contato </b>
                        {item.telefoneContato}
                      </td>
                      <td id="emailContato-cell">
                        <b className="visible-xs"> Email de Contato </b>
                        {item.emailContato}
                      </td>
                      <td id="LinkInstitucoes-cell">
                        <LinkInstitucoes
                          entityBase={item}
                        />
                      </td>

                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          <button
                            className={'btn btn-primary btn-sm'}
                            onClick={() => handleEditUser(item)}
                          >
                            <i className="fa fa-pencil-alt" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      ) : (
        <div className="alert alert-warning">Nenhum cliente encontrado</div>
      )}
      {dataToEdit && (
        <ModalCriarCliente
          isNew={isNew}
          isOpen={isOpen}
          onClose={onClose}
          dataToEdit={dataToEdit}
        />
      )}
    </div>
  );
};
export default ListaClientes