import { AlertDialog, AlertDialogBody, AlertDialogCloseButton, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, useDisclosure } from '@chakra-ui/react';
import React, { forwardRef, useImperativeHandle } from 'react';

export type DialogConfirmRefProps = {
  open: () => void;
  close: () => void;
};

type ButtonConfirmColor =
  | 'red'
  | 'whiteAlpha'
  | 'blackAlpha'
  | 'gray'
  | 'orange'
  | 'yellow'
  | 'green'
  | 'teal'
  | 'blue'
  | 'cyan'
  | 'purple'
  | 'pink'
  | 'linkedin'
  | 'facebook'
  | 'messenger'
  | 'whatsapp'
  | 'twitter'
  | 'telegram';

type Largura =
  | '3xl'
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | '2xl'
  | '4xl'
  | '5xl'
  | '6xl'
  | 'full';

type ButtonType = {
  text: string;
  color?: ButtonConfirmColor;
  closeOnClick?: boolean;
  onClick?: () => Promise<void> | void;
};

type Props = {
  titulo: string;
  mensagem?: string;
  cancelButton: ButtonType;
  buttons?: ButtonType[];
  largura?: Largura;
  children?: JSX.Element;
};

/*
  Exemplo:

  const confirmRef = useRef<ConfirmFormRefProps>(null);
  confirmRef.current.open();

  <DialogConfirm
      ref={confirmRef}
      titulo="Encerrar atendimento"
      mensagem="Confirma o encerramento do antedimento?"
      cancelButton={{ text: 'Cancelar' }}
      buttons={[
        {
          text: 'Confirma',
          color: 'red',
          closeOnClick: true,
          onclick: () => {},
        },
      ]}
    />
 */

export const DialogConfirm = forwardRef<DialogConfirmRefProps, Props>(
  ({ mensagem, titulo, buttons, cancelButton, largura, children }, ref) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = React.useRef();

    useImperativeHandle(ref, () => {
      return {
        open: () => onOpen(),
        close: () => onClose(),
      };
    });

    function cancela() {
      onClose();
      if (cancelButton.onClick) {
        cancelButton.onClick();
      }
    }

    return (
      <>
        <AlertDialog
          motionPreset="slideInBottom"
          leastDestructiveRef={cancelRef}
          onClose={cancela}
          isOpen={isOpen}
          isCentered
          size={largura || 'md'}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <AlertDialogOverlay />

          <AlertDialogContent>
            <AlertDialogHeader>{titulo}</AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody>{children || mensagem || null}</AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                colorScheme={cancelButton.color || 'gray'}
                onClick={cancela}
              >
                {cancelButton.text}
              </Button>
              {!!buttons &&
                buttons.map((btn, index) => (
                  <Button
                    key={index}
                    colorScheme={btn.color || 'blue'}
                    ml={3}
                    onClick={() => {
                      if (btn.closeOnClick) {
                        onClose();
                      }
                      btn.onClick();
                    }}
                  >
                    {btn.text}
                  </Button>
                ))}
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </>
    );
  }
);
