import {
  Box,
  Flex,
  Heading,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import { useApiOnMount, useApiRequest } from 'hooks';
import { Button } from 'reactstrap';
import { useEffect, useState } from 'react';
import { MdOutlineArrowBack } from 'react-icons/md';
import ListaDeInstituicoes from '../lista-de-instituicoes';
import ModalCriarInstituicao from '../modal-criar-instituicao';
import { useRouter } from 'components/util/entity-utils';
import { Loading } from 'components/Loading';

export default function Page() {
  const { asPath } = useRouter();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [sgCliente, setSgCliente] = useState('');

  const api = useApiRequest('aswinstsaude', []);

  useEffect(() => {
    let arrayCliente = (asPath || '').split('/', 3);
    if (arrayCliente[2]) {
      setSgCliente(arrayCliente[2]);
      api.request({
        method: '_list',
        sgCliente: arrayCliente[2],
      });
    }
  }, []);

  return (
    <>
      <div>
        <div
          className="d-flex flex-row align-items-center justify-content-between"
          id="cadastro-instituicao-heading"
          data-cy="CadastroInstituicaoHeading"
        >
          <div className="d-flex flex-row align-items-center jh-create-entity">
            <Button
              color="primary"
              id="save-entity"
              onClick={() => window.history.back()}
            >
              <i className="fa fa-arrow-left" />
            </Button>
            {Boolean(api.state) && (
              <b className="ms-2 fw-bold">
                {`Cliente > ${api.state[0]?.nmCliente ?? '...'
                  } > Instituições de saúde`}
              </b>
            )}
          </div>
          <div className="">
            <Button
              onClick={onOpen}
              color="primary"
              className="btn btn-primary float-right jh-create-entity"
            >
              Cadastrar nova instituição de saúde
            </Button>
          </div>
        </div>
        <Box margin={'24px 0'}>
          <ListaDeInstituicoes
            dadosInst={api.state ?? []}
            reloadList={() => {
              api.request({
                method: '_list',
                sgCliente,
              });
            }}
          />
        </Box>
      </div>
      <ModalCriarInstituicao isOpen={isOpen} onClose={onClose} isNew={true} />
      {api.isLoading && <Loading />}
    </>
  );
}
