import { useUser } from 'hooks';
import { useState } from 'react';
import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { api } from 'services/api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { ModalPropsType } from '../../types';

export const ModalForm = ({
    isNew,
    dados,
    onRequestClose,
}: ModalPropsType) => {
    const MySwal = withReactContent(Swal);
    const user = useUser();
    const [open, setOpen] = useState(true);
    const [nomeEspecialidade, setNomeEspecialidade] = useState<string>(dados?.peDescricao)

    const handleRequestClose = () => {
        setOpen(false);
        setTimeout(onRequestClose, 500);
    };

    const handleFormSubmit = async () => {
        if (isNew) {
            const res = await api
                .post('aswgerenciarespecialidades', {
                    method: '_create',
                    descricao: nomeEspecialidade,
                    sgCliente: user.sgCliente
                })
            const json = await res.data;
            MySwal.fire({
                title: 'Cadastro de especialidade',
                html: json.message,
                icon: json.statusCode === 200 ? 'success' : 'error',
                showConfirmButton: true,
                confirmButtonColor: json.statusCode === 200 ? '#008000' : '#e70015',
            });
        } else {
            const res = await api
                .post('aswgerenciarespecialidades', {
                    method: '_update',
                    descricao: dados.peDescricao,
                    novaDescricao: nomeEspecialidade,
                    codigo: dados.peCodigo,
                    sgCliente: user.sgCliente
                });
            const json = await res.data;
            MySwal.fire({
                title: 'Edição de especialidade',
                html: json.message,
                icon: json.statusCode === 200 ? 'success' : 'error',
                showConfirmButton: true,
                confirmButtonColor: json.statusCode === 200 ? '#008000' : '#e70015',
            });
        }
        handleRequestClose();
    }

    return (
        <div>
            <Modal
                size={'p'}
                isOpen={open}
            >
                <ModalHeader toggle={handleRequestClose}>
                    <div id="page-heading">
                        <span className="page-header ml-3">
                            {isNew ? (
                                <span>CRIAR NOVA ESPECIALIDADE</span>
                            ) : (
                                <span>EDITAR ESPECIALIDADE</span>
                            )}
                        </span>
                    </div>
                </ModalHeader>
                <div>
                    <ModalBody>
                        <Row className="justify-content-center mx-3">
                            <Label>Nome da especialidade</Label>
                            <Input value={nomeEspecialidade} onChange={(e) => setNomeEspecialidade(e.target.value)} />
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            disabled={nomeEspecialidade ? false : true}
                            color="primary"
                            onClick={handleFormSubmit}
                            className="float-right"
                        >
                            Confirmar Alterações
                        </Button>
                    </ModalFooter>
                </div>
            </Modal>
        </div>
    )
}