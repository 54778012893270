import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export function useUrlParams() {
  const { search, pathname } = useLocation();
  const history = useHistory();

  const url = React.useMemo(() => new URLSearchParams(search), [search]);

  return {
    get: (param: string) => url.get(param),
    set: (param: string, value: string) => {
      url.set(param, value);
      history.replace({ pathname, search: url.toString() });
      // setValue(val);
    },
  };
}
