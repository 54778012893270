import { Box, Flex, Heading, Select } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Button, Col, Row } from 'reactstrap';

import { Loading } from 'components/Loading';
import { useApiRequest, useUser } from 'hooks';
import { api } from 'services/api';
import { changeInputText, dateTimeToServer } from 'shared/util';
import { getDataValue } from 'shared/util/pegar-hora-consultas';

import { TabelaRecepcao } from './components/tabela/tabelaRecepcao';

export type InstType = {
  instSaude: string;
  sgInstSaude: string;
};

const AtendimentoRecepcao = () => {
  const usuarioLogado = useUser();
  const listarConversasInterval = useRef(null);
  const dataHoje = new Date();
  const controller = new AbortController();
  const [intervaloBusca, setIntervaloBusca] = useState(true);
  const [loading, setLoading] = useState(false);
  const agendamentosHook = useApiRequest('aswagendausuprofsaude', []);
  const [dataToList, setDataToList] = useState<any[]>([]);
  const [local, setLocal] = useState('');
  const [instituicao, setInstituicao] = useState('');
  const [busca, setBusca] = useState('');
  const [agendamentosToList, setAgendamentosToList] = useState<any[]>([]);
  const [type, setType] = useState('');
  const [startDate, setStartDate] = useState<Date>();

  const instHook = useApiRequest<Array<InstType>>('aswinstsaude', []);

  async function listarInst() {
    instHook.request({
      method: '_listIsUsuarioLogado',
    });
  }

  const handleChangeSelectLocal = async () => {
    setLoading(true);
    const { data: listaLocal } = await api.post('aswagendalocaisatendimento', {
      method: 'listarAgendaLocalAtendimento',
      laDescricao: '',
      listarTodos: true,
      psCpf: usuarioLogado?.cpf,
      sgCliente: usuarioLogado.sgCliente,
      sgInstSaude: instituicao,
    });
    setDataToList(listaLocal.data);
    setLoading(false);
  };

  const loadAgendamentos = async (date?: Date) => {
    let tempoInicial = new Date(dataHoje.setHours(0, 0, 0));
    if (type === 'F') {
      agendamentosHook.request({
        method: '_listarAgendaPresencialAtendimento',
        dataInicial: dateTimeToServer(tempoInicial),
        localAtendimento: local,
        sgCliente: usuarioLogado.sgCliente,
        sgInstSaude: instituicao,
      });
      agendamentosHook.state.sort(function (a, b) {
        if (getDataValue(a.agendamento) > getDataValue(b.agendamento)) {
          return 1;
        }
        if (getDataValue(a.agendamento) < getDataValue(b.agendamento)) {
          return -1;
        }
        return 0;
      });
      setAgendamentosToList(agendamentosHook.state);
      setIntervaloBusca(false);
    } else {
      agendamentosHook.request({
        method: '_listarAgendaTeleconsultaAtendimento',
        dataInicial: date
          ? dateTimeToServer(date)
          : dateTimeToServer(tempoInicial),
        sgCliente: usuarioLogado.sgCliente,
        sgInstSaude: instituicao,
      });
      agendamentosHook.state.sort(function (a, b) {
        if (getDataValue(a.agendamento) > getDataValue(b.agendamento)) {
          return 1;
        }
        if (getDataValue(a.agendamento) < getDataValue(b.agendamento)) {
          return -1;
        }
        return 0;
      });
      setAgendamentosToList(agendamentosHook.state);
      setIntervaloBusca(false);
    }
  };

  useEffect(() => {
    if (!instituicao) {
      listarInst();
    } else {
      handleChangeSelectLocal();
    }
  }, [instituicao]);

  useEffect(() => {
    if (!intervaloBusca) {
      setIntervaloBusca(true);
      clearTimeout(listarConversasInterval.current);
      controller.abort();
    }
  }, [intervaloBusca]);

  useEffect(() => {
    if (local !== '') {
      loadAgendamentos();
    }
  }, [local]);

  useEffect(() => {
    if (!busca) {
      filtrarDados('');
    } else {
      agendamentosHook.state.sort(function (a, b) {
        if (getDataValue(a.agendamento) > getDataValue(b.agendamento)) {
          return 1;
        }
        if (getDataValue(a.agendamento) < getDataValue(b.agendamento)) {
          return -1;
        }
        return 0;
      });
      setAgendamentosToList(agendamentosHook.state);
    }
  }, [agendamentosHook.state]);

  document.addEventListener('keydown', function (e) {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  });

  const filtrarDados = (busca: string) => {
    if (busca.trim()) {
      const buscaFiltrada = agendamentosHook.state.filter(
        (paciente) =>
          paciente.usuario.toLowerCase().includes(busca.toLocaleLowerCase()) |
          paciente.usuarioCPF.toLowerCase().includes(busca.toLocaleLowerCase())
      );
      buscaFiltrada.sort(function (a, b) {
        if (getDataValue(a.agendamento) > getDataValue(b.agendamento)) {
          return 1;
        }
        if (getDataValue(a.agendamento) < getDataValue(b.agendamento)) {
          return -1;
        }
        return 0;
      });
      setAgendamentosToList(buscaFiltrada);
    } else {
      agendamentosHook.state.sort(function (a, b) {
        if (getDataValue(a.agendamento) > getDataValue(b.agendamento)) {
          return 1;
        }
        if (getDataValue(a.agendamento) < getDataValue(b.agendamento)) {
          return -1;
        }
        return 0;
      });
      setAgendamentosToList(agendamentosHook.state);
    }
  };

  function fnSearchDate(date: Date) {
    setStartDate(date);
  }

  return (
    <Box>
      <Box>
        <Heading as="h3" fontSize={'lg'} fontWeight={'normal'}>
          RECEPÇÃO
        </Heading>

        <Box as="form" margin={'16px 0'}>
          <Box>
            <Select
              className="mb-2"
              borderRadius={'1rem'}
              size={'lg'}
              onChange={(event) => {
                const { value } = event.target;
                event.persist();
                setInstituicao(value);
              }}
              placeholder={'Selecione a instituição de saúde'}
            >
              {instHook.state.map((item, index) => {
                return (
                  <option key={index} value={item.sgInstSaude}>
                    {item.instSaude}
                  </option>
                );
              })}
            </Select>

            <Select
              disabled={!instituicao}
              className="mb-2"
              borderRadius={'1rem'}
              size={'lg'}
              onChange={(event) => {
                const { value } = event.target;
                event.persist();
                setType(value);
              }}
              onBlur={() => type === 'T' && loadAgendamentos()}
              placeholder={'Selecione o tipo de atendimento'}
            >
              <option key={1} value={'F'}>
                {' '}
                Presencial{' '}
              </option>
              <option key={2} value={'T'}>
                {' '}
                Teleconsulta{' '}
              </option>
            </Select>
            {type === 'F' && (
              <Select
                disabled={!instituicao}
                borderRadius={'1rem'}
                size={'lg'}
                onChange={(event) => {
                  const { value } = event.target;
                  event.persist();
                  setLocal(value);
                }}
                placeholder={'Local de atendimento'}
              >
                {dataToList &&
                  dataToList.map((item, index) => {
                    return (
                      <option key={index} value={item.laDescricao}>
                        {item.laNome}
                      </option>
                    );
                  })}
              </Select>
            )}
            <Box w="full" display="flex" alignItems="center">
              <Box id="form-filter" w="full" mr={3}>
                <div className="row mt-1 ml-3 mr-3">
                  <Col md="12" className="col-filter-cadastro-cliente-cliente">
                    <Row className="mr-1 mt-1">
                      <input
                        className="form-control"
                        type="text"
                        style={{ borderRadius: '1rem' }}
                        id="cadastro-cliente-cliente"
                        value={busca}
                        onChange={(e) => setBusca(changeInputText(e))}
                        placeholder="Buscar nome ou CPF"
                      />
                    </Row>
                  </Col>
                </div>
                <div className="row mb-2 mr-4 justify-content-end pull-right">
                  <Button
                    id="search-submit"
                    color="primary"
                    onClick={() => filtrarDados(busca)}
                  >
                    <i className="fa fa-search" />
                    &nbsp;
                  </Button>
                </div>
              </Box>
              {type === 'T' && (
                <Box w="15%">
                  <ReactDatePicker
                    name="dataInicial"
                    id="cadastro-cliente-cliente"
                    className="form-control"
                    placeholderText="Data Inicial"
                    selected={startDate}
                    onChange={(date: Date) => {
                      fnSearchDate(date);
                      loadAgendamentos(date);
                    }}
                    // onBlur={() => }
                    value={startDate as unknown as string}
                    dateFormat="dd/MM/yyyy"
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Flex>
        <Box mx="auto" w="full">
          <TabelaRecepcao
            agendamentos={agendamentosToList}
            loadAgendamentos={loadAgendamentos}
            dateSelected={startDate}
          />
        </Box>
      </Flex>
      {loading || (agendamentosHook.isLoading && <Loading />)}
    </Box>
  );
};

export default AtendimentoRecepcao;
