import React from 'react';
import { Switch } from 'react-router-dom';
import { BASE_API_VERSION_PATH } from 'shared/util';
import ErrorBoundaryRoute from '../../shared/error/error-boundary-route';
import { GrupoMenu } from './_base/grupo-menu';

const apiUrl = BASE_API_VERSION_PATH + 'aswgrupomenu';

export const nGrupoMenu = (props) => GrupoMenu(props).return();

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute path={match.path} component={nGrupoMenu} />
    </Switch>
  </>
);

export default Routes;
