import axios, { AxiosError } from 'axios';
import Cookie from 'js-cookie';

import { AUTH_TOKEN_KEY, BASE_API_VERSION_PATH, LOG } from 'shared/util';

const api = axios.create({
  baseURL: BASE_API_VERSION_PATH,
  headers: {
    Authorization: Cookie.get(AUTH_TOKEN_KEY),
  },
});

api.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = Cookie.get(AUTH_TOKEN_KEY);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    if (!!response.data?.token) {
      Cookie.set(AUTH_TOKEN_KEY, response.data.token);
    }
    if (!!response.data?.newToken) {
      Cookie.set(AUTH_TOKEN_KEY, response.data.newToken);
    }

    if ([401, 404].includes(response.data?.statusCode)) {
      if (
        ['token', 'expirado'].every((key) =>
          String(response.data?.message).toLowerCase().includes(key)
        )
      ) {
        localStorage.clear();
        Cookie.remove(AUTH_TOKEN_KEY);
        document.location.href = '/login';
      }
    }

    return response;
  },
  (error: AxiosError) => {
    //nao vai cair aqui se for unauthorized, pois a api nao está tratando de maneira correta os statusCode
    LOG('api.interceptors.response -> AxiosError', error);
    if (error?.response?.status === 401) {
      if (error.response.data?.code === 'token.expired') {
        //refresh token
      } else {
        //do logout
      }
    }
  }
);

export { api };
