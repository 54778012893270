import { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import Select from 'react-select';
import { Button, Col, Row, Table } from 'reactstrap';

import { Loading } from 'components/Loading';
import { useOnlyMounted } from 'hooks/useOnlyMounted';
import { useUser } from 'hooks/useUser';
import { api } from 'services/api';
import {
  changeInputText,
  dateTimeToScreen,
  dateTimeToServer,
  dateToServer,
} from 'shared/util';

import { Container, CustomTd, CustomTdHorarios, Horas } from './styles';

export type EspecialidadeType = {
  descricao: string;
  codigo: string;
};

export type DisponibilidadeAgendaType = {
  psNome: string;
  peDescricao: string;
  psCPF: string;
  psEmail: string;
  horas: {
    descricao: string;
    horas: string[];
  }[];
};

export default function DisponibilidadeAgenda() {
  const user = useUser();
  const [inst, setInst] = useState<Partial<typeof user['institucoes'][0]>>();

  const [isLoading, setIsLoading] = useState(false);
  const [dataPesquisa, setDataPesquisa] = useState(new Date());
  const [textoFiltro, setTextoFiltro] = useState('');
  const [especialidades, setEspecialidades] = useState<EspecialidadeType[]>([]);
  const [especialidadeSelected, setEspecialidadeSelect] =
    useState<EspecialidadeType>();
  const [disponibilidadeAgendasList, setDisponibilidadeAgendasList] = useState<
    DisponibilidadeAgendaType[]
  >([]);
  const [disponibilidadeAgendaFilter, setDisponibilidadeAgendaFilter] =
    useState<DisponibilidadeAgendaType[]>([]);
  const { onlyMounted } = useOnlyMounted();

  const getEspecialidades = () => {
    executaRequisicao(
      'aswprofissionalsaude',
      {
        method: '_disponibilidadeAgenda',
        action: 'listarEspecialidades',
        sgCliente: user?.sgCliente,
        sgInstSaude: inst?.institucao,
      },
      ({ data, success }) => {
        if (success && Array.isArray(data)) {
          setEspecialidades(data);
        }
      }
    );
  };

  const getDisponibilidadeAgendas = () => {
    executaRequisicao(
      'aswprofissionalsaude',
      {
        method: '_disponibilidadeAgenda',
        action: 'listarDisponibilidadeAgenda',
        data: dateToServer(dataPesquisa),
        codigo: especialidadeSelected.codigo,
        sgCliente: user?.sgCliente,
        sgInstSaude: inst?.institucao,
      },
      ({ data, success }) => {
        if (success && Array.isArray(data)) {
          setDisponibilidadeAgendasList(data);
          setDisponibilidadeAgendaFilter(data);
        }
      }
    );
  };

  const executaRequisicao = (
    url: string,
    params: object,
    callback: Function
  ) => {
    setIsLoading(true);
    api
      .post(url, params)
      .then(({ data }) => {
        onlyMounted(() => {
          callback(data);
        });
      })
      .finally(() => {
        onlyMounted(() => {
          setIsLoading(false);
        });
      });
  };

  useEffect(() => {
    getEspecialidades();
  }, []);

  useEffect(() => {
    if (textoFiltro.trim()) {
      const filtered = disponibilidadeAgendasList.filter((consulta) => {
        return Object.values(consulta).some((key) =>
          String(key).toLowerCase().includes(textoFiltro.toLowerCase())
        );
      });
      setDisponibilidadeAgendaFilter(filtered);
    } else {
      setDisponibilidadeAgendaFilter(disponibilidadeAgendasList);
    }
  }, [textoFiltro]);

  useEffect(() => {
    if (especialidadeSelected && dataPesquisa && inst?.institucao) {
      getDisponibilidadeAgendas();
    }
  }, [especialidadeSelected, dataPesquisa, inst]);

  return (
    <Container>
      <div className="breadcrumb">
        <h6>PROFISSIONAL DE SAÚDE - DISPONIBILIDADE DE AGENDA</h6>
      </div>
      <Row>
        <Col md="8">
          <span>Instituição de Saúde</span>
          <Select
            className={'css-select-control mh-40px'}
            placeholder={'Selecione instituição'}
            name={'instituicao'}
            options={(user?.institucoes || []).map((inst) => ({
              label: inst.institucaoNome,
              value: inst.institucao,
            }))}
            onChange={(value) => {
              setInst({
                institucao: value.value,
                institucaoNome: value.label,
              });
            }}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md="8">
          <span>Especialidade</span>
          <Select
            className={'css-select-control mh-40px'}
            placeholder={'Selecione a especialidade'}
            name={'instituicao'}
            options={especialidades.map((especialidade) => ({
              label: especialidade.descricao,
              value: especialidade.codigo,
            }))}
            onChange={(value) =>
              setEspecialidadeSelect({
                descricao: value.label,
                codigo: value.value,
              })
            }
          />
        </Col>
        <Col md="4">
          <span>Data de Pesquisa</span>
          <ReactDatePicker
            className="form-control"
            placeholderText="Data Inicial"
            selected={dataPesquisa}
            onChange={(date: Date) => {
              setDataPesquisa(date);
            }}
            // value={startDate as unknown as string}
            minDate={new Date()}
            dateFormat="dd/MM/yyyy"
          />
        </Col>
      </Row>
      {disponibilidadeAgendasList.length > 0 && (
        <Col md="12" className="mt-3">
          <span>Pesquisar</span>
          <input
            placeholder="Pesquisar profissional de saúde"
            className="form-control"
            // value={textoFiltro}
            onChange={(evento) => setTextoFiltro(changeInputText(evento))}
          />
        </Col>
      )}

      <div>
        {disponibilidadeAgendaFilter.length > 0 && (
          <div className="table-list mt-4">
            <Table
              responsive
              className={'table-hover table-striped table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th style={{ width: '20%' }}>Nome</th>
                  <th style={{ width: '20%' }}>Especialidade</th>
                  <th style={{ width: '20%' }}>Tipo Agenda</th>
                  <th style={{ width: '40%' }}>Horários Disponíveis</th>
                </tr>
              </thead>
              <tbody>
                {disponibilidadeAgendaFilter.map(
                  (disponibilidadeAgenda, i: number) => (
                    <tr
                      key={`${disponibilidadeAgenda.psNome}${disponibilidadeAgenda.peDescricao}${i}`}
                    >
                      <td style={{ padding: '1rem' }}>
                        {disponibilidadeAgenda.psNome}
                      </td>
                      <td style={{ padding: '1rem' }}>
                        {disponibilidadeAgenda.peDescricao}
                      </td>
                      <CustomTdHorarios colSpan={2}>
                        <Table responsive>
                          <tbody>
                            {disponibilidadeAgenda?.horas?.map(
                              (hora, index) => (
                                <tr
                                  className={index > 0 ? 'dashed' : ''}
                                  key={index}
                                >
                                  <td
                                    style={{
                                      width: '25%',
                                    }}
                                  >
                                    {hora.descricao}
                                  </td>
                                  <CustomTd style={{ width: '65%' }}>
                                    <div key={index} className="a">
                                      <div className="b">
                                        {hora.horas.map((h) => (
                                          <Horas key={h}>{h}</Horas>
                                        ))}
                                      </div>
                                    </div>
                                  </CustomTd>
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                      </CustomTdHorarios>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </div>
        )}

        {!isLoading && !disponibilidadeAgendaFilter.length && (
          <div className="mt-4 alert alert-warning">Nada encontrado</div>
        )}
      </div>

      {isLoading && <Loading />}
    </Container>
  );
}
