import styled from "styled-components";

export const Container = styled.div`
  width: 33.3%;
  height: 100%;
  max-height: 450px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

interface MessageCardProps {
  isSelected: boolean;
}

export const MessageCard = styled.div<MessageCardProps>`
  padding: 10px 15px;
  transition: .3s ease;
  color: #6c757d;

  background-color: ${({ isSelected }) => isSelected && '#a4d2eb'};

  h6 {
    font-size: 12px;
    line-height: 14px;
    color: #444;
  }

  p {
    font-size: 14px;
    line-height: 20px;
  }

  span {
    font-size: 12px;
    line-height: 17px;
  }

  :hover {
    cursor: pointer;
    color: #fff !important;
    background: #77c3ed;

    h6 {
      color: #fff;
    }
  }
`;

export const CardContent = styled.div`
  margin-left: 12px;
  width: 100%;
`;
