import { Loading } from 'components/Loading';
import dayjs from 'dayjs';
import { Dispatch, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from 'reactstrap';
import { api } from 'services/api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { DadosConvenio, DadosUsuarioType, PacienteType } from './types';

const MySwal = withReactContent(Swal);

type ModalConvenioProps = {
  isOpen: boolean;
  onClose: () => void;
  userForm: PacienteType;
  instituicaoSelecionada: any[];
  state: any;
  setState: Dispatch<any>;
  optionsConvenio: any[];
  dadosConvenioEdit: DadosConvenio;
};

export const ModalConvenio = ({
  isOpen,
  onClose,
  userForm,
  instituicaoSelecionada,
  state,
  setState,
  optionsConvenio,
  dadosConvenioEdit
}: ModalConvenioProps) => {
  const [modalFormError, setModalFormError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [optionsPlano, setOptionsoptionsPlano] = useState([]);
  const [disabledFilds, setDisabledFilds] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const formMethods = useForm<PacienteType>({
    defaultValues: {},
  });

  const buscarPlanos = async (convenio) => {
    let options = [];
    convenio.planos
      ? convenio.planos.map((p: any, index) =>
        options.push({
          label: p.value,
          value: index,
        })
      )
      : (options = []);
    setOptionsoptionsPlano(options);
  };

  const cadastrarPaciente = async () => {
    try {
      setLoading(true);
      if (state) {
        const novoConvenio = { ...state };
        const dadosConvenio = {
          convenio: novoConvenio.convenioId.value,
          plano: novoConvenio.planoId.label,
          cartaoData: dayjs(novoConvenio.cartaoValidade).format('YYYY-MM-DD'),
          cartaoNumero: novoConvenio.cartaoNumero,
          instSaude: instituicaoSelecionada,
        };

        if (
          !dadosConvenio.convenio ||
          !dadosConvenio.plano ||
          !dadosConvenio.cartaoNumero
        ) {
          setModalFormError(true);
          return;
        }
        setModalFormError(false);
      
      
        const body = {
          method: editing || dadosConvenioEdit.numeroCartao ? '_update' : '_insert',
          nmUsuario: userForm.nome,
          usuarioNomeSocial: userForm.usuarioNomeSocial,
          usuarioCNS: userForm.usuarioCNS,
          emailUsuario: userForm.email,
          telefoneUsuario: userForm.telefone,
          usuarioCEP: userForm.cep,
          usuarioCPF: userForm.cpf.replace(/[^0-9,]*/g, '').replace(',', '.'),
          usuarioCidade: userForm.cidadeUsuario,
          usuarioEndereco: userForm.enderecoUsuario,
          usuarioGenero: userForm.genero,
          usuarioInstSaudePrefer: instituicaoSelecionada,
          usuarioNasc: userForm.nascimento,
          usuarioNumero: userForm.numeroUsuario,
          usuarioBairro: userForm.bairro,
          usuarioComplemento: userForm.complementoUsuario,
          usuarioPais: 'Brasil',
          usuarioUF: userForm.ufUsuario,
          dadosConvenio,
        };

        await api.post('aswusuarios', body).then((response) => {
          if (
            response.data.statusCode === 200 ||
            response.data.statusCode === 201
          ) {
            setTimeout(() => {
              location.reload();
            }, 2100);

            onCloseEditModal();
            MySwal.fire({
              title: dadosConvenioEdit?.convenio?.length > 0 ? 'Usuario editado corretamente!' : 'Usuario cadastrado no sistema corretamente!',
              icon: 'success',
            });
          } else {
            MySwal.fire({
              title: 'Não foi possível cadastrar o usuário',
              icon: 'warning',
              showConfirmButton: true,
              confirmButtonColor: '#ffc107',
            });
          }
        });
      }
    } finally {
      setLoading(false);
    }
  };
  const onCloseEditModal = () => {
    formMethods.reset();
    onClose();
  };

  useEffect(() => {
    if (dadosConvenioEdit?.convenio) {
      setDisabledFilds(true);
      optionsConvenio.map((item) => {
        if (item.value == dadosConvenioEdit.convenio) {
          setState({ ...state, convenioId: item });
          buscarPlanos(item);
        }
      })
    }
  }, [optionsConvenio]);

  useEffect(() => {
    if (dadosConvenioEdit?.convenio?.length > 0) {
      let formatarData = dadosConvenioEdit?.cartaoData?.split("-");
      let dataFormato = new Date(formatarData?.[1] + '-' + formatarData?.[2] + '-' + formatarData?.[0]);
      optionsPlano?.map((item) => {
        if (item?.label == dadosConvenioEdit?.plano) {
          setState({ ...state, planoId: item, cartaoNumero: dadosConvenioEdit.numeroCartao, cartaoValidade: dataFormato });
        }
      });
    }
  }, [optionsPlano])


  const isEditing = () => {
    if(!editing){
      setEditing(true);
      setDisabledFilds(false)
    } else {
      onCloseEditModal()
    }
  }

  return (

    <Modal
      id="modalConvenio"
      isOpen={isOpen}
      onClose={onCloseEditModal}
      className="cadastro-cliente-new-modal"
      size={'xl'}
    //   toggle={() => setShowModal(false)}
    >
      <ModalHeader toggle={() => onCloseEditModal()}>
        <p className="modal-title" style={{ fontSize: '16px' }}>
          Cadastrar convênio {loading && 'Carregando'}
        </p>

      </ModalHeader>
      <ModalBody>
        <Row className="justify-content-center">
          <Col md="10">
            <Row>
              <Col md="12">
                <Label
                  className="mt-4 label-single-line"
                  htmlFor="convenio-instituicao-show"
                >
                  Instituição de Saúde
                </Label>
                <input
                  value={instituicaoSelecionada}
                  className="form-control"
                  type="text"
                  id="convenio-instituicao-show"
                  name="convenio-instituicao-show"
                  disabled
                />
              </Col>
              <input
                disabled={disabledFilds}
                type="hidden"
                id="convenio-instituicao"
                name="convenio-instituicao"
                defaultValue="<?php echo $_GET['instValue']?>"
              />
              <Col md="12">
                <Label
                  className="mt-4 label-single-line"
                  htmlFor="convenio-nome"
                >
                  Nome do Convênio*
                </Label>
                <Select
                  isDisabled={disabledFilds}
                  placeholder={'Selecione...'}
                  className={'css-select-control'}
                  value={state?.convenioId}
                  onChange={(options) => {
                    setState({ ...state, convenioId: options });
                    buscarPlanos(options);
                  }}
                  options={optionsConvenio}
                />
                {modalFormError && !state.convenioId?.value && (
                  <span className="text-warning">Campo obrigatório</span>
                )}
              </Col>
              <Col md="12">
                <Label
                  className="mt-4 label-single-line"
                  htmlFor="convenio-plano"
                >
                  Tipo do Plano*
                </Label>
                <Select
                  isDisabled={disabledFilds}
                  placeholder={
                    state.convenioId
                      ? 'Selecione...'
                      : 'Selecione primeiramente o convênio'
                  }
                  value={state?.planoId}
                  className={'css-select-control'}
                  onChange={(options) => {
                    setState({ ...state, planoId: options });
                  }}
                  options={state.convenioId ? optionsPlano : []}
                />
                {modalFormError && !state.planoId?.label && (
                  <span className="text-warning">Campo obrigatório</span>
                )}
              </Col>
              <Col md="6">
                <Label
                  className="mt-4 label-single-line"
                  htmlFor="convenio-cartao"
                >
                  Número do Cartão*
                </Label>
                <input
                  disabled={disabledFilds}
                  className="form-control"
                  value={state?.cartaoNumero}
                  type="text"
                  id="convenio-cartao"
                  name="convenio-cartao"
                  required
                  onChange={(event) => {
                    const { value } = event.target;
                    event.persist();
                    setState({ ...state, cartaoNumero: value });
                  }}
                />
                {modalFormError && !state.cartaoNumero && (
                  <span className="text-warning">Campo obrigatório</span>
                )}
              </Col>
              <Col md="6">
                <Label className="mt-4 label-single-line">
                  Data de Validade do Cartão
                </Label>
                <ReactDatePicker
                  disabled={disabledFilds}
                  className="form-control"
                  placeholderText="Data Validade"
                  selected={state.cartaoValidade}
                  onChange={(date: Date) => {
                    setState({ ...state, cartaoValidade: date });
                  }}
                  value={state.cartaoValidade}
                  minDate={new Date()}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                />
              </Col>
              <input
                type="hidden"
                name="sgCliente"
                defaultValue="<?php echo $sgCliente?>"
                id="sgCliente"
              />
              <input
                type="hidden"
                name="usuarioCPF"
                defaultValue="<?php echo $_GET['cpf']? />"
                id="usuarioCPF"
              />
            </Row>

            <br />
            <Row className="justify-content-center">
              <Col md="11">
                <Button
                  onClick={() => cadastrarPaciente()}
                  color="primary"
                  style={{ float: 'right', marginLeft: '10px' }}
                  className="btn btn-primary float-right jh-create-entity"
                >
                  <span>{dadosConvenioEdit?.convenio?.length > 0 ? 'Confirmar' : 'Cadastrar Paciente'}</span>
                </Button>

                <Button
                  onClick={() => isEditing()}
                  color="primary"
                  style={{ float: 'right' }}
                  className="btn btn-primary float-right jh-create-entity"
                >
                  <span>{editing ? 'Cancelar' : 'Editar'}</span>
                </Button>
              </Col>
            </Row>
            <br />
          </Col>
        </Row>
      </ModalBody>
      {loading && <Loading />}
    </Modal>
  );
};

export default ModalConvenio;
