import { Box, Flex, FormControl, Heading, Select } from '@chakra-ui/react';
import { Loading } from 'components/Loading';
import { useApiRequest, useUser } from 'hooks';
import { useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { api } from 'services/api';
import { dateTimeToServer } from 'shared/util';
import { getDataValue } from 'shared/util/pegar-hora-consultas';
import { TabelaTriagem } from './components/tabela/tabelaTriagem';

export type InstType = {
  instSaude: string;
  sgInstSaude: string;
};

export type AgendaPresencialType = {
  agIdConsulta: string;
  agTeleFisico: string;
  agendamento: string;
  convenio: string;
  cpf: string;
  descricao: string;
  dtChechin: string;
  genero: string;
  idConsulta: string;
  nmInstSaude: string;
  nome: string;
  numeroCartao: string;
  planoConvenio: string;
  sgInstSaude: string;
  status: string;
  usuario: string;
  usuarioCPF: string;
  tempoMedioConsulta: number;
  idade: number;
  triagemExecutada: number;
  linkProfSaude?: string;
  locationName?: string;
  validadeCartao?: Date;
};

const AtendimentoTriagem = () => {
  const listarConversasInterval = useRef(null);
  const controller = new AbortController();
  const dataInicial = new Date();
  const [intervaloBusca, setIntervaloBusca] = useState(true);
  const [loading, setLoading] = useState(false);
  const [local, setLocal] = useState('');
  const agendamentosHook = useApiRequest<AgendaPresencialType[]>(
    'aswagendausuprofsaude',
    []
  );
  const [dataToList, setDataToList] = useState<any[]>([]);
  const usuarioLogado = useUser();
  const [busca, setBusca] = useState('');
  const [agendamentosToList, setAgendamentosToList] = useState<any[]>([]);
  const [instituicao, setInstituicao] = useState('');

  const instHook = useApiRequest<Array<InstType>>(
    'aswinstsaude',
    []
  );

  async function listarInst() {
    instHook.request({
      method: '_listIsUsuarioLogado',
    });
  }

  const loadAgendamentos = async () => {
    agendamentosHook.request({
      method: '_listarAgendaPresencialTriagem',
      dataInicial: dateTimeToServer(new Date(dataInicial.setHours(0, 0, 0))),
      localAtendimento: local,
      isTriagem: true,
      sgCliente: usuarioLogado.sgCliente,
      sgInstSaude: instituicao,
    });
    agendamentosHook.state.sort(function (a, b) {
      if (getDataValue(a.agendamento) > getDataValue(b.agendamento)) {
        return 1;
      }
      if (getDataValue(a.agendamento) < getDataValue(b.agendamento)) {
        return -1;
      }
      return 0;
    });
    setAgendamentosToList(agendamentosHook.state);
    setIntervaloBusca(false);
  };

  // useEffect(() => {
  //   handleChangeSelectLocal();
  // }, []);

  useEffect(() => {
    if (local !== '') {
      loadAgendamentos();
    }
  }, [local]);

  const handleChangeSelectLocal = async () => {
    setLoading(true);
    const { data: listaLocal } = await api.post('aswagendalocaisatendimento', {
      method: 'listarAgendaLocalAtendimento',
      laDescricao: '',
      listarTodos: true,
      psCpf: usuarioLogado?.cpf,
      sgCliente: usuarioLogado.sgCliente,
      sgInstSaude: instituicao,
    });
    setDataToList(listaLocal.data);
    setLoading(false);
  };

  useEffect(() => {
    if (instituicao == '') {
      listarInst();
    } else {
      handleChangeSelectLocal();
    }
  }, [instituicao]);

  useEffect(() => {
    if (!intervaloBusca) {
      setIntervaloBusca(true);
      clearTimeout(listarConversasInterval.current);
      controller.abort();
      listarConversasInterval.current = setTimeout(
        () => loadAgendamentos(),
        30000
      );
    }
  }, [intervaloBusca]);

  useEffect(() => {
    if (!busca) {
      filtrarDados('');
    } else {
      agendamentosHook.state.sort(function (a, b) {
        if (getDataValue(a.agendamento) > getDataValue(b.agendamento)) {
          return 1;
        }
        if (getDataValue(a.agendamento) < getDataValue(b.agendamento)) {
          return -1;
        }
        return 0;
      });
      setAgendamentosToList(agendamentosHook.state);
    }
  }, [agendamentosHook.state]);

  // document.addEventListener('keydown', function (e) {
  //   if (e.keyCode === 13) {
  //     e.preventDefault();
  //   }
  // });

  const filtrarDados = (busca: string) => {
    if (busca.trim()) {
      const buscaFiltrada = agendamentosHook.state.filter(
        (paciente) =>
          paciente.usuario.toLowerCase().includes(busca.toLocaleLowerCase()) ||
          paciente.usuarioCPF.toLowerCase().includes(busca.toLocaleLowerCase())
      );

      buscaFiltrada.sort(function (a, b) {
        if (getDataValue(a.agendamento) > getDataValue(b.agendamento)) {
          return 1;
        }
        if (getDataValue(a.agendamento) < getDataValue(b.agendamento)) {
          return -1;
        }
        return 0;
      });
      setAgendamentosToList(buscaFiltrada);
    } else {
      agendamentosHook.state.sort(function (a, b) {
        if (getDataValue(a.agendamento) > getDataValue(b.agendamento)) {
          return 1;
        }
        if (getDataValue(a.agendamento) < getDataValue(b.agendamento)) {
          return -1;
        }
        return 0;
      });
      setAgendamentosToList(agendamentosHook.state);
    }
  };
  return (
    <Box>
      <Box>
        <Heading as="h3" fontSize={'lg'} fontWeight={'normal'}>
          ATENDIMENTO - PRESENCIAL
        </Heading>
        <Box as="form" margin={'16px 0'}>
          <FormControl>
            <Select
              className='mb-2'
              borderRadius={'1rem'}
              size={'lg'}
              onChange={(event) => {
                const { value } = event.target;
                event.persist();
                setInstituicao(value);
              }}
              placeholder={'Selecione a instituição de saúde'}
            >
              {instHook.state.map((item, index) => {
                return (
                  <option key={index} value={item.sgInstSaude}>
                    {item.instSaude}
                  </option>
                );
              })}
            </Select>
            <div className='mt-2'>
              <Select
                borderRadius={'1rem'}
                disabled={instituicao == '' ? true : false}
                size={'lg'}
                onChange={(event) => {
                  const { value } = event.target;
                  event.persist();
                  setLocal(value);
                }}
                placeholder={'Selecione um local de atendimento'}
              >
                {dataToList &&
                  dataToList.map((item, index) => {
                    return (
                      <option key={index} value={item.laDescricao}>
                        {item.laNome}
                      </option>
                    );
                  })}
              </Select>
            </div>
            <div id="form-filter">
              <div className="row mt-1 ml-3 mr-3">
                <Col md="12" className="col-filter-cadastro-cliente-cliente">
                  <Row className="mr-1 mt-1">
                    <input
                      className="form-control"
                      type="text"
                      style={{ borderRadius: '1rem' }}
                      id="cadastro-cliente-cliente"
                      value={busca}
                      onChange={(e) => setBusca(e.target.value)}
                      placeholder={'Buscar nome ou CPF'}
                    />
                  </Row>
                </Col>
              </div>
              <div className="row mb-2 mr-4 justify-content-end pull-right">
                <Button
                  id="search-submit"
                  color="primary"
                  onClick={() => filtrarDados(busca)}
                >
                  <i className="fa fa-search" />
                </Button>
              </div>
            </div>
          </FormControl>
        </Box>
      </Box>
      <Flex>
        <Box mx="auto" w="full">
          <TabelaTriagem agendamentos={agendamentosToList} />
        </Box>
      </Flex>
      {loading || (agendamentosHook.isLoading && <Loading />)}
    </Box>
  );
};

export default AtendimentoTriagem;
