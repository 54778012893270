import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import { Loading } from 'components/Loading';
import { useOnlyMounted } from 'hooks';
/* eslint no-console: off */
import React, { useState } from 'react';
import { Switch, useLocation } from 'react-router-dom';
import { api } from 'services/api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ErrorBoundaryRoute from '../../shared/error/error-boundary-route';

const MySwal = withReactContent(Swal);

export const DashboardSindromeGripal = () => {
  const [isLoading, setIsLoading] = useState(false);
  // const [instList, setInstList] = useState<InstType[]>([]);
  const [instituicao, setinstituicao] = useState('ColabHA');
  const { onlyMounted } = useOnlyMounted();
  const [embedded, setEmbedded] = useState(false);
  const [dashboardObj, setDashboardObj] =
    React.useState<QuickSightEmbedding.EmbeddableObject>(null);
  const dashboardRef = React.createRef<HTMLDivElement>();

  async function embed(sgInstSaude: string): Promise<void> {
    executaRequisicao(
      'aswquick',
      { dashboard: 'sindrome_gripal' },
      ({ data, success }) => {
        if (!data.EmbedUrl) {
          MySwal.fire({
            title: 'Erro ao consultar Dashboard',
            html: '',
            icon: 'error',
            showConfirmButton: true,
            confirmButtonColor: '#e70015',
          });
          return;
        }
        const options = {
          url: data.EmbedUrl,
          container: dashboardRef.current as HTMLElement,
          height: 'AutoFit',
          loadingHeight: '400px',
          scrolling: 'no',
          locale: 'pt-BR',
          footerPaddingEnabled: true,
          parameters: {
            sgInstSaude,
          },
        };
        setDashboardObj(QuickSightEmbedding.embedDashboard(options));
        setEmbedded(true);
      }
    );
  }

  const executaRequisicao = (
    url = 'aswinstsaude',
    params: object,
    callback: Function
  ) => {
    // setIsLoading(true);
    api
      .post(url, params)
      .then(({ data }) => {
        onlyMounted(() => {
          callback(data);
        });
      })
      .finally(() => {
        onlyMounted(() => {
          // setIsLoading(false);
        });
      });
  };

  // function handleSgInstSaude(sgInstSaude: string) {
  //   dashboardObj.setParameters({ sgInstSaude });
  // }

  // React.useEffect(() => {
  //   executaRequisicao(
  //     'aswinstsaude',
  //     { method: '_listIsUsuarioLogado' },
  //     ({ data, success }) => {
  //       if (success && Array.isArray(data)) {
  //         setInstList(data);
  //       }
  //     }
  //   );
  // }, []);

  React.useEffect(() => {
    // if (instituicao !== '') {
    // if (!embedded) {
    embed(instituicao);
    return;
    // }
    // handleSgInstSaude(instituicao);
    // }
  }, [instituicao]);

  return (
    <>
      <div className="breadcrumb">
        <h6>DASHBOARD - SÍNDROME GRIPAL</h6>
      </div>
      {/* <Col md="3">
        <Label className="label-single-line" for="instituicaos">
          Instituição de Saúde
        </Label>
      </Col>
      <Col className="col-md-12">
        <Select
          className={'css-select-control mh-40px'}
          placeholder={'Selecione a Instituição'}
          name={'instituicao'}
          options={instList.map((inst) => ({
            label: inst.instSaude,
            value: inst.sgInstSaude,
          }))}
          onChange={(e) => setinstituicao(e.value)}
        />
      </Col> */}
      <div ref={dashboardRef} />
      {isLoading && <Loading />}
    </>
  );
};

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute
        path={match.path}
        component={DashboardSindromeGripal}
      />
    </Switch>
  </>
);

export default Routes;
