import styled from 'styled-components';

// A4

export const ContainerA4 = styled.div`
  height: 1060px;
  width: 794px;
  padding: 0;
  margin: 0;
  background-color: white;
`;

export const HeaderA4 = styled.div`
  margin-top: 60px;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: lightgoldenrodyellow; */

  h1 {
    color: #000;
    font-size: 27.76px;
    font-weight: 700;
  }
`;

export const HeaderTitleLogoA4 = styled.div`
  display: flex;
  align-items: center;
  /* background-color: lightblue; */
  padding: 10px 20px;
  border-bottom: 1px solid lightgray;
`;

export const HeaderSubTitleA4 = styled.div`
  padding: 10px 20px;
  border-bottom: 1px solid lightgray;

  h2 {
    text-align: center;
    color: #000;
    font-family: Roboto, sans-serif;
    font-size: 19.75px;
    font-weight: 500;
  }
`;

export const TitleRegistroPresencaA4 = styled.p`
  margin-top: 40px;
  text-align: center;
  font-size: 51.06px;
  font-weight: 700;
`;

export const ContainerQrCodeA4 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 50px 0;
`;

export const ContainerListInstrucoesA4 = styled.div`
  /* background-color: lightblue; */
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ListInstrucoesA4 = styled.ul`
  list-style: none;
`;

export const ListItemA4 = styled.li`
  display: flex;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 400;
  margin-top: 10px;
`;

export const ListIconA4 = styled.div`
  background-color: #0b64c2;
  padding: 1px 8px;
  margin-right: 5px;
  border-radius: 25px;
  color: #fff;
`;

export const ContainerFooterA4 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    margin: 20px;
    color: #fff;
    background-color: #76a8dc;
    padding: 5px 10px;
    border: 1px solid #0b64c2;
    border-radius: 25px;
  }

  i {
    font-size: 18px;
    font-weight: 500;
  }
`;

//A5

export const ContainerMasterA5 = styled.div`
  height: 1120px;
  width: 794px;
  margin: 0;
  padding: 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  box-sizing: border-box;
`;

export const ContainerA5 = styled.div`
  height: 794px;
  width: 560px;
  margin: -110px;
  padding: 0;
  background-color: #fff;
  /* border: 1px solid black; */
  transform: rotate(-90deg);
`;

export const HeaderA5 = styled.div`
  margin-top: 40px;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    color: #000;
    font-size: 19.62px;
    font-weight: 700;
  }
`;

export const HeaderTitleLogoA5 = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid lightgray;
`;

export const HeaderSubTitleA5 = styled.div`
  padding: 10px 20px;
  border-bottom: 1px solid lightgray;

  h2 {
    text-align: center;
    color: #000;
    font-family: Roboto, sans-serif;
    font-size: 13.89px;
    font-weight: 500;
  }
`;

export const TitleRegistroPresencaA5 = styled.p`
  margin-top: 20px;
  text-align: center;
  font-size: 36.09px;
  font-weight: 700;
`;

export const ContainerQrCodeA5 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 50px 0;
`;

export const ContainerListInstrucoesA5 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ListInstrucoesA5 = styled.ul`
  list-style: none;
`;

export const ListItemA5 = styled.li`
  display: flex;
  align-items: center;
  color: #000;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 12.72px;
  margin-top: 10px;
`;

export const ListIconA5 = styled.div`
  background-color: #0b64c2;
  padding: 1px 8px;
  margin-right: 5px;
  border-radius: 25px;
  color: #fff;
`;

export const ContainerFooterA5 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    color: #fff;
    background-color: #76a8dc;
    padding: 5px 10px;
    border: 1px solid #0b64c2;
    border-radius: 25px;
    margin: 20px;
  }

  i {
    font-size: 12.72px;
    font-weight: 500;
  }
`;
