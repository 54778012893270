import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Divider,
  Flex,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  SimpleGrid,
  Stack,
  Switch,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { IGenericResponseDTO } from 'components/crud/dtos/api.dto';
import { IModalCrudDTO } from 'components/crud/dtos/IModalDto';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { api } from 'services/api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { colors } from 'theme/colors';
import * as Yup from 'yup';
import { IConfigParamsDTO, IQuestionarioDTO } from '../../types';
import { Input } from 'components/crud/Input';
import { useUser } from 'hooks';
import 'react-datepicker/dist/react-datepicker.css';

function ModalCrud({
  isOpen,
  onClose,
  configParams,
  originParams,
  handlerChange,
  qcid,
}: IModalCrudDTO<IQuestionarioDTO, IConfigParamsDTO>) {
  const [qsDataInicio, setQsDataInicio] = useState(new Date());
  const [qsDataFim, setQsDataFim] = useState(new Date());
  const [ativo, setAtivo] = useState<boolean>(false);
  const user = useUser();
  const requiredFieldText = 'Campo obrigatório*';
  const requiredFieldChecked = 'Ao menos uma opção deve ser flegada*';
  const requiredFieldNumber = 'Apenas números*';
  const requiredFieldMin = 'Apenas números positivos*';
  const schema = Yup.object().shape({
    qsNome: Yup.string().required(requiredFieldText),
    qsQtdPerguntas: Yup.number()
      .typeError(requiredFieldNumber)
      .positive(requiredFieldMin)
      .required(requiredFieldText),
    qsVisualizacao: Yup.string()
      .min(1, requiredFieldChecked)
      .required(requiredFieldText),
    qsDescricao: Yup.string().required(requiredFieldText),
    qsAtivo: Yup.boolean(),
  });
  const {
    register,
    setValue,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<IQuestionarioDTO>({
    resolver: yupResolver(schema),
  });

  const criarProvider = async (
    configParams: IConfigParamsDTO,
    params: IQuestionarioDTO,
    onClose: any,
    handlerChange: () => void
  ) => {
    const MySwal = withReactContent(Swal);

    const body = {
      method: 'createQuiz',
      cliente: user?.sgCliente,
      instsaude: configParams.instituicao,
      qsNome: params.qsNome,
      qcID: params.qcID ?? qcid,
      qsVisualizacao: String(params.qsVisualizacao),
      qsQtdPerguntas: params.qsQtdPerguntas,
      qsDescricao: params.qsDescricao,
      qsDataInicio: params.qsDataInicio ?? qsDataInicio,
      qsDataFim: params.qsDataFim ?? qsDataFim,
      qsAtivo: params.qsAtivo == true ? String(1) : String(0),
    };

    await api.post('aswquestionario', body).then((response) => {
      const dataResponse: IGenericResponseDTO<null> = response?.data || {};
      if (dataResponse.statusCode == 200 || dataResponse?.statusCode == 201) {
        handlerChange();
        onClose();
        reset();
        MySwal.fire({
          title: dataResponse?.message || 'Criado com sucesso!',
          icon: 'success',
        });
      } else {
        MySwal.fire({
          title: dataResponse?.message || 'Houve um erro ao tentar criar!',
          icon: 'error',
        });
      }
    });
  };

  const atualizarProvider = async (
    configParams: IConfigParamsDTO,
    originParams: IQuestionarioDTO,
    params: IQuestionarioDTO,
    onClose: () => void,
    handlerChange: () => void
  ) => {
    const MySwal = withReactContent(Swal);

    const body = {
      method: 'updateQuiz',
      cliente: user?.sgCliente,
      instsaude: configParams.instituicao,
      qsNome: params.qsNome,
      qcID: originParams.qcID ?? qcid,
      qsID: originParams.qsID,
      qsVisualizacao: String(params.qsVisualizacao),
      qsQtdPerguntas: params.qsQtdPerguntas,
      qsDescricao: params.qsDescricao,
      qsDataInicio: params.qsDataInicio ?? qsDataInicio,
      qsDataFim: params.qsDataFim ?? qsDataFim,
      qsAtivo: params.qsAtivo == true ? String(1) : String(0),
    };

    await api.post('aswquestionario', body).then((response) => {
      const dataResponse: IGenericResponseDTO<null> = response?.data || {};
      if (dataResponse.statusCode == 200 || dataResponse?.statusCode == 201) {
        handlerChange();
        onClose();
        MySwal.fire({
          title: dataResponse?.message || 'Editado com sucesso!',
          icon: 'success',
        });
      } else {
        MySwal.fire({
          title: dataResponse?.message || 'Houve um erro ao tentar editar!',
          icon: 'error',
        });
      }
    });
  };

  useEffect(() => {
    if (originParams === null) {
      setValue('qsNome', '');
      setValue('qsVisualizacao', '');
      setValue('qsQtdPerguntas', 1);
      setQsDataInicio(new Date());
      setQsDataFim(new Date());
      setValue('qsDescricao', '');
      setValue('qsAtivo', false);
      setAtivo(false);
    } else {
      setValue('qsNome', originParams.qsNome);
      setValue('qsVisualizacao', originParams.qsVisualizacao.substring(0, 1));
      setValue('qsQtdPerguntas', originParams.qsQtdPerguntas);
      setValue('qsDescricao', originParams.qsDescricao);
      setQsDataInicio(new Date(Date.parse(originParams.qsDataInicio)));
      setQsDataFim(new Date(Date.parse(originParams.qsDataFim)));
      setValue('qsAtivo', originParams.qsAtivo);
      setAtivo(originParams.qsAtivo);
    }
  }, [originParams]);

  const handleFormSubmit = async (params: IQuestionarioDTO) => {
    try {
      if (originParams) {
        atualizarProvider(
          configParams,
          originParams,
          params,
          onClose,
          handlerChange
        );
      } else {
        criarProvider(configParams, params, onClose, handlerChange);
      }
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
      }
    }
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size={'4xl'}
    >
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <ModalContent>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <ModalHeader>
            {originParams ? 'Editar' : 'Criar'} questionário
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid columns={2} gap={4} my={1}>
              <Box experimental_spaceY={2}>
                <Text>Nome</Text>
                <Input
                  type="text"
                  name="qsNome"
                  placeholder="Nome"
                  {...register('qsNome', {})}
                />
                {errors.qsNome?.message && (
                  <Text color={colors.orange}>{errors.qsNome?.message}</Text>
                )}
              </Box>
              <Box experimental_spaceY={2}>
                <Text>Visibilidade</Text>
                <Stack>
                  <Controller
                    name="qsVisualizacao"
                    control={control}
                    render={({ field: { ref, value, ...rest } }) => {
                      return (
                        <RadioGroup
                          {...rest}
                          colorScheme="blue"
                          defaultValue={value}
                        >
                          <Stack spacing={[1, 5]} direction={['column', 'row']}>
                            <Radio value="W">Web</Radio>
                            <Radio value="A">App</Radio>
                            <Radio value="T">Tablet</Radio>
                          </Stack>
                        </RadioGroup>
                      );
                    }}
                  />
                </Stack>
                {errors.qsVisualizacao?.message && (
                  <Text color={colors.orange}>
                    {errors.qsVisualizacao?.message}
                  </Text>
                )}
              </Box>
              <GridItem colSpan={2}>
                <Text>Descrição</Text>
                <Textarea
                  name="qcDescricao"
                  placeholder="Descrição"
                  {...register('qsDescricao', {
                    /**/
                  })}
                />
                {errors.qsDescricao?.message && (
                  <Text color={colors.orange}>
                    {errors.qsDescricao?.message}
                  </Text>
                )}
              </GridItem>
              <Box experimental_spaceY={2}>
                <Text>Data de início</Text>
                <DatePicker
                  autoComplete="off"
                  id="data"
                  className="form-control react-datepicker"
                  name="data"
                  dateFormat="dd/MM/yyyy"
                  selected={qsDataInicio}
                  onChange={(date) => setQsDataInicio(date)}
                  locale="pt"
                />
              </Box>
              <Box experimental_spaceY={2}>
                <Text>Data final</Text>
                <DatePicker
                  autoComplete="off"
                  id="data"
                  className="form-control react-datepicker"
                  name="data"
                  dateFormat="dd/MM/yyyy"
                  selected={qsDataFim}
                  onChange={(date) => setQsDataFim(date)}
                  locale="pt"
                />
              </Box>
              <Box experimental_spaceY={2}>
                <Text>Qtd. perguntas</Text>
                <Input
                  type="number"
                  name="qsQtdPerguntas"
                  placeholder="Qtd. perguntas"
                  {...register('qsQtdPerguntas', {
                    /**/
                  })}
                />
                {errors.qsQtdPerguntas?.message && (
                  <Text color={colors.orange}>
                    {errors.qsQtdPerguntas?.message}
                  </Text>
                )}
              </Box>
            </SimpleGrid>
          </ModalBody>
          <Divider color={colors.gray_blue} mt={'1rem'} />
          <ModalFooter>
            <Flex
              gap="2rem"
              justifyContent="space-between"
              flexDirection="row"
              alignItems="center"
              width={'100%'}
            >
              <Flex gap={4}>
                <Switch
                  style={{ lineHeight: 1.8 }}
                  defaultChecked={ativo}
                  onChange={() => {
                    setAtivo(!ativo);
                    setValue('qsAtivo', !ativo);
                  }}
                  name="qsAtivo"
                  placeholder="Descrição"
                  {...register('qsAtivo', {
                    /**/
                  })}
                />
                <Text>Questionário Ativo</Text>
              </Flex>
              <Button
                variant="outline"
                border="1px"
                color={colors.blue}
                borderColor={colors.blue}
                _hover={{ bg: colors.blue, color: '#fff' }}
                _active={{ bg: colors.textBlue }}
                type="submit"
              >
                {originParams ? 'Editar' : 'Criar'}
              </Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

export { ModalCrud };
