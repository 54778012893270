//@ts-nocheck
import { Button } from 'reactstrap'
import {
  ScheduleContainer,
  TurnHoursContainer,
  TurnsContainer
} from '../styles'

type AvailableScheduleProps = {
  state: any
  setState: React.Dispatch<any>
}

export function AvailableSchedule({ state, setState }: AvailableScheduleProps) {
  return (
    <ScheduleContainer>
      <TurnsContainer>
        <Button
          style={{
            width: '100%',
            height: '40px',
          }}
          onClick={() =>
            setState({ ...state, periodoSelecionado: 'madrugada' })
          }
          className={
            state?.horariosDisponiveis?.madrugada.length === 0
              ? 'btn btn-primary disabled'
              : state.periodoSelecionado === 'madrugada'
                ? 'btn-selected'
                : 'btn btn-primary'
          }
        >
          <span>Madrugada</span>
        </Button>
        <Button
          style={{ width: '100%' }}
          onClick={() => setState({ ...state, periodoSelecionado: 'manha' })}
          className={
            state?.horariosDisponiveis?.manha.length === 0
              ? 'btn btn-primary disabled'
              : state.periodoSelecionado === 'manha'
                ? 'btn-selected'
                : 'btn btn-primary'
          }
        >
          <span>Manhã</span>
        </Button>
        <Button
          style={{ width: '100%' }}
          onClick={() => setState({ ...state, periodoSelecionado: 'tarde' })}
          className={
            state?.horariosDisponiveis?.tarde.length === 0
              ? 'btn btn-primary disabled'
              : state.periodoSelecionado === 'tarde'
                ? 'btn-selected'
                : 'btn btn-primary'
          }
        >
          <span>Tarde</span>
        </Button>
        <Button
          style={{ width: '100%' }}
          onClick={() => setState({ ...state, periodoSelecionado: 'noite' })}
          className={
            state?.horariosDisponiveis?.noite.length === 0
              ? 'btn btn-primary disabled'
              : state.periodoSelecionado === 'noite'
                ? 'btn-selected'
                : 'btn btn-primary'
          }
        >
          <span>Noite</span>
        </Button>
      </TurnsContainer>
      <TurnHoursContainer>
        {state?.periodoSelecionado &&
          state?.horariosDisponiveis[state?.periodoSelecionado].map(
            (horario, index) => {
              return (
                <div className="hours-container" key={index}>
                  {horario.map((hour, index) => {
                    return (
                      <Button
                        style={{
                          width: 150,
                          margin: '8px auto',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                        className={
                          state.horarioSelecionado === hour
                            ? 'btn-selected'
                            : 'btn btn-primary'
                        }
                        onClick={() =>
                          setState({ ...state, horarioSelecionado: hour })
                        }
                        key={`${hour}_${index}`}
                      >
                        {hour}
                      </Button>
                    )
                  })}
                </div>
              )
            }
          )}
      </TurnHoursContainer>
    </ScheduleContainer>
  )
}
