/* eslint-disable no-console */
import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export interface ICopiarSemanaSeguinteProps {
  className?: string;
  show: boolean;
  setShow: Function;
  saveData?: any;
}

const CopiarSemanaSeguinte = (props: ICopiarSemanaSeguinteProps) => {
  const [qtdSemanas, setQtdSemanas] = useState<string>('');

  const onClickSave = () => {
    props.saveData(qtdSemanas);
    props.setShow(!props.show);
    setQtdSemanas('');
  };

  return (
    <div id={'gestao-agenda-data-filtro-container'}>
      <Modal size={'lg'} isOpen={props.show} toggle={() => props.setShow(!props.show)} className={props.className}>
        <ModalHeader toggle={() => props.setShow(!props.show)}>Copiar para semana seguinte</ModalHeader>
        <ModalBody className="p-4">
          <div>
            <div className="input-group m-1">
              <span className="input-group">Quantidade de semanas:</span>
              <input
                className="form-control"
                type="number"
                name="qtdSemanasInput"
                id="qtd-semanas-input"
                value={qtdSemanas}
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                onChange={event => setQtdSemanas(event.target.value)}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button disabled={qtdSemanas?.length <= 0} color="primary" onClick={() => onClickSave()}>
            Confirmar alterações
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CopiarSemanaSeguinte;
