import { Box, Stack } from '@chakra-ui/react';
import { ConsultaProvider } from 'contexts/Consulta';
import { ProntuarioDetalhes } from 'contexts/Consulta/components/prontuario-detalhes';
import { useParams } from 'react-router-dom';
import { HeaderModal } from './header/HeaderModal';

export const AtendimentoPresencial = (props) => {
  const { idConsulta } = useParams<{ idConsulta: string }>();

  return (
    <ConsultaProvider {...props} idConsulta={idConsulta} eTriagem>
      <Stack direction={['row', 'column']}>
        <Box>
          <HeaderModal />
        </Box>
      </Stack>
    </ConsultaProvider>
  );
};
