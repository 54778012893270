import { Box, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Col, Label, Row, Table } from 'reactstrap';

import { useApiRequest, useUser } from 'hooks';
import { Modaltrigem } from 'pages/atendimento/components/modal-triagem';
import { SearchButton } from 'pages/liberacao-agendamento/styles';

import searchIcon from './assets/search-line.svg';

interface IHistoricoAtendimento {
  usuarioNomeSocial: string;
  nmUsuario: string;
  peDescricao: string;
  usuarioCPF: string;
  huDataHoraAgenda: string;
  huTipo: string;
  huDescricao: string;
  huDtInicioConsulta: string;
  huDtFimConsulta: string;
  huRetorno: string;
  dtInclusao: string;
  agIdConsulta: string;
  huSubjetivo: string;
  huObjetivo: string;
  huAvaliacao: string;
  huPlano: string;
  psNome: string;
  huTriagPressaoArterial?: string;
  hrTriagFreqCardiaca?: string;
  huTriagFreqRespiratoria?: string;
  huTriagSaturacao?: string;
  huTriagTemperatura?: string;
  huTriagPeso?: string;
  huTriagAltura?: string;
  huTriagSGTosse?: number;
  huTriagSGCoriza?: number;
  huTriagSGDorGarganta?: number;
  huTriagSGDorCabeca?: number;
  huTriagSGEspirros?: number;
  huTriagSGDorCostas?: number;
  huTriagSGDiarreia?: number;
  huTriagSGFebre?: number;
  huTriagSGOutros?: string;
  listagemCID10?: any[];
  listagemPrescricao?: any[];
}

export default function HistoricoDeAtendimentos() {
  const [openModalWithIdConsulta, setOpenModalWithIdConsulta] = useState('');
  const [cpfPaciente, setCpfPaciente] = useState<string>('');

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const user = useUser();

  const historicoAtendimentoAPI = useApiRequest<IHistoricoAtendimento[]>(
    'aswhistusuario',
    []
  );

  const handleSearchButton = () => {
    if (user.grupoEscolhido.grupo === 'Adm Teleconsulta' || user.grupoEscolhido.grupo === 'recepcao' || user.grupoEscolhido.grupo === 'Adm FCV') {
      historicoAtendimentoAPI.request({
        method: '_listWebAdm',
        search: cpfPaciente,
        dataAgendaStart: startDate
          ? dayjs(startDate).format('YYYY-MM-DD 00:00:00')
          : '',
        dataAgendaEnd: endDate
          ? dayjs(endDate).format('YYYY-MM-DD 23:59:59')
          : '',
        sgInstSaude: user.institucoes[0].institucao
      });
    } else {
      historicoAtendimentoAPI.request({
        method: '_listWeb',
        search: cpfPaciente,
        dataAgendaStart: startDate
          ? dayjs(startDate).format('YYYY-MM-DD 00:00:00')
          : '',
        dataAgendaEnd: endDate
          ? dayjs(endDate).format('YYYY-MM-DD 23:59:59')
          : '',
      });
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="breadcrumb">
          <h6>HISTÓRICO DE ATENDIMENTOS</h6>
        </div>
      </div>
      <Row>
        <Col md="6">
          <Label for="historico-atendimento-paciente">Buscar</Label>
          <input
            id="historico-atendimento-paciente"
            placeholder="CPF do paciente"
            type="text"
            className="form-control"
            value={cpfPaciente}
            onChange={(evt) => setCpfPaciente(evt.target.value)}
          />
        </Col>
        <Col md="2">
          <span>Data início</span>
          <ReactDatePicker
            className="form-control"
            placeholderText="Data Inicial"
            selected={startDate}
            onChange={(date: Date) => {
              setStartDate(date);
            }}
            value={startDate as unknown as string}
            dateFormat="dd/MM/yyyy"
          />
        </Col>
        <Col md="2">
          <span>Data Final</span>
          <ReactDatePicker
            className="form-control"
            placeholderText="Data Final"
            selected={endDate}
            onChange={(date: Date) => {
              setEndDate(date);
            }}
            value={endDate}
            minDate={startDate}
            dateFormat="dd/MM/yyyy"
          />
        </Col>
        <Col md="2">
          <SearchButton
            style={{ marginTop: 5 }}
            color="primary"
            onClick={() => {
              handleSearchButton();
            }}
          >
            {historicoAtendimentoAPI.isLoading ? (
              <>
                <i className="fas fa-spinner fa-spin"></i>
              </>
            ) : (
              <i className="fa fa-search" color="warning" />
            )}
          </SearchButton>
        </Col>
      </Row>

      <div>
        {historicoAtendimentoAPI.state.length > 0 ? (
          <div className="table-list mt-4">
            <Table
              responsive
              className={'table-hover table-striped table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th>Profissional de Saúde</th>
                  <th>Especialidade</th>
                  <th>Paciente</th>
                  <th>Horário de Atendimento</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {historicoAtendimentoAPI.state.map(
                  (historico: any, i: number) => (
                    <tr key={`${historico.psNome + i}`}>
                      <td>{historico.psNome}</td>
                      <td>{historico.huDescricao}</td>
                      <td>{historico.nmUsuario}</td>
                      <td>
                        {dayjs(historico.huDataHoraAgenda).format(
                          'DD-MM-YYYY HH:mm'
                        )}
                      </td>
                      <td className="text-center">
                        <div className="btn-group flex-btn-group-container">
                          <button
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Detalhes"
                            className={'btn btn-primary btn-lg'}
                            onClick={() =>
                              setOpenModalWithIdConsulta(historico.agIdConsulta)
                            }
                          >
                            <i className="fa fa-search" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </div>
        ) : (
          <Box w="full" display="flex" justifyContent="center">
            <Box mt={4} mb={4}>
              <Box display="flex" justifyContent="center">
                <img src={searchIcon} alt="icon" />
              </Box>
              <Box textAlign="center" >
                <Text color="#9C9C9C" fontWeight='bold'>
                  Faça uma pesquisa por nome, CPF ou período
                </Text>
                <Text fontSize='14px' color="#9C9C9C"> Use a barra de pesquisa acima</Text>
              </Box>
            </Box>
          </Box>
        )}

        {/* {!historicoAtendimentoAPI.isLoading &&
          !historicoAtendimentoAPI.state.length && (
            <div className="mt-4 alert alert-warning">Nada encontrado</div>
          )} */}
      </div>

      {openModalWithIdConsulta.length > 0 && (
        <Modaltrigem
          eHistorico
          idConsulta={openModalWithIdConsulta}
          nomeUsuario={historicoAtendimentoAPI.state[0].nmUsuario}
          usuarioNomeSocial={
            historicoAtendimentoAPI?.state[1]?.usuarioNomeSocial
          }
          close={() => {
            setOpenModalWithIdConsulta('');
          }}
        />
      )}
    </>
  );
}
