import { Loading } from 'components/Loading';
import { useUser } from "hooks";
import { useEffect, useState } from "react";
import { Button, Col, Container, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import { api } from "services/api";
import { changeInputText } from 'shared/util';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { ModalForm } from "./componentes/ModalForm";
import { DadosType } from './types';

export const especialidadesProfissional = () => {
  const user = useUser();
  const MySwal = withReactContent(Swal);
  const [showModalForm, setShowModalForm] = useState(false);
  const [deleteModal, setDeleteModal] = useState('');
  const [inputFilter, setInputFilter] = useState('');
  const [especialidade, setEspecialidade] = useState<DadosType | null>(null);
  const [especialidadeList, setEspecialidadeList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const excluirEspecialidades = async (idEspecialidade: string) => {
    const res = api
      .post('aswgerenciarespecialidades', {
        method: '_delete',
        descricao: idEspecialidade,
        sgCliente: user.sgCliente
      });
    const json = await res;
    MySwal.fire({
      title: 'Deletar especialidade',
      html: json.data.message,
      icon: json.data.statusCode === 200 ? 'success' : 'error',
      showConfirmButton: true,
      confirmButtonColor: json.data.statusCode === 200 ? '#008000' : '#e70015',
    });
    listarEspecialidade();
  }
  const listarEspecialidade = async () => {
    api
      .post('aswgerenciarespecialidades', {
        method: '_list',
        sgCliente: user.sgCliente
      }).then(({ data }) => {
        setEspecialidadeList(data.data)
        setIsLoading(false)
      }).catch((err) => {
        console.log('erro ao listar especialidades' + err);
        setIsLoading(false)
      })

  }
  const filterEspecialidades = inputFilter.length > 0 ? especialidadeList.filter(especialidade => especialidade.peDescricao.includes(inputFilter)) : especialidadeList

  useEffect(() => {
    if (!showModalForm) {
      listarEspecialidade();
    }

  }, [showModalForm]);

  return (
    <Container>
      <h4
        id="gerenciamento-profissional-saude-heading"
        data-cy="GerenciamentoProfissionalSaudeHeading"
      >
        <div className="d-flex justify-content-between">
          <span>Especialidades</span>
          <Button
            onClick={() => setShowModalForm(true)}
            color="primary"
            className="btn btn-primary float-right jh-create-entity"
          >
            <span>Cadastrar Nova Especialidade</span>
          </Button>
        </div>
      </h4>
      <div className="table-responsive">
        <div id="form-filter">
          <div className="row mt-1 ml-3 mr-3">
            <Col
              md="12"
              className="col-filter-gerenciamento-profissional-saude-nome"
            >
              <Row className="mr-1 mt-1">
                <Label
                  style={{ backgroundColor: 'red' }}
                  id="nomeLabel"
                  for="gerenciamento-profissional-saude-nome"
                >
                  Especialidade
                </Label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Buscar especialidade"
                  name="nome"
                  id="gerenciamento-profissional-saude-nome"
                  value={inputFilter}
                  onChange={(event) => setInputFilter(changeInputText(event))}
                />
              </Row>
            </Col>
          </div>
        </div>

        {especialidadeList?.length > 0 && (
          <div
            id="gerenciamento-profissional-saude-table-list"
            className="table-list mt-4"
          >
            <Table
              id="gerenciamento-profissional-saude-table-list"
              responsive
              aria-describedby="gerenciamento-profissional-saude-heading"
              className={'table-hover table-striped table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="align-top">Especialidade</th>
                  <th className="align-top text-center">Editar</th>
                  <th className="align-top text-center">Excluir</th>
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {filterEspecialidades?.map(
                  (especialidade, i: number) => (
                    <tr key={`entity-${i}`}>
                      <td id="nome-cell">{especialidade.peDescricao}</td>
                      <td className="text-center">
                        <div className="btn-group flex-btn-group-container">
                          <a
                            className={'btn btn-primary btn-lg'}
                            onClick={() => {
                              setEspecialidade(especialidade);
                              setShowModalForm(true);
                            }}
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Editar"
                          >
                            <i className="fa fa-pencil-alt" />
                          </a>
                        </div>
                      </td>
                      <td className="text-center">
                        <div className="btn-group flex-btn-group-container">
                          <a
                            className={'btn btn-primary btn-lg'}
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            onClick={() =>
                              setDeleteModal(especialidade.peDescricao)
                            }
                            title="Excluir"
                          >
                            <i className="fa fa-trash" />
                          </a>
                        </div>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </div>
        )}
        {showModalForm && (
          <ModalForm
            isNew={!especialidade}
            onRequestClose={() => {
              setShowModalForm(false);
              setEspecialidade(null);
            }}
            dados={especialidade}
          />
        )}
      </div>
      {isLoading && <Loading />}
      {deleteModal &&
        <Modal
          size={'sm'}
          isOpen={deleteModal ? true : false}
        >
          <ModalHeader>
          </ModalHeader>
          <ModalBody>
            <Row className="justify-content-center mx-3">
              <span className="page-header ml-3">
                Tem certeza que deseja apagar a especialidade?
              </span>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setDeleteModal('')}>Cancelar</Button>
            <Button color="btn btn-primary" onClick={() => {
              excluirEspecialidades(deleteModal)
              setDeleteModal('')
            }}>Sim</Button>
          </ModalFooter>
        </Modal>
      }
    </Container>
  )
}