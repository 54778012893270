import styled from 'styled-components';
import { colors } from 'theme/colors';

export const HeaderContainer = styled.div`
  nav.navbar {
    background: linear-gradient(180deg, #d9f0fc 0%, #a4d2eb 100%);
    min-height: 15px;
    padding: 0;

    .collapse.navbar-collapse {
      flex-grow: 0;
    }

    span,
    a {
      color: ${colors.textBlue};
    }

    @media only screen {
      div {
        .dropdown-menu.dropdown-menu-right.show {
          background: #fff !important;
          border-top: none;
          margin-top: 10px;
          padding-top: 0;
        }

        ul {
          text-align: left;
        }
      }
    }

    .dropdown-item {
      color: ${colors.textBlue};
      text-decoration: none;
      background-color: #fff !important;
      :active {
        font-weight: bold;
      }
    }

    button.navbar-toggler {
      border: solid 1px ${colors.textBlue};
      .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(39, 103, 160, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
      }
    }
  }
`;
