import { VideoComponent } from 'components/video';
import { useUrlParams } from 'hooks';

export function VideoScreen() {
  const urlParams = useUrlParams();

  return (
    <>
      <VideoComponent
        {...{
          apiKey: urlParams.get('apiKey'),
          sessionId: urlParams.get('sid'),
          token: urlParams.get('token'),
        }}
      />
    </>
  );
}
