import { Spinner } from '@chakra-ui/react';
import styled, { css } from 'styled-components';

const Container = styled.div`
  position: relative;
`;

const Loading = styled.div<{ borderRadius: number; backgroundColor: string }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ backgroundColor, borderRadius }) => css`
    background-color: ${backgroundColor};
    border-radius: ${borderRadius}px;
  `}
`;

type Props = {
  children: JSX.Element;
  isLoading: boolean;
  spinnerSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  borderRadius?: number;
  backgroundColor?: string;
};

export function WrapperLoading({
  children,
  isLoading,
  spinnerSize = 'sm',
  borderRadius = 8,
  backgroundColor = 'rgba(0, 0, 0, 0.1)',
}: Props) {
  return (
    <Container>
      <>
        {children}
        {isLoading && (
          <Loading
            borderRadius={borderRadius}
            backgroundColor={backgroundColor}
          >
            <Spinner size={spinnerSize} />
          </Loading>
        )}
      </>
    </Container>
  );
}
