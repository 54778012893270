import { Range } from 'rc-slider';
import React, { useState } from 'react';

export interface IGerenciamentoProfissionalSaudeProps {
  baseState?: any;
  baseProps?: any;
  setState?: any;
  entityBase?: any;
}

export const RangeDateHeader = (props: IGerenciamentoProfissionalSaudeProps) => {
  const [state, setState] = useState({ value: [0] });
  const handleChange = (value: any) => { };

  const marks = (() => {
    const m: any = {};
    for (let val = 0; val <= 72; val++) {
      if (val % 3 === 0) {
        m[val] = Math.floor(val / 3) + 'h';
      }
    }
    return m;
  })();
  const handleStyle = { border: '0 none', backgroundColor: 'transparent', cursor: 'default' };

  return (
    <div style={{ width: '100%', height: '25px' }}>
      <Range
        marks={marks}
        min={0}
        max={72}
        dots={false}
        draggableTrack={false}
        dotStyle={handleStyle}
        trackStyle={[handleStyle]}
        railStyle={handleStyle}
        handleStyle={[handleStyle, handleStyle]}
      />
    </div>
  );
};

export default RangeDateHeader;
