import { Badge, Box, Button, Flex, Select } from '@chakra-ui/react';
import moment from 'moment';
import { useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Table } from 'reactstrap';

import { DialogConfirm, DialogConfirmRefProps } from 'components/dialog-confirm';
import { Loading } from 'components/Loading';
import { WrapperLoading } from 'components/wraper-loading';
import { useApiRequest, useFlashText, useUser } from 'hooks';
import { AgendaPresencialType } from 'pages/atendimento-triagem';
import { api } from 'services/api';
import { dateTimeToServer, LOG } from 'shared/util';

import { Pagination } from '../../../../components/pagination';
import cancelIcon from '../../../../shared/assets/cancelIcon.svg';

type TabelaRecepcaoProps = {
  agendamentos: Array<{
    agIdConsulta: string;
    convenio: string;
    cpf: string;
    descricao: string;
    usuario: string;
    usuarioCPF: string;
    idConsulta: string;
    locationName: string;
    nmInstSaude: string;
    nome: string;
    numeroCartao: string;
    planoConvenio: string;
    sgInstSaude: string;
    status: string;
    dtChechin: Date;
    agendamento: Date;
    tempoMedioConsulta: number;
    triagemExecutada: number;
    idade: number;
    genero: 'M' | 'F';
    agTeleFisico: 'F' | 'T';
  }>;
  loadAgendamentos: (date?: Date) => Promise<void>;
  dateSelected?: Date;
};

type MotivoCancelamentoType = {
  mcDescricao: string;
  mcOrdemExibicao: number;
  mcPSPaciente: string;
};

export const TabelaRecepcao = ({
  agendamentos,
  loadAgendamentos,
  dateSelected
}: TabelaRecepcaoProps) => {
  const [loading, setLoading] = useState(false);
  const [todayDate, setTodayDate] = useState(dateTimeToServer(new Date()));
  const recepcaoUser = useUser();
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const confirmCancelRef = useRef<DialogConfirmRefProps>(null);
  const [motivoCancelamento, setMotivoCancelamento] =
    useState<MotivoCancelamentoType | null>(null);
  const motivoFlash = useFlashText();
  const [atendimentoSelecionado, setAtendimentoSelecionado] = useState<any>();
  const [startDate, setStartDate] = useState<Date>();

  const motivosHook = useApiRequest<Array<MotivoCancelamentoType>>(
    'aswmotivocancelamento',
    []
  );

  const agendamentosHook = useApiRequest<AgendaPresencialType[]>(
    'aswagendausuprofsaude',
    []
  );

  //página
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = agendamentos.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const confirmarAgendamento = async (atendimento: any) => {
    setLoading(true);
    await api.post('aswagendausuprofsaude', {
      method: '_checkIn',
      profSaude: atendimento.cpf,
      paciente: atendimento.usuarioCPF,
      data: atendimento.agendamento,
      local: atendimento.locationName,
      recepCaoCPF: recepcaoUser?.cpf,
      sgCliente: recepcaoUser.sgCliente,
      sgInstSaude: atendimento.sgInstSaude,
      agTeleFisico: atendimento.agTeleFisico,
    });

    await loadAgendamentos(dateSelected);
    setLoading(false);
  };

  const registarFalta = async (atendimento: any) => {
    setLoading(true);
    await api.post('aswagendausuprofsaude', {
      method: '_registrarFalta',
      profSaude: atendimento.cpf,
      paciente: atendimento.usuarioCPF,
      data: atendimento.agendamento,
      local: atendimento.locationName,
      recepCaoCPF: recepcaoUser?.cpf,
      sgCliente: recepcaoUser.sgCliente,
      sgInstSaude: atendimento.sgInstSaude,
    });

    await loadAgendamentos(dateSelected);
    setLoading(false);
  };

  const registrarEvasao = async (atendimento: any) => {
    setLoading(true);
    await api.post('aswagendausuprofsaude', {
      method: '_registrarEvasao',
      profSaude: atendimento.cpf,
      paciente: atendimento.usuarioCPF,
      data: atendimento.agendamento,
      local: atendimento.locationName,
      recepCaoCPF: recepcaoUser?.cpf,
      sgCliente: recepcaoUser.sgCliente,
      sgInstSaude: atendimento.sgInstSaude,
    });

    await loadAgendamentos(dateSelected);
    setLoading(false);
  };

  function fnSearchDate(date: Date) {
    setStartDate(date);
  }

  return (
    <div className="table-responsive">

      <>
        {agendamentos && agendamentos.length > 0 ? (
          <div id="atendimento-table-list" className="table-list">
            <Table
              id="atendimento-table-list"
              responsive
              aria-describedby="atendimento-heading"
              className={
                'table-hover table-striped table-responsive-css td-atendimentos'
              }
            >
              <thead className={'thead-light'}>
                <tr>
                  <th>Horário de atendimento</th>
                  <th>Profissional de Saúde</th>
                  <th>Especialidade</th>
                  <th>Paciente</th>
                  <th>CPF</th>
                  <th>Idade</th>
                  <th>Gênero</th>
                  <th style={{ textAlign: 'center' }}>Status</th>
                  <th>Cancelar</th>
                </tr>
              </thead>
              <tbody>
                {currentPosts.map((atendimento: any, i: number) => (
                  <tr key={`entity-${i}`}>
                    <td id="horario-cell">
                      <b className="visible-xs">Horário de Atendimento</b>
                      {moment(atendimento.agendamento).format('HH:mm')} -{' '}
                      {moment(atendimento.agendamento)
                        .add(atendimento.tempoMedioConsulta, 'minutes')
                        .format('HH:mm')}
                    </td>

                    <td id="profissional">
                      <b className="visible-xs">Profissional de Saúde</b>
                      {atendimento.nome}
                    </td>
                    <td id="especialidade">
                      <b className="visible-xs">Especialidade</b>
                      {atendimento.descricao}
                    </td>
                    <td id="paciente-cell">
                      <b className="visible-xs"> Paciente </b>
                      {atendimento.usuario}
                    </td>
                    <td id="CPF" style={{ textAlign: 'center' }}>
                      <b className="visible-xs">CPF</b>
                      {atendimento.usuarioCPF}
                    </td>
                    <td id="Idade" style={{ textAlign: 'center' }}>
                      <b className="visible-xs">Idade</b>
                      {atendimento.idade}
                    </td>
                    <td id="genero" style={{ textAlign: 'center' }}>
                      <b className="visible-xs">Gênero</b>
                      {atendimento.genero}
                    </td>

                    <td style={{ textAlign: 'center' }}>
                      {Boolean(atendimento.dtChechin) ? (
                        <div>
                          <Flex w="full" justify="space-around" align="center">
                            <Button
                              colorScheme="green"
                              borderRadius="15px"
                              id="btn-comparecimento"
                              color="black"
                              disabled={true}
                            >
                              Confirmado
                            </Button>
                            {atendimento.agTeleFisico === 'F' && (
                              <Button
                                variant="outline"
                                colorScheme="yellow"
                                borderRadius="15px"
                                id="btn-comparecimento"
                                color="black"
                                onClick={() => {
                                  registrarEvasao(atendimento);
                                }}
                                disabled={!!atendimento.dtEvasao}
                              >
                                Evasão
                              </Button>
                            )}

                            {atendimento.agTeleFisico === 'T' && (
                              <Button
                                variant={!atendimento.dtFalta ? "outline" : 'solid'}
                                colorScheme="blue"
                                color={atendimento.dtFalta && "black"}
                                borderRadius="15px"
                                id="btn-comparecimento"
                                onClick={() => {
                                  registarFalta(atendimento);
                                }}
                                disabled={!!atendimento.dtFalta}
                              >
                                {atendimento.dtFalta ? 'Faltou' : 'Falta'}
                              </Button>
                            )}
                          </Flex>
                        </div>
                      ) : (
                        <Flex w="full" justify="space-around" align="center">
                          <Button
                            variant="outline"
                            colorScheme="teal"
                            borderRadius="15px"
                            id="btn-comparecimento"
                            onClick={() => {
                              confirmarAgendamento(atendimento);
                            }}
                            disabled={!!atendimento.dtFalta}
                            // disabled={
                            //   moment(atendimento.agendamento)
                            //     .format('DD/MM/YYYY')
                            //     .includes(
                            //       moment(todayDate).format('DD/MM/YYYY')
                            //     )
                            //     ? false
                            //     : true
                            // }
                          >
                            {atendimento.agTeleFisico === 'T'
                              ? 'Confirmar'
                              : 'Check-in'}
                          </Button>

                          <Button
                            variant="outline"
                            colorScheme="blue"
                            borderRadius="15px"
                            id="btn-comparecimento"
                            onClick={() => {
                              registarFalta(atendimento);
                            }}
                            disabled={!!atendimento.dtFalta}
                            // disabled={
                            //   moment(atendimento.agendamento)
                            //     .format('DD/MM/YYYY')
                            //     .includes(
                            //       moment(todayDate).format('DD/MM/YYYY')
                            //     )
                            //     ? false
                            //     : true
                            // }
                          >
                            Falta
                          </Button>
                        </Flex>
                      )}
                    </td>

                    <td>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          cursor: 'pointer',
                        }}
                        title="Cancelar Agendamento"
                        onClick={() => {
                          setAtendimentoSelecionado(atendimento);
                          if (motivosHook.state.length < 1) {
                            motivosHook.request({
                              method: '_listSpecifics',
                            });
                          }
                          setMotivoCancelamento(null);
                          setAtendimentoSelecionado(atendimento);
                          confirmCancelRef?.current?.open();
                        }}
                      >
                        <img src={cancelIcon} alt="cancelIcon" />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {agendamentos.length > 10 && (
              <div>
                {' '}
                <Pagination
                  postsPerPage={postsPerPage}
                  totalPosts={agendamentos.length}
                  paginate={paginate}
                />
              </div>
            )}
          </div>
        ) : (
          <div className="alert alert-warning">
            Não foram encontrados atendimentos
          </div>
        )}
      </>
      {loading && <Loading />}
      <DialogConfirm
        ref={confirmCancelRef}
        titulo="Cancelar o atendimento"
        cancelButton={{ text: 'Cancelar' }}
        buttons={[
          {
            text: 'Confirma',
            color: 'red',
            // closeOnClick: true,
            onClick: () => {
              LOG(motivoCancelamento);
              if (!motivoCancelamento) {
                motivoFlash.setText('Selecione o motivo!');
                return;
              }
              agendamentosHook
                .submit({
                  method: '_cancel',
                  agDataHoraAgenda: atendimentoSelecionado?.agendamento,
                  agIdConsulta: atendimentoSelecionado?.idConsulta,
                  psCPF: atendimentoSelecionado?.cpf,
                  usuarioCPF: atendimentoSelecionado?.usuarioCPF,
                  mcDescricao: motivoCancelamento?.mcDescricao,
                  mcPSPaciente: motivoCancelamento?.mcPSPaciente,
                  sgCliente: 'asg',
                  sgInstSaude: atendimentoSelecionado.sgInstSaude,
                })
                .then((response) => {
                  if (response.success) {
                    agendamentosHook.setState(
                      agendamentosHook.state.filter(
                        (a) =>
                          a.idConsulta !== atendimentoSelecionado?.idConsulta
                      )
                    );
                  }
                  confirmCancelRef?.current?.close();
                });
            },
          },
        ]}
      >
        <Box flex="1">
          <WrapperLoading isLoading={motivosHook.isLoading}>
            <Select
              size={'md'}
              value={motivoCancelamento?.mcDescricao || ''}
              onChange={(event) => {
                const { value } = event.target;
                if (value) {
                  event.persist();
                  const motivo = motivosHook.state.find(
                    (m) => m.mcDescricao === value
                  );
                  if (motivo) {
                    setMotivoCancelamento(motivo);
                  }
                }
              }}
            >
              <option value="">Selecione o motivo</option>
              {motivosHook.state.map((item, index) => {
                return (
                  <option key={index} value={item.mcDescricao}>
                    {item.mcDescricao}
                  </option>
                );
              })}
            </Select>
          </WrapperLoading>
          <small>&nbsp;{motivoFlash.text}</small>
        </Box>
      </DialogConfirm>
    </div>
  );
};
