/* eslint no-console: off */
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import { useOnlyMounted } from 'hooks';
import React, { useState } from 'react';
import { api } from 'services/api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

export const DashRocheqt = () => {
  const [instituicao, setinstituicao] = useState('techtools');
  const { onlyMounted } = useOnlyMounted();
  const [dashboardObj, setDashboardObj] = useState(null);
  const dashboardRef = React.useRef(null);

  async function embed(sgInstSaude: string): Promise<void> {
    executaRequisicao(
      'aswquick',
      { dashboard: 'rocheqt' },
      ({ data, success }) => {
        if (!data.EmbedUrl) {
          MySwal.fire({
            title: 'Erro ao consultar Dashboard',
            html: '',
            icon: 'error',
            showConfirmButton: true,
            confirmButtonColor: '#e70015',
          });
          return;
        }
        const options = {
          url: data.EmbedUrl,
          container: dashboardRef.current,
          height: 'AutoFit',
          loadingHeight: '400px',
          scrolling: 'no',
          locale: 'pt-BR',
          footerPaddingEnabled: true,
        };
        setDashboardObj(QuickSightEmbedding.embedDashboard(options));
      }
    );
  }

  const executaRequisicao = (
    url = 'aswinstsaude',
    params: object,
    callback: Function
  ) => {
    api
      .post(url, params)
      .then(({ data }) => {
        onlyMounted(() => {
          callback(data);
        });
      })
      .finally(() => {
        onlyMounted(() => {});
      });
  };

  React.useEffect(() => {
    embed(instituicao);
    return;
  }, [instituicao]);

  return (
    <>
      <div className="breadcrumb">
        <h6>DASHBOARD - ROCHE QT</h6>
      </div>
      <div ref={dashboardRef} />
    </>
  );
};

export default DashRocheqt;
