import { ButtonProps } from 'reactstrap';
import styled from 'styled-components';

interface Props extends ButtonProps {
  aprovar: boolean;
}

const colors = {
  aprovado: '#6fda99',
  reprovado: '#e78e4e',
};

export const ContainerBotoes = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
`;

export const BotaoAprovar = styled.button<Props>`
  color: ${(props) =>
    props.aprovar === true ? `${colors.aprovado}` : `${colors.reprovado}`};
  border: 1px solid
    ${(props) =>
      props.aprovar === true ? `${colors.aprovado}` : `${colors.reprovado}`};
  padding: 5px 10px;
  border-radius: 10px;
  transition: 0.5s;

  :hover {
    color: white;
    background-color: ${(props) =>
      props.aprovar === true ? `${colors.aprovado}` : `${colors.reprovado}`};
    i {
      color: white;
    }
  }

  :disabled {
    opacity: 0.6;
  }

  i {
    color: ${(props) =>
      props.aprovar === true ? `${colors.aprovado}` : `${colors.reprovado}`};
    margin-right: 5px;
  }
`;
