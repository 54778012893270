import { useState } from 'react';

export const useFlashText = () => {
  const [text, _setText] = useState<string>('');

  return {
    text,
    setText: (t: string, timer = 1) => {
      _setText(t);
      setTimeout(() => {
        _setText('');
      }, timer * 1000);
    },
  };
};
