import styled from 'styled-components';

export const Container = styled.main`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Col = styled.div`
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Img = styled.img.attrs({
  src: '/images/imgTiliaHome.svg',
})`
  max-width: 50%;
  max-height: 100%;
`;
