export function DocumentViewicon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_418_2561)">
        <path
          d="M18 6H10C8.9 6 8.01 6.9 8.01 8L8 24C8 25.1 8.89 26 9.99 26H22C23.1 26 24 25.1 24 24V12L18 6ZM10 8H17L22 13V21.58L20.16 19.74C21.44 17.8 21.23 15.17 19.52 13.46C18.55 12.49 17.28 12 16 12C14.72 12 13.45 12.49 12.47 13.46C10.52 15.41 10.52 18.57 12.47 20.51C13.44 21.48 14.72 21.97 16 21.97C16.96 21.97 17.92 21.69 18.75 21.14L21.6 24H10V8ZM18.11 19.1C17.55 19.66 16.8 19.98 16 19.98C15.2 19.98 14.45 19.67 13.89 19.1C13.33 18.54 13.01 17.79 13.01 16.99C13.01 16.19 13.32 15.44 13.89 14.88C14.45 14.31 15.2 14 16 14C16.8 14 17.55 14.31 18.11 14.88C18.67 15.44 18.99 16.19 18.99 16.99C18.99 17.79 18.68 18.54 18.11 19.1Z"
          fill="#56A1E1"
        />
      </g>
      <rect x="0.5" y="0.5" width="31" height="31" rx="9.5" stroke="#56A1E1" />
      <defs>
        <clipPath id="clip0_418_2561">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(6 6)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
