import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 66.6%;
  height: 100%;
  position: relative;
  padding: 0 15px;
`;

export const ContactInfo = styled.div`
  display: block;
  background-color: rgba(238, 238, 238, 0.55);
  color: #444;
  padding: 10px 15px;
`;

export const ContactContent = styled.div`
  color: #444;
  margin-left: 12px;
  width: 70%;

  h6 {
    font-size: 12px;
    line-height: 14px;
    margin-top: 6px;
  }

  p {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const ChatPanel = styled.div`
  color: #444;
  height: 100%;
`;

export const ChatContent = styled.div`
  height: 100%;
  max-height: 450px;
  overflow-x: hidden;
  overflow-y: scroll;
`;

export const MessageContainer = styled.div`
`;

interface MessageContentProps {
  type: 'I' | 'O'
}

export const MessageContent = styled.div<MessageContentProps>`
  background-color: ${({ type }) => type === 'O' ? '#77c3ed' : '#eee'};
  color: ${({ type }) => type === 'O' ? '#eee' : '#444'};
  border-radius: 9px;
  box-sizing: border-box;
  margin: 10px 30px;
  padding: 10px 14px;
  display: block;
  position: relative;

  animation-delay: 0s;
  animation-direction: normal;
  animation-duration: 1s;
  animation-fill-mode: none;
  animation-iteration-count: 1;
  animation-name: fadeIn;
  animation-play-state: running;
  animation-timing-function: ease-in;

  ::after {
    ${({ type }) =>
      type === 'O' && css`
        border-bottom-color: rgb(255, 255, 255);
        border-bottom-style: none;
        border-bottom-width: 0px;
        border-image-outset: 0;
        border-image-repeat: stretch;
        border-image-slice: 100%;
        border-image-source: none;
        border-image-width: 1;
        border-left-color: #77c3ed;
        border-left-style: solid;
        border-left-width: 20px;
        border-right-color: rgb(255, 255, 255);
        border-right-style: none;
        border-right-width: 0px;
        border-top-color: rgba(0, 0, 0, 0);
        border-top-style: solid;
        border-top-width: 20px;
        box-sizing: border-box;
        content: "";
        display: block;
        height: 20px;
        margin-right: -20px;
        margin-top: -10px;
        position: absolute;
        right: 0px;
        top: 28.5625px;
        width: 20px;
      `
    };

    ${({ type }) =>
      type === 'I' && css`
        border-bottom-color: rgb(68, 68, 68);
        border-bottom-style: none;
        border-bottom-width: 0px;
        border-image-outset: 0;
        border-image-repeat: stretch;
        border-image-slice: 100%;
        border-image-source: none;
        border-image-width: 1;
        border-left-color: rgb(68, 68, 68);
        border-left-style: none;
        border-left-width: 0px;
        border-right-color: rgb(238, 238, 238);
        border-right-style: solid;
        border-right-width: 20px;
        border-top-color: rgba(0, 0, 0, 0);
        border-top-style: solid;
        border-top-width: 20px;
        box-sizing: border-box;
        content: "";
        display: block;
        height: 20px;
        left: 0px;
        margin-left: -20px;
        margin-top: -10px;
        position: absolute;
        top: 28.5625px;
        width: 20px;
      `
    };
  };

`;

export const InputContainer = styled.div`
  background: #eee;
  display: flex;
  align-items: baseline;
  padding: 10px 15px;
  align-items: center;
  margin-top: 19px;
  bottom: 0;

  input {
    margin: 0 10px;
    padding: 6px 2px;
  }

  i {
    color: grey;
    font-size: 30px;
    vertical-align: middle;
  }

  i:last-of-type {
    margin-left: 25px;
  }
`;
