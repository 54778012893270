import {
  Button,
  Checkbox,
  CheckboxGroup,
  Divider,
  Flex,
  Heading,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';

import { api } from 'services/api';
import { colors } from 'theme/colors';

import { FormMultipleOptions } from './components/FormMultipleOptions';
import { FormOneOption } from './components/FormOneOption';
import { FormTextArea } from './components/FormTextArea';
import { IQuestionarioDTO } from './dtos/IFormularioQuestionarioDto';

/**
 * @returns FormularioQuestionario
 */

const FormularioQuestionario = () => {
  const [queuesQuestion, setQueuesQuestion] = useState([]);
  const [questionSelected, setQuestionSelected] = useState([]);
  const [questionId, setQuestionId] = useState(0);
  const tabsRef = useRef([]);
  /**
   * @description o padrao array para 'groupQuestions' + 'type' dispensa a checagens desnecessarias
   */
  const dataListaQuestions = {
    idQuestionario: 123, // opcional
    idCampanha: 123, // opcional
    titleQuestions: 'O que voce mais gosta no nosso App?',
    questions: [
      {
        questionId: 1,
        titleQuestion:
          'Pergunta de multipla escolha? 1 (com regras de amarracao)',
        type: 'alternativa',
        groupQuestions: [
          { title: 'Opção 1', value: 'opcao1' },
          { title: 'Opção 2', value: 'opcao2' },
          { title: 'Opção 3', value: 'opcao3' },
        ], // Multiplas resposta 3/5 perguntas
        rules: [
          {
            rulesGroup: ['opcao1'],
            nextQuestionId: 2,
          },
          {
            rulesGroup: ['opcao2'],
            nextQuestionId: 4,
          },
          {
            rulesGroup: ['opcao2', 'opcao3'],
            nextQuestionId: 3,
          },
        ],
      },
      {
        questionId: 2,
        titleQuestion: 'Pergunta de unica escolha? 2',
        type: 'unica',
        groupQuestions: [
          { title: 'Opção 1', value: 'opcao1' },
          { title: 'Opção 2', value: 'opcao2' },
        ], // Resposta unica (apenas 1 selecao)
        rules: [],
      },
      {
        questionId: 3,
        titleQuestion: 'Pergunta dissertativa? 3',
        type: 'dissertativa',
        groupQuestions: [], // Dissertativa texto livre
        rules: [],
      },
      {
        questionId: 4,
        titleQuestion: 'Pergunta de escolha unica? 4',
        type: 'unica',
        groupQuestions: [
          { title: 'Opção 1', value: 'opcao1' },
          { title: 'Opção 2', value: 'opcao2' },
        ], // Resposta unica (apenas 1 selecao)
        rules: [],
      },
    ],
  };

  const checkFleguedQuestions = () => {
    const status = dataListaQuestions.questions.every((v) =>
      queuesQuestion.includes(v)
    );
  };

  useEffect(() => {
    // TODO por enquanto a primeira questao, randomico ou por ordem definida
    setQuestionId(dataListaQuestions.questions[0].questionId);
  }, []);

  useEffect(() => {
    checkFleguedQuestions();
  }, [queuesQuestion]);

  /**
   *
   * @param key
   * @param selected
   * @returns
   */
  const nextQuestion = (key: number, selected: string[]) => {
    // Clean
    setQuestionSelected([]);

    const findQuestion = dataListaQuestions.questions[key];

    if (!selected.length) return;

    let findNextQuestionRule;

    // Apenas questoes com regras
    if (findQuestion.rules.length) {
      if (findQuestion.type === 'alternativa') {
        findNextQuestionRule = findQuestion.rules.find((rule) => {
          return rule.rulesGroup.sort().join('') === selected.sort().join('');
        });
      }
      if (findQuestion.type === 'unica') {
        findNextQuestionRule = findQuestion.rules.find((rule) => {
          return rule.rulesGroup.includes(selected[0]);
        });
      }
      if (findQuestion.type === 'dissertativa') {
        findNextQuestionRule = findQuestion.rules[0];
      }

      if (!findNextQuestionRule) return;

      // Set question
      setQueuesQuestion((oldArray) => [...oldArray, findQuestion.questionId]);
      setQuestionId(findNextQuestionRule.nextQuestionId);
    } else {
      // O restante de questoes nao respondidas
      setQueuesQuestion((oldArray) => [...oldArray, findQuestion.questionId]);
      const nextQuestion = dataListaQuestions.questions.filter(
        (question) =>
          !queuesQuestion.some(
            (b) =>
              question.questionId === findQuestion.questionId ||
              b === question.questionId
          )
      );
      if (nextQuestion[0]?.questionId) {
        setQuestionId(nextQuestion[0].questionId);
      }
    }
  };

  /**
   */
  const fallBackQuestions = () => {
    // Clean
    setQuestionSelected([]);

    if (!queuesQuestion.length) return;
    const item = queuesQuestion.pop();
    setQuestionId(item);
    setQueuesQuestion(queuesQuestion.filter((queue) => queue !== item));
  };

  return (
    <Flex gap="2rem" justifyContent="" flexDirection="column">
      <Heading as="h3" size="md">
        {dataListaQuestions.titleQuestions}
      </Heading>
      <Divider color={colors.gray_blue} />
      {dataListaQuestions.questions.map((questao: any, key: any) => {
        return (
          questao.questionId === questionId && (
            <section
              key={questao.questionId}
              ref={(el) => (tabsRef.current[questao.questionId] = el)}
            >
              <Flex gap="2rem" justifyContent="" flexDirection="column">
                {(questao.type === 'alternativa' && (
                  <FormMultipleOptions
                    selected={(e) => setQuestionSelected(e)}
                    options={questao.groupQuestions.map(({ title, value }) => ({
                      title,
                      value,
                    }))}
                    title={questao.titleQuestion}
                  />
                )) ||
                  (questao.type === 'unica' && (
                    <FormOneOption
                      selected={(e) => setQuestionSelected([e])}
                      options={questao.groupQuestions.map(
                        ({ title, value }) => ({
                          title,
                          value,
                        })
                      )}
                      title={questao.titleQuestion}
                    />
                  )) ||
                  (questao.type === 'dissertativa' && (
                    <FormTextArea
                      title={questao.titleQuestion}
                      onInput={(e) => setQuestionSelected([e])}
                    />
                  ))}
                <Divider color={colors.gray_blue} />
                <Flex gap="2rem" justifyContent="center" flexDirection="row">
                  <Button
                    variant="outline"
                    border="1px"
                    color={colors.gray}
                    borderColor={colors.gray}
                    _hover={{ bg: colors.gray, color: '#fff' }}
                    _active={{ bg: colors.gray_2 }}
                    type="submit"
                    onClick={fallBackQuestions}
                  >
                    Voltar
                  </Button>
                  <Button
                    variant="outline"
                    border="1px"
                    color={colors.blue}
                    borderColor={colors.blue}
                    _hover={{ bg: colors.blue, color: '#fff' }}
                    _active={{ bg: colors.textBlue }}
                    type="submit"
                    onClick={() => nextQuestion(key, questionSelected)}
                  >
                    Avançar
                  </Button>
                </Flex>
              </Flex>
            </section>
          )
        );
      })}
    </Flex>
  );
};

export { FormularioQuestionario };
