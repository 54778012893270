/* eslint-disable no-console */
import moment from 'moment';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { api } from 'services/api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { IGestaoDeAgendaProps } from '../_base/gestao-de-agenda';

const MySwal = withReactContent(Swal);
export interface IPeriodoSemAtividadeProps {
  className?: string;
  show: boolean;
  setShow: Function;
  initialData?: any;
  context: IGestaoDeAgendaProps;
  parsedUserData: any;
  afterChangeData: Function;
  sgInstSaude: string;
  sgCliente: string;
}

const PeriodoSemAtividade = (props: IPeriodoSemAtividadeProps) => {
  const { sgCliente, sgInstSaude } = props;
  const [periodoNew, setPeriodoNew] = useState([] as any);
  const [descricaoNew, setDescricaoNew] = useState('' as any);
  const [data, setData] = useState(
    props.context.state?.dadosPeriodoSemAtividade
  );
  const DataFiltros = (propsDataFiltros: any) => {
    const [startDate, setStartDate] = useState(propsDataFiltros.startDate);
    const [endDate, setEndDate] = useState(propsDataFiltros.endDate);
    const onChange = (dates: any) => {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
      if (start && end) {
        propsDataFiltros.onChange([start, end]);
      }
    };

    return (
      <div id={'gestao-agenda-data-filtro-container'}>
        <i className="fa fa-calendar" />
        <DatePicker
          id={'liberacao-agendamento-data-filtro'}
          placeholderText="Data Inicial - Data Final"
          onChange={onChange}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          minDate={new Date()}
          dateFormat="dd/MM/yyyy"
        />
      </div>
    );
  };

  const savePeriodoSemAtividade = async () => {
    try {
      const _data = [...data];

      const periodos = _data.map((dt) => {
        return {
          descricao: dt.descricao,
          inicio: moment(dt.periodo[0]).format('MM/DD/yyyy'),
          fim: moment(dt.periodo[1]).format('MM/DD/yyyy'),
        };
      });
      const body = {
        method: '_updateMany',
        sgCliente,
        sgInstSaude,
        psCPF: props.parsedUserData.cpf,
        periodos,
      };
      const result = await api.post(`aswperiodosematividade`, body);

      const json = await result.data;

      if (!!json.success) {
        setData(_data);
        setPeriodoNew([null, null]);
        setDescricaoNew('');
        props.setShow(!props.show, true);
        await props.afterChangeData();
        MySwal.fire({
          title: 'Período Adicionado',
          html: json.message || 'Adicionado com sucesso',
          icon: 'success',
          showConfirmButton: true,
          confirmButtonColor: '#008000',
        });
      } else {
        MySwal.fire({
          title: 'Erro ao salvar',
          html:
            json.message ||
            'Verifique a conexão, caso persista, contate o suporte.',
          icon: 'error',
          showConfirmButton: true,
          confirmButtonColor: '#e70015',
        });
      }
    } catch (err) {
      console.error(err.message);
      MySwal.fire({
        title: 'Erro ao salvar',
        html: err.message || '',
        icon: 'error',
        showConfirmButton: true,
        confirmButtonColor: '#e70015',
      });
    }
  };

  const deletePeriodoSemAtividade = async (value: any) => {
    try {
      const body = {
        method: '_delete',
        sgCliente,
        sgInstSaude,
        psCPF: props.parsedUserData.cpf,
        descricao: value.descricao || '',
        inicio: value.periodo[0],
        fim: value.periodo[1],
      };
      const result = await api.post(`aswperiodosematividade`, body);
      const json = await result.data;

      if (!!json.success) {
        const _data = [
          ...data.filter((v: any, i: any) => v.descricao !== value.descricao),
        ];
        setData(_data);
        MySwal.fire({
          title: 'Período Deletado',
          html: json.message || 'Deletado com sucesso',
          icon: 'success',
          showConfirmButton: true,
          confirmButtonColor: '#008000',
        });
      } else {
        MySwal.fire({
          title: 'Erro ao apagar',
          html: json.message || '',
          icon: 'error',
          showConfirmButton: true,
          confirmButtonColor: '#e70015',
        });
      }
    } catch (err) {
      console.error(err.message);
      MySwal.fire({
        title: 'Erro ao apagar',
        html: err.message || '',
        icon: 'error',
        showConfirmButton: true,
        confirmButtonColor: '#e70015',
      });
    }
    await props.afterChangeData();
  };

  return (
    <div id={'gestao-agenda-data-filtro-container'}>
      <Modal
        size={'lg'}
        isOpen={props.show}
        toggle={() => props.setShow(!props.show)}
        className={props.className}
      >
        <ModalHeader toggle={() => props.setShow(!props.show)}>
          Período Sem Atividade
        </ModalHeader>
        <ModalBody>
          <div>
            <table className="table-hover table-striped table-responsive-css table">
              <tr>
                <th colSpan={1}>Período</th>
                <th colSpan={3}>Descrição</th>
                <th></th>
              </tr>
              {data.map((v: any, i: any) => {
                return (
                  <tr key={i}>
                    <td colSpan={1}>
                      <DataFiltros
                        startDate={v.periodo[0]}
                        endDate={v.periodo[1]}
                        onChange={(x: any) => {
                          const _data = [...data];
                          _data[i] = { ...v, periodo: x };
                          setData(_data);
                        }}
                      />
                    </td>
                    <td colSpan={3}>
                      <input
                        type="text"
                        className="form-control"
                        value={v.descricao}
                        onChange={(evt) => {
                          const _data = [...data];
                          _data[i] = { ...v, descricao: evt.target.value };
                          setData(_data);
                        }}
                      />
                    </td>
                    <td>
                      <button
                        className="border-0"
                        onClick={() => {
                          deletePeriodoSemAtividade(v);
                        }}
                      >
                        <i className="fa fa-trash" />
                      </button>
                    </td>
                  </tr>
                );
              })}

              <tr>
                <td colSpan={1}>
                  <DataFiltros
                    startDate={periodoNew[0]}
                    endDate={periodoNew[1]}
                    onChange={setPeriodoNew}
                  />
                </td>
                <td colSpan={3}>
                  <input
                    type="text"
                    id="input-description"
                    className="form-control"
                    value={descricaoNew}
                    onChange={(x) => setDescricaoNew(x.target.value)}
                  />
                </td>
                <td>
                  <button
                    className="border-0"
                    onClick={() => {
                      const _data = [
                        ...data,
                        { periodo: periodoNew, descricao: descricaoNew },
                      ];
                      setData(_data);
                      setPeriodoNew([null, null]);
                      setDescricaoNew('');
                    }}
                  >
                    <i className="fa fa-plus" />
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              savePeriodoSemAtividade();
            }}
            disabled={data.length === 0}
          >
            Confirmar alterações
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default PeriodoSemAtividade;
