/* eslint-disable no-console */
import { Range } from 'rc-slider';
import React, { useState } from 'react';

export interface IGerenciamentoProfissionalSaudeProps {
  baseState?: any;
  baseProps?: any;
  setState?: any;
  entityBase?: any;
  getChangedValue: (rangeValue: Array<number>) => void;
  minimalTime: number;
  disabled: boolean;
  data?: any;
}

export const RangeDate = (props: IGerenciamentoProfissionalSaudeProps) => {
  const handleStyle = { border: '4px solid #4F95D9', backgroundColor: '#8FCBFE', width: '17px', height: '17px' };
  const railStyle = { height: '3px', backgroundColor: '#BDBDBD', marginTop: '2px' };
  const trackStyle = { height: '3px', backgroundColor: '#4F95D9', marginTop: '2px' };
  const [state, setState] = useState({ value: props?.data?.dayValue ? props?.data?.dayValue : [0, 0, 0, 0, 0, 0, 0, 0] });

  const handleChange = (value: any) => {
    setState({ ...state, value });
  };

  const timeConvert = mark => {
    const hours = mark / 60;
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);
    return `${rhours.toString().length === 1 ? `0${rhours}` : rhours}:${rminutes.toString().length === 1 ? `${rminutes}0` : rminutes}`;
  };

  const marks = (() => {
    const m: any = {};
    state.value.forEach((val: number) => {
      if (val > 0) {
        m[val] = timeConvert(val);
      }
    });
    return m;
  })();

  return (
    <div className="range-date" style={{ width: '100%', height: '60px' }}>
      <Range
        allowCross={true}
        marks={marks}
        disabled={props.disabled}
        min={0}
        step={props.minimalTime}
        max={1440}
        value={state.value}
        onAfterChange={props.getChangedValue}
        onChange={handleChange}
        trackStyle={[trackStyle, railStyle, trackStyle, railStyle, trackStyle, railStyle, trackStyle, railStyle]}
        railStyle={railStyle}
        handleStyle={[handleStyle, handleStyle, handleStyle, handleStyle, handleStyle, handleStyle, handleStyle, handleStyle]}
      />
    </div>
  );
};

export default RangeDate;
