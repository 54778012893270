import React, { useEffect, useState } from 'react';
import { Loading } from 'components/Loading';
import { Button, Col, Table } from 'reactstrap';
import { Container } from './styles';
import { useOnlyMounted, useUser } from 'hooks';
import { changeInputText } from 'shared/util';
import { api } from 'services/api';
import ModalFilaAtendimento from './ModalFilaAtendimento';
import { Box, Select } from '@chakra-ui/react';

export type FilaAtendimentoType = {
  sgInstSaude?: string;
  faCodigo?: number;
  faDescricao: string;
};

export default function FilasAtendimento() {
  const usuarioLogado = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [textoFiltro, setTextoFiltro] = useState('');
  const [instituicao, setInstituicao] = useState('');
  const [filasAtedimentoList, setFilasAtedimentoList] = useState<
    FilaAtendimentoType[]
  >([]);
  const [filasAtendimentoFilter, setFilasAtendimentoFilter] = useState<
    FilaAtendimentoType[]
  >([]);
  const [filaAtendimentoSelected, setFilaAtendimentoSelected] =
    useState<FilaAtendimentoType>(null);
  const [showModalForm, setShowModalForm] = useState<boolean>(false);
  const { onlyMounted } = useOnlyMounted();

  const getFilasAtendimento = () => {
    if (instituicao != '') {
      executaRequisicao(
        'aswfilasatendimento',
        {
          method: 'listarFilaAtendimento',
          sgCliente: usuarioLogado.sgCliente,
          sgInstSaude: instituicao
        },
        ({ data, success }) => {
          if (success && Array.isArray(data)) {
            setFilasAtedimentoList(data);
            setFilasAtendimentoFilter(data);
          }
        }
      );
    }
  };

  const executaRequisicao = (
    url = 'aswinstsaude',
    params: object,
    callback: Function
  ) => {
    setIsLoading(true);
    api
      .post(url, params)
      .then(({ data }) => {
        onlyMounted(() => {
          callback(data);
        });
      })
      .finally(() => {
        onlyMounted(() => {
          setIsLoading(false);
        });
      });
  };

  const toggle = () => {
    setShowModalForm(!showModalForm);
  };

  useEffect(() => {
    if (usuarioLogado?.sgCliente) {
      getFilasAtendimento();
    }
  }, [usuarioLogado, instituicao]);

  useEffect(() => {
    if (textoFiltro.trim()) {
      const filtered = filasAtedimentoList.filter((consulta) => {
        return Object.values(consulta).some(
          (key) => String(key).toLowerCase().includes(textoFiltro.toLowerCase())
          // ||
          // String(dateTimeToScreen(new Date(key))).includes(textoFiltro)
        );
      });
      setFilasAtendimentoFilter(filtered);
    } else {
      setFilasAtendimentoFilter(filasAtedimentoList);
    }
  }, [textoFiltro]);

  const handleModalClick = (filaAtendimento: FilaAtendimentoType | null) => {
    setFilaAtendimentoSelected(filaAtendimento);
    toggle();
  };
  return (
    <Container>
      <div className="d-flex justify-content-between">
        <div className="breadcrumb">
          <h6>FILAS DE ATENDIMENTO</h6>
        </div>
        <Button
          color="primary"
          className="btn btn-primary"
          onClick={() => handleModalClick(null)}
        >
          Cadastrar Nova Fila de Atendimento
        </Button>
      </div>
      <Box as="form" className="my-3">
        {!instituicao ? 'Selecione a instituição de saúde' : 'Instituição de saúde'}
        <Select
          size={'md'}
          className={"mt-1"}
          value={instituicao}
          onChange={(event) => {
            event.persist();
            const { value } = event.target;
            setInstituicao(value);
          }}
        >
          <option value="">Selecione a instituição...</option>
          {usuarioLogado?.institucoes &&
            usuarioLogado.institucoes.map((item, index) => {
              return (
                <option key={index} value={item.institucao}>
                  {item.institucaoNome}
                </option>
              );
            })}
        </Select>
      </Box>
      {filasAtedimentoList.length > 0 && (
        <Col md="12 mt-1">
          <span>Buscar filas de atendimento</span>
          <input
            placeholder="Digite aqui..."
            className="form-control"
            // value={textoFiltro}
            onChange={(evento) => setTextoFiltro(changeInputText(evento))}
          />
        </Col>
      )}

      <div>
        {filasAtendimentoFilter.length > 0 && (
          <div className="table-list mt-4">
            <Table
              responsive
              className={'table-hover table-striped table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th>Descrição</th>
                  <th className="text-center">Editar</th>
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {filasAtendimentoFilter.map(
                  (filaAtendimento: FilaAtendimentoType, i: number) => (
                    <tr key={`${filaAtendimento.faCodigo}`}>
                      <td>{filaAtendimento.faDescricao}</td>
                      <td className="text-center">
                        <div className="btn-group flex-btn-group-container">
                          <button
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Editar"
                            className={'btn btn-primary btn-lg'}
                            onClick={() => handleModalClick(filaAtendimento)}
                          >
                            <i className="fa fa-pencil-alt" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </div>
        )}

        {!isLoading && !filasAtendimentoFilter.length && (
          <div className="mt-4 alert alert-warning">Nada encontrado</div>
        )}
      </div>

      {isLoading && <Loading />}

      <ModalFilaAtendimento
        showModalForm={showModalForm}
        filaAtendimentoSelected={filaAtendimentoSelected}
        toggle={toggle}
        atualizarDados={getFilasAtendimento}
      />
    </Container>
  );
}
