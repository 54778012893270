
type JwtType = {
  email?: string;
  user?: string;
  exp: number;
  iat: number;
};
export const getPayloadJWT = <T = JwtType>(token: string): T | null => {
  try {
    const arr = token.split('.');
    if (arr.length > 0) {
      return JSON.parse(String(atob(arr[1])));
    }
    return null;
  } catch (e) {
    return null;
  }
};

export const isValidJWT = (exp: number) => {
  return exp * 1000 > Date.now();
};
