import { useConsultaContext } from 'contexts/Consulta';
import { useApiRequest, useUser } from 'hooks';
import React, { useEffect } from 'react';

type Props = {};

interface IQuestionarioSaudeMulher {
  prIdentificador: string;
  prNome: string;
  valorLongo: string;
  valor: string;
  valorFinal: string;
}

export const QuestionarioSaudeMulher = ({}: Props) => {
  const { consulta: dadosConsulta } = useConsultaContext();

  const questionarioApi = useApiRequest<IQuestionarioSaudeMulher[]>(
    'aswquestionario',
    [] as IQuestionarioSaudeMulher[]
  );

  useEffect(() => {
    if (dadosConsulta?.dadosConsulta.usuarioCPF) {
      questionarioApi.request({
        method: 'consultaQuestionarioSaudeMulher',
        usuarioCPF: dadosConsulta?.dadosConsulta.usuarioCPF,
        // usuarioCPF: '42263628886',
      });
    }
  }, [dadosConsulta]);

  return (
    <div>
      <p>
        <b>Questionário Saúde da Mulher</b>
      </p>
      {questionarioApi?.state?.length > 0 ? (
        <div className="w-100 mt-3 d-flex flex-column justify-content-center align-items-center">
          {questionarioApi?.state?.map((item) => (
            <div
              key={item.prIdentificador}
              className="w-100 border-top d-flex justify-content-between align-items-center py-2"
            >
              <p className="w-75">{item.prNome}</p>
              <p className="w-25 text-right">{item.valorFinal}</p>
            </div>
          ))}
        </div>
      ) : (
        <p className="mt-3">Questionário não respondido.</p>
      )}
    </div>
  );
};
