import { Link } from 'react-router-dom';

type Props = {
  cpf: string;
};

export const GestaoDeAgendaProfissionalLink = ({ cpf }: Props) => {
  return (
    <div className="btn-group flex-btn-group-container">
      <Link 
       title="Telessaúde"
       className="btn btn-primary btn-sm" 
       to={`/gerenciamento-profissional-saude/${cpf}/gestao-de-agenda-profissional?tipo=telessaude`}>
        <i className="fa fa-calendar" />
      </Link>
    </div>
  );
};
