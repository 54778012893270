import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { api } from 'services/api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as Yup from 'yup';
import { TelemedicinaType } from '../page';

const MySwal = withReactContent(Swal);

interface IPropsModalTelemedicina {
  showModalForm: boolean;
  toggle: Function;
  telemedicinaSelected?: TelemedicinaType | null;
  atualizarDados: Function;
}

export default function ModalTelemedicina({
  showModalForm,
  toggle,
  telemedicinaSelected,
  atualizarDados,
}: IPropsModalTelemedicina) {
  const isEditing = telemedicinaSelected ? true : false;

  const requiredFieldsMessage = 'Campo obrigatório';
  const schema = Yup.object().shape<
    Partial<Record<keyof TelemedicinaType, Yup.AnySchema>>
  >({
    telemedicina: Yup.string().required(requiredFieldsMessage),
    emailTokenTelemedicina: Yup.string().email('Insira um e-mail válido'),
    // tokenTelemedicina: Yup.string().required(requiredFieldsMessage),
    // methodTelemedicina: Yup.string(),
    // host: Yup.string(),
    // path: Yup.string(),
    // port: Yup.string(),
    // queryGroup: Yup.string(),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    // defaultValues: {
    //   telemedicina: isEditing ? telemedicinaSelected.telemedicina : '',
    //   emailTokenTelemedicina: isEditing ? telemedicinaSelected.telemedicina : '',
    //   tokenTelemedicina: '',
    // },
  });

  const handleOpenModal = () => {
    if (isEditing) {
      setValue('telemedicina', telemedicinaSelected.telemedicina);
      setValue(
        'emailTokenTelemedicina',
        telemedicinaSelected.emailTokenTelemedicina
      );
    }
  };

  const formValues = (data: TelemedicinaType) => {
    api
      .post('aswtelemedicina', {
        method: isEditing ? '_update' : '_insert',
        telemedicina: data.telemedicina,
        emailTokenTelemedicina: data.emailTokenTelemedicina,
        // tokenTelemedicina: data.tokenTelemedicina,
        // host: data.host,
        // path: data.path,
        // port: data.port,
        // methodTelemedicina: data.methodTelemedicina,
        // queryGroup: data.queryGroup,
      })
      .then(({ data }) => {
        if (data.success) {
          MySwal.fire({
            title: isEditing
              ? 'Telemedicina alterada'
              : 'Telemedicina cadastrada',
            html: 'Salvo com sucesso',
            icon: 'success',
            showConfirmButton: true,
            confirmButtonColor: '#008000',
          });
          atualizarDados();
          toggle();
        } else {
          MySwal.fire({
            title: isEditing ? 'Erro ao alterar' : 'Erro ao cadastrar',
            html: isEditing
              ? 'Não foi possível alterar a Telemedicina'
              : 'Não foi possível cadastrar a Telemedicina',
            icon: 'error',
            showConfirmButton: true,
            confirmButtonColor: '#008000',
          });
        }
      });
  };

  return (
    <Modal
      className="cadastro-telemedicina-new-modal"
      size={'xl'}
      isOpen={showModalForm}
      onClosed={reset}
      onOpened={handleOpenModal}
      toggle={() => {
        toggle();
      }}
    >
      <ModalHeader
        toggle={() => {
          toggle();
        }}
      >
        {!isEditing ? 'CADASTRAR NOVA TELEMEDICINA' : 'EDITAR TELEMEDICINA'}
      </ModalHeader>
      <form onSubmit={handleSubmit(formValues)}>
        <ModalBody>
          <Row className="justify-content-center">
            <Col md="11">
              <Row className="row-cadastro-telemedicina-first-column">
                <Col md="4">
                  <div>
                    <Row>
                      <Col md="12">
                        <Label
                          className="mt-4 label-single-line"
                          id="telemedicinaLabel"
                          for="cadastro-telemedicina-telemedicina"
                        >
                          Nome da Telemedicina *
                        </Label>
                      </Col>
                      <Col md="12">
                        <input
                          {...register('telemedicina')}
                          id="cadastro-telemedicina-telemedicina"
                          type="text"
                          name="telemedicina"
                          className={'form-control'}
                          readOnly={isEditing}
                          // defaultValue={
                          //   isEditing ? telemedicinaSelected.telemedicina : ''
                          // }
                        />
                        {errors?.telemedicina?.message && (
                          <div className="text-warning pt-2">
                            {errors?.telemedicina?.message}
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                </Col>

                {/* <Col md="4">
                  <div>
                    <Row> */}
                {/* <Col md="12">
                        <Label
                          className="mt-4 label-single-line"
                          id="tokenTelemedicinaLabel"
                          for="cadastro-telemedicina-tokenTelemedicina"
                        >
                          Token Telemedicina
                        </Label>
                      </Col>
                      <Col md="12">
                        <input
                          {...register('tokenTelemedicina')}
                          id="cadastro-telemedicina-tokenTelemedicina"
                          type="text"
                          name="tokenTelemedicina"
                          className={'form-control'}
                          value={
                            isEditing
                              ? telemedicinaSelected.emailTokenTelemedicina
                              : ''
                          }
                        />
                        {errors?.tokenTelemedicina?.message && (
                          <div className="text-warning pt-2">
                            {errors?.tokenTelemedicina?.message}
                          </div>
                        )}
                      </Col> */}
                {/* </Row>
                  </div>
                </Col> */}

                <Col md="4">
                  <div>
                    <Row>
                      <Col md="12">
                        <Label
                          className="mt-4 label-single-line"
                          id="emailTokenTelemedicinaLabel"
                          for="cadastro-telemedicina-emailTokenTelemedicina"
                        >
                          E-mail Token Telemedicina
                        </Label>
                      </Col>
                      <Col md="12">
                        <input
                          readOnly={false}
                          {...register('emailTokenTelemedicina')}
                          id="cadastro-telemedicina-emailTokenTelemedicina"
                          type="text"
                          name="emailTokenTelemedicina"
                          className={'form-control'}
                          // defaultValue={
                          //   isEditing
                          //     ? telemedicinaSelected.emailTokenTelemedicina
                          //     : ''
                          // }
                        />
                        {errors?.emailTokenTelemedicina?.message && (
                          <div className="text-warning pt-2">
                            {errors?.emailTokenTelemedicina?.message}
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                </Col>

                {/*
                <Col md="4">
                  <div>
                   <Row>
                       <Col md="12">
                        <Label
                          className="mt-4 label-single-line"
                          id="hostLabel"
                          for="cadastro-telemedicina-host"
                        >
                          Host
                        </Label>
                      </Col>
                      { <Col md="12">
                        <input
                          {...register('host')}
                          id="cadastro-telemedicina-host"
                          type="text"
                          name="host"
                          className={'form-control'}
                        />
                        {errors?.host?.message && (
                          <div className="text-warning pt-2">
                            {errors?.host?.message}
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                </Col>
                */}
                {/*
                <Col md="4">
                  <div>
                    <Row>
                      <Col md="12">
                        <Label
                          className="mt-4 label-single-line"
                          id="pathLabel"
                          for="cadastro-telemedicina-path"
                        >
                          Path
                        </Label>
                      </Col>
                      <Col md="12">
                        <input
                          {...register('path')}
                          id="cadastro-telemedicina-path"
                          type="text"
                          name="path"
                          className={'form-control'}
                        />
                        {errors?.path?.message && (
                          <div className="text-warning pt-2">
                            {errors?.path?.message}
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                </Col>
                */}

                {/* <Col md="2">
                  <div>
                    <Row>
                      <Col md="12">
                        <Label
                          className="mt-4 label-single-line"
                          id="portLabel"
                          for="cadastro-telemedicina-port"
                        >
                          Port
                        </Label>
                      </Col>
                      <Col md="12">
                        <input
                          {...register('port')}
                          id="cadastro-telemedicina-port"
                          type="text"
                          name="port"
                          className={'form-control'}
                        />
                        {errors?.port?.message && (
                          <div className="text-warning pt-2">
                            {errors?.port?.message}
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                </Col>
                */}

                {/*
                <Col md="2">
                  <div>
                    <Row>
                      <Col md="12">
                        <Label
                          className="mt-4 label-single-line"
                          id="methodLabel"
                          for="cadastro-telemedicina-method"
                        >
                          Method
                        </Label>
                      </Col>
                       <Col md="12">
                        <input
                          {...register('methodTelemedicina')}
                          id="cadastro-telemedicina-method"
                          type="text"
                          name="methodTelemedicina"
                          className={'form-control'}
                        />
                        {errors?.methodTelemedicina?.message && (
                          <div className="text-warning pt-2">
                            {errors?.methodTelemedicina?.message}
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                </Col>
                */}

                {/*<Col md="4">
                  <div>
                    <Row>
                       <Col md="12">
                        <Label
                          className="mt-4 label-single-line"
                          id="queryGroupLabel"
                          for="cadastro-telemedicina-queryGroup"
                        >
                          QueryGroup
                        </Label>
                      </Col>
                      <Col md="12">
                        <input
                          {...register('queryGroup')}
                          id="cadastro-telemedicina-queryGroup"
                          type="text"
                          name="queryGroup"
                          className={'form-control'}
                        />
                        {errors?.queryGroup?.message && (
                          <div className="text-warning pt-2">
                            {errors?.queryGroup?.message}
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                </Col>
                */}
              </Row>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            id="save-entity"
            className="float-right jh-create-entity"
            type="submit"
          >
            {!isEditing ? 'Cadastrar Telemedicina' : 'Confirmar Alterações'}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
