import styled from 'styled-components';

export const Container = styled.div``;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.p`
  color: #898989;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  margin-top: 15px;
`;

export const TitleTriagemMedica = styled(Title)`
  margin-top: 30px;
`;

export const TableSMS = styled.table`
  margin-top: 15px;
`;

export const THeadSMS = styled.thead`
  /* border-bottom: 1px solid #e5e5e5; */
`;

export const ThSMS = styled.th`
  padding: 10px;
  text-transform: capitalize;
`;

export const TrSMS = styled.tr`
  border-bottom: 1px solid #e5e5e5;
`;

export const TdSMS = styled.td`
  padding: 15px;
  /* background-color: aliceblue; */
`;

export const EmptyMsg = styled.p`
  margin-top: 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #b1b1b1;
`;

export const SuccessSpan = styled.span`
  background-color: #b7ffd4;
  padding: 3px 10px;
  border-radius: 20px;
`;

export const NotSuccessSpan = styled.span`
  background-color: #ffe5e3;
  padding: 3px 10px;
  border-radius: 20px;
`;
