import { Loading } from 'components/Loading';
import { apiFetch, getListAxios } from 'components/util/entity-utils';
import { ConsultaProvider, useConsultaContext } from 'contexts/Consulta';
import { useUser } from 'hooks';
import { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-quill/dist/quill.snow.css';
import { useHistory } from 'react-router-dom';
import SelectAsync from 'react-select/async';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { TipoAtendimentoType } from 'types/consulta';
import { AppointmentTabs } from './components/appointment-tabs';
import { Header } from './components/header';
import { Nonagon } from './components/nonagon';
import { ConsultaContainer } from './styles';

const MySwal = withReactContent(Swal);

type Props = {
  tipoAtendimento: TipoAtendimentoType;
};

const ConsultaComponent = ({ tipoAtendimento }: Props) => {
  const {
    consulta,
    isFetchingConsulta: isFetchingDadosConsulta,
    idConsulta,
    headerHeight,
  } = useConsultaContext();

  const user = useUser();

  const history = useHistory();
  const paramProfissionalCPF = consulta?.dadosConsulta?.psCPF || '';

  const [showModalCancelamento, setShowModalCancelamento] = useState(false);
  const [motivoCancelamento, setMotivoCancelamento] = useState(
    undefined as any
  );
  const [motivoCancelamentoStartFilter, setMotivoCancelamentoStartFilter] =
    useState(undefined as any);
  const [paramUsuarioCPF, setParamUsuarioCPF] = useState(undefined as any);
  const [paramInstSaude, setParamInstSaude] = useState(undefined as any);
  const [paramDataHoraAgenda, setParamDataHoraAgenda] = useState(
    undefined as any
  );

  const [startTime, setStartTime] = useState(new Date());

  const cancelarConsulta = () => {
    const body = {
      sgInstSaude: paramInstSaude,
      psCPF: paramProfissionalCPF,
      usuarioCPF: paramUsuarioCPF,
      agDataHoraAgenda: paramDataHoraAgenda,
      mcDescricao: motivoCancelamento.mcDescricao,
      mcPSPaciente: 'M',
      cancelamento: 1,
      idConsulta: idConsulta,
    };

    const onSuccess = (resp) => {
      if (+resp.status === 201) {
        setShowModalCancelamento(false);
        setMotivoCancelamento(undefined);
        MySwal.fire({ html: 'Atendimento cancelado' }).then(() => {
          history.push('/atendimento');
        });
      }
    };

    apiFetch('aswagendausuprofsaude', { method: '_cancel', body, onSuccess });
  };

  if (!consulta?.dadosConsulta?.usuarioCPF) {
    return <Loading />;
  }

  return (
    <>
      <ConsultaContainer>
        <Header tipoAtendimento={tipoAtendimento} />

        {isFetchingDadosConsulta && <Loading />}

        <Row style={{ marginTop: `${headerHeight}px` }}>
          <div>
            {!!consulta?.dadosConsulta?.nonagonPacienteIframe && (
              <div className="mb-3">
                <Nonagon
                  sgCliente={user?.sgCliente}
                  usuarioCPF={consulta?.dadosConsulta?.usuarioCPF}
                  link={consulta?.dadosConsulta?.nonagonPacienteIframe}
                />
              </div>
            )}

            <div className="table-responsive">
              <div id="atendimento-form-view" className="form-view">
                <div className="body-update">
                  <AppointmentTabs />
                </div>
              </div>
            </div>
          </div>
        </Row>
      </ConsultaContainer>

      <Modal
        size={'md'}
        isOpen={showModalCancelamento}
        className="cadastro-telemedicina-new-modal"
        toggle={() => setShowModalCancelamento(false)}
      >
        <ModalHeader toggle={() => setShowModalCancelamento(false)}>
          {'Por favor descreva o motivo do cancelamento'}
        </ModalHeader>
        <div>
          <ModalBody>
            <Row style={{ margin: 0 }}>
              <Col md={12}>
                <SelectAsync
                  instanceId="atendimento-cid10-filter"
                  className={'css-select-control'}
                  placeholder=""
                  cacheOptions
                  value={motivoCancelamento}
                  onChange={(options) => setMotivoCancelamento(options)}
                  defaultOptions={
                    motivoCancelamentoStartFilter
                      ? motivoCancelamentoStartFilter
                      : []
                  }
                  onMenuOpen={async () => {
                    // if (cid10SelectedStartFilter === undefined) {
                    const result = await getListAxios(
                      'aswmotivocancelamento',
                      { mcPSPaciente: 'M' },
                      0,
                      100,
                      'mcDescricao,asc',
                      'mcDescricao'
                    );
                    setMotivoCancelamentoStartFilter(
                      result
                        ? result.map((p: any) => ({
                            ...p,
                            value: p.id,
                            label: `${p.mcDescricao}`,
                          }))
                        : []
                    );
                    // }
                  }}
                  loadOptions={(inputValue, callback) => {
                    (async () => {
                      const result = await getListAxios(
                        'aswmotivocancelamento',
                        { mcDescricao: inputValue, mcPSPaciente: 'M' },
                        0,
                        100,
                        'mcDescricao,asc',
                        'mcDescricao'
                      );
                      callback(
                        result
                          ? result.map((p: any) => ({
                              ...p,
                              value: p.id,
                              label: `${p.mcDescricao}`,
                            }))
                          : []
                      );
                    })();
                  }}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              id="save-entity"
              onClick={(evt) => {
                cancelarConsulta();
              }}
            >
              Ok
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </>
  );
};

export const Consulta = (props) => (
  <ConsultaProvider {...props}>
    <ConsultaComponent {...props} />
  </ConsultaProvider>
);
