import styled from 'styled-components';

export const Container = styled.div`
  .table-list {
    max-height: 400px;
    overflow-y: scroll;
  }

  td {
    vertical-align: top;
  }
`;

export const Horas = styled.div`
  background-color: rgba(11, 100, 194, 0.2);
  padding: 2px 5px;
  border-radius: 10px;
  margin: 5px 5px;
`;

export const CustomTd = styled.td`
  .b {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const CustomTdHorarios = styled.td`
  padding: 0 1rem !important;

  .dashed {
    background-image: linear-gradient(to right, #d4d4d4 33%, transparent 0%);
    background-position: top;
    background-size: 15px 1px;
    background-repeat: repeat-x;
  }
`;
