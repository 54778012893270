import * as Styles from './style';
import { useCtrl } from './ctrl';

type Props = {
  apiKey: string;
  sessionId: string;
  token: string;
};

export function VideoComponent(props: Props) {
  const ctrl = useCtrl(props.apiKey, props.sessionId, props.token);

  return (
    <Styles.Container>
      <Styles.Content>
        <div id="main">
          <div id="videos">
            <div id="aguardo">
              <p style={{ marginTop: '150px' }}>Aguardando participante...</p>
            </div>
            <div id="desconectado" className="oculto">
              <p style={{ marginTop: '150px' }}>Consulta encerrada!</p>
            </div>
            <div id="subscriber"></div>
            <div id="publisher"></div>
          </div>

          <div id="controls" className="Embed-control-container">
            <div
              id="end"
              className="ots-video-control circle leave-meeting-red"
              data-wd="endCall"
              data-tooltip="Leave Meeting"
              onClick={ctrl.endCall}
            ></div>
            <div
              id="toggleLocalAudio"
              className="ots-video-control circle audio"
              data-wd="toggleLocalAudio"
              data-tooltip="Toggle Microphone"
              onClick={ctrl.toggleLocalAudio}
            ></div>

            <div
              id="toggleLocalVideo"
              className="ots-video-control circle video"
              data-wd="toggleLocalVideo"
              data-tooltip="Toggle Camera"
              onClick={ctrl.toggleLocalVideo}
            ></div>

            <div
              id="switchVideoCamera"
              className="ots-video-control circle switch"
              data-wd="switchVideoCamera"
              data-tooltip="Switch Video Camera"
              onClick={ctrl.switchVideoCamera}
            ></div>

            <div
              id="chat"
              className="ots-video-control circle chat"
              data-wd="togglechat"
              data-tooltip="Toggle Chat"
              onClick={ctrl.togglechat}
            ></div>
            <div
              id="buttonholder"
              style={{
                display: 'inline-flex',
                marginLeft: 10,
              }}
            ></div>
          </div>
        </div>

        <aside id="chatWindow">
          <div className="chat-header">
            <p
              style={{
                padding: '0 16px',
                fontWeight: 'bold',
              }}
            >
              Chat
            </p>
            <p></p>
            <div id="closechat" onClick={ctrl.closechat}>
              <p
                style={{
                  padding: '0 16px',
                  cursor: 'pointer',
                  fontWeight: 'bold',
                }}
              >
                X
              </p>
            </div>
          </div>

          <div id="textchat">
            <form
              className="chat-form"
              autoComplete="off"
              onSubmit={ctrl.submitMessage}
            >
              <input
                type="text"
                placeholder="Digite aqui sua mensagem"
                value={ctrl.msgTxt}
                onChange={(e) => ctrl.setmsgTxt(e.target.value)}
              />
            </form>

            <div id="history">
              {ctrl.messages.map((m, index) => (
                <p key={index} className={m.from}>
                  {m.message}
                </p>
              ))}
            </div>
          </div>
        </aside>
      </Styles.Content>
    </Styles.Container>
  );
}
