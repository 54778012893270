import React from 'react';
import { DropdownItem } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface IMenuItem {
  icon?: IconProp;
  to: string;
  id?: string;
  'data-cy'?: string;
}

export const MenuItem: React.FC<IMenuItem> = (props) => {
  const { to, icon, id, children, 'data-cy': string } = props;

  return (
    <DropdownItem tag={Link} to={to} id={id} data-cy={props['data-cy']}>
      {icon && <FontAwesomeIcon icon={icon} fixedWidth />} {children}
    </DropdownItem>
  );
};
