import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Card, Container } from 'reactstrap';

import { Footer } from 'components/footer';
import { Header } from 'components/header';
import { PageNotFound } from 'components/page-not-found';
import { Login } from 'pages/login';
import { AUTH_TOKEN_KEY, AUTH_USER_DATA, getPayloadJWT, isValidJWT, LOG } from 'shared/util';

import Entities from './pages';
import ErrorBoundaryRoute from './shared/error/error-boundary-route';

export interface IAppProps {
  isAuthenticated: any;
  isAdmin: any;
  currentLocale: any;
  setLocale: any;
  ribbonEnv: any;
  isInProduction: any;
  isOpenAPIEnabled: any;
}

const Routes = (props: IAppProps) => {
  const paddingTop = '60px';

  const { replace, location } = useHistory();

  useEffect(() => {
    try {
      const token = Cookies.get(AUTH_TOKEN_KEY);

      const clearAll = () => {
        Cookies.remove(AUTH_TOKEN_KEY);
        localStorage.clear();
      };

      if (!token || !localStorage.getItem(AUTH_USER_DATA)) {
        clearAll();
        replace('/login');
        return;
      }

      if (token) {
        const payload = getPayloadJWT(token);

        if (payload) {
          if (!isValidJWT(payload.exp)) {
            clearAll();
            replace('/login');
          }
        }
      }
    } catch (err) {
      LOG('Catch no useEffect src/routes.tsx', { err });
    }
  }, [, location.pathname]);

  return (
    <div className="app-container" style={{ paddingTop }}>
      <Header
        isAuthenticated={props.isAuthenticated}
        isAdmin={props.isAdmin}
        isLogin={props['location']['pathname'] === '/login'}
        currentLocale={props.currentLocale}
        onLocaleChange={props.setLocale}
        ribbonEnv={props.ribbonEnv}
        isInProduction={props.isInProduction}
        isOpenAPIEnabled={props.isOpenAPIEnabled}
      />
      <div className="container-fluid view-container" id="app-view-container">
        <Card className="jh-card">
          <Container className="view-routes">
            <Switch>
              <ErrorBoundaryRoute path="/login" component={Login} />
              <ErrorBoundaryRoute path="/" component={Entities} />
              <ErrorBoundaryRoute component={PageNotFound} />
            </Switch>
          </Container>
        </Card>
      </div>
      <Footer />
    </div>
  );
};
export default Routes;
