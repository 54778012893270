import { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import GestaoDeAgendaProfissional from 'pages/gestao-de-agenda-profissional';
import ErrorBoundaryRoute from 'shared/error/error-boundary-route';
import { AUTH_USER_DATA, LOG } from 'shared/util';

import AgendamentoConsultas from './agendamento-consultas';
import AgendarPaciente from './agendar-paciente';
import Atendimento from './atendimento';
import AtendimentoRecepcao from './atendimento-recepcao';
import AtendimentoTriagem from './atendimento-triagem';
import { AtendimentoPresencial } from './atendimento-triagem/components/consulta/index';
import CadastroAdministradores from './cadastro-administradores';
import CadastroCliente from './cadastro-cliente';
import CadastroInstituicao from './cadastro-instituicao';
import CadastroPaciente from './cadastro-paciente';
import CadastroTelemedicina from './cadastro-telemedicina';
import CmoTriagemMedica from './cmo-triagem-medica';
import DashboardGeral from './dashboard-geral';
import { DashboardSindromeGripal } from './dashboard-sindrome-gripal';
import { DashboardTelemedicina } from './dashboard-telemedicina';
import DetalhesLiberacaoAgendamento from './detalhes-liberacao-agendamento';
import DisponibilidadeAgenda from './disponibilidade-agenda';
import ElegibilidadePaciente from './elegibilidade-paciente';
import FaleConosco from './fale-conosco';
import FilasAtendimento from './filas-atendimento';
import { FormularioQuestionario } from './questionario/formulario-questionario';
import { GerenciamentoProfissionalSaude } from './gerenciamento-profissional-saude';
import { GestaoCampanhas } from './questionario/gestao-campanhas';
import GestaoDeAgenda from './gestao-de-agenda';
import { GestaoPerguntas } from './questionario/gestao-perguntas';
import { GestaoQuestionarios } from './questionario/gestao-questionarios';
import GrupoMenu from './grupo-menu';
import HistoricoDeAtendimentos from './historico-de-atendimentos';
import { Home } from './home';
import { LiberacaoAgendamento } from './liberacao-agendamento';
import LocalAtendimento from './local-atendimento';
import MenuRotas from './menu-rotas';
import PerfilMenu from './perfil-menu';
import PerfilMenuRotas from './perfil-menu-rotas';
import PeriodoSemAtividade from './periodo-sem-atividade';
import { PrevServEncaminhamentoUsuario } from './pre-serv-encaminhamento-paciente';
import ProfissionalSaude from './profissional-saude';
import { SmsCadastroEpisodio } from './sms-cadastro-episodio';
import { SmsCadastroPaciente } from './sms-cadastro-paciente';
import { SmsConsultaPaciente } from './sms-consulta-paciente';
import { SmsConsultaPacienteDetalhes } from './sms-consulta-paciente-detalhes';
import { SmsConsultaPacienteDetalhesPerfilCompleto } from './sms-consulta-paciente-detalhes-perfil-completo';
import SmsPAAutorizacaoPaciente from './sms-pa-autorizacao-paciente';
import SmsPAAutorizacaoPacientePerfilCompleto from './sms-pa-autorizacao-paciente-perfil-completo';
import { atendimentoCompartilhado } from './atendimento-compartilhado';
import { consultaEmergencial } from './atendimento-compartilhado/componentes/consulta';
import { MedicalCenterAtendimento } from './atendimento-medical-center';
import { GestaoAlternativas } from './questionario/gestao-alternativa';
import { especialidadesProfissional } from './especialidades-profissional';
import ConsultaAgendamentos from './consulta-agendamentos';
import DashRoche from './dash-roche';
import DashRocheqt from './dash-rocheqt';
import DashSMS from './dash-sms';

const Routes = ({ match, history }) => {
  useEffect(() => {
    if (!localStorage.getItem(AUTH_USER_DATA)) {
      history.replace('/login');
    }
  }, []);

  return (
    <div>
      <Switch>
        <ErrorBoundaryRoute
          path={`${match.url}gestao-campanhas`}
          component={GestaoCampanhas}
        />
        <ErrorBoundaryRoute
          path={`${match.url}gestao-questionarios`}
          component={GestaoQuestionarios}
        />
        <ErrorBoundaryRoute
          path={`${match.url}gestao-perguntas/:qsId`}
          component={GestaoPerguntas}
        />
        <ErrorBoundaryRoute
          path={`${match.url}gestao-alternativas/:qsId/:qpId`}
          component={GestaoAlternativas}
        />
        <ErrorBoundaryRoute
          path={`${match.url}formulario-questionario`}
          component={FormularioQuestionario}
        />
        <ErrorBoundaryRoute
          path={`${match.url}periodo-sem-atividade`}
          component={PeriodoSemAtividade}
        />
        <Route
          path={`${match.url}gestao-de-agenda-telessaude`}
          render={(props) => (
            <GestaoDeAgenda {...props} teleFisico="T" key="T" />
          )}
        />
        <Route
          path={`${match.url}gestao-de-agenda-presencial`}
          render={(props) => (
            <GestaoDeAgenda {...props} teleFisico="F" key="F" />
          )}
        />
        <ErrorBoundaryRoute
          path={`${match.url}dashboard`}
          component={DashboardGeral}
        />
        <ErrorBoundaryRoute
          path={`${match.url}dashboard-telemedicina`}
          component={DashboardTelemedicina}
        />
        <ErrorBoundaryRoute
          path={`${match.url}dashboard-sindrome-gripal`}
          component={DashboardSindromeGripal}
        />
        <Route
          path={`${match.url}atendimento-telessaude`}
          render={({ ...rest }) => (
            <Atendimento
              match={match}
              tipoAtendimento="telessaude"
              {...rest}
              key="telessaude"
            />
          )}
        />
        <Route
          path={`${match.url}atendimento-presencial`}
          render={({ ...rest }) => (
            <Atendimento
              match={match}
              tipoAtendimento="presencial"
              {...rest}
              key="presencial"
            />
          )}
        />
        <ErrorBoundaryRoute
          path={`${match.url}historico-atendimento`}
          component={HistoricoDeAtendimentos}
        />
        <ErrorBoundaryRoute
          path={`${match.url}cadastro-telemedicina`}
          component={CadastroTelemedicina}
        />
        <ErrorBoundaryRoute
          path={`${match.url}cadastro-cliente`}
          component={CadastroCliente}
        />
        <ErrorBoundaryRoute
          path={`${match.url}cadastro-instituicao`}
          component={CadastroInstituicao}
        />
        <ErrorBoundaryRoute
          path={`${match.url}cadastro-administradores`}
          component={CadastroAdministradores}
        />
        <ErrorBoundaryRoute
          path={`${match.url}detalhes-liberacao-agendamento`}
          component={DetalhesLiberacaoAgendamento}
        />
        <ErrorBoundaryRoute
          path={`${match.url}grupo-menu`}
          component={GrupoMenu}
        />
        <ErrorBoundaryRoute
          path={`${match.url}perfil-menu`}
          component={PerfilMenu}
        />
        <ErrorBoundaryRoute
          path={`${match.url}menu-rotas`}
          component={MenuRotas}
        />

        <ErrorBoundaryRoute
          path={`${match.url}perfil-menu-rotas`}
          component={PerfilMenuRotas}
        />
        <ErrorBoundaryRoute
          path={`${match.url}agendamento-consultas`}
          component={AgendamentoConsultas}
        />
        <ErrorBoundaryRoute
          path={`${match.url}liberacao-agendamento`}
          component={LiberacaoAgendamento}
        />
        <ErrorBoundaryRoute
          path={`${match.url}profissional-saude`}
          component={ProfissionalSaude}
        />
        <ErrorBoundaryRoute
          path={`${match.url}cadastro-paciente`}
          component={CadastroPaciente}
        />
        <ErrorBoundaryRoute
          path={`${match.url}agendar-paciente`}
          component={AgendarPaciente}
        />
        <ErrorBoundaryRoute
          path={`${match.url}elegibilidade-paciente`}
          component={ElegibilidadePaciente}
        />
        <Route
          path={`${match.url}gerenciamento-profissional-saude/:cpf/gestao-de-agenda-profissional`}
          component={GestaoDeAgendaProfissional}
          exact
        />
        <Route
          path={`${match.url}gerenciamento-profissional-saude`}
          component={GerenciamentoProfissionalSaude}
        />
        <Route
          path={`${match.url}local-atendimento`}
          component={LocalAtendimento}
        />
        <Route
          path={`${match.url}filas-atendimento`}
          component={FilasAtendimento}
        />
        <Route
          path={`${match.url}atendimento-triagem/:idConsulta`}
          component={AtendimentoPresencial}
        />
        <ErrorBoundaryRoute
          path={`${match.url}atendimento-triagem`}
          component={AtendimentoTriagem}
        />
        <ErrorBoundaryRoute
          path={`${match.url}atendimento-recepcao`}
          component={AtendimentoRecepcao}
        />
        <ErrorBoundaryRoute
          path={`${match.url}disponibilidade-agenda`}
          component={DisponibilidadeAgenda}
        />
        <ErrorBoundaryRoute
          path={`${match.url}fale-conosco`}
          component={FaleConosco}
        />
        <ErrorBoundaryRoute
          path={`${match.url}sms-cadastro-paciente`}
          component={SmsCadastroPaciente}
        />
        <ErrorBoundaryRoute
          path={`${match.url}sms-cadastro-episodio`}
          component={SmsCadastroEpisodio}
        />
        <ErrorBoundaryRoute
          path={`${match.url}sms-consulta-paciente/:cpf/perfil-completo`}
          component={SmsConsultaPacienteDetalhesPerfilCompleto}
        />
        <ErrorBoundaryRoute
          path={`${match.url}sms-consulta-paciente/:cpf/:identificadorSMS`}
          component={SmsConsultaPacienteDetalhes}
        />
        <ErrorBoundaryRoute
          path={`${match.url}sms-consulta-paciente`}
          component={SmsConsultaPaciente}
        />
        <ErrorBoundaryRoute
          path={`${match.url}sms-pa-autorizacao-paciente/:cpf/perfil-completo`}
          component={SmsPAAutorizacaoPacientePerfilCompleto}
        />
        <ErrorBoundaryRoute
          path={`${match.url}sms-pa-autorizacao-paciente`}
          component={SmsPAAutorizacaoPaciente}
        />
        <ErrorBoundaryRoute
          path={`${match.url}cmo-triagem-medica/:cpf/perfil-completo`}
          component={SmsConsultaPacienteDetalhesPerfilCompleto}
        />
        <ErrorBoundaryRoute
          path={`${match.url}cmo-triagem-medica`}
          component={CmoTriagemMedica}
        />
        <ErrorBoundaryRoute
          path={`${match.url}pre-serv-encaminhamento-paciente`}
          component={PrevServEncaminhamentoUsuario}
        />
        <ErrorBoundaryRoute
          path={`${match.url}especialidade-profissional`}
          component={especialidadesProfissional}
        />
        <ErrorBoundaryRoute
          path={`${match.url}atendimento-compartilhado`}
          component={atendimentoCompartilhado}
        />
        <ErrorBoundaryRoute
          path={`${match.url}medical-center-atendimentos/:idConsulta/:psCpf/:participanteCpf`}
          component={consultaEmergencial}
        />
        <ErrorBoundaryRoute
          path={`${match.url}medical-center-atendimentos`}
          component={MedicalCenterAtendimento}
        />
        <ErrorBoundaryRoute
          path={`${match.url}consulta-agendamento`}
          component={ConsultaAgendamentos}
        />
        <ErrorBoundaryRoute
          path={`${match.url}dash-roche`}
          component={DashRoche}
        />
        <ErrorBoundaryRoute path={`${match.url}dash-sms`} component={DashSMS} />

        <ErrorBoundaryRoute
          path={`${match.url}dash-rocheqt`}
          component={DashRocheqt}
        />
        <ErrorBoundaryRoute path={`/`} component={Home} />
      </Switch>
    </div>
  );
};

export default Routes;
