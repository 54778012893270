import React from 'react';
import { Link } from '../../../components/util/translate-component';

export interface IProfissionalSaudeProps {
  baseState?: any;
  baseProps?: any;
  setState?: any;
  entityBase?: any;
}

const ProfissionalSaudeComponentCustomize = (props: IProfissionalSaudeProps) => {
  return (
    <div className="btn-group flex-btn-group-container">
      <Link href={`/profissional-saude/${props.entityBase.id}/gestao-de-agenda`}>
        <a className="btn btn-primary btn-sm">
          <i className="fa fa-book-medical fa-1x" />
        </a>
      </Link>
    </div>
  );
};

export default ProfissionalSaudeComponentCustomize;
