import styled from 'styled-components';
import { Button } from 'reactstrap';

export const Container = styled.div`
  .table-list {
    max-height: 400px;
    overflow-y: scroll;
  }
`;

export const SearchButton = styled(Button)`
  @media (min-width: 768px) {
    position: relative;
    top: 23px;
  }
`;
