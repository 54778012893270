import { Box, Heading } from '@chakra-ui/react';
import { Loading } from 'components/Loading';
import { useApiRequest, useUser } from 'hooks';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Table } from 'reactstrap';
import { api } from 'services/api';
import { calcularIdade } from 'shared/util/calcularIdade';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { AtendimentoEmergenciatype } from './types';

export const MedicalCenterAtendimento = () => {
  const user = useUser();
  const history = useHistory();
  const listarConsultasInterval = useRef(null);
  const MySwal = withReactContent(Swal);
  const [filasProf, setFilasProf] = useState<[]>();
  const [instList, setInstList] = useState<[]>();
  const [especialidade, setEspecialidade] = useState<string>('');
  const agendamentosHook = useApiRequest<AtendimentoEmergenciatype[]>(
    'aswagendausuprofsaude',
    []
  );
  const pegarAtendimentoHook = useApiRequest('aswagendausuprofsaude', []);

  const verificaAtendimento = async (agendamentoEscolhido, solicitante) => {
    agendamentosHook.request({
      method: '_listEmergencias',
      sgCliente: user.sgCliente,
      instituicoes: instList,
      filas: filasProf,
    });

    let agendamentoExiste = agendamentosHook.state.filter(
      (a) => a.idConsulta == agendamentoEscolhido
    );

    if (agendamentoExiste[0]?.idConsulta == agendamentoEscolhido) {
      pegarAtendimentoHook.request({
        method: '_confirmarChamado',
        uuid: agendamentoExiste[0]?.uuid,
        psCPF: user.cpf,
        especialidade: especialidade,
        faCodigo: agendamentoExiste[0]?.faCodigo,
      });
      history.push(
        `/medical-center-atendimentos/${agendamentoEscolhido}/${solicitante}/${user.cpf}/`
      );
    } else {
      MySwal.fire({
        title: 'Atendimento',
        html: 'Atendimento ocupado por outro profissional',
        icon: 'error',
        showConfirmButton: true,
        confirmButtonColor: '#ffc012',
      });
    }
  };

  const loadAgendamentos = async (filas, intituicoes) => {
    agendamentosHook.request({
      method: '_listEmergencias',
      sgCliente: user.sgCliente,
      filas: filas,
      instituicoes: intituicoes,
    });
  };

  const buscarFilaseInstis = async () => {
    const requisicaoFilas = await api.post('aswprofissionalsaude', {
      method: 'findPS',
      cpf: user.cpf,
    });
    setEspecialidade(requisicaoFilas?.data?.data.especialidade);
    const dadosLocal = requisicaoFilas?.data?.data.locaisAtendimento;
    const filas = dadosLocal
      .filter((e) => e.filas[0]?.faCodigo != null)
      .map((e) => {
        return e.filas[0]?.faCodigo;
      });
    setFilasProf(filas);

    const instuicoes = requisicaoFilas?.data?.data?.instituicoes;
    const intsValues = instuicoes.map((e) => {
      return e.value;
    });

    loadAgendamentos(filas, intsValues);
    setInstList(intsValues);
  };

  useEffect(() => {
    if (user.sgCliente) {
      buscarFilaseInstis();
    }
  }, [user]);

  useEffect(() => {
    if (filasProf && instList) {
      listarConsultasInterval.current = window.setInterval(
        () => loadAgendamentos(filasProf, instList),
        10000
      );
    }
  }, [instList]);

  return (
    <Box>
      <Box>
        <Heading as="h3" fontSize={'lg'} fontWeight={'normal'}>
          CENTRO MÉDICO
        </Heading>
        <div className="table-responsive mt-5">
          <div id="atendimento-table-list" className="table-list">
            {agendamentosHook.isLoading && <Loading />}
            <Table
              id="atendimento-table-list"
              responsive
              aria-describedby="atendimento-heading"
              className={
                'table-hover table-striped table-responsive-css td-atendimentos'
              }
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="text-left">Hora da Solicitação</th>
                  <th className="text-left">
                    Instituição de Saúde Solicitante
                  </th>
                  <th className="text-left">
                    Profissional de Saúde Solicitante
                  </th>
                  <th className="text-left">Fila Solicitante</th>
                  <th className="text-left">Paciente</th>
                  <th className="text-left">CPF</th>
                  <th className="text-left">Idade</th>
                  <th className="text-left">Gênero</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {agendamentosHook.state.map((atendimento, i) => (
                  <tr key={`entity-${i}`}>
                    <td className="text-left">
                      <b className="visible-xs">Hora da Solicitação</b>
                      {new Intl.DateTimeFormat('pt-BR', {
                        timeStyle: 'medium',
                        timeZone: 'America/Sao_Paulo',
                        // timeZoneName: 'long',
                        dateStyle: 'short',
                      }).format(new Date(atendimento?.dtInclusao))}
                    </td>
                    <td className="text-left">
                      <b className="visible-xs">Instituiçãol de Saúde</b>
                      {atendimento?.sgInstSaude}
                    </td>
                    <td className="text-left">
                      <b className="visible-xs">Profissional de Saúde</b>
                      {atendimento?.psNome}
                    </td>
                    <td className="text-left">
                      <b className="visible-xs">Fila Solicitante</b>
                      {atendimento?.faDescricao}
                    </td>
                    <td className="text-left">
                      <b className="visible-xs">Paciente</b>
                      {atendimento?.nmUsuario
                        ? atendimento?.nmUsuario
                        : 'Não Informado'}
                    </td>
                    <td className="text-left">
                      <b className="visible-xs">CPF</b>
                      {atendimento?.UsuarioCPF}
                    </td>
                    <td className="text-left">
                      <b className="visible-xs">Idade</b>
                      {atendimento?.usuarioNasc &&
                        calcularIdade(atendimento?.usuarioNasc)}
                    </td>
                    <td className="text-left">
                      <b className="visible-xs">Gênero</b>
                      {atendimento?.usuarioGenero?.toUpperCase() == 'F'
                        ? 'Feminino'
                        : 'Masculino'}
                    </td>
                    <td className="text-center">
                      <div className="btn-group flex-btn-group-container">
                        <button
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Ir para triagem"
                          className="btn btn-primary btn-sm radius-50 square-30px"
                          onClick={() => {
                            clearInterval(listarConsultasInterval.current);
                            verificaAtendimento(atendimento?.idConsulta, atendimento?.psCpfSolicitante);
                          }}
                        >
                          <i className="fa fa-play" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </Box>
    </Box>
  );
};
