import React from 'react';
import { Switch } from 'react-router-dom';
import { BASE_API_VERSION_PATH } from 'shared/util';
import ErrorBoundaryRoute from '../../shared/error/error-boundary-route';
import { PerfilMenu } from './_base/perfil-menu';

const apiUrl = BASE_API_VERSION_PATH + 'aswperfilmenu';

export const nPerfilMenu = (props) => PerfilMenu(props).return();

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute path={match.path} component={nPerfilMenu} />
    </Switch>
  </>
);

export default Routes;
