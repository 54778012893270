import env from 'env.json';

export const BASE_API_VERSION_PATH = env.BASE_API;

export const AUTH_TOKEN_KEY = 'AUTH_TOKEN_KEY';
export const AUTH_USER_DATA = 'AUTH_USER_DATA';
export const AUTH_USER_ROUTES = 'AUTH_USER_ROUTES';

export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm';
export const APP_SERVER_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

/*
  prod:

  https://4m3pt6vqjg.execute-api.us-east-1.amazonaws.com/prod/


  -----------------
  dev
  https://15y18czjq8.execute-api.us-east-2.amazonaws.com/ha/
*/
