import {
  Box,
  Button, Checkbox, CheckboxGroup, Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay, SimpleGrid, Stack, Text,
  Textarea
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { IModalCrudDTO } from 'components/crud/dtos/IModalDto';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { api } from 'services/api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { colors } from 'theme/colors';
import * as Yup from 'yup';
import {
  IAlternativaDTO,
  IConfigParamsDTO
} from '../../dtos/IGestaoAlternativa.dto';

import 'react-datepicker/dist/react-datepicker.css';
import { useLocation } from 'react-router-dom';

function ModalCrud({
  dados,
  isOpen,
  onClose,
  configParams,
  originParams,
  handlerChange,
}: IModalCrudDTO<IAlternativaDTO, IConfigParamsDTO>) {
  const search = useLocation().search;
  const requiredFieldText = 'Campo obrigatório*';
  const MySwal = withReactContent(Swal);
  const tipoPergunta = new URLSearchParams(search).get('tipo') == 'M' || new URLSearchParams(search).get('tipo') == 'A' ? true : false;
  const [iniciarSelecionada, setIniciarSelecionada] = useState<boolean>(false);
  const schema = Yup.object().shape({
    qrTexto: Yup.string().required(requiredFieldText),
    qrRespSelecionada: Yup.boolean()
  });
  const {
    register,
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IAlternativaDTO>({
    resolver: yupResolver(schema),
  });
  const cleanFilds = () => {
    setValue('qrTexto', '');
    setIniciarSelecionada(false);
    setValue('qrRespSelecionada', false);
  }

  useEffect(() => {
    if (originParams === null) {
      cleanFilds();
    } else {
      setValue('qrTexto', originParams.qrTexto);
      setIniciarSelecionada(originParams.qrRespSelecionada);
      setValue('qrRespSelecionada', originParams.qrRespSelecionada);
    }
  }, [originParams]);

  const handleFormSubmit = async (params: IAlternativaDTO) => {
    if (originParams) {
      const { data } = await api.post('aswquestionario', {
        method: "updateAnswer",
        qsID: configParams.qsId,
        qpID: configParams.qpId,
        qrID: originParams.qrID,
        qrOrdem: originParams.qrOrdem,
        qrPergAntecessora: originParams.qrPergAntecessora,
        qrPergSucessora: originParams.qrPergSucessora,
        qrTexto: params.qrTexto,
        qrRespSelecionada: iniciarSelecionada == true ? 1 : 0,
      });

      if (data.success == true) {
        cleanFilds();
        handlerChange();
        onClose();
        MySwal.fire({
          title: data?.message || 'Editado com sucesso!',
          icon: 'success',
        });
      } else {
        MySwal.fire({
          title: data?.message || 'Houve um erro ao tentar editar!',
          icon: 'error',
        });
      }
    } else {
      const { data } = await api.post('aswquestionario', {
        method: "createAnswer",
        qsID: configParams.qsId,
        qpID: configParams.qpId,
        qrOrdem: configParams.ultima + 1,
        qrTexto: params.qrTexto,
        qrRespSelecionada: iniciarSelecionada == true ? 1 : 0,
      });

      if (data.success == true) {
        cleanFilds();
        handlerChange();
        onClose();
        MySwal.fire({
          title: data?.message || 'Criado com sucesso!',
          icon: 'success',
        });
      } else {
        MySwal.fire({
          title: data?.message || 'Houve um erro ao tentar criar!',
          icon: 'error',
        });
      }
    }
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size={'4xl'}
    >
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <ModalContent>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <ModalHeader>
            {originParams ? 'Editar' : 'Criar'} alternativa
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid columns={1} gap={4} my={1}>
              <Box experimental_spaceY={2}>
                <Text>Enunciado da alternativa</Text>
                <Textarea
                  name="qpTexto"
                  placeholder="Texto da alternativa"
                  {...register('qrTexto', {
                    /**/
                  })}
                />
                {errors.qrTexto?.message && (
                  <Text color={colors.orange}>{errors.qrTexto?.message}</Text>
                )}
              </Box>
              {tipoPergunta &&
                <Stack>
                  <Controller
                    name="qrRespSelecionada"
                    control={control}
                    render={({ field: { ref, value, ...rest } }) => {
                      return (
                        <CheckboxGroup
                          {...rest}
                          colorScheme="blue"
                        >
                          <Stack spacing={[1, 5]} direction={['column', 'row']}>
                            <Checkbox
                              defaultChecked={iniciarSelecionada}
                              onChange={(e) => {
                                setValue('qrRespSelecionada', !iniciarSelecionada);
                                setIniciarSelecionada(!iniciarSelecionada);
                              }}
                            >
                              Iniciar Selecionada
                            </Checkbox>
                          </Stack>
                        </CheckboxGroup>
                      );
                    }}
                  />
                </Stack>
              }
            </SimpleGrid>
          </ModalBody>
          <Divider color={colors.gray_blue} mt={'1rem'} />
          <ModalFooter>
            <Flex
              gap="2rem"
              justifyContent="space-between"
              flexDirection="row"
              alignItems="center"
              width={'100%'}
            >
              <Button
                variant="outline"
                border="1px"
                color={colors.blue}
                borderColor={colors.blue}
                _hover={{ bg: colors.blue, color: '#fff' }}
                _active={{ bg: colors.textBlue }}
                type="submit"
              >
                {originParams ? 'Editar' : 'Criar'}
              </Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

export { ModalCrud };

