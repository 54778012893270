import dayjs from 'dayjs';

export const convertDateTimeFromServer = (date: any) =>
  date ? dayjs(date).format('YYYY-MM-DDTHH:mm') : null;

export const convertDateTimeToServer = (date: any) =>
  date ? dayjs(date).toDate() : null;

export const displayDefaultDateTime = (date?: Date | string) =>
  new Date(dayjs(date).startOf('day').format('YYYY-MM-DDTHH:mm'));

export const addDay = (date?: Date | string, days = 1) =>
  dayjs(date).add(days, 'day').format() as unknown as Date;

export const dateTimeToScreen = (date: Date) =>
  date ? dayjs(date).format('DD/MM/YY HH:mm') : '';

export const dateToScreen = (date: Date | string) =>
  date ? dayjs(date).format('DD/MM/YYYY') : '';

export const dateTimeToServer = (date: Date) =>
  dayjs(date).format('YYYY-MM-DD HH:mm:ss');

export const dateToServer = (date: Date) => dayjs(date).format('YYYY-MM-DD');

export const estouAtrasado = (date: Date) => dayjs() > dayjs(date);

export const dateIsBefore = (date: Date) => {
  const d1 = dayjs(date);
  const d2 = dayjs(new Date());

  return dayjs(d2).isAfter(dayjs(d1), 'days');
};
