import { forwardRef, Select as SelectCha, Stack } from '@chakra-ui/react';

type SelectProps = {
  data: {
    value: string;
    option: string;
  }[];
  placeholder: string;
  handlerChange?: (props: string) => void;
};

/**
 * @docs
 * - https://chakra-ui.com/docs/components/select/usage
 *
 * @description Observação! Para o uso com react hooks não há necessidade do uso do callback handleChange.
 * O {..rest} (apenas no final) do react-hook toma para si todos os eventos no final.
 *
 * @returns Select
 */
const Select = forwardRef(
  (
    {
      placeholder = 'Selecione uma opção',
      data = [],
      handlerChange,
      ...rest
    }: SelectProps,
    ref
  ) => (
    <SelectCha
      placeholder={placeholder}
      size="md"
      onChange={(event: any) => handlerChange(event.target.value)}
      ref={ref}
      {...rest}
    >
      {data.map((param, key) => (
        <option value={param.value} key={key}>
          {param.option}
        </option>
      ))}
    </SelectCha>
  )
);

export { Select };
