import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AUTH_TOKEN_KEY, AUTH_USER_DATA } from 'shared/util';
import { UserDataType } from 'types/user';

interface IGrupo {
  sgCliente: string;
  sgInstSaude: string;
  grupo: string;
  perfil: string;
  rotas: string[];
}

type User = UserDataType & {
  handleLogout: () => void;
  grupoEscolhido: IGrupo;
};

export const useUser = () => {
  const [user, setUser] = useState<Partial<User>>({});
  const history = useHistory();
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem(AUTH_USER_DATA));
    if (userData) {
      setUser(userData);
    }
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    Cookies.remove(AUTH_TOKEN_KEY);
    history.replace('/login');
    document.location.href = '/login';
  };

  if (user) {
    user.handleLogout = handleLogout;
  }

  return (
    user || {
      handleLogout,
    }
  );
};
