import styled from 'styled-components';

const ScheduleContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0 auto;
  justify-content: center;
  margin: 32px 0;
`;

const TurnsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  margin: 16px auto;
  text-align: center;
`;

const TurnHoursContainer = styled.div`
  margin: 16px auto;
  display: flex;
  text-align: center;

  .hours-container {
    margin: 0 8px;
  }
`;

const ConfirmScheduleContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    border: 1px solid #56a1e1;
    background: transparent;
    color: #56a1e1;
    border-radius: 8px;
    height: 48px;
    padding: 0 16px;
    margin: 32px 0;
    transition: background 175ms ease-in-out;

    &:hover {
      border: 1px solid #56a1e1;
      background: #56a1e1;
      color: #fff;
    }

    &:disabled {
      cursor: not-allowed;
      border: 1px solid #56a1e1;
      filter: opacity(50%);
    }
  }
`;

const ScheduleForm = styled.form``;

export {
  ScheduleContainer,
  TurnsContainer,
  TurnHoursContainer,
  ConfirmScheduleContainer,
  ScheduleForm,
};
