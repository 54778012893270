import OT from '@opentok/client';
import { useEffect, useRef, useState } from 'react';

type SignalMessageType = {
  data: string;
  from: {
    connectionId: string;
  };
};

type MessageType = {
  from: 'mine' | 'theirs';
  message: string;
};

export function useCtrl(apiKey: string, sessionId: string, token: string) {
  const opentok = useRef<OT.Session>();
  const subscriber = useRef<OT.Subscriber>();
  const publisher = useRef<OT.Publisher>();

  const [conectado, setconectado] = useState(true);
  const [audio, setaudio] = useState(true);
  const [video, setvideo] = useState(true);
  const [chat, setchat] = useState(false);
  const [msgTxt, setmsgTxt] = useState('');
  const [messages, setMessages] = useState<MessageType[]>([]);

  function initializeSession() {
    if (!apiKey || !sessionId || !token) {
      return;
    }
    opentok.current = OT.initSession(apiKey, sessionId);

    opentok.current.on('streamCreated', function streamCreated(event) {
      subscriber.current = opentok.current.subscribe(
        event.stream,
        'subscriber',
        {
          insertMode: 'append',
          width: '100%',
          height: '100%',
        },
        (error) => {
          if (error) {
            console.error(
              'There was an error publishing: ',
              error.name,
              error.message
            );
          }
        }
      );
    });
    opentok.current.on('streamDestroyed', function (event) {
      console.log('Stream stopped. Reason: ' + event.reason);
    });
    opentok.current.on('sessionDisconnected', (event) => {
      console.error(
        'You were disconnected from the opentok.current.',
        event.reason
      );
      if (event.reason == 'networkDisconnected') {
        alert('Sua conex�o foi interrompida.');
      }
    });
    //rpz
    opentok.current.on({
      connectionCreated: (event) => {
        //connectionCount++;
        if (
          event.connection.connectionId !=
          opentok.current.connection.connectionId
        ) {
          //console.log('Another client connected. ' + connectionCount + ' total.');
          console.log(
            'Another client connected. ' +
              opentok.current.connection.connectionId
          );
          const divaguardo = document.getElementById('aguardo');
          divaguardo.classList.add('oculto');
          const divdesconectado = document.getElementById('desconectado');
          divdesconectado.classList.add('oculto');
        }
      },
      connectionDestroyed: (event) => {
        //connectionCount--;
        console.log('A client disconnected.');
        const divaguardo = document.getElementById('aguardo');
        divaguardo.classList.remove('oculto');
      },
    });

    publisher.current = OT.initPublisher(
      'publisher',
      {
        insertMode: 'append',
        width: '100%',
        height: '100%',
        name: 'Local',
        style: { buttonDisplayMode: 'off' },
      },
      (initErr) => {
        if (initErr) {
          console.error(
            'There was an error initializing the publisher: ',
            initErr.name,
            initErr.message
          );
          return;
        }
      }
    );

    // Connect to the session
    opentok.current.connect(token, (error) => {
      // If the connection is successful, initialize a publisher and publish to the session
      if (!error) {
        // If the connection is successful, publish the publisher to the session
        opentok.current.publish(
          publisher.current,
          function publishCallback(publishErr) {
            if (publishErr) {
              console.error(
                'There was an error publishing: ',
                publishErr.name,
                publishErr.message
              );
            }
          }
        );
      } else {
        console.error(
          'There was an error connecting to the session: ',
          error.name,
          error.message
        );
      }
    });

    opentok.current.on('signal:msg', (_event) => {
      const event = _event as unknown as SignalMessageType;
      setMessages((prev) => [
        ...prev,
        {
          from:
            event.from.connectionId === opentok.current.connection.connectionId
              ? 'mine'
              : 'theirs',
          message: event.data,
        },
      ]);

      if (!chat) {
        const butchat = document.getElementById('chat');
        butchat.classList.add('chat-aviso');
      }
    });
  }

  const submitMessage = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    opentok.current.signal(
      {
        type: 'msg',
        data: msgTxt,
      },
      (error) => {
        if (error) {
          console.log('Error sending signal:', error.name, error.message);
        } else {
          setmsgTxt('');
        }
      }
    );
  };

  function endCall() {
    if (conectado) {
      opentok.current.disconnect();
      //window.location.href='fim.html';
      setconectado(false);
      const divag = document.getElementById('aguardo');
      divag.classList.add('oculto');
      const divdesconect = document.getElementById('desconectado');
      divdesconect.classList.remove('oculto');
    } else {
      window.location.reload();
      //conectado = true;
    }
    document.getElementById('end').classList.toggle('entrar');
  }

  useEffect(() => {
    if (publisher.current) {
      publisher.current.publishAudio(audio);
    }
  }, [audio]);

  function toggleLocalAudio() {
    document.getElementById('toggleLocalAudio').classList.toggle('muted');
    setaudio((prev) => !prev);
  }

  useEffect(() => {
    if (publisher.current) {
      publisher.current.publishVideo(video);
    }
  }, [video]);

  function toggleLocalVideo() {
    document.getElementById('toggleLocalVideo').classList.toggle('muted');
    setvideo((prev) => !prev);
  }

  function switchVideoCamera() {
    publisher.current.cycleVideo();
  }

  function togglechat() {
    const chatWindow = document.getElementById('chatWindow');
    chatWindow.classList.toggle('active');
    setchat((prev) => !prev);
    const butchat = document.getElementById('chat');
    butchat.classList.remove('chat-aviso');
  }

  function closechat() {
    const chatWindow = document.getElementById('chatWindow');
    chatWindow.classList.remove('active');
    setchat(false);
    const butchat = document.getElementById('chat');
    butchat.classList.remove('chat-aviso');
  }

  useEffect(() => {
    initializeSession();
    return () => {
      if (opentok.current) {
        opentok.current.disconnect();
      }
    };
  }, [apiKey, sessionId, token]);

  return {
    closechat,
    messages,
    submitMessage,
    endCall,
    toggleLocalAudio,
    toggleLocalVideo,
    switchVideoCamera,
    togglechat,
    msgTxt,
    setmsgTxt,
  };
}
