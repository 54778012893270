/* eslint no-console: off */

import cookie from 'js-cookie';
import pick from 'lodash/pick';
import { IPaginationBaseState } from 'react-jhipster';
import { api } from 'services/api';
import { AUTH_TOKEN_KEY } from 'shared/util';

export interface AppProps {
  cacheMetrics: any;
  twoDigitAfterPointFormat: string;
}

export interface IApiRequestProps {
  showMessageOnErro?: boolean;
  body?: {};
  method?: string;
  onSuccess?(replay: {
    data?: any;
    headers?: Headers;
    userAccount?: any;
  }): void;
  onError?(replay: any): void;
}

export type GetServerSideProps = (context: any) => {};

export const nextCookie = (x: any) => {
  const q: any = {};
  q[AUTH_TOKEN_KEY] = cookie.get(AUTH_TOKEN_KEY);
  return q;
};

export const useRouter = () => ({
  base_url: window.location.origin,
  host: window.location.host,
  location: window.location,
  search: window.location.search,
  asPath: window.location.pathname,
  push(link: string) {},
});

export const getFilterFromSelect = (filterValue: any, type?: any) => {
  if (!filterValue) {
    return '';
  } else if (!!filterValue && filterValue.constructor === Object) {
    return filterValue.value;
  } else if (!!filterValue && filterValue.constructor === Array) {
    return filterValue.map((v: any) => v.value).join(',');
  }
  return '';
};

export const getValueRecursive = (
  entity: any,
  fieldPath: any,
  returnOnNull = null,
  returnJoin = ', '
): any => {
  const field = fieldPath.slice(0, 1).pop();

  if (!entity) return returnOnNull;
  if (!fieldPath) return returnOnNull;
  if (fieldPath.length === 0) return returnOnNull;

  if (Array.isArray(entity)) {
    if (fieldPath.length === 1) {
      const _result = entity.map((enti) => enti[field]).join(returnJoin);
      return _result;
    }

    const _result1 = entity
      .map((enti) => {
        return getValueRecursive(
          enti[field],
          fieldPath.slice(1),
          returnOnNull,
          returnJoin
        );
      })
      .join(', ');
    return _result1;
  } else {
    if (fieldPath.length === 1) {
      const result = entity && entity[field] ? entity[field] : returnOnNull;
      return result;
    }
    return entity[field]
      ? getValueRecursive(
          entity[field],
          fieldPath.slice(1),
          returnOnNull,
          returnJoin
        )
      : returnOnNull;
  }
};

export const jsonParse = (raw: string, returnObject = true) => {
  try {
    return JSON.parse(raw);
  } catch (err) {
    if (returnObject) {
      return {};
    }
    return [];
  }
};

export const getFormFromSelect = (filterValue: any, type: string) => {
  if (!!filterValue && filterValue.constructor === Object) {
    return { ...filterValue, id: filterValue.value };
  } else if (!!filterValue && filterValue.constructor === Array) {
    return filterValue.map((v: { value: any }) => ({ ...v, id: v.value }));
  }
  return ['many-to-many', 'one-to-many'].includes(type) ? [] : null;
};

export const getListAxios: any = async (
  endpoint: any,
  filters: any,
  page: any,
  size: any,
  sort: any,
  selectFields = ''
) => {
  filters['page'] = page;
  filters['size'] = size;
  filters['sort'] = sort;

  const res = await api.post(endpoint, {
    method: '_list',
    ...filters,
  });

  const _json = res ? res.data : [];
  return [
    ...(_json['data']
      ? _json['data'].map((v: any) => ({
          ...v,
          id: Math.floor((1 + Math.random()) * 0x10000),
        }))
      : []),
  ];
};

export async function apiFetch(endpoint, options: IApiRequestProps = {}) {
  const successCode = [200, 201, 202];
  const showMessageOnErro =
    options['showMessageOnErro'] === false ? false : true;
  let method = '_list';
  let body = {};
  let onSuccess = (replay) => {};
  let onError = (replay) => {};

  if (options['method']) method = options['method'];

  if (options['body']) body = options['body'];

  if (options['onSuccess']) onSuccess = options['onSuccess'];

  if (options['onError']) onError = options['onError'];
  try {
    const request = await api.post(`${endpoint}`, { method, ...body });
    const data = await request.data;

    const replay = { ...data, status: data?.statusCode || 500 };
    if (
      successCode.includes(request.status) &&
      successCode.includes(data?.statusCode)
    ) {
      onSuccess(replay);
    } else {
      onError(replay);
    }
    return replay;
  } catch (error) {
    // console.info(error)
    return false;
  }
}

export async function apiList(endpoint, options: IApiRequestProps = {}) {
  return await apiFetch(endpoint, { ...options, method: '_list' });
}

export async function apiFind(endpoint, options: IApiRequestProps = {}) {
  return await apiFetch(endpoint, { ...options, method: '_find' });
}

export async function apiInsert(endpoint, options: IApiRequestProps = {}) {
  return await apiFetch(endpoint, { ...options, method: '_insert' });
}

export async function apiUpdate(endpoint, options: IApiRequestProps = {}) {
  return await apiFetch(endpoint, { ...options, method: '_update' });
}

export async function apiDelete(endpoint, options: IApiRequestProps = {}) {
  return await apiFetch(endpoint, { ...options, method: '_delete' });
}

export const showFieldsSelectAsync = (
  entity: any,
  fields: any,
  returnJoin: any = ', '
) => {
  if (returnJoin !== false && returnJoin !== null) {
    return fields
      .split(';')
      .map((field: string) => {
        const _result = getValueRecursive(entity, field.split('.'), null);
        return _result;
      })
      .join(returnJoin);
  } else if (returnJoin === null) {
    const _arrayResult = fields.split(';').map((field: string) => {
      const _result = getValueRecursive(entity, field.split('.'), null);
      return _result;
    });
    if (Array.isArray(_arrayResult)) {
      return _arrayResult && _arrayResult.length > 0 && _arrayResult[0]
        ? (_arrayResult[0] + '').split(', ')
        : [];
    }
    return _arrayResult ? [_arrayResult] : [];
  }
  return fields
    .split(';')
    .map((field: string) => {
      const _result = getValueRecursive(entity, field.split('.'), null);
      return _result;
    })
    .pop();
};

export const sleepFunction = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const checkAnyValueFields = (
  entity: any,
  fields: any,
  value: string,
  equals = true
) => {
  const values = showFieldsSelectAsync(entity, fields, '*+*+*');
  if (values) {
    return equals === values.split('*+*+*').includes(value + '');
  }
  return false;
};

/**
 * Removes fields with an 'id' field that equals ''.
 * This function was created to prevent entities to be sent to
 * the server with an empty id and thus resulting in a 500.
 *
 * @param entity Object to clean.
 */
export const cleanEntity = (entity: any) => {
  const keysToKeep = Object.keys(entity).filter(
    (k) =>
      !(entity[k] instanceof Object) ||
      (entity[k]['id'] !== '' && entity[k]['id'] !== -1)
  );

  return pick(entity, keysToKeep);
};
/**
 * Removes fields with an 'id' field that equals ''.
 * This function was created to prevent entities to be sent to
 * the server with relationship fields with empty an empty id and thus
 * resulting in a 500.
 *
 * @param entity Object to clean.
 */
export const viacepRequest = async (
  cep: string,
  fn: (arg0: {
    cepRequestBairro: any;
    cepRequestCep: any;
    cepRequestComplemento: any;
    cepRequestGia: any;
    cepRequestIbge: any;
    cepRequestLocalidade: any;
    cepRequestLogradouro: any;
    cepRequestUf: any;
    cepRequestUnidade: any;
  }) => void
) => {
  await fetch('https://viacep.com.br/ws/' + cep + '/json/')
    .then((res) => res.json())
    .then(
      (result) => {
        if (!result.erro) {
          fn({
            cepRequestBairro: result.bairro ? result.bairro : '',
            cepRequestCep: result.cep ? result.cep : '',
            cepRequestComplemento: result.complemento ? result.complemento : '',
            cepRequestGia: result.gia ? result.gia : '',
            cepRequestIbge: result.ibge ? result.ibge : '',
            cepRequestLocalidade: result.localidade ? result.localidade : '',
            cepRequestLogradouro: result.logradouro ? result.logradouro : '',
            cepRequestUf: result.uf ? result.uf : '',
            cepRequestUnidade: result.unidade ? result.unidade : '',
          });
        } else {
          fn(null);
        }
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        fn(null);
      }
    );
};

/**
 * Simply map a list of element to a list a object with the element as id.
 *
 * @param idList Elements to map.
 * @returns The list of objects with mapped ids.
 */
export const mapIdList = (idList: ReadonlyArray<any>) =>
  idList
    .filter((entityId: any) => entityId !== '')
    .map((entityId: any) => ({ id: entityId }));

export const overridePaginationStateWithQueryParams = (
  paginationBaseState: IPaginationBaseState,
  locationSearch: string
) => {
  const params = new URLSearchParams(locationSearch);
  const page = params.get('page');
  const sort = params.get('sort');
  if (page && sort) {
    const sortSplit = sort.split(',');
    paginationBaseState.activePage = +page;
    paginationBaseState.sort = sortSplit[0];
    paginationBaseState.order = sortSplit[1];
  }
  return paginationBaseState;
};

export const quillEditorModules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image', 'video'],
    ['clean'],
  ],
  clipboard: {
    matchVisual: false,
  },
};
export const quillEditorFormats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
];

export const prettifyXml = (sourceXml: string) => {
  const xmlDoc = new DOMParser().parseFromString(sourceXml, 'application/xml');
  const xsltDoc = new DOMParser().parseFromString(
    [
      // describes how we want to modify the XML - indent everything
      '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
      '  <xsl:strip-space elements="*"/>',
      '  <xsl:template match="para[content-style][not(text())]">', // change to just text() to strip space in text nodes
      '    <xsl:value-of select="normalize-space(.)"/>',
      '  </xsl:template>',
      '  <xsl:template match="node()|@*">',
      '    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
      '  </xsl:template>',
      '  <xsl:output indent="yes"/>',
      '</xsl:stylesheet>',
    ].join('\n'),
    'application/xml'
  );

  const xsltProcessor = new XSLTProcessor();
  xsltProcessor.importStylesheet(xsltDoc);
  const resultDoc = xsltProcessor.transformToDocument(xmlDoc);
  const resultXml = new XMLSerializer().serializeToString(resultDoc);
  return resultXml;
};

export const trim = (s: string, c: string) => {
  if (c === ']') c = '\\]';
  if (c === '^') c = '\\^';
  if (c === '\\') c = '\\\\';
  return s.replace(new RegExp('^[' + c + ']+|[' + c + ']+$', 'g'), '');
};

export const generateUUID = () => {
  return 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
    .split('')
    .map((v) => (v !== 'x' ? v : Math.floor(Math.random() * 10)))
    .join('');
};

export const unique = <T>(arr: T[]): T[] =>
  arr.filter((el, i, array) => array.indexOf(el) === i);
